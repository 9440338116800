import { Button, Card, Checkbox, Col, Collapse, DatePicker, Form, Input, Modal, Row, Select, Space, Tag, Upload, Spin, Empty, Result, Typography } from 'antd'
import Paragraph from 'antd/lib/typography/Paragraph'
import { ClockCircleOutlined, MinusCircleOutlined, PlusOutlined, AimOutlined, EditOutlined, UploadOutlined } from '@ant-design/icons'
import React, { useEffect, useReducer, useState } from 'react'
import { DefaultEditor } from 'react-simple-wysiwyg'
import { useGetTalentCV, useUpdateCV } from '../../../hooks/api/useTalent'
import { useParams } from 'react-router'
import { nonHookUploader } from '../../../hooks/nonHookUploader'
import { useGetLookup } from '../../../hooks/api/useLookups'
import { generateKey } from '../../../hooks/generateKey'
import { useGetPerson } from '../../../hooks/api/usePerson'
import { useCanEdit } from '../../../hooks/useCanEdit'
import dayjs from 'dayjs'
import { useGetSingleEmplyee } from '../../../hooks/api/useEmployees'
import { TalentProfilePdf } from '../../../components/TalentProfilePdf'
import { makeId } from '../../../hooks/uuid'
import { useDebounce } from '../../../hooks/useDebounce'

const { Panel } = Collapse
const { Option } = Select
const { CheckableTag } = Tag
const { RangePicker } = DatePicker
const { Text, Title } = Typography

function cvReducer (state, action) {
  const { type, payload, field } = action
  switch (type) {
    case 'initialize': {
      return {
        ...state,
        initialized: true,
        ...payload
      }
    }
    case 'field': {
      return {
        ...state,
        hasChanged: true,
        [field]: payload,
        changedFields: {
          ...state.changedFields,
          [field]: payload
        }
      }
    }
    case 'legislativeRequirements': {
      const mutatedPayload = payload.map(item => {
        if (item?.id) {
          return item
        } else {
          return {
            name: item.name,
            id: makeId(5),
            file: {
              imageUrl: item.file.file?.imageUrl,
              name: item.file.file?.name,
              type: item.file.file?.type
            }
          }
        }
      })

      return {
        ...state,
        hasChanged: true,
        legislativeRequirements: mutatedPayload,
        changedFields: {
          ...state.changedFields,
          legislativeRequirements: mutatedPayload
        }
      }
    }
    case 'professionalAssociations': {
      const mutatedPayload = payload.map(item => {
        return {
          name: item.name,
          file: {
            imageUrl: item.file.file.imageUrl,
            name: item.file.file.name,
            type: item.file.file.type
          }
        }
      })

      return {
        ...state,
        hasChanged: true,
        professionalAssociations: mutatedPayload,
        changedFields: {
          ...state.changedFields,
          professionalAssociations: mutatedPayload
        }
      }
    }
    case 'mobility': {
      return {
        ...state,
        mobility: {
          ...state.mobility,
          [field]: payload
        },
        hasChanged: true
      }
    }
    case 'career': {
      return {
        ...state,
        careerHistory: [...state.careerHistory, payload],
        hasChanged: true,
        changedFields: {
          ...state.changedFields,
          careerHistory: [...state.careerHistory, payload]
        }
      }
    }
    case 'career-update': {
      const newArr = state.careerHistory
      newArr[field] = payload

      return {
        ...state,
        careerHistory: newArr,
        hasChanged: true,
        changedFields: {
          ...state.changedFields,
          careerHistory: newArr
        }
      }
    }
    case 'saved': {
      return {
        ...state,
        hasChanged: false
      }
    }
    default: {
      throw new Error(`Unsupported action type: ${type}`)
    }
  }
}

const optionsWithDisabled = [
  { label: 'Spoken', value: 'Spoken' },
  { label: 'Written', value: 'Written' }
]

const tags = [
  'People Management', 'Administrative'
]

export const TalentCV = () => {
  const [state, dispatch] = useReducer(cvReducer, { initialized: false, hasChanged: false, changedFields: {} })
  const debouncedChangedFields = useDebounce(state.changedFields, 1000)

  const [visible, setVisible] = useState(false)
  const [cHist, setCHist] = useState(null)
  const [cHistIndex, setCHistIndex] = useState(null)
  const [preview, setPreview] = useState(false)
  const [selectedTags, setSelectedTags] = useState([])
  const [form] = Form.useForm()
  const { employeeUid } = useParams()

  function handleChange (tag, checked) {
    const nextSelectedTags = checked ? [...selectedTags, tag] : selectedTags.filter(t => t !== tag)
    setSelectedTags(nextSelectedTags)
  }

  // REQS
  const cvQuery = useGetTalentCV({ employeeUid })
  const cvMutation = useUpdateCV()
  const mobLookups = useGetLookup({ group: 'TalentCV', key: 'Mobility' })
  // const langLookups = useGetLookup({ group: 'TalentCV', key: 'Languages' })
  const workLookups = useGetLookup({ group: 'TalentCV', key: 'WorkEnvironmentPreferences' })
  const thungelaValuesLookups = useGetLookup({ group: 'TalentDevelopmentPlan', key: 'ThungelaValues' })
  const personQuery = useGetPerson({ authenticated: true })
  const employeeQuery = useGetSingleEmplyee({ employeeUid })
  const canEdit = useCanEdit(personQuery?.data, 'talentProfile', window.location.pathname)

  // EFFECTS
  useEffect(() => {
    if (!cvQuery.isLoading && !cvQuery.isError) {
      const initialObj = {
        professionalSummary: cvQuery.data?.professionalSummary || '',
        educationDoc: cvQuery.data?.educationDoc || [],
        otherEducationCertifications: cvQuery.data?.otherEducationCertifications || [],
        otherEducationInternalCourses: cvQuery.data?.otherEducationInternalCourses || [],
        mobility: cvQuery.data?.mobility || '',
        mobilityComments: cvQuery.data?.mobilityComments || '',
        languagesDoc: cvQuery.data?.languagesDoc || [],
        skills: cvQuery.data?.skills || [],
        values: cvQuery.data?.values || [],
        interests: cvQuery.data?.interests || [],
        workEnvironmentPreferences: cvQuery.data?.workEnvironmentPreferences || [],
        careerHistory: cvQuery.data?.careerHistory || [],
        legislativeRequirements: cvQuery.data?.legislativeRequirements ? cvQuery.data?.legislativeRequirements.map(x => ({ ...x, id: makeId(5) })) : [],
        professionalAssociations: cvQuery.data?.professionalAssociations || []
      }
      dispatch({ type: 'initialize', payload: initialObj })
    }
    if ((!cvQuery.isLoading && cvQuery.isError) || !cvQuery.data) {
      dispatch({ type: 'initialize' })
    }
    // eslint-disable-next-line
  }, [cvQuery.isLoading, cvQuery.data, cvQuery.isError])

  useEffect(() => {
    async function autoSave () {
      if (debouncedChangedFields && state.hasChanged) {
        await cvMutation.mutateAsync({ employeeUid, data: state.changedFields })
        dispatch({ type: 'saved' })
      }
    }
    autoSave()
    // eslint-disable-next-line
  }, [debouncedChangedFields])
  function checkObjByKeyLengthAndValue (obj, length) {
    if (!obj) return false
    // Check if the object has exactly 4 keys
    if (Object.keys(obj).length !== length) {
      return false
    }

    // Check if each key has a value (is not undefined)
    for (const key in obj) {
      // eslint-disable-next-line
      if (obj.hasOwnProperty(key) && obj[key] === undefined) {
        return false
      }
    }
    return true
  }
  console.log('cHist::: ', cHist)

  return (
    <>
      {!cvQuery.isLoading && state.initialized
        ? (

          <>
            {!cvQuery.isError
              ? (
                <>
                  <Modal
                    visible={visible}
                    title='Edit Career History'
                    okText='Save'
                    cancelText='Cancel'
                    onCancel={() => {
                      setVisible(x => !x)
                      setCHist(null)
                      setCHistIndex(null)
                      form.resetFields()
                    }}
                    okButtonProps={
                      {
                        className: 'bg-blue-400'
                      }
                    }
                    onOk={() => {
                      form
                        .validateFields()
                        .then((values) => {
                          setCHist(null)
                          setCHistIndex(null)
                          if (cHist) {
                            dispatch({ type: 'career-update', field: cHistIndex, payload: { duration: `${dayjs(values.date[0]).year()}-${dayjs(values.date[1]).year()}`, ...values, areasOfExp: selectedTags } })
                          } else {
                            dispatch({ type: 'career', payload: { duration: `${dayjs(values.date[0]).year()}-${dayjs(values.date[1]).year()}`, ...values, areasOfExp: selectedTags } })
                          }
                          setVisible(false)
                          form.resetFields()
                        })
                    }}
                    width='55%'
                  >
                    <Form
                      form={form}
                      layout='vertical'
                      name='form_in_modal'
                      initialValues={cHist ? { ...cHist, date: cHist.duration ? [dayjs(cHist.duration.split('-')[0]), !cHist.duration.split('-')[1].includes('Present') ? dayjs(cHist.duration.split('-')[1]) : dayjs()] : null } : {}}
                    >
                      <Row gutter='32'>
                        <Col flex={12}>
                          <Form.Item
                            name='title'
                            label='Job Title'
                          >
                            <Input />
                          </Form.Item>
                          <Form.Item
                            name='discipline'
                            label='Job Discipline'
                          >
                            <Input />
                          </Form.Item>
                          <Form.Item
                            name='function'
                            label='Business Unit /Function'
                          >
                            <Input />
                          </Form.Item>
                          <Form.Item
                            name='description'
                            label='Job Description'
                          >
                            {/* <TextArea rows={4} /> */}
                            <DefaultEditor />
                          </Form.Item>
                        </Col>
                        <Col flex={12}>
                          <Form.Item name='relevantExperiences' label='Relevant Experiences'>
                            <Input />
                          </Form.Item>
                          <Form.Item label='Areas of Experience'>
                            {tags.map(tag => (
                              <CheckableTag
                                key={tag}
                                checked={selectedTags.indexOf(tag) > -1}
                                onChange={checked => handleChange(tag, checked)}
                              >
                                {tag}
                              </CheckableTag>
                            ))}
                          </Form.Item>
                          <Form.Item name='date' label='Period'>
                            <RangePicker picker='year' />
                          </Form.Item>
                          <Form.Item name='location' label='Location'>
                            <Input />
                          </Form.Item>
                        </Col>
                      </Row>
                    </Form>
                  </Modal>
                  {!employeeQuery.isLoading && employeeQuery.data && (
                    <Row className='px-4' justify='end'>
                      <Button type='primary' className='bg-blue-400' onClick={() => setPreview(true)}>Preview PDF</Button>
                    </Row>
                  )}
                  <TalentProfilePdf preview={preview} setPreview={setPreview} state={state} employeeQuery={employeeQuery} />
                  <Collapse ghost>
                    <Panel header='Professional Summary' key='1' className='testPanel'>
                      <Row justify='space-between' className='operational__card'>
                        <Paragraph className='editable__field w-full' editable={canEdit && { onChange: (e) => dispatch({ type: 'field', field: 'professionalSummary', payload: e }) }}>{state.professionalSummary}</Paragraph>
                      </Row>
                    </Panel>
                    <Panel header='Education' key='2' className='testPanel'>
                      <Row justify='space-between' className='operational__card'>
                        <Title level={5}>School Education</Title>
                        <Form
                          onValuesChange={(_, allValues) => {
                            if (allValues?.educationDoc) {
                              let valid = true
                              allValues?.educationDoc.forEach(doc => {
                                if (!checkObjByKeyLengthAndValue(doc, 4)) {
                                  valid = false
                                }
                              })
                              if (valid) {
                                dispatch({ type: 'field', field: 'educationDoc', payload: allValues?.educationDoc })
                              }
                            }
                          }}
                          onFinish={values => dispatch({ type: 'field', field: 'educationDoc', payload: values.educationDoc })}
                          initialValues={{ educationDoc: state?.educationDoc?.map(x => ({ ...x, yearObtained: dayjs(x?.yearObtained) })) }}
                          autoComplete='off'
                          className='w-full'
                        >
                          <Form.List name='educationDoc'>
                            {(fields, { add, remove }) => (
                              <>
                                {fields.map(({ key, name, fieldKey, ...restField }) => (
                                  <Space key={key} style={{ display: 'flex', marginBottom: 8 }} align='baseline'>
                                    <Form.Item
                                      {...restField}
                                      name={[name, 'gradePassed']}
                                      fieldKey={[fieldKey, 'gradePassed']}
                                      rules={[{ required: true, message: 'Missing Grade Passed' }]}
                                    >
                                      {/* HIGHEST GRADE PASSED */}
                                      <Select disabled={!canEdit} style={{ minWidth: 200 }} placeholder='Highest grade passed'>
                                        <Option value='8th Grade'>8th Grade</Option>
                                        <Option value='9th Grade'>9th Grade</Option>
                                        <Option value='10th Grade'>10th Grade</Option>
                                        <Option value='11th Grade'>11th Grade</Option>
                                        <Option value='Grade 12 Certificate'>Grade 12 Certificate</Option>
                                      </Select>
                                    </Form.Item>
                                    <Form.Item
                                      {...restField}
                                      name={[name, 'yearObtained']}
                                      fieldKey={[fieldKey, 'yearObtained']}
                                    >
                                      <DatePicker disabled={!canEdit} placeholder='Year Obtained' />
                                    </Form.Item>
                                    <Form.Item
                                      {...restField}
                                      name={[name, 'school']}
                                      fieldKey={[fieldKey, 'school']}
                                      rules={[{ required: true, message: 'Missing school' }]}
                                    >
                                      <Input disabled={!canEdit} placeholder='School' />
                                    </Form.Item>
                                    <Form.Item
                                      {...restField}
                                      name={[name, 'location']}
                                      fieldKey={[fieldKey, 'location']}
                                    >
                                      <Input disabled={!canEdit} placeholder='Location' />
                                    </Form.Item>
                                    <MinusCircleOutlined onClick={() => remove(name)} />
                                  </Space>
                                ))}
                                <Form.Item>
                                  <Button disabled={!canEdit} type='dashed' onClick={() => add()} block icon={<PlusOutlined />}>
                                    Add School Education
                                  </Button>
                                </Form.Item>
                              </>
                            )}
                          </Form.List>
                        </Form>

                        <Title level={5}>Tertiary Qualifications</Title>
                        <Form
                          onValuesChange={(_, allValues) => {
                            if (allValues?.otherEducationCertifications) {
                              console.log('allValues?.otherEducationCertifications::: ', allValues?.otherEducationCertifications)
                              let valid = true
                              allValues?.otherEducationCertifications.forEach(doc => {
                                if (!checkObjByKeyLengthAndValue(doc, 4)) {
                                  valid = false
                                }
                              })
                              if (valid) {
                                dispatch({ type: 'field', field: 'otherEducationCertifications', payload: allValues?.otherEducationCertifications })
                              }
                            }
                          }}
                          onFinish={values => dispatch({ type: 'field', field: 'otherEducationCertifications', payload: values.otherEducationCertifications })} initialValues={{ otherEducationCertifications: state?.otherEducationCertifications?.map(x => ({ ...x, yearObtained: dayjs(x?.yearObtained) })) }} autoComplete='off' className='w-full'
                        >
                          <Form.List name='otherEducationCertifications'>
                            {(fields, { add, remove }) => (
                              <>
                                {fields.map(({ key, name, fieldKey, ...restField }) => (
                                  <Space key={key} style={{ display: 'flex', marginBottom: 8 }} align='baseline'>
                                    <Form.Item
                                      {...restField}
                                      name={[name, 'institutionName']}
                                      fieldKey={[fieldKey, 'institutionName']}
                                      rules={[{ required: true, message: 'Missing Institution Name' }]}
                                    >
                                      <Input disabled={!canEdit} placeholder='Institution Name' />
                                    </Form.Item>
                                    <Form.Item
                                      {...restField}
                                      name={[name, 'yearObtained']}
                                      fieldKey={[fieldKey, 'yearObtained']}
                                    >
                                      <DatePicker disabled={!canEdit} placeholder='Year Obtained' />
                                    </Form.Item>
                                    <Form.Item
                                      {...restField}
                                      name={[name, 'degreeDescription']}
                                      fieldKey={[fieldKey, 'degreeDescription']}
                                      rules={[{ required: true, message: 'Missing Degree Description' }]}
                                    >
                                      <Input disabled={!canEdit} placeholder='Degree Description' />
                                    </Form.Item>
                                    <Form.Item
                                      {...restField}
                                      name={[name, 'location']}
                                      fieldKey={[fieldKey, 'location']}
                                    >
                                      <Input disabled={!canEdit} placeholder='Location' />
                                    </Form.Item>
                                    <MinusCircleOutlined onClick={() => remove(name)} />
                                  </Space>
                                ))}
                                <Form.Item>
                                  <Button disabled={!canEdit} type='dashed' onClick={() => add()} block icon={<PlusOutlined />}>
                                    Add Tertiary Qualification
                                  </Button>
                                </Form.Item>
                              </>
                            )}
                          </Form.List>
                        </Form>

                        <Title level={5}>Certifications and Programs</Title>
                        <Form
                          onValuesChange={(_, allValues) => {
                            if (allValues?.otherEducationInternalCourses) {
                              console.log('allValues?.otherEducationInternalCourses::: ', allValues?.otherEducationInternalCourses)
                              let valid = true
                              allValues?.otherEducationInternalCourses.forEach(doc => {
                                if (!checkObjByKeyLengthAndValue(doc, 2)) {
                                  valid = false
                                }
                              })
                              if (valid) {
                                dispatch({ type: 'field', field: 'otherEducationInternalCourses', payload: allValues?.otherEducationInternalCourses })
                              }
                            }
                          }}
                          onFinish={values => dispatch({ type: 'field', field: 'otherEducationInternalCourses', payload: values.otherEducationInternalCourses })} initialValues={{ otherEducationInternalCourses: state?.otherEducationInternalCourses?.map(x => ({ ...x, yearObtained: dayjs(x?.yearObtained) })) }} autoComplete='off' className='w-full'
                        >
                          <Form.List name='otherEducationInternalCourses'>
                            {(fields, { add, remove }) => (
                              <>
                                {fields.map(({ key, name, fieldKey, ...restField }) => (
                                  <Space key={key} style={{ display: 'flex', marginBottom: 8 }} align='baseline'>
                                    <Form.Item
                                      {...restField}
                                      name={[name, 'degreeDescription']}
                                      fieldKey={[fieldKey, 'degreeDescription']}
                                      rules={[{ required: true, message: 'Missing institution Name' }]}
                                    >
                                      <Input disabled={!canEdit} placeholder='Name' />
                                    </Form.Item>
                                    <Form.Item
                                      {...restField}
                                      name={[name, 'yearObtained']}
                                      fieldKey={[fieldKey, 'yearObtained']}
                                    >
                                      <DatePicker disabled={!canEdit} placeholder='Year Obtained' />
                                    </Form.Item>
                                    <MinusCircleOutlined onClick={() => remove(name)} />
                                  </Space>
                                ))}
                                <Form.Item>
                                  <Button disabled={!canEdit} type='dashed' onClick={() => add()} block icon={<PlusOutlined />}>
                                    Add Certifications or Programs
                                  </Button>
                                </Form.Item>
                              </>
                            )}
                          </Form.List>
                        </Form>
                        <Title level={5}>Legal Certificates</Title>
                        <Form
                          onValuesChange={(_, allValues) => {
                            if (allValues?.legislativeRequirements) {
                              console.log('allValues?.legislativeRequirements::: ', allValues?.legislativeRequirements)
                              let valid = true
                              allValues?.legislativeRequirements.forEach(doc => {
                                if (!checkObjByKeyLengthAndValue(doc, 2)) {
                                  valid = false
                                }
                              })
                              if (valid) {
                                dispatch({ type: 'field', field: 'legislativeRequirements', payload: allValues?.legislativeRequirements })
                              }
                            }
                          }}
                          onFinish={values => dispatch({ type: 'legislativeRequirements', payload: values.legislativeRequirements })} initialValues={{ legislativeRequirements: state?.legislativeRequirements }} autoComplete='off' className='w-full'
                        >
                          <Form.List name='legislativeRequirements'>
                            {(fields, { add, remove }) => (
                              <>
                                {fields.map(({ key, name, fieldKey, ...restField }) => (
                                  <Space key={key} style={{ display: 'flex', marginBottom: 8 }} align='baseline'>
                                    <Form.Item
                                      {...restField}
                                      name={[name, 'name']}
                                      fieldKey={[fieldKey, 'name']}
                                      rules={[{ required: true, message: 'Missing Name' }]}
                                    >
                                      <Input disabled={!canEdit} placeholder='Name' />
                                    </Form.Item>
                                    <Form.Item
                                      {...restField}
                                      name={[name, 'file']}
                                      fieldKey={[fieldKey, 'file']}
                                      rules={[{ required: true, message: 'Missing Document' }]}
                                    >
                                      <Upload
                                        disabled={!canEdit}
                                        customRequest={nonHookUploader}
                                        fileList={state.legislativeRequirements[fieldKey]
                                          ? [{
                                              uid: '-1',
                                              name: state.legislativeRequirements[fieldKey]?.file?.name,
                                              status: 'done',
                                              url: state.legislativeRequirements[fieldKey]?.file?.imageUrl
                                            }]
                                          : undefined}
                                      >
                                        <Button icon={<UploadOutlined />}>Click to Upload</Button>
                                      </Upload>
                                    </Form.Item>
                                    <MinusCircleOutlined onClick={() => remove(name)} />
                                  </Space>
                                ))}
                                <Form.Item>
                                  <Button disabled={!canEdit} type='dashed' onClick={() => add()} block icon={<PlusOutlined />}>
                                    Add Legal Certificate
                                  </Button>
                                </Form.Item>
                              </>
                            )}
                          </Form.List>
                        </Form>
                      </Row>
                    </Panel>
                    <Panel header='Mobility' key='4' className='testPanel'>
                      <Row justify='space-between' className='operational__card'>
                        <div className='card__item card__item--spaceBottom'>
                          <p className='caption'>Mobility Status</p>
                          <Select disabled={!canEdit} loading={mobLookups.isLoading} defaultValue='local' value={state.mobility} onChange={e => dispatch({ type: 'field', field: 'mobility', payload: e })} style={{ marginBottom: '1rem', minWidth: 250 }}>
                            {!mobLookups.isLoading && !mobLookups.isError && mobLookups.data && mobLookups.data.map(mob => (
                              <Option key={generateKey(mob)} value={mob}>{mob}</Option>
                            ))}
                          </Select>
                          <p className='caption'>Mobility Comments</p>
                          <Paragraph disabled={!canEdit} className='editable__field' editable={{ onChange: (e) => dispatch({ type: 'field', field: 'mobilityComments', payload: e }) }}>{state.mobilityComments}</Paragraph>
                        </div>
                      </Row>
                    </Panel>
                    <Panel header='Languages' key='5' className='testPanel'>
                      <Row justify='space-between' className='operational__card'>
                        <Form
                          onValuesChange={(_, allValues) => {
                            if (allValues?.languagesDoc) {
                              console.log('allValues?.languagesDoc::: ', allValues?.languagesDoc)
                              let valid = true
                              allValues?.languagesDoc.forEach(doc => {
                                if (!checkObjByKeyLengthAndValue(doc, 2)) {
                                  valid = false
                                }
                              })
                              if (valid) {
                                dispatch({ type: 'field', field: 'languagesDoc', payload: allValues?.languagesDoc })
                              }
                            }
                          }}
                          onFinish={values => dispatch({ type: 'field', field: 'languagesDoc', payload: values.languagesDoc })} initialValues={{ languagesDoc: state?.languagesDoc }} autoComplete='off' className='w-full'
                        >
                          <Form.List name='languagesDoc'>
                            {(fields, { add, remove }) => (
                              <>
                                {fields.map(({ key, name, fieldKey, ...restField }) => (
                                  <Space key={key} style={{ display: 'flex', marginBottom: 8 }} align='baseline'>
                                    <Form.Item
                                      {...restField}
                                      name={[name, 'language']}
                                      fieldKey={[fieldKey, 'language']}
                                      rules={[{ required: true, message: 'Missing language' }]}
                                    >
                                      <Input disabled={!canEdit} placeholder='Enter a language' />
                                      {/* <Select loading={langLookups.isLoading} style={{ marginBottom: '1rem', width: 150 }} placeholder='Select a language'>
                                        {!langLookups.isLoading && !langLookups.isError && langLookups.data && langLookups.data.map(lang => (
                                          <Option key={generateKey(lang)} value={lang}>{lang}</Option>
                                        ))}
                                      </Select> */}
                                    </Form.Item>
                                    <Form.Item
                                      {...restField}
                                      name={[name, 'competency']}
                                      fieldKey={[fieldKey, 'competency']}
                                      rules={[{ required: true, message: 'Missing competency' }]}
                                    >
                                      <Checkbox.Group
                                        disabled={!canEdit}
                                        style={{ marginLeft: '2rem' }}
                                        options={optionsWithDisabled}
                                      />
                                    </Form.Item>
                                    <MinusCircleOutlined onClick={() => remove(name)} />
                                  </Space>
                                ))}
                                <Form.Item>
                                  <Button disabled={!canEdit} type='dashed' onClick={() => add()} block icon={<PlusOutlined />}>
                                    Add Language
                                  </Button>
                                </Form.Item>
                              </>
                            )}
                          </Form.List>
                        </Form>
                      </Row>
                    </Panel>
                    <Panel header='Skills | Values | Interests' key='6' className='testPanel'>
                      <Row>
                        <Card title='Skills' style={{ width: '33.33%' }}>
                          <Form
                            onValuesChange={(_, allValues) => {
                              if (allValues?.skills) {
                                console.log('allValues?.skills::: ', allValues?.skills)
                                let valid = true
                                allValues?.skills.forEach(doc => {
                                  if (!checkObjByKeyLengthAndValue(doc, 1)) {
                                    valid = false
                                  }
                                })
                                if (valid) {
                                  dispatch({ type: 'field', field: 'skills', payload: allValues?.skills })
                                }
                              }
                            }}
                            onFinish={values => dispatch({ type: 'field', field: 'skills', payload: values.skills })} initialValues={{ skills: state?.skills }} autoComplete='off' className='w-full'
                          >
                            <Form.List name='skills'>
                              {(fields, { add, remove }) => (
                                <>
                                  {fields.map(({ key, name, fieldKey, ...restField }) => (
                                    <Space key={key} style={{ display: 'flex', marginBottom: 8 }} align='baseline'>
                                      <Form.Item
                                        {...restField}
                                        name={[name, 'skill']}
                                        fieldKey={[fieldKey, 'skill']}
                                        rules={[{ required: true, message: 'Missing skill' }]}
                                      >
                                        <Input disabled={!canEdit} placeholder='Enter Skill' />
                                      </Form.Item>
                                      <MinusCircleOutlined onClick={() => remove(name)} />
                                    </Space>
                                  ))}
                                  <Form.Item>
                                    <Button disabled={!canEdit} type='dashed' onClick={() => add()} block icon={<PlusOutlined />}>
                                      Add Skill
                                    </Button>
                                  </Form.Item>
                                </>
                              )}
                            </Form.List>
                          </Form>
                        </Card>
                        <Card title='Values' style={{ width: '33.33%' }}>
                          <Form
                            onValuesChange={(_, allValues) => {
                              if (allValues?.values) {
                                console.log('allValues?.values::: ', allValues?.values)
                                let valid = true
                                allValues?.values.forEach(doc => {
                                  if (!checkObjByKeyLengthAndValue(doc, 1)) {
                                    valid = false
                                  }
                                })
                                if (valid) {
                                  dispatch({ type: 'field', field: 'values', payload: allValues?.values })
                                }
                              }
                            }}
                            onFinish={values => dispatch({ type: 'field', field: 'values', payload: values.values })} initialValues={{ values: state?.values }} autoComplete='off' className='w-full'
                          >
                            <Form.List name='values'>
                              {(fields, { add, remove }) => (
                                <>
                                  {fields.map(({ key, name, fieldKey, ...restField }) => (
                                    <Space key={key} style={{ display: 'flex', marginBottom: 8 }} align='baseline'>
                                      <Form.Item
                                        {...restField}
                                        name={[name, 'value']}
                                        fieldKey={[fieldKey, 'value']}
                                        rules={[{ required: true, message: 'Missing value' }]}
                                      >
                                        {/* <Input placeholder='Enter Value' /> */}
                                        <Select style={{ width: 175 }} loading={thungelaValuesLookups.isLoading} disabled={!canEdit || thungelaValuesLookups.isError || !thungelaValuesLookups.data}>
                                          {!thungelaValuesLookups.isLoading && thungelaValuesLookups.data && thungelaValuesLookups.data.map(option => (
                                            <Option key={option} value={option}>{option}</Option>
                                          ))}
                                        </Select>
                                      </Form.Item>
                                      <MinusCircleOutlined onClick={() => remove(name)} />
                                    </Space>
                                  ))}
                                  <Form.Item>
                                    <Button disabled={!canEdit} type='dashed' onClick={() => add()} block icon={<PlusOutlined />}>
                                      Add Value
                                    </Button>
                                  </Form.Item>
                                </>
                              )}
                            </Form.List>
                          </Form>
                        </Card>
                        <Card title='Interests' style={{ width: '33.33%' }}>
                          <Form
                            onValuesChange={(_, allValues) => {
                              if (allValues?.interests) {
                                console.log('allValues?.interests::: ', allValues?.interests)
                                let valid = true
                                allValues?.interests.forEach(doc => {
                                  if (!checkObjByKeyLengthAndValue(doc, 1)) {
                                    valid = false
                                  }
                                })
                                if (valid) {
                                  dispatch({ type: 'field', field: 'interests', payload: allValues?.interests })
                                }
                              }
                            }}
                            onFinish={values => dispatch({ type: 'field', field: 'interests', payload: values.interests })} initialValues={{ interests: state?.interests }} autoComplete='off' className='w-full'
                          >
                            <Form.List name='interests'>
                              {(fields, { add, remove }) => (
                                <>
                                  {fields.map(({ key, name, fieldKey, ...restField }) => (
                                    <Space key={key} style={{ display: 'flex', marginBottom: 8 }} align='baseline'>
                                      <Form.Item
                                        {...restField}
                                        name={[name, 'interest']}
                                        fieldKey={[fieldKey, 'interest']}
                                        rules={[{ required: true, message: 'Missing interest' }]}
                                      >
                                        <Input disabled={!canEdit} placeholder='Enter Interest' />
                                      </Form.Item>
                                      <MinusCircleOutlined onClick={() => remove(name)} />
                                    </Space>
                                  ))}
                                  <Form.Item>
                                    <Button disabled={!canEdit} type='dashed' onClick={() => add()} block icon={<PlusOutlined />}>
                                      Add Interest
                                    </Button>
                                  </Form.Item>
                                </>
                              )}
                            </Form.List>
                          </Form>
                        </Card>
                      </Row>
                    </Panel>
                    <Panel header='Work Environment Preferences' key='7' className='testPanel'>
                      <Row justify='space-between' className='operational__card'>
                        <div className='card__item card__item--spaceBottom'>
                          <p className='caption'>Preference</p>
                          <Select disabled={!canEdit} mode='multiple' allowClear loading={workLookups.isLoading} placeholder='Work Environment Preferences' onChange={e => dispatch({ type: 'field', field: 'workEnvironmentPreferences', payload: e })} style={{ width: '40%' }} value={state.workEnvironmentPreferences}>
                            <Option value='Production Role'>Production Role</Option>
                            <Option value='Support Role'>Support Role</Option>
                            <Option value='Operation Based Role'>Operation Based Role</Option>
                            <Option value='Central Based role'>Central Based role</Option>
                            <Option value='Individual Contributor Role'>Individual Contributor Role</Option>
                            <Option value='Manager Role'>Manager Role</Option>
                          </Select>
                        </div>
                      </Row>
                    </Panel>
                    <Panel header='Career History' key='8' className='testPanel'>
                      {state.careerHistory && state.careerHistory.length
                        ? (
                          <>
                            {state.careerHistory.map((job, i) => (
                              <Card
                                key={i}
                                title={<>{job.title}: <Tag style={{ marginLeft: '1rem' }} icon={<ClockCircleOutlined />} color='#fdb933'>{job.duration}</Tag></>}
                                extra={
                                  <Button
                                    onClick={() => {
                                      setVisible(x => !x)
                                      setCHist(job)
                                      setCHistIndex(i)
                                      form.setFieldsValue({ ...job, date: job.duration ? [dayjs(job.duration.split('-')[0]), !job.duration.split('-')[1].includes('Present') ? dayjs(job.duration.split('-')[1]) : dayjs()] : null })
                                    }}
                                    type='primary' className='bg-blue-400' shape='circle' icon={<EditOutlined />} size='small'
                                  />
                                      }
                                className='mb-8'
                              >
                                <div className='flex gap-6 justify-between items-center'>
                                  <div className='flex flex-col w-8/12'>
                                    <div className='mb-4' dangerouslySetInnerHTML={{ __html: job.description }} />
                                    <Text className='uppercase' style={{ fontSize: '0.625rem' }} type='secondary'>Areas of Experience</Text>
                                    <Row>
                                      {job.areasOfExp.map((item, i) => (
                                        <Col className='mb-4' key={i} xs={6}>
                                          <Tag color='#108ee9'>{item}</Tag>
                                        </Col>
                                      ))}
                                    </Row>
                                    <Text className='mb-2 uppercase' type='secondary'><AimOutlined /> {job.location}</Text>
                                  </div>
                                  <div className='w-4/12 flex flex-col' direction='vertical'>
                                    <Text type='secondary' style={{ textTransform: 'uppercase', fontSize: '0.625rem' }}>Discipline</Text>
                                    <Text className='mb-2'>{job.discipline}</Text>
                                    <Text type='secondary' style={{ textTransform: 'uppercase', fontSize: '0.625rem' }}>Business Unit / Function</Text>
                                    <Text>{job.function}</Text>
                                  </div>
                                </div>
                              </Card>
                            ))}
                          </>

                          )
                        : (
                          <Empty />
                          )}
                      <Button style={{ marginTop: '1rem' }} onClick={() => setVisible(x => !x)} type='primary' className='bg-blue-400'>Add career experience</Button>
                    </Panel>
                    <Panel header='Professional Associations' key='10' className='testPanel'>
                      <Row justify='space-between' className='operational__card'>
                        <Form
                          onValuesChange={(_, allValues) => {
                            if (allValues?.professionalAssociations) {
                              console.log('allValues?.professionalAssociations::: ', allValues?.professionalAssociations)
                              let valid = true
                              allValues?.professionalAssociations.forEach(doc => {
                                if (!checkObjByKeyLengthAndValue(doc, 2)) {
                                  valid = false
                                }
                              })
                              if (valid) {
                                dispatch({ type: 'field', field: 'professionalAssociations', payload: allValues?.professionalAssociations })
                              }
                            }
                          }}
                          onFinish={values => dispatch({ type: 'professionalAssociations', payload: values.professionalAssociations })} initialValues={{ professionalAssociations: state?.professionalAssociations }} autoComplete='off' className='w-full'
                        >
                          <Form.List name='professionalAssociations'>
                            {(fields, { add, remove }) => (
                              <>
                                {fields.map(({ key, name, fieldKey, ...restField }) => (
                                  <Space key={key} style={{ display: 'flex', marginBottom: 8 }} align='baseline'>
                                    <Form.Item
                                      {...restField}
                                      name={[name, 'name']}
                                      fieldKey={[fieldKey, 'name']}
                                      rules={[{ required: true, message: 'Missing Name' }]}
                                    >
                                      <Input placeholder='Name' />
                                    </Form.Item>
                                    <Form.Item
                                      {...restField}
                                      name={[name, 'file']}
                                      fieldKey={[fieldKey, 'file']}
                                      rules={[{ required: true, message: 'Missing Document' }]}
                                    >
                                      <Upload
                                        customRequest={nonHookUploader}
                                        fileList={state.professionalAssociations[fieldKey]
                                          ? [{
                                              uid: '-1',
                                              name: state.professionalAssociations[fieldKey]?.file?.name,
                                              status: 'done',
                                              url: state.professionalAssociations[fieldKey]?.file?.imageUrl
                                            }]
                                          : undefined}
                                      >
                                        <Button icon={<UploadOutlined />}>Click to Upload</Button>
                                      </Upload>
                                    </Form.Item>
                                    <MinusCircleOutlined onClick={() => remove(name)} />
                                  </Space>
                                ))}
                                <Form.Item>
                                  <Button disabled={!canEdit} type='dashed' onClick={() => add()} block icon={<PlusOutlined />}>
                                    Add Professional Association
                                  </Button>
                                </Form.Item>
                              </>
                            )}
                          </Form.List>
                        </Form>
                      </Row>
                    </Panel>
                  </Collapse>

                  {/* {state.hasChanged && (
                    <Affix style={{ position: 'absolute', bottom: 65, right: 30 }} offsetBottom={65}>
                      <Button
                        loading={saving}
                        onClick={async () => {
                          setSaving(true)
                          await cvMutation.mutateAsync({ employeeUid, data: state.changedFields })
                          dispatch({ type: 'saved' })
                          setSaving(false)
                        }}
                        type='primary' className='bg-blue-400'
                        shape='round'
                        size='large'
                        icon={<SaveOutlined />}
                      >
                        {saving ? 'Saving' : 'Save'} Changes
                      </Button>
                    </Affix>
                  )} */}
                </>
                )
              : (
                <Result
                  status='warning'
                  title='We are unable to fetch this Talent CV right now'
                />
                )}
          </>

          )
        : (
          <div className='w-full flex items-center justify-center py-24'>
            <Spin size='large' />
          </div>
          )}

    </>

  )
}
