import { PDFExport } from '@progress/kendo-react-pdf'
import { Button, Card, Col, Divider, Modal, Row, Space, Table, Tag, Typography } from 'antd'
import React, { useRef } from 'react'
import { ProfileCard } from './ProfileCard'
import dayjs from 'dayjs'
import { talentProfileTable } from '../constants/talentProfileTable'
import { PdfHeader } from './PdfHeader'

const { Text } = Typography

export const TalentProfilePdf = ({ state, employeeQuery, preview, setPreview }) => {
  const exportPDFWithComponent = () => {
    if (pdfExportComponent.current) {
      pdfExportComponent.current.save()
    }
  }
  const pdfExportComponent = useRef(null)

  return (
    <Modal
      visible={preview}
      okText='Export as PDF'
      title='Talent Profile PDF'
      onCancel={() => setPreview(x => !x)}
      footer={[
        <Button key='cancel' onClick={() => setPreview(x => !x)}>Cancel</Button>,
        <Button onClick={exportPDFWithComponent} key='submit' type='primary' className='bg-blue-400'>Export as PDF</Button>
      ]}
      width='90%'
    >
      <PDFExport margin='2cm' fileName={`Talent-Profile-${employeeQuery.data.person.firstName + ' ' + employeeQuery.data.person.lastName}-${dayjs().format('DD/MM/YYYY')}`} ref={pdfExportComponent}>
        <div className='p-4 relative' style={{ fontWeight: 'initial', fontVariant: 'initial', lineHeight: 'initial' }}>
          <PdfHeader title='Talent Profile PDF' />
          {/* <div className='p-2 rounded-md w-fit absolute z-50 top-2 right-2' style={{ backgroundColor: '#333333' }}><img src='/images/thungela.png' alt='brand logo' className='h-8 object-cover' /></div> */}
          <ProfileCard person={employeeQuery.data} />
          <Divider />
          {state.professionalSummary && (
            <>
              <div className='mb-12'>
                <h3 className='text-lg font-bold mb-2'>Professional Summary</h3>
                <Text>{state.professionalSummary}</Text>
              </div>
              <Divider />
            </>
          )}

          {state?.educationDoc?.length
            ? (
              <>
                <h3 className='text-lg font-bold mb-2'>School</h3>
                <Table className='mb-12' size='small' bordered pagination={false} columns={talentProfileTable.school.columns} dataSource={state?.educationDoc?.map(x => ({ ...x, yearObtained: dayjs(x?.yearObtained).format('DD/MM/YYYY') }))} />
              </>
              )
            : null}

          {state?.otherEducationCertifications?.length
            ? (
              <>
                <h3 className='text-lg font-bold mb-2'>Tertiary Qualifications</h3>
                <Table className='mb-12' size='small' bordered pagination={false} columns={talentProfileTable.tertiary.columns} dataSource={state?.otherEducationCertifications?.map(x => ({ ...x, yearObtained: dayjs(x?.yearObtained).format('DD/MM/YYYY') }))} />
              </>
              )
            : null}

          {state?.otherEducationInternalCourses?.length
            ? (
              <>
                <h3 className='text-lg font-bold mb-2'>Certifications and Programs</h3>
                <Table className='mb-12' size='small' bordered pagination={false} columns={talentProfileTable.certs.columns} dataSource={state?.otherEducationInternalCourses?.map(x => ({ ...x, yearObtained: dayjs(x?.yearObtained).format('DD/MM/YYYY') }))} />
              </>
              )
            : null}

          {state?.legislativeRequirements?.length
            ? (
              <>
                <h3 className='text-lg font-bold mb-2'>Legal Certificates</h3>
                <Table className='mb-12' size='small' bordered pagination={false} columns={talentProfileTable.legal.columns} dataSource={state?.legislativeRequirements} />
              </>
              )
            : null}

          {state.mobility && state.mobilityComments && (
            <>
              <h3 className='text-lg font-bold mb-2'>Mobility</h3>
              <Table
                className='mb-12'
                size='small'
                bordered
                pagination={false}
                columns={talentProfileTable.mobility.columns}
                dataSource={[
                  {
                    key: 1,
                    mobility: state?.mobility || '',
                    mobilityComments: state?.mobilityComments || ''
                  }
                ]}
              />
            </>
          )}

          {state?.languagesDoc?.length
            ? (
              <>
                <h3 className='text-lg font-bold mb-2'>Languages</h3>
                <Table
                  className='mb-12'
                  size='small'
                  bordered
                  pagination={false}
                  columns={talentProfileTable.languages.columns}
                  dataSource={state?.languagesDoc?.map(({ language, competency }) => {
                    const competencyMap = {
                      Spoken: false,
                      Written: false
                    }
                    competency.forEach(c => {
                      competencyMap[c] = true
                    })
                    return {
                      language,
                      Spoken: competencyMap.Spoken ? 'Yes' : 'No',
                      Written: competencyMap.Written ? 'Yes' : 'No'
                    }
                  })}
                />
              </>
              )
            : null}

          {state?.skills?.length > 0 && state?.values?.length > 0 && state?.interests?.length > 0 && (
            <>
              <h3 className='text-lg font-bold mb-2'>Skills | Values | Interests</h3>
              <Row className='mb-12'>
                {state?.skills.length > 0 && (

                  <Card title='Skills' style={{ width: '33.33%' }}>
                    <ul>
                      {state?.skills.map((skill, i) => (
                        <li key={`${skill.skill}-${i}`}>{skill.skill}</li>
                      ))}
                    </ul>
                  </Card>
                )}
                {state?.values.length > 0 && (

                  <Card title='Values' style={{ width: '33.33%' }}>
                    <ul>
                      {state?.values.map((value, i) => (
                        <li key={`${value.value}-${i}`}>{value.value}</li>
                      ))}
                    </ul>
                  </Card>
                )}
                {state?.interests.length > 0 && (
                  <Card title='Interests' style={{ width: '33.33%' }}>
                    <ul>
                      {state?.interests.map((int, i) => (
                        <li key={`${int.interest}-${i}`}>{int.interest}</li>
                      ))}
                    </ul>
                  </Card>
                )}
              </Row>

            </>
          )}

          {state.workEnvironmentPreferences?.length
            ? (
              <>
                <h3 className='text-lg font-bold mb-2'>Work Environment Preferences</h3>
                {state.workEnvironmentPreferences.map((pref, i) => (
                  <Space className='mb-12' key={`${pref}-${i}}`}>
                    <Tag>{pref}</Tag>
                  </Space>
                ))}
              </>
              )
            : null}

          {state.careerHistory?.length
            ? (
              <>
                <h3 className='text-lg font-bold mb-2'>Career History</h3>
                <Table className='mb-12' size='small' bordered pagination={false} columns={talentProfileTable.hixtory.columns} dataSource={state.careerHistory} />
              </>
              )
            : null}

          {state?.professionalAssociations?.length
            ? (
              <>
                <h3 className='text-lg font-bold mb-2'>Professional Associations</h3>
                {state?.professionalAssociations.map((asc, i) => (
                  <Row gutter={['16']} justify='space-between' key={JSON.stringify(asc)}>
                    <Col className='mb-4' span={8}>
                      <p className='caption'>Name</p>
                      {asc.name}
                    </Col>
                    <Divider />
                  </Row>
                ))}
              </>
              )
            : null}
        </div>

      </PDFExport>
    </Modal>
  )
}
