import { Divider, Typography } from 'antd'
import React from 'react'

export const PdfHeader = ({ title }) => {
  return (
    <>
      <div class='flex w-full justify-between items-center mb-4'>
        <Typography.Title style={{ margin: 0 }} level={3}>{title}</Typography.Title>
        <div className='p-2 rounded-md w-fit' style={{ backgroundColor: '#333333' }}><img src='/images/thungela.png' alt='brand logo' className='h-8 object-cover' /></div>
      </div>
      <Divider />
    </>
  )
}
