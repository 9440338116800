import { Spin } from 'antd'
import React from 'react'
import { BrowserRouter as Router, Redirect, Route, Switch } from 'react-router-dom'
import { Navbar } from './components/Navbar'
import { Signin } from './views/auth/Signin'
import { Employee } from './views/employee/Employee'
import { Employees } from './views/Employees'
import { Reporting } from './views/reporting/Reporting'
import { ReportingItem } from './views/reporting/ReportingItem'
import { Succession } from './views/succession/Succession'
import { SuccessionRole } from './views/succession/SuccessionRole'
import { Worklist } from './views/worklist/Worklist'
import { WorklistByFilter } from './views/worklist/WorklistByFilter'
import { useAuthIdToken } from '@react-query-firebase/auth'
import { auth } from './firebase'
import { SignInEmail } from './views/auth/SignInEmail'
import { PMWorklist } from './views/pm-worklist/PMWorklist'
import { Disciplines } from './views/succession/Disciplines'

function App () {
  // eslint-disable-next-line
  const tokenResult = useAuthIdToken(['token'], auth)

  return (
    <Router>
      <div className='app' id='app'>
        {tokenResult.data && (<Navbar />)}
        {tokenResult.isLoading
          ? (
            <div className='w-full h-48 flex items-center justify-center'>
              <Spin />
            </div>
            )
          : (
            <Switch>
              <>
                <Route exact path='/auth/signin' component={Signin} />
                <Route exact path='/auth/signin/email' component={SignInEmail} />
                <div className='container mx-auto'>
                  {tokenResult.data
                    ? (
                      <>
                        <Route exact path='/employees' component={Employees} />
                        <Route exact path='/employees/:employeeUid' component={Employee} />
                        <Route exact path='/tm-worklist' component={Worklist} />
                        <Route exact path='/pm-worklist' component={PMWorklist} />
                        <Route exact path='/tm-worklist/:type' component={WorklistByFilter} />
                        <Route exact path='/succession-planner' component={Disciplines} />
                        <Route exact path='/succession-planner/:discipline' component={Succession} />
                        <Route exact path='/succession-planner/:discipline/:role/:type?' component={SuccessionRole} />
                        <Route exact path='/reporting' component={Reporting} />
                        <Route exact path='/reporting/:type' component={ReportingItem} />
                        <Route exact path='/'>
                          <Redirect to='/employees' />
                        </Route>
                      </>
                      )
                    : (
                      <>
                        <Route path='*'>
                          {window.location.pathname.includes('email')
                            ? <Redirect to='/auth/signin/email' />
                            : <Redirect to='/auth/signin' />}
                        </Route>
                      </>
                      )}
                </div>
              </>
            </Switch>
            )}
      </div>
    </Router>
  )
}

export default App
