import React, { useEffect, useReducer, useState } from 'react'
import { Affix, Button, Col, Collapse, DatePicker, Divider, Form, Input, message, Progress, Radio, Result, Row, Select, Space, Spin, Typography } from 'antd'
import Paragraph from 'antd/lib/typography/Paragraph'
import Title from 'antd/lib/typography/Title'
import { SaveOutlined, MinusCircleOutlined, PlusOutlined } from '@ant-design/icons'
import { useGetTalentID, useUpdateTalentID } from '../../../hooks/api/useTalentID'
import { useParams } from 'react-router'
import dayjs from 'dayjs'
import { useGetLookup } from '../../../hooks/api/useLookups'
import { generateKey } from '../../../hooks/generateKey'
import Checkbox from 'antd/lib/checkbox/Checkbox'
import { useQueryClient } from 'react-query'
import axios from 'axios'
import { DebouncedSelect } from '../../../components/DebouncedSelect'
import Text from 'antd/lib/typography/Text'
import { useGetSingleEmplyee } from '../../../hooks/api/useEmployees'

const { Option } = Select
const { Panel } = Collapse

function talentIDReducer (state, action) {
  const { type, payload, field } = action
  switch (type) {
    case 'initialize': {
      return {
        ...state,
        initialized: true,
        ...payload
      }
    }
    case 'field-discipline': {
      return {
        ...state,
        hasChanged: true,
        talentDisciplineReview: {
          ...state.talentDisciplineReview,
          [field]: payload
        },
        changedFields: {
          ...state.changedFields,
          talentDisciplineReview: {
            ...state.changedFields.talentDisciplineReview,
            [field]: payload
          }
        }
      }
    }
    case 'movementsToDelete': {
      return {
        ...state,
        talentOperationalMovementsToDelete: payload,
        changedFields: {
          ...state.changedFields,
          talentOperationalMovementsToDelete: payload
        }
      }
    }
    case 'field-operation': {
      return {
        ...state,
        hasChanged: true,
        talentOperationalReview: {
          ...state.talentOperationalReview,
          [field]: payload
        },
        changedFields: {
          ...state.changedFields,
          talentOperationalReview: {
            ...state.changedFields.talentOperationalReview,
            [field]: payload
          }
        }
      }
    }
    case 'field': {
      return {
        ...state,
        hasChanged: true,
        [field]: payload,
        changedFields: {
          ...state.changedFields,
          [field]: payload
        }
      }
    }
    case 'role': {
      return {
        ...state,
        role: payload
      }
    }
    case 'saved': {
      return {
        ...state,
        hasChanged: false
      }
    }
    default: {
      throw new Error(`Unsupported action type: ${type}`)
    }
  }
}

export const TalentID = () => {
  // const [personResp, setPersonResp] = React.useState([])
  const queryClient = useQueryClient()

  // CONSTANTS
  const { employeeUid } = useParams()

  // STATE
  const [state, dispatch] = useReducer(talentIDReducer, { initialized: false, hasChanged: false, changedFields: {} })
  const [saving, setSaving] = useState(false)
  const [nextLookupsList, setNextLookupsList] = useState([])
  const currentYear = dayjs().year()
  const [year, setYear] = useState(dayjs().year())
  const [moveForm] = Form.useForm()
  const [actionForm] = Form.useForm()
  const [showFields, setShowFields] = useState(false)

  // NETWORK
  const employeeQuery = useGetSingleEmplyee({ employeeUid })
  const talentIDQuery = useGetTalentID({ employeeUid, year })
  const talentIDMutation = useUpdateTalentID()
  const aopLookups = useGetLookup({ group: 'TalentOverview', key: 'AssessmentOfPotential' })
  const skillLookups = useGetLookup({ group: 'TalentOverview', key: 'SkillDevelopmentNeeds' })
  const upLookups = useGetLookup({ group: 'TalentOverview', key: 'UltimatePotential' })
  const promLookups = useGetLookup({ group: 'TalentOverview', key: 'Promotability' })
  const poolLookups = useGetLookup({ group: 'TalentOverview', key: 'TalentPoolLevel' })
  const nextLookups = useGetLookup({ group: 'Dynamic', key: 'JobTitle' })
  const opLookups = useGetLookup({ group: 'TalentOverview', key: 'OperationalReviewNomination' })
  const lowLookups = useGetLookup({ group: 'Dynamic', key: 'LevelOfWork' })

  useEffect(() => {
    if (!talentIDQuery.isLoading) {
      let initialObj = {}
      if (talentIDQuery.data) {
        initialObj = {
          // ...person.talentID,
          talentOperationalReview: {
            assessmentOfPotential: talentIDQuery.data.talentOperationalReview?.assessmentOfPotential || '',
            skillDevelopmentNeeds: talentIDQuery.data.talentOperationalReview?.skillDevelopmentNeeds || '',
            nominatedFor: talentIDQuery.data.talentOperationalReview?.nominatedFor || '',
            careerActionsDoc: talentIDQuery.data.talentOperationalReview?.careerActionsDoc?.map(x => ({ ...x, date: dayjs(x?.date) })) || [],
            talentReviewComments: talentIDQuery.data.talentOperationalReview?.talentReviewComments || '',
            managersComments: talentIDQuery.data.talentOperationalReview?.managersComments || '',
            managerOnceRemovedComments: talentIDQuery.data.talentOperationalReview?.managerOnceRemovedComments || '',
            talentPoolLevel: talentIDQuery.data.talentOperationalReview?.talentPoolLevel || '',
            isFlightRisk: talentIDQuery.data.talentOperationalReview?.isFlightRisk || false
          },
          talentDisciplineReview: {
            assessmentOfPotential: talentIDQuery.data.talentDisciplineReview?.assessmentOfPotential || '',
            ultimatePotential: talentIDQuery.data.talentDisciplineReview?.ultimatePotential || '',
            promotability: talentIDQuery.data.talentDisciplineReview?.promotability || '',
            nextMove: talentIDQuery.data.talentDisciplineReview?.nextMove || '',
            action: talentIDQuery.data.talentDisciplineReview?.action || '',
            reviewComments: talentIDQuery.data.talentDisciplineReview?.reviewComments || '',
            mpaPotential: talentIDQuery.data.talentDisciplineReview?.mpaPotential || ''
          },
          talentOperationalMovements: talentIDQuery.data?.talentOperationalMovements || [],
          talentOperationalMovementsToDelete: [],
          role: ''
        }
      }
      if (talentIDQuery.data.talentDisciplineReview?.promotability || talentIDQuery.data.talentDisciplineReview?.nextMove || talentIDQuery.data.talentDisciplineReview?.action) setShowFields(true)
      dispatch({ type: 'initialize', payload: initialObj })
      moveForm.setFieldsValue({ talentOperationalMovements: initialObj.talentOperationalMovements })
      actionForm.setFieldsValue({ careerActionsDoc: initialObj.talentOperationalReview.careerActionsDoc })
    }
    // eslint-disable-next-line
  }, [talentIDQuery.isLoading, talentIDQuery.data, talentIDQuery.isError])

  useEffect(() => {
    if (!nextLookups.isLoading && nextLookups.data) {
      setNextLookupsList(nextLookups.data)
    }
  }, [nextLookups.isLoading, nextLookups.data])

  if (talentIDQuery.isError) {
    return (
      <Result
        status='warning'
        title='We are unable to fetch talent identification right now'
      />
    )
  }

  async function fetchUserList (name) {
    return axios.get(
      `${process.env.REACT_APP_API_URL}/talent/employees?q=${name}`,
      {
        headers: {
          Authorization: `Bearer ${queryClient.getQueryData('token')?.token ?? ''}`,
          'X-Tenant-Id': process.env.REACT_APP_TENANT_ID
        }
      }
    ).then(res => {
      return res.data.data.map(person => ({
        label: person.person.firstName + ' ' + person.person.lastName,
        value: person.person.firstName + ' ' + person.person.lastName
      }))
    })
  }

  return (
    <>
      {!talentIDQuery.isLoading && state.initialized
        ? (
          <>
            <Row className='px-4' justify='space-between'>
              <Title level={2}>Talent Overview</Title>
              <Space>
                <Text>Year: </Text>
                <Select onChange={value => setYear(value)} defaultValue={year} style={{ width: 120 }}>
                  <Option value={currentYear}>{currentYear}</Option>
                  <Option value={currentYear - 1}>{currentYear - 1}</Option>
                  <Option value={currentYear - 2}>{currentYear - 2}</Option>
                  <Option value={currentYear - 3}>{currentYear - 3}</Option>
                  <Option value={currentYear - 4}>{currentYear - 4}</Option>
                </Select>
              </Space>
            </Row>
            <Row className='px-4' justify='space-between'>
              <Col flex={1}>
                <p className='caption mb-4'>Assesment of Potential</p>
                <Progress className='mt-2' format={() => <p className='text-xs p-3 text-center'>{state.talentOperationalReview.assessmentOfPotential}</p>} type='circle' percent={100} />
              </Col>
              <Col flex={1}>
                <p className='caption mb-4'>Talent Pool</p>
                <Progress className='mt-2' type='circle' format={() => <p className='text-xs p-3 text-center'>{state.talentOperationalReview.talentPoolLevel}</p>} percent={100} />
              </Col>
              <Col flex={1}>
                <p className='caption mb-4'>PM Rating</p>
                <Progress className='mt-2' type='circle' format={() => <p className='text-xs p-3 text-center'>{talentIDQuery?.data?.talentPerformance?.rating?.descriptor}</p>} percent={100} />
                {/* {(() => {
                    const levelOfWork = parseInt(employeeQuery.data.talentCompanyPerson?.levelOfWork?.split(' ')[1])
                    const assessmentOfPotential = state.talentOperationalReview.assessmentOfPotential.replace(/[^0-9]/g, '')
                    const pot = (assessmentOfPotential.length) ? parseInt(assessmentOfPotential) : 0

                    if (Number.isInteger(levelOfWork) && Number.isInteger(pot)) {
                      return <Progress className='mt-2' type='circle' format={() => `${levelOfWork + pot}`} percent={100} />
                    } else {
                      return <Progress className='mt-2' type='circle' format={() => ''} percent={100} />
                    }
                  })()} */}
              </Col>

              <Col flex={1}>
                <p className='caption mb-4'>MPA Current</p>
                <Progress className='mt-2' type='circle' format={() => state.talentDisciplineReview.ultimatePotential?.split(' ')[1]} percent={100} />
              </Col>

              <Col flex={1}>
                <p className='caption mb-4'>MPA Potential</p>
                {(() => {
                  const levelOfWork = parseInt(employeeQuery.data.talentCompanyPerson?.levelOfWork?.split(' ')[1])
                  const assessmentOfPotential = state.talentOperationalReview.assessmentOfPotential.replace(/[^0-9]/g, '')
                  const pot = (assessmentOfPotential.length) ? parseInt(assessmentOfPotential) : 0
                  const lowPot = levelOfWork + pot
                  const mpa = parseInt(state.talentDisciplineReview.mpaPotential?.split(' ')[1]) || '-'

                  return <Progress className='mt-2' type='circle' status={(mpa > lowPot) ? 'normal' : (mpa < lowPot) ? 'exception' : 'success'} strokeColor={(mpa > lowPot) ? 'orange' : (mpa < lowPot) ? '#ff4d4f' : '#52c41a'} format={() => mpa} percent={100} />
                })()}

              </Col>
              {/* <Col flex={1}>
                <p className='caption mb-4'>Performance Rating</p>
                <Progress className='mt-2' type='circle' format={() => '5'} percent={100} />
              </Col> */}
            </Row>
            <Collapse ghost defaultActiveKey={['2', '3']}>
              <Panel header='Operational Review' key='2'>
                <Row justify='space-between' className='operational__card'>
                  <Title level={4} style={{ width: '100%', marginBottom: '1rem' }}>Operational Review</Title>
                  <div className='card__item'>
                    <p className='caption'>Assesement of Potential</p>
                    <Select loading={aopLookups.isLoading} onChange={e => dispatch({ type: 'field-operation', field: 'assessmentOfPotential', payload: e })} value={state.talentOperationalReview.assessmentOfPotential} defaultValue={state.talentOperationalReview.assessmentOfPotential} style={{ width: '88%' }}>
                      {!aopLookups.isLoading && !aopLookups.isError && aopLookups.data && aopLookups.data.map(aop => (
                        <Option key={generateKey(aop)} value={aop}>{aop}</Option>
                      ))}
                    </Select>
                  </div>
                  <div className='card__item'>
                    <p className='caption'>Skill Development Needs</p>
                    <Select mode='multiple' loading={skillLookups.isLoading} onChange={e => dispatch({ type: 'field-operation', field: 'skillDevelopmentNeeds', payload: e })} defaultValue={Array.isArray(state.talentOperationalReview.skillDevelopmentNeeds) ? state.talentOperationalReview.skillDevelopmentNeeds : [...state.talentOperationalReview.skillDevelopmentNeeds || []]} value={Array.isArray(state.talentOperationalReview.skillDevelopmentNeeds) ? state.talentOperationalReview.skillDevelopmentNeeds : [...state.talentOperationalReview.skillDevelopmentNeeds || []]} style={{ width: '88%' }}>
                      {!skillLookups.isLoading && !skillLookups.isError && skillLookups.data && skillLookups.data.map(skill => (
                        <Option key={generateKey(skill)} value={skill}>{skill}</Option>
                      ))}
                    </Select>
                  </div>

                  <div className='card__item'>
                    <p className='caption'>Leadership Programs</p>
                    {/* opLookups */}
                    <Select loading={opLookups.isLoading} onChange={e => dispatch({ type: 'field-operation', field: 'nominatedFor', payload: e })} value={state.talentOperationalReview.nominatedFor} defaultValue={state.talentOperationalReview.nominatedFor} style={{ width: '88%' }}>
                      {!opLookups.isLoading && !opLookups.isError && opLookups.data && opLookups.data.map(op => (
                        <Option key={generateKey(op)} value={op}>{op}</Option>
                      ))}
                    </Select>
                  </div>
                  <div className='card__item'>
                    <p className='caption'>Talent Pool</p>
                    {/* opLookups */}
                    <Select loading={poolLookups.isLoading} onChange={e => dispatch({ type: 'field-operation', field: 'talentPoolLevel', payload: e })} value={state.talentOperationalReview.talentPoolLevel} defaultValue={state.talentOperationalReview.talentPoolLevel} style={{ width: '88%' }}>
                      {!poolLookups.isLoading && !poolLookups.isError && poolLookups.data && poolLookups.data.map(op => (
                        <Option key={generateKey(op)} value={op}>{op}</Option>
                      ))}
                    </Select>
                  </div>
                  <Checkbox className='mt-4' checked={state.talentOperationalReview.isFlightRisk} onChange={e => dispatch({ type: 'field-operation', field: 'isFlightRisk', payload: e.target.checked })}>Flight Risk</Checkbox>
                  <Divider />
                  <Title level={4} style={{ width: '100%', marginBottom: '1rem' }}>Talent Identification</Title>
                  <p className='text-xs my-2 uppercase font-bold'>Succession movements</p>
                  <Form form={moveForm} className='w-full' onFinish={values => dispatch({ type: 'field', field: 'talentOperationalMovements', payload: values.talentOperationalMovements })} initialValues={{ talentOperationalMovements: state?.talentOperationalMovements }} autoComplete='off'>
                    <Form.List name='talentOperationalMovements'>
                      {(fields, { add, remove }) => (
                        <>
                          {fields.map(({ key, name, fieldKey, ...restField }, i) => (
                            <Space key={key} style={{ display: 'flex', marginBottom: 8 }} align='baseline'>
                              <Form.Item
                                {...restField}
                                name={[name, 'movementType']}
                                fieldKey={[fieldKey, 'movementType']}
                                rules={[{ required: true, message: 'Missing type' }]}
                              >
                                <Radio.Group>
                                  <Radio value='promotion'>Promotion</Radio>
                                  <Radio value='linearMove'>Lateral Move</Radio>
                                </Radio.Group>
                              </Form.Item>
                              <Form.Item
                                {...restField}
                                name={[name, 'time']}
                                fieldKey={[fieldKey, 'time']}
                              >
                                <Select loading={promLookups.isLoading} placeholder='Timeline' style={{ minWidth: 200 }}>
                                  {!promLookups.isLoading && !promLookups.isError && promLookups.data && promLookups.data.map(prom => (
                                    <Option key={generateKey(prom)} value={prom}>{prom}</Option>
                                  ))}
                                </Select>
                              </Form.Item>
                              <Form.Item
                                {...restField}
                                name={[name, 'nextMove']}
                                fieldKey={[fieldKey, 'nextMove']}
                              >
                                <Select
                                  loading={nextLookups.isLoading}
                                  placeholder='Next Move'
                                  style={{ minWidth: 250 }}
                                  showSearch
                                  filterOption={(input, option) => option.value.toLowerCase().indexOf(input.toLowerCase()) >= 0}
                                  dropdownRender={menu => (
                                    <>
                                      {menu}
                                      <Divider style={{ margin: '8px 0' }} />
                                      <Space align='center' style={{ padding: '0 8px 4px' }}>
                                        <Input placeholder='Please enter item' value={state.role} onChange={(e) => dispatch({ type: 'role', payload: e.target.value })} />
                                        <Typography.Link
                                          onClick={(e) => {
                                            if (state.role !== '') {
                                              setNextLookupsList([state.role, ...nextLookupsList])
                                              dispatch({ type: 'role', payload: '' })
                                            } else {
                                              message.error('Field cannot be empty')
                                            }
                                          }}
                                          style={{ whiteSpace: 'nowrap' }}
                                        >
                                          <PlusOutlined /> Add item
                                        </Typography.Link>
                                      </Space>
                                    </>
                                  )}
                                >
                                  {!nextLookups.isLoading && !nextLookups.isError && nextLookups.data && nextLookupsList.map(next => (
                                    <Option key={generateKey(next)} value={next}>{next}</Option>
                                  ))}
                                </Select>
                              </Form.Item>
                              <MinusCircleOutlined onClick={() => {
                                const itemToDel = state.talentOperationalMovements[i]

                                if (itemToDel?.companyPersonUid) {
                                  dispatch({ type: 'movementsToDelete', payload: [...state.talentOperationalMovementsToDelete, itemToDel] })
                                }

                                remove(name)
                              }}
                              />
                            </Space>
                          ))}
                          <Form.Item>
                            <Button type='dashed' onClick={() => add()} block icon={<PlusOutlined />}>
                              Add Movement
                            </Button>
                          </Form.Item>
                        </>
                      )}
                    </Form.List>
                    <Form.Item>
                      <Button type='primary' className='bg-blue-400' htmlType='submit'>
                        Save
                      </Button>
                    </Form.Item>
                  </Form>
                  <Divider />
                  <p className='text-xs my-2 uppercase font-bold'>Actions</p>
                  <Form form={actionForm} className='w-full' onFinish={values => dispatch({ type: 'field-operation', field: 'careerActionsDoc', payload: values.careerActionsDoc })} initialValues={{ careerActionsDoc: state?.talentOperationalReview?.careerActionsDoc.map(x => ({ ...x, date: dayjs(x?.date) })) }} autoComplete='off'>
                    <Form.List name='careerActionsDoc'>
                      {(fields, { add, remove }) => (
                        <>
                          {fields.map(({ key, name, fieldKey, ...restField }) => (
                            // <Space key={key} style={{ display: 'flex', alignItems: 'center', marginBottom: 8 }} align='baseline'>
                            <div key={key} className='flex items-center gap-8'>
                              {/* <p className='caption'>Career Action</p> */}
                              <Form.Item
                                {...restField}
                                name={[name, 'action']}
                                fieldKey={[fieldKey, 'action']}
                                className='w-1/3'
                              >

                                <Input placeholder='Career Action' />
                              </Form.Item>
                              {/* <p className='caption'>Due Date</p> */}
                              <Form.Item
                                {...restField}
                                name={[name, 'date']}
                                fieldKey={[fieldKey, 'date']}
                                className='w-1/3'
                              >
                                <DatePicker placeholder='Due Date' style={{ width: '100%' }} />
                              </Form.Item>
                              {/* <p className='caption'>Person Responsible</p> */}
                              <Form.Item
                                {...restField}
                                name={[name, 'responsibility']}
                                fieldKey={[fieldKey, 'responsibility']}
                                className='w-1/3'
                              >
                                <DebouncedSelect
                                  // mode='multiple'
                                  showSearch
                                  placeholder='Person Responsible'
                                  fetchOptions={fetchUserList}
                                />

                              </Form.Item>
                              <MinusCircleOutlined onClick={() => remove(name)} />
                            </div>
                            // </Space>
                          ))}
                          <Form.Item>
                            <Button type='dashed' onClick={() => add()} block icon={<PlusOutlined />}>
                              Add Action
                            </Button>
                          </Form.Item>
                        </>
                      )}
                    </Form.List>
                    <Form.Item>
                      <Button type='primary' className='bg-blue-400' htmlType='submit'>
                        Save
                      </Button>
                    </Form.Item>
                  </Form>
                  <Divider />
                  <Title level={4} style={{ width: '100%', marginBottom: '1rem' }}>Comments</Title>
                  <div className='card__item card__item--spaceBottom'>
                    <p className='caption'>Talent Review Comments</p>
                    <Paragraph placeholder='Testing placeholder' className='editable__field' editable={{ onChange: (e) => dispatch({ type: 'field-operation', field: 'talentReviewComments', payload: e }) }}>{state?.talentOperationalReview?.talentReviewComments}</Paragraph>
                  </div>
                  <div className='card__item card__item--spaceBottom'>
                    <p className='caption'>Manager’s Comments</p>
                    <Paragraph className='editable__field' editable={{ onChange: (e) => dispatch({ type: 'field-operation', field: 'managersComments', payload: e }) }}>{state?.talentOperationalReview?.managersComments}</Paragraph>
                  </div>
                  <div className='card__item card__item--spaceBottom'>
                    <p className='caption'>MoR’s  Comments</p>
                    <Paragraph className='editable__field' editable={{ onChange: (e) => dispatch({ type: 'field-operation', field: 'managerOnceRemovedComments', payload: e }) }}>{state?.talentOperationalReview?.managerOnceRemovedComments}</Paragraph>
                  </div>
                </Row>

              </Panel>
              <Panel header='Discipline Review' key='3'>
                <Row justify='space-between' className='operational__card'>
                  <Title level={4} style={{ width: '100%', marginBottom: '1rem' }}>Discipline Review</Title>
                  <div className='card__item'>
                    <p className='caption'>Assesement of Potential</p>
                    <Select loading={aopLookups.isLoading} value={state.talentDisciplineReview.assessmentOfPotential} defaultValue={state.talentDisciplineReview.assessmentOfPotential} onChange={e => dispatch({ type: 'field-discipline', field: 'assessmentOfPotential', payload: e })} style={{ minWidth: '85%' }}>
                      {/* {!lowLookups.isLoading && lowLookups.data && lowLookups.data.map(option => (
                        <Option key={option} value={option}>{option}</Option>
                      ))} */}
                      {!aopLookups.isLoading && !aopLookups.isError && aopLookups.data && aopLookups.data.map(aop => (
                        <Option key={generateKey(aop)} value={aop}>{aop}</Option>
                      ))}
                    </Select>
                  </div>
                  <div className='card__item'>
                    <p className='caption'>MPA Current</p>
                    <Select loading={upLookups.isLoading} value={state.talentDisciplineReview.ultimatePotential} defaultValue={state.talentDisciplineReview.ultimatePotential} onChange={e => dispatch({ type: 'field-discipline', field: 'ultimatePotential', payload: e })} style={{ minWidth: '85%' }}>
                      {!lowLookups.isLoading && lowLookups.data && lowLookups.data.map(option => (
                        <Option key={option} value={option}>{option}</Option>
                      ))}
                    </Select>
                  </div>
                  <div className='card__item'>
                    <p className='caption'>MPA Potential</p>
                    <Select loading={lowLookups.isLoading} value={state.talentDisciplineReview.mpaPotential} defaultValue={state.talentDisciplineReview.mpaPotential} onChange={e => dispatch({ type: 'field-discipline', field: 'mpaPotential', payload: e })} style={{ minWidth: '85%' }}>
                      {!lowLookups.isLoading && lowLookups.data && lowLookups.data.map(option => (
                        <Option key={option} value={option}>{option}</Option>
                      ))}
                    </Select>
                    {/* <Select loading={mpaLookups.isLoading} value={state.talentDisciplineReview.mpaPotential} defaultValue={state.talentDisciplineReview.mpaPotential} onChange={e => dispatch({ type: 'field-discipline', field: 'mpaPotential', payload: e })} style={{ minWidth: '85%' }}>
                      {!mpaLookups.isLoading && !mpaLookups.isError && mpaLookups.data && mpaLookups.data.map(up => (
                        <Option key={generateKey(up)} value={up}>{up}</Option>
                      ))}
                    </Select> */}
                  </div>
                  <Divider />

                  {showFields
                    ? (
                      <>
                        <div className='card__item'>
                          <p className='caption'>Promotability</p>
                          <Select loading={promLookups.isLoading} value={state.talentDisciplineReview.promotability} defaultValue={state.talentDisciplineReview.promotability} onChange={e => dispatch({ type: 'field-discipline', field: 'promotability', payload: e })} style={{ minWidth: '85%' }}>
                            {!promLookups.isLoading && !promLookups.isError && promLookups.data && promLookups.data.map(prom => (
                              <Option key={generateKey(prom)} value={prom}>{prom}</Option>
                            ))}
                          </Select>
                        </div>
                        <div className='card__item'>
                          <p className='caption'>Next Move</p>
                          <Select loading={nextLookups.isLoading} value={state.talentDisciplineReview.nextMove} defaultValue={state.talentDisciplineReview.nextMove} onChange={e => dispatch({ type: 'field-discipline', field: 'nextMove', payload: e })} style={{ minWidth: '85%' }}>
                            {!nextLookups.isLoading && !nextLookups.isError && nextLookups.data && nextLookups.data.map(next => (
                              <Option key={generateKey(next)} value={next}>{next}</Option>
                            ))}
                          </Select>
                        </div>
                        <div className='card__item'>
                          <p className='caption'>Action</p>
                          <Paragraph editable={{ onChange: (e) => dispatch({ type: 'field-discipline', field: 'action', payload: e }) }}>{state.talentDisciplineReview.action}</Paragraph>
                        </div>
                        <Button
                          className='mt-6'
                          onClick={() => {
                            dispatch({ type: 'field-discipline', field: 'promotability', payload: null })
                            dispatch({ type: 'field-discipline', field: 'nextMove', payload: null })
                            dispatch({ type: 'field-discipline', field: 'action', payload: null })
                            setShowFields(false)
                          }}
                          block
                          type='dashed'
                        >
                          Remove Promotability
                        </Button>
                      </>
                      )
                    : <Button onClick={() => setShowFields(true)} block type='dashed'>Add Promotability</Button>}

                  <Divider />
                  <div className='card__item card__item--spaceBottom'>
                    <p className='caption'>Discipline Review Comments</p>
                    <Paragraph className='editable__field' editable={{ onChange: (e) => dispatch({ type: 'field-discipline', field: 'reviewComments', payload: e }) }}>{state.talentDisciplineReview.reviewComments}</Paragraph>
                  </div>
                </Row>
              </Panel>
            </Collapse>
            {state.hasChanged && (
              <Affix style={{ position: 'absolute', bottom: 65, right: 30 }} offsetBottom={65}>
                <Button
                  loading={saving}
                  onClick={async () => {
                    setSaving(true)
                    await talentIDMutation.mutateAsync({ employeeUid, year, data: state.changedFields })
                    setSaving(false)
                    dispatch({ type: 'saved' })
                  }}
                  type='primary' className='bg-blue-400'
                  shape='round'
                  size='large'
                  icon={<SaveOutlined />}
                >
                  {saving ? 'Saving' : 'Save'} Changes
                </Button>
              </Affix>
            )}
          </>
          )
        : (
          <div className='w-full flex items-center justify-center py-24'>
            <Spin size='large' />
          </div>
          )}
    </>
  )
}
