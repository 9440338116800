import { Button, Card, Comment, Divider, Space, Tag } from 'antd'
import dayjs from 'dayjs'
import React from 'react'

export const ProfileCard = ({ person, isPdf }) => {
  console.log(person)
  return (
    <Card>
      <Comment
        author={person.person.firstName + ' ' + person.person.lastName}
        avatar={person.person.imageUrl || `https://avatars.dicebear.com/api/initials/${person.person.firstName[0]}.svg`}
        content={`${person.employment.jobTitle} | ${dayjs(person.attributes?.timeInRole ? person.attributes?.timeInRole : person.employment?.startDate).from(dayjs(), true)}`}
      />
      <Space align='center'>
        <Tag color='orange'>{person?.talentCompanyPerson?.jobLocation}</Tag>
        <Tag color='red'>{person?.attributes?.department}</Tag>
        <Tag color='blue'>{person?.attributes?.GRFSubDisciplineName}</Tag>
      </Space>
      <Divider />
      <div className='flex w-full'>
        <div className='w-1/2  flex flex-col gap-2'>
          <div className='flex w-full gap-2'>
            <p className='w-1/4 text-gray-400'>LoW: </p><p>{person.talentCompanyPerson?.levelOfWork?.split(' ')[1] || 'NA'}</p>
          </div>
          <div className='flex w-full gap-2'>
            <p className='w-1/4 text-gray-400'>Band: </p><p>{person.talentCompanyPerson?.payScaleGroup || 'NA'}</p>
          </div>
          <div className='flex w-full gap-2'>
            <p className='w-1/4 text-gray-400'>Email: </p><p>{person.email || 'Unset'}</p>
          </div>
          <div className='flex w-full gap-2'>
            {!isPdf && (<><p className='w-1/4 text-gray-400'>Gender: </p><p>{person.sex || 'Unset'}</p></>)}
          </div>
          <div className='flex w-full gap-2'>
            {!isPdf && (<><p className='w-1/4 text-gray-400'>Race: </p><p>{person.race || 'Unset'}</p></>)}
          </div>
        </div>
        <div className='w-1/2 flex flex-col gap-2'>
          <div className='flex w-full gap-2'>
            {!isPdf && (<><p className='w-1/4 text-gray-400'>Age: </p><p>{dayjs().diff(dayjs(person?.dateOfBirth), 'years')}</p></>)}
          </div>
          <div className='flex w-full gap-2'>
            {!isPdf && (<><p className='w-1/4 text-gray-400'>Date of hire: </p><p>{person?.employment?.startDate ? dayjs(person?.employment?.startDate).format('DD/MM/YYYY') : 'Unset'}</p></>)}
          </div>
          <div className='flex w-full gap-2'>
            {!isPdf && (<><p className='w-1/4 text-gray-400'>Years to retirement: </p><p>{(person?.attributes?.retirementDate) ? dayjs(person?.attributes?.retirementDate).diff(dayjs(), 'years') : 60 - dayjs().diff(dayjs(person?.dateOfBirth), 'years')}</p></>)}
            {/* {!isPdf && (<><p className='w-1/4 text-gray-400'>Years to retirement: </p><p>{60 - dayjs().diff(dayjs(person?.dateOfBirth), 'years')}</p></>)} */}
          </div>
          <div className='flex w-full gap-2'>
            <p className='w-1/4 text-gray-400'>Manager: </p><p><Button className='p-0' href={person.employment?.jobManager?.companyPerson?.uid} size='small' type='link'>{person.employment?.jobManager?.companyPerson?.person?.firstName} {person.employment?.jobManager?.companyPerson?.person?.lastName}</Button></p>
          </div>
          <div className='flex w-full gap-2'>
            <p className='w-1/4 text-gray-400'>MoR: </p><p><Button className='p-0' href={person.employment?.jobManager?.jobManager?.companyPerson?.uid} type='link' size='small'>{person.employment?.jobManager?.jobManager?.companyPerson?.person ? `${person.employment?.jobManager?.jobManager?.companyPerson?.person?.firstName} ${person.employment?.jobManager?.jobManager?.companyPerson?.person?.lastName}` : 'None'}</Button></p>
          </div>

        </div>
      </div>
    </Card>
  )
}
