import { Button, Dropdown, Input, PageHeader, Select, Space, Table, Tabs, Upload } from 'antd'
import dayjs from 'dayjs'
import React, { useMemo, useReducer, useRef, useState } from 'react'
import { EditableCell, EditableRow } from '../../components/EditableCell'
import { ExportMenu } from '../../components/ExportMenu'
import { TagFactory } from '../../components/TagFactory'
import { useCreatePerformanceContract } from '../../hooks/api/usePerformance'
import { useGetPMWorklist, useUploadCSV } from '../../hooks/api/useWorklist'
import { useSimpleExporter } from '../../hooks/useExporter'
import { useQueryParam } from '../../hooks/useQueryParam'
import { makeId } from '../../hooks/uuid'
import { SearchOutlined, UploadOutlined } from '@ant-design/icons'
import { useGetPerson } from '../../hooks/api/usePerson'

const { TabPane } = Tabs

const pmWorklistReducer = (state, action) => {
  const { type, field, payload } = action
  switch (type) {
    case 'initialize': {
      return {
        ...state,
        ...payload,
        initialized: true
      }
    }
    case 'field': {
      return {
        ...state,
        [field]: payload
      }
    }
    default: {
      throw new Error(`Unsupported action type: ${type}`)
    }
  }
}

export const PMWorklist = () => {
  const [state, dispatch] = useReducer(pmWorklistReducer, {
    initialized: false,
    daily: [],
    yearEnd: [],
    calibration: [],
    searchText: '',
    searchedColumn: '',
    year: null
  })

  const contractMutation = useCreatePerformanceContract('Successfully updated calibration')
  const worklistQuery = useGetPMWorklist({ year: state?.year })
  const queryParam = useQueryParam()
  const csvMutation = useUploadCSV()
  const availTabs = ['daily', 'calibration']
  const personQuery = useGetPerson({ authenticated: true })
  const [execute, setExecute] = useState(false)
  const [type, setType] = useState('csv')
  const [tableEl, setTableEl] = useState(null)
  const [name, setName] = useState(null)
  const [loading, setLoading] = useState(false)
  const currentYear = dayjs().year()

  const dailyTableRef = useRef(null)
  const calibrationTableRef = useRef(null)
  const yearEndTableRef = useRef(null)
  const searchInput = useRef(null)
  const isHRManager = personQuery?.data?.companyPerson?.admin?.roles?.includes('talent.HRManager')

  function handleTabsChange (key) {
    const newUrl = window.location.protocol + '//' + window.location.host + window.location.pathname + '?tab=' + key
    window.history.pushState({ path: newUrl }, '', newUrl)
  }

  const handleSearch = (selectedKeys, confirm, dataIndex) => {
    confirm()
    dispatch({ type: 'field', field: 'searchText', payload: selectedKeys[0] })
    dispatch({ type: 'field', field: 'searchedColumn', payload: dataIndex })
  }

  const handleReset = (clearFilters) => {
    clearFilters()
    dispatch({ type: 'field', field: 'searchText', payload: '' })
  }

  const getColumnSearchProps = (dataIndex) => ({
    filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => (
      <div
        style={{
          padding: 8
        }}
      >
        <Input
          ref={searchInput}
          placeholder={`Search ${dataIndex}`}
          value={selectedKeys[0]}
          onChange={(e) => setSelectedKeys(e.target.value ? [e.target.value] : [])}
          onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
          style={{
            marginBottom: 8,
            display: 'block'
          }}
        />
        <Space>
          <Button
            type='primary'
            onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
            className='bg-blue-400'
            size='small'
            style={{
              width: 90
            }}
          >
            Search
          </Button>
          <Button
            onClick={() => clearFilters && handleReset(clearFilters)}
            size='small'
            style={{
              width: 90
            }}
          >
            Reset
          </Button>
          <Button
            type='link'
            size='small'
            onClick={() => {
              confirm({
                closeDropdown: false
              })
              dispatch({ type: 'field', field: 'searchText', payload: selectedKeys[0] })
              dispatch({ type: 'field', field: 'searchedColumn', payload: dataIndex })
            }}
          >
            Filter
          </Button>
        </Space>
      </div>
    ),
    filterIcon: (filtered) => (
      <SearchOutlined
        style={{
          color: filtered ? '#1890ff' : undefined
        }}
      />
    ),
    onFilter: (value, record) =>
      record[dataIndex].toString().toLowerCase().includes(value.toLowerCase()),
    onFilterDropdownOpenChange: (visible) => {
      if (visible) {
        setTimeout(() => searchInput.current?.select(), 100)
      }
    }
  })

  const handleSave = async (row) => {
    const newData = [...state?.calibration]
    const index = newData.findIndex((item) => row.key === item.key)
    const item = { ...newData[index], ...row }

    newData.splice(index, 1, item)
    if (item) {
      setLoading(true)
      await contractMutation.mutate({
        year: item?._year,
        employeeUid: item?._companyPersonUid,
        data: {
          rating: {
            valueContributor: item?.['Values Demonstrated'] === 'Yes',
            manager: item?.['Manager - Calculated Rating'],
            ops: item?.['Operational Rating'],
            discipline: item?.['Discipline Rating'],
            company: item?.['Company Rating'],
            final: item?.['Final Rating'],
            descriptor: item?.['Rating Descriptor'],
            operationalReasonForAdjustment: item?.['Operational Reason for Adjustment'],
            disciplineReasonForAdjustment: item?.['Discipline Reason for Adjustment'],
            companyReasonForAdjustment: item?.['Company Reason for Adjustment']
          }
        }
      }, {
        onSettled: () => {
          setLoading(false)
        }
      })
    }
  }

  const generateHeaders = (obj) => {
    const headers = []
    if (obj) {
      Object?.keys(obj).map(key => {
        if (!key.includes('_')) {
          const DEFAULTS = {
            title: key,
            dataIndex: key,
            key: key,
            width: '10vw',
            ellipsis: true
          }
          switch (key) {
            case 'Snapshot 1':
            case 'Snapshot 2':
            case 'Contract Status':
              headers.push({
                ...DEFAULTS,
                sorter: (a, b) => a?.[key].length - b?.[key].length,
                sortDirections: ['descend', 'ascend'],
                render: (tag) => {
                  return (
                    <TagFactory status={tag}>{tag}</TagFactory>
                  )
                }
              })
              break
            case 'Operational Rating':
            case 'Discipline Rating':
            case 'Company Rating':
              headers.push({
                ...DEFAULTS,
                editble: true,
                onCell: (record) => ({
                  record,
                  editable: true,
                  dataIndex: key,
                  inputType: 'number',
                  title: key,
                  handleSave
                })
              })
              break
            case 'Operational Reason for Adjustment':
            case 'Discipline Reason for Adjustment':
            case 'Company Reason for Adjustment':
              headers.push({
                ...DEFAULTS,
                editble: true,
                width: '15vw',
                ellipsis: true,
                onCell: (record) => ({
                  record,
                  editable: true,
                  dataIndex: key,
                  inputType: 'textarea',
                  title: key,
                  handleSave
                })
              })
              break
            case 'Values Demonstrated':
              headers.push({
                ...DEFAULTS,
                editble: true,
                onCell: (record) => ({
                  record,
                  editable: true,
                  dataIndex: key,
                  inputType: 'select',
                  title: key,
                  handleSave
                })
              })
              break

            case 'Company Number':
            case 'Employee Number':
              headers.push({
                ...DEFAULTS,
                ...getColumnSearchProps(key),
                sorter: (a, b) => a?.[key] - b?.[key],
                sortDirections: ['descend', 'ascend']
              })
              break
            case 'Full Name':
            case 'Name':
              headers.push({
                ...DEFAULTS,
                ...getColumnSearchProps(key),
                sorter: (a, b) => a?.[key]?.length - b?.[key]?.length,
                sortDirections: ['descend', 'ascend']
              })
              break
            default:
              headers.push({
                ...DEFAULTS
              })
              break
          }
        }
        return null
      })
    }
    return headers
  }

  const dailyHeaders = useMemo(() => {
    if (worklistQuery?.data && !worklistQuery?.isLoading) {
      if (isHRManager) {
        const managerLocation = personQuery?.data?.companyPerson?.talentCompanyPerson?.jobLocation
        dispatch({ type: 'field', field: 'daily', payload: worklistQuery?.data?.daily.filter(x => x?.Location === managerLocation) })
      } else {
        dispatch({ type: 'field', field: 'daily', payload: worklistQuery?.data?.daily })
      }
      return generateHeaders(worklistQuery?.data?.daily?.[0])
    }
    // eslint-disable-next-line
  }, [worklistQuery?.data, worklistQuery?.isLoading, worklistQuery?.isFetching])
  const yearEndHeaders = useMemo(() => {
    if (worklistQuery?.data && !worklistQuery?.isLoading) {
      if (isHRManager) {
        const managerLocation = personQuery?.data?.companyPerson?.talentCompanyPerson?.jobLocation
        dispatch({ type: 'field', field: 'yearEnd', payload: worklistQuery?.data?.yearEnd.filter(x => x?.Location === managerLocation) })
      } else {
        dispatch({ type: 'field', field: 'yearEnd', payload: worklistQuery?.data?.yearEnd })
      }
      return generateHeaders(worklistQuery?.data?.yearEnd?.[0])
    }
    // eslint-disable-next-line
  }, [worklistQuery?.data, worklistQuery?.isLoading, worklistQuery?.isFetching])
  const calibrationHeaders = useMemo(() => {
    if (worklistQuery?.data && !worklistQuery?.isLoading) {
      dispatch({ type: 'field', field: 'calibration', payload: worklistQuery?.data?.calibration?.map(x => ({ ...x, 'Reason for adjustment': x['Reason for adjustment'] !== '' ? x['Reason for adjustment'] : '-', 'Values Demonstrated': x['Values Demonstrated'] ? 'Yes' : 'No' })) })
      return generateHeaders(worklistQuery?.data?.calibration?.[0])
    }
    // eslint-disable-next-line
  }, [worklistQuery?.data, worklistQuery?.isLoading, worklistQuery?.isFetching])

  useSimpleExporter({
    execute,
    type,
    name,
    callback: () => {
      setExecute(false)
      setTableEl(null)
      setName(null)
    },
    payload: tableEl
  })

  const components = {
    body: {
      row: EditableRow,
      cell: EditableCell
    }
  }

  const csvFileToArray = csv => {
    const lines = csv.split('\n')

    const headers = lines[0]
      .split(',')
      .map((header) => header.replace(/\r/g, '').trim().toLowerCase())
    console.log('headeres::: ', headers)

    const json = []

    for (let i = 1; i < lines.length; i++) {
      const values = lines[i]
        .split(',')
        .map((value) => value.replace(/\r/g, '').trim())

      const obj = {}

      headers.forEach((header, index) => {
        obj[header] = values[index]
      })

      if (Object.values(obj).join('')) json.push(obj)
    }
    return json
  }
  const uploadCsv = async ({ onError, onSuccess, file, onProgress }) => {
    try {
      console.log(file)
      if (file) {
        const fileReader = new window.FileReader()
        fileReader.onload = async function (event) {
          const text = event.target.result
          const csvArr = csvFileToArray(text)
          csvArr.pop()

          await csvMutation.mutateAsync({
            data: csvArr
          }, {
            onSuccess: () => onSuccess()
          })
        }
        fileReader.readAsText(file)
      }
    } catch (error) {
      onError(error)
    }
  }

  return (
    <div className='worklist__page'>
      <div className='flex justify-between items-center mt-8'>
        <PageHeader className='pl-0' title='Performance Management Worklist' />
        <Space direction='vertical'>
          <p className='text-gray-400'>Year</p>
          <Select onChange={(value) => dispatch({ type: 'field', field: 'year', payload: value })} defaultValue={state.year} style={{ width: 120 }}>
            <Select.Option value={null}>Current</Select.Option>
            <Select.Option value={currentYear}>{currentYear}</Select.Option>
            <Select.Option value={currentYear - 1}>{currentYear - 1}</Select.Option>
            <Select.Option value={currentYear - 2}>{currentYear - 2}</Select.Option>
            <Select.Option value={currentYear - 3}>{currentYear - 3}</Select.Option>
          </Select>
        </Space>
      </div>

      <Tabs defaultActiveKey={availTabs.includes(queryParam.get('tab')) ? queryParam.get('tab') : availTabs[0]} onChange={handleTabsChange}>
        <TabPane className='relative' tab='Daily' key='daily'>
          <Dropdown
            disabled={worklistQuery.isLoading}
            overlay={<ExportMenu
              setType={setType}
              setExecute={setExecute}
              setData={() => {
                setTableEl(state?.daily)
                // setPayloadToReport(worklistQuery?.data?.daily)
                // setReportHeader(dailyHeaders)
              }} setName={() => setName(`pm-daily-${makeId(6)}_${dayjs().format('DD/MM/YYYY')}`)}
                     />}
          >
            <Button style={{ margin: 0 }} type='primary' className='bg-blue-400' shape='round'>
              Export
            </Button>
          </Dropdown>
          <Table ref={dailyTableRef} className='mt-8' scroll={{ x: true }} rowKey='Employee Number' loading={worklistQuery.isLoading} columns={dailyHeaders} dataSource={state?.daily} />
        </TabPane>
        {!isHRManager
          ? (
            <TabPane tab='Calibration' key='calibration'>
              <Space>
                <Dropdown
                  disabled={worklistQuery.isLoading}
                  overlay={<ExportMenu
                    setType={setType}
                    setExecute={setExecute}
                    setData={() => {
                      setTableEl(state?.calibration)
                    // setPayloadToReport(worklistQuery?.data?.calibration)
                    // setReportHeader(calibrationHeaders)
                    }}
                    setName={() => setName(`calibration-${makeId(6)}_${dayjs().format('DD/MM/YYYY')}`)}
                           />}
                >
                  <Button style={{ margin: 0 }} type='primary' className='bg-blue-400' shape='round'>
                    Export
                  </Button>
                </Dropdown>
                <Upload
                  showUploadList={false}
                  customRequest={uploadCsv} accept='.csv' id='csvInput' name='calibrate'
                >
                  <Button shape='round' icon={<UploadOutlined />}>Upload CSV</Button>
                </Upload>
              </Space>
              <Table ref={calibrationTableRef} className='mt-8' scroll={{ x: true }} rowKey='_companyPersonUid' components={components} loading={worklistQuery.isFetching || worklistQuery.isLoading || loading} columns={calibrationHeaders} dataSource={state?.calibration} />
            </TabPane>
            )
          : null}
        <TabPane tab='Year End Evaluation' key='yearEnd'>
          <Dropdown
            disabled={worklistQuery.isLoading}
            overlay={<ExportMenu
              setType={setType}
              setExecute={setExecute}
              setData={() => {
                setTableEl(state?.yearEnd)
              // setPayloadToReport(worklistQuery?.data?.daily)
              // setReportHeader(dailyHeaders)
              }} setName={() => setName(`pm-yearEnd-${makeId(6)}_${dayjs().format('DD/MM/YYYY')}`)}
                     />}
          >
            <Button style={{ margin: 0 }} type='primary' className='bg-blue-400' shape='round'>
              Export
            </Button>
          </Dropdown>
          <Table ref={yearEndTableRef} scroll={{ x: true }} className='mt-8' rowKey='Employee Number' loading={worklistQuery.isLoading} columns={yearEndHeaders} dataSource={state?.yearEnd} />
        </TabPane>
      </Tabs>
    </div>
  )
}
