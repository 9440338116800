import { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import { message } from 'antd'
import { addDoc, collection } from 'firebase/firestore'
import db from '../firebase'

export const useSendNotification = messageToSend => {
  const [loading, setLoading] = useState(false)

  useEffect(() => {
    async function send (payload) {
      if (payload) {
        setLoading(true)
        try {
          await addDoc(collection(db, 'notifications'), {
            ...payload.payload,
            from: 'Thungela PM Notification <noreply@winwinza.com>'
          })
          setLoading(false)
        } catch (error) {
          setLoading(false)
          message.error(error?.message)
        }
      }
    }
    send(messageToSend)
  }, [messageToSend])

  return { loading }
}

useSendNotification.propTypes = {
  to: PropTypes.arrayOf(PropTypes.string),
  message: PropTypes.exact({
    subject: PropTypes.string,
    text: PropTypes.string,
    html: PropTypes.string
  })
}
