import {
  Button,
  Card,
  Dropdown,
  Empty,
  PageHeader,
  Pagination,
  Select,
  Skeleton,
  Space,
  Spin
} from 'antd'
import Search from 'antd/lib/input/Search'
import dayjs from 'dayjs'
import React, { useEffect, useReducer, useState } from 'react'
import relativeTime from 'dayjs/plugin/relativeTime'
import { useGetEmployees } from '../hooks/api/useEmployees'
import { EmployeeCard } from '../components/EmployeeCard'
import { useGetLookup } from '../hooks/api/useLookups'
import { useJsonExporter } from '../hooks/useExporter'
import { makeId } from '../hooks/uuid'
import { ExportMenu } from '../components/ExportMenu'
import { headers } from '../constants/reportHeaders'
import { useGetPerson } from '../hooks/api/usePerson'
dayjs.extend(relativeTime)
const { Option } = Select

const filterReducer = (state, action) => {
  const { type, field, payload } = action
  switch (type) {
    case 'field': {
      return {
        ...state,
        [field]: payload,
        page: 1
      }
    }
    case 'page': {
      return {
        ...state,
        page: payload
      }
    }
    case 'clear': {
      // client.invalidateQueries('employees')
      return {
        ...state,
        low: null,
        discipline: null,
        location: null,
        q: null,
        page: 1
      }
    }
    default: {
      throw new Error(`Unsupported action type: ${type}`)
    }
  }
}

export const Employees = () => {
  const [state, dispatch] = useReducer(filterReducer, { low: null, discipline: null, location: null, q: null, page: 1 })
  const [execute, setExecute] = useState(false)
  const [type, setType] = useState('csv')
  // STATE
  const personQuery = useGetPerson({ authenticated: true })
  const employeesQuery = useGetEmployees({ ...state })
  const disciplineLookups = useGetLookup({ group: 'Dynamic', key: 'Discipline' })
  const locationLookups = useGetLookup({ group: 'Dynamic', key: 'JobLocation' })
  const lowLookups = useGetLookup({ group: 'Dynamic', key: 'LevelOfWork' })
  console.log('personQuery::: ', personQuery)
  useJsonExporter({
    execute,
    type,
    payload: employeesQuery?.data?.data,
    name: `employees-${makeId(6)}_${dayjs().format('DD/MM/YYYY')}`,
    callback: () => setExecute(false),
    headers: headers.employees
  })

  const isHRManager = personQuery?.data?.companyPerson?.admin?.roles?.includes('talent.HRManager')
  useEffect(() => {
    if (isHRManager) {
      const location = personQuery?.data?.companyPerson?.talentCompanyPerson?.jobLocation
      if (location) {
        dispatch({ type: 'field', field: 'location', payload: location })
      }
    }
    // eslint-disable-next-line
  }, [isHRManager])

  return (
    <div className='employees__page'>
      <PageHeader style={{ paddingLeft: 0 }} title='Employees' />
      <div className='w-full flex justify-between mb-6 mt-2'>
        <Space direction='horizontal'>
          <Space direction='vertical'>
            <p className='text-gray-400'>LoW</p>
            <Select onChange={(value) => dispatch({ type: 'field', field: 'low', payload: value })} placeholder='Select LoW' value={state.low} loading={lowLookups.isLoading} disabled={lowLookups.isError || !lowLookups.data} style={{ minWidth: 150 }}>
              <Option value={null}>None</Option>
              {!lowLookups.isLoading && lowLookups.data && lowLookups.data.map(option => (
                <Option key={option} value={option}>{option}</Option>
              ))}
            </Select>
          </Space>
          <Space direction='vertical'>
            <p className='text-gray-400'>Discipline</p>
            <Select onChange={(value) => dispatch({ type: 'field', field: 'discipline', payload: value })} placeholder='Select Discipline' value={state.discipline} loading={disciplineLookups.isLoading} disabled={disciplineLookups.isError || !disciplineLookups.data} style={{ minWidth: 150 }}>
              <Option value={null}>None</Option>
              {!disciplineLookups.isLoading && disciplineLookups.data && disciplineLookups.data.map(option => (
                <Option key={option} value={option}>{option}</Option>
              ))}
            </Select>
          </Space>
          <Space direction='vertical'>
            <p className='text-gray-400'>Location</p>
            <Select onChange={(value) => dispatch({ type: 'field', field: 'location', payload: value })} placeholder='Select location' value={state.location} loading={locationLookups.isLoading} disabled={isHRManager || locationLookups.isError || !locationLookups.data} style={{ minWidth: 150 }}>
              <Option value={null}>None</Option>
              {!locationLookups.isLoading && locationLookups.data && locationLookups.data.map(option => (
                <Option key={option} value={option}>{option}</Option>
              ))}
            </Select>
          </Space>
          {!isHRManager
            ? (
                (state.location || state.discipline || state.low) && <Button onClick={() => dispatch({ type: 'clear' })} className='mt-7 ml-4 bg-blue-400' type='primary' shape='round'>Clear</Button>
              )
            : null}
        </Space>
        <Space className='mt-7'>
          <Search onSearch={(value) => dispatch({ type: 'field', field: 'q', payload: value })} placeholder='Search Employee' style={{ width: 200 }} />
          <Dropdown overlay={<ExportMenu setType={setType} setExecute={setExecute} />}>
            <Button className='bg-blue-400' type='primary' shape='round'>
              Export
            </Button>
          </Dropdown>
        </Space>
      </div>
      {employeesQuery.isFetching && (
        <div className='w-full flex justify-center py-4'>
          <Spin tip='Fetching employees...' />
        </div>
      )}
      {!employeesQuery.isLoading && employeesQuery.data.data
        ? (
          <>
            {employeesQuery.data.data !== undefined && employeesQuery.data.data?.length
              ? (
                <div className='pb-16'>

                  {employeesQuery?.data.data.map((employee, i) => (
                    <EmployeeCard key={i} employee={employee} index={i} />
                  ))}

                  <Pagination defaultPageSize={50} showTotal={total => `Total: ${total} employees`} className='mt-8' defaultCurrent={state.page} onChange={(page) => dispatch({ type: 'page', payload: page })} total={employeesQuery.data.numberOfResults} hideOnSinglePage showSizeChanger={false} />
                </div>
                )
              : (
                <Empty />
                )}
          </>
          )
        : (
          <>
            <Card className='mb-8'>
              <Skeleton loading avatar active />
            </Card>
            <Card className='mb-8'>
              <Skeleton loading avatar active />
            </Card>
          </>
          )}
    </div>
  )
}
