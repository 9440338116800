import React, { useRef } from 'react'
import { PDFExport } from '@progress/kendo-react-pdf'
import { Button, Card, Divider, Modal, Table, Tag, Typography } from 'antd'
import dayjs from 'dayjs'
import { ProfileCard } from './ProfileCard'
import { ContractCard, planColumns } from './ContractCard'
import { PerformanceTables } from './PerformanceTables'
import { performanceTable } from '../constants/performanceTable'
import uuidv4 from '../hooks/uuid'
import { isMe } from '../hooks/isMe'
import { useGetPerson } from '../hooks/api/usePerson'
import { useParams } from 'react-router-dom'
import { PdfHeader } from './PdfHeader'

export const PerformancePdf = ({ state, year, preview, employeeQuery, setPreview }) => {
  const pdfExportComponent = useRef(null)
  const personQuery = useGetPerson({ authenticated: true })
  const { employeeUid } = useParams()

  const exportPDFWithComponent = () => {
    if (pdfExportComponent.current) {
      pdfExportComponent.current.save()
    }
  }
  return (
    <Modal
      visible={preview}
      okText='Export as PDF'
      title='PDF Preview'
      onCancel={() => setPreview(x => !x)}
      footer={[
        <Button key='cancel' onClick={() => setPreview(x => !x)}>Cancel</Button>,
        <Button onClick={exportPDFWithComponent} key='submit' type='primary' className='bg-blue-400'>Export as PDF</Button>
      ]}
      width='90%'
    >
      <PDFExport margin='1cm' fileName={`performance-${dayjs().format('DD/MM/YYYY')}`} ref={pdfExportComponent}>
        <div className='p-6 relative' style={{ fontWeight: 'initial', fontVariant: 'initial', lineHeight: 'initial' }}>
          {/* <div className='p-2 rounded-md w-fit top-2 left-2 z-50 mb-4' style={{ backgroundColor: '#333333' }}><img src='/images/thungela.png' alt='brand logo' className='h-8 object-cover' /></div> */}
          <PdfHeader title='Performance Contract PDF' />
          <ProfileCard person={employeeQuery.data} isPdf />
          <br />
          {!employeeQuery.isLoading && employeeQuery.data && (
            <>
              <PerformanceTables isPdf employee={employeeQuery.data} year={year} />
              <br />
            </>
          )}
          <ContractCard contract={state} isPdf />
          <Divider />
          <Typography.Title level={5}>Snapshots</Typography.Title>
          <Card title='Snapshot 1' className='mb-6' extra={state?.reviews?.[0]?.status && state?.reviews?.[0]?.status !== 'draft' && <Tag key='status' color='blue'>{state?.reviews?.[0]?.status}</Tag>}>
            {state?.reviews?.[0]
              ? (
                <>
                  <Typography.Title level={5}>Key Results Area(s)</Typography.Title>
                  <Table
                    expandable={{
                      defaultExpandAllRows: true
                    }}
                    size='small'
                    bordered
                    className='mb-8'
                    pagination={false}
                    rowKey='id'
                    columns={performanceTable?.kra?.columns}
                    dataSource={state?.reviews ? state?.reviews[0]?.kra?.map(kra => ({ ...kra, id: uuidv4(), children: kra?.objectives?.map(obj => ({ ...obj, empty: null })) })) : []}
                  />
                  <Typography.Title level={5}>IDP(s)</Typography.Title>
                  <Table
                    expandable={{
                      defaultExpandAllRows: true
                    }}
                    size='small'
                    bordered
                    pagination={false}
                    rowKey='id'
                    columns={performanceTable?.idp?.columns}
                    dataSource={state?.reviews ? state?.reviews[0]?.idp.map(kra => ({ ...kra, id: uuidv4() })) : []}
                  />
                  {state?.reviews && state?.reviews?.length && state?.reviews[0]?.status === 'Snapshot Finalised'
                    ? (
                      <>
                        <Typography.Title className='mt-8' level={5}>Manager Comment</Typography.Title>
                        <Typography.Text>{state?.reviews[0]?.managerComment}</Typography.Text>
                      </>
                      )
                    : null}
                </>
                )
              : 'Snapshot Outstanding'}
          </Card>
          {state?.reviews?.[1]
            ? (
              <Card title='Snapshot 2' className='mb-6' extra={state?.reviews?.[1]?.status && state?.reviews?.[1]?.status !== 'draft' && <Tag key='status' color='blue'>{state?.reviews?.[1]?.status}</Tag>}>
                <Typography.Title level={5}>Key Results Area(s)</Typography.Title>
                <Table
                  expandable={{
                    defaultExpandAllRows: true
                  }}
                  size='small'
                  bordered
                  className='mb-8'
                  pagination={false}
                  rowKey='id'
                  columns={performanceTable?.kra?.columns}
                  dataSource={state?.reviews ? state?.reviews?.[1]?.kra?.map(kra => ({ ...kra, id: uuidv4(), children: kra?.objectives?.map(obj => ({ ...obj, empty: null })) })) : []}
                />
                <Typography.Title level={5}>IDP(s)</Typography.Title>
                <Table
                  expandable={{
                    defaultExpandAllRows: true
                  }}
                  size='small'
                  bordered
                  pagination={false}
                  rowKey='id'
                  columns={performanceTable?.idp?.columns}
                  dataSource={state?.reviews ? state?.reviews?.[1]?.idp.map(kra => ({ ...kra, id: uuidv4() })) : []}
                />
                {state?.reviews && state?.reviews?.length && state?.reviews?.[1]?.status === 'Snapshot Finalised'
                  ? (
                    <>
                      <Typography.Title className='mt-8' level={5}>Manager Comment</Typography.Title>
                      <Typography.Text>{state?.reviews?.[1]?.managerComment}</Typography.Text>
                    </>
                    )
                  : null}
              </Card>
              )
            : 'Snapshot Outstanding'}
          <Divider />
          <Typography.Title level={5}>Year End Review</Typography.Title>

          <Card className='mb-8'>
            <Typography.Title level={5}>
              {isMe(personQuery?.data, employeeUid)
                ? 'Self Assessment'
                : 'Employee Self Assessment'}
            </Typography.Title>
            <Table
              expandable={{
                defaultExpandAllRows: true
              }}
              bordered
              className='mb-8'
              pagination={false}
              rowKey='id'
              columns={performanceTable.final.employee?.columns}
              dataSource={state?.assessments?.employee?.response?.slice(0, -1)?.map((kra, ki) => ({ ...kra, name: `${ki + 1}. ${kra?.name}`, id: uuidv4(), key: uuidv4(), children: kra.objectives.map((obj, oi) => ({ ...obj, name: '', objective: `${ki + 1}.${oi + 1}. ${obj?.name}`, empty: null, key: uuidv4() })) }))}
            />
            <Typography.Title level={5}>Development Plan</Typography.Title>
            <Table
              expandable={{
                defaultExpandAllRows: true
              }}
              bordered
              className='mb-8'
              pagination={false}
              rowKey='name'
              columns={performanceTable.final.employee.columns}
              dataSource={state?.assessments?.employee?.response?.[state?.assessments?.employee?.response?.length - 1] && [state?.assessments?.employee?.response?.[state?.assessments?.employee?.response?.length - 1]]?.map((kra, ki) => ({ ...kra, name: `${ki + 1}. ${kra?.name}`, id: uuidv4(), key: uuidv4(), children: kra.objectives.map((obj, oi) => ({ ...obj, name: '', objective: `${ki + 1}.${oi + 1}. ${obj?.name}`, empty: null, key: uuidv4() })) }))}
            />
            <Table size='small' bordered className='mb-8' pagination={false} columns={planColumns} dataSource={state?.developmentPlans} />

          </Card>
        </div>
      </PDFExport>
    </Modal>
  )
}
