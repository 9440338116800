import { Menu } from 'antd'
import React from 'react'

export const ExportMenu = ({ setType, setExecute, setData = () => {}, setName = () => {} }) => {
  const handleExecute = ({ key }) => {
    setName()
    setData()
    setType(key)
    setExecute(true)
  }

  return (
    <Menu onClick={handleExecute}>
      <Menu.Item key='csv'>Format: CSV</Menu.Item>
      <Menu.Item key='xlsx'>Format: XLSX</Menu.Item>
      <Menu.Item key='xlsm'>Format: XLSM</Menu.Item>
    </Menu>
  )
}
