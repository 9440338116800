import { Button, Dropdown, PageHeader, Select, Space, Table, Tabs } from 'antd'
import Title from 'antd/lib/typography/Title'
import dayjs from 'dayjs'
import React, { useEffect, useReducer, useState } from 'react'
import { Link } from 'react-router-dom'
import { ExportMenu } from '../../components/ExportMenu'
import { headers } from '../../constants/reportHeaders'
import { useGetLookup } from '../../hooks/api/useLookups'
import { useGetWorklist } from '../../hooks/api/useWorklist'
import { generateKey } from '../../hooks/generateKey'
import { groupBy } from '../../hooks/groupBy'
import { toCamelCase, unCamelCase } from '../../hooks/unCamelCase'
import { useJsonExporter } from '../../hooks/useExporter'
import { useQueryParam } from '../../hooks/useQueryParam'
import { makeId } from '../../hooks/uuid'

const { Option } = Select
const { TabPane } = Tabs
const { Column, ColumnGroup } = Table

const worklistReducer = (state, action) => {
  const { type, field, payload } = action
  switch (type) {
    case 'initialize': {
      return {
        ...state,
        initialized: true,
        data: payload
      }
    }
    case 'field': {
      return {
        ...state,
        filters: {
          [field]: payload
        }
      }
    }
    case 'clear': {
      // client.invalidateQueries('employees')
      return {
        ...state,
        filters: {
          low: null, discipline: null, location: null
        }
      }
    }
    default: {
      throw new Error(`Unsupported action type: ${type}`)
    }
  }
}
export const Worklist = () => {
  const [state, dispatch] = useReducer(worklistReducer, { filters: { low: null, discipline: null, location: null }, data: null, initialized: false })
  const [execute, setExecute] = useState(false)
  const [type, setType] = useState('csv')
  const [payloadToReport, setPayloadToReport] = useState(null)
  const [name, setName] = useState(null)
  const availTabs = ['all', 'low', 'disciplines', 'locations', 'coaching']
  const queryParam = useQueryParam()

  // NETWORK
  const worklistQuery = useGetWorklist({ ...state.filters })
  const disciplineLookups = useGetLookup({ group: 'Dynamic', key: 'Discipline' })
  const locationLookups = useGetLookup({ group: 'Dynamic', key: 'JobLocation' })
  const lowLookups = useGetLookup({ group: 'Dynamic', key: 'LevelOfWork' })

  const handleHeaders = () => {
    if (name) {
      switch (name.split('-')[0]) {
        case 'levelOfWork':
          return headers.worklist.low
        case 'disciplines':
          return headers.worklist.disciplines
        case 'locations':
          return headers.worklist.locations
        case 'coaching':
          return headers.worklist.coaching

        default: {
          throw new Error('Unsupported name')
        }
      }
    }
  }

  useJsonExporter({
    execute,
    type,
    name,
    callback: () => {
      setExecute(false)
      setPayloadToReport(null)
      setName(null)
    },
    payload: payloadToReport,
    headers: handleHeaders()
  })

  function handleTabsChange (key) {
    const newUrl = window.location.protocol + '//' + window.location.host + window.location.pathname + '?tab=' + key
    window.history.pushState({ path: newUrl }, '', newUrl)
  }

  useEffect(() => {
    if (!worklistQuery.isLoading && worklistQuery.data) {
      const payload = Object.keys(headers.worklistData).map(key => {
        const headings = headers.worklistData[key].headings.map((heading, i) => ({ group: heading.split('_')[0], column: toCamelCase(heading) }))
        const values = headers.worklistData[key].values.map(row => {
          return Object.assign(...row.map((line, i) => ({ [headings[i].column === '' ? `empty_${i}` : headings[i].column]: line })))
        })
        return {
          key,
          ...headers.worklistData[key],
          headings: Array.from(groupBy(headings, item => item.group)).map((item, i) => ({ group: item[0], columns: item[1].map(cols => ({ column: cols.column })) })),
          values
        }
      })
      dispatch({ type: 'initialize', payload })
    }
  }, [worklistQuery.isLoading, worklistQuery.data])
  return (
    <div className='worklist__page'>
      <PageHeader className='pl-0' title='Talent Management Worklist' />
      <div className='w-full flex justify-between mb-6 mt-2'>
        <Space direction='horizontal'>
          <Space direction='vertical'>
            <p className='text-gray-400'>LoW</p>
            <Select onChange={(value) => dispatch({ type: 'field', field: 'low', payload: value })} placeholder='Select LoW' value={state.low} loading={lowLookups.isLoading} disabled={lowLookups.isError || !lowLookups.data} style={{ minWidth: 150 }}>
              {!lowLookups.isLoading && lowLookups.data && lowLookups.data.map(option => (
                <Option key={option} value={option}>{option}</Option>
              ))}
            </Select>
          </Space>
          <Space direction='vertical'>
            <p className='text-gray-400'>Discipline</p>
            <Select onChange={(value) => dispatch({ type: 'field', field: 'discipline', payload: value })} placeholder='Select Discipline' value={state.discipline} loading={disciplineLookups.isLoading} disabled={disciplineLookups.isError || !disciplineLookups.data} style={{ minWidth: 150 }}>
              <Option value={null}>None</Option>
              {!disciplineLookups.isLoading && disciplineLookups.data && disciplineLookups.data.map(option => (
                <Option key={option} value={option}>{option}</Option>
              ))}
            </Select>
          </Space>
          <Space direction='vertical'>
            <p className='text-gray-400'>Location</p>
            <Select onChange={(value) => dispatch({ type: 'field', field: 'location', payload: value })} placeholder='Select location' value={state.location} loading={locationLookups.isLoading} disabled={locationLookups.isError || !locationLookups.data} style={{ minWidth: 150 }}>
              <Option value={null}>None</Option>
              {!locationLookups.isLoading && locationLookups.data && locationLookups.data.map(option => (
                <Option key={option} value={option}>{option}</Option>
              ))}
            </Select>
          </Space>
          <Button onClick={() => dispatch({ type: 'clear' })} className='mt-7 ml-4 bg-blue-400' type='primary' shape='round'>
            Clear
          </Button>
        </Space>
      </div>
      <Tabs defaultActiveKey={availTabs.includes(queryParam.get('tab')) ? queryParam.get('tab') : availTabs[0]} onChange={handleTabsChange}>
        {state.initialized
          ? (
            <>
              <TabPane tab='All ' key='all'>

                {state.data.filter(x => x.key === 'levelOfWork').map((table, i) => (
                  <React.Fragment key={generateKey(`${table.key}_${i}`)}>
                    <div className='flex justify-between'>
                      <Title level={4}>{unCamelCase(table.key)}</Title>
                      <Dropdown className='mb-2.5' overlay={<ExportMenu setType={setType} setExecute={setExecute} setData={() => setPayloadToReport(state.data.filter(x => x.key === 'levelOfWork').map(table => (table.values))[0])} setName={() => setName(`levelOfWork-${makeId(6)}_${dayjs().format('DD/MM/YYYY')}`)} />}>
                        <Button type='primary' className='bg-blue-400' shape='round'>
                          Export
                        </Button>
                      </Dropdown>
                    </div>

                    <Table bordered size='small' dataSource={table.values}>
                      {table.headings.map((heading, hI) => (
                        <ColumnGroup key={generateKey(`${heading.group}_${i}`)} title={heading.group}>
                          {heading.columns.map((col, cI) => {
                            if (cI === 0 && hI === 0) {
                              return (
                                <Column
                                  title={col.column.split('_')[1]}
                                  key={generateKey(`${col.column}_${i}`)}
                                  dataIndex={col.column}
                                  render={(record) => <Link to={`/tm-worklist/${record.replace(/ *\([^)]*\) */g, '').replace(/\s+/g, '-')}?type=low`} className='text-blue-400'>{record}</Link>}
                                />
                              )
                            } else {
                              return (
                                <Column
                                  title={col.column.split('_')[1]}
                                  key={generateKey(`${col.column}_${i}`)}
                                  dataIndex={col.column}
                                />
                              )
                            }
                          })}
                        </ColumnGroup>
                      ))}
                    </Table>
                  </React.Fragment>
                ))}
                {state.data.filter(x => x.key === 'disciplines').map((table, i) => (
                  <React.Fragment key={generateKey(`${table.key}_${i}`)}>
                    <div className='flex justify-between'>
                      <Title level={4}>{unCamelCase(table.key)}</Title>
                      <Dropdown className='mb-2.5' overlay={<ExportMenu setType={setType} setExecute={setExecute} setData={() => setPayloadToReport(state.data.filter(x => x.key === 'disciplines').map(table => (table.values))[0])} setName={() => setName(`disciplines-${makeId(6)}_${dayjs().format('DD/MM/YYYY')}`)} />}>
                        <Button type='primary' className='bg-blue-400' shape='round'>
                          Export
                        </Button>
                      </Dropdown>
                    </div>
                    <Table bordered size='small' dataSource={table.values}>
                      {table.headings.map((heading, hI) => (
                        <ColumnGroup key={generateKey(`${heading.group}_${i}`)} title={heading.group}>
                          {heading.columns.map((col, cI) => {
                            if (cI === 0 && hI === 0) {
                              return (
                                <Column
                                  title={col.column.split('_')[1]}
                                  key={generateKey(`${col.column}_${i}`)}
                                  dataIndex={col.column}
                                  render={(record) => <Link to={`/tm-worklist/${record.replace(/ *\([^)]*\) */g, '').replace(/\s+/g, '-')}?type=discipline`} className='text-blue-400'>{record}</Link>}
                                />
                              )
                            } else {
                              return (
                                <Column
                                  title={col.column.split('_')[1]}
                                  key={generateKey(`${col.column}_${i}`)}
                                  dataIndex={col.column}
                                />
                              )
                            }
                          })}
                        </ColumnGroup>
                      ))}
                    </Table>
                  </React.Fragment>
                ))}
                {state.data.filter(x => x.key === 'locations').map((table, i) => (
                  <React.Fragment key={generateKey(`${table.key}_${i}`)}>
                    <div className='flex justify-between'>
                      <Title level={4}>{unCamelCase(table.key)}</Title>
                      <Dropdown className='mb-2.5' overlay={<ExportMenu setType={setType} setExecute={setExecute} setData={() => setPayloadToReport(state.data.filter(x => x.key === 'locations').map(table => (table.values))[0])} setName={() => setName(`locations-${makeId(6)}_${dayjs().format('DD/MM/YYYY')}`)} />}>
                        <Button type='primary' className='bg-blue-400' shape='round'>
                          Export
                        </Button>
                      </Dropdown>
                    </div>
                    <Table bordered size='small' dataSource={table.values}>
                      {table.headings.map((heading, hI) => (
                        <ColumnGroup key={generateKey(`${heading.group}_${i}`)} title={heading.group}>
                          {heading.columns.map((col, cI) => {
                            if (cI === 0 && hI === 0) {
                              return (
                                <Column
                                  title={col.column.split('_')[1]}
                                  key={generateKey(`${col.column}_${i}`)}
                                  dataIndex={col.column}
                                  render={(record) => <Link to={`/tm-worklist/${record.replace(/ *\([^)]*\) */g, '').replace(/\s+/g, '-')}?type=location`} className='text-blue-400'>{record}</Link>}
                                />
                              )
                            } else {
                              return (
                                <Column
                                  title={col.column.split('_')[1]}
                                  key={generateKey(`${col.column}_${i}`)}
                                  dataIndex={col.column}
                                />
                              )
                            }
                          })}
                        </ColumnGroup>
                      ))}
                    </Table>
                  </React.Fragment>
                ))}
              </TabPane>
              <TabPane tab='Level of Work' key='low'>
                {state.data.filter(x => x.key === 'levelOfWork').map((table, i) => (
                  <React.Fragment key={generateKey(`${table.key}_${i}`)}>
                    <div className='flex justify-between'>
                      <Title level={4}>{unCamelCase(table.key)}</Title>
                      <Dropdown className='mb-2.5' overlay={<ExportMenu setType={setType} setExecute={setExecute} setData={() => setPayloadToReport(state.data.filter(x => x.key === 'levelOfWork').map(table => (table.values))[0])} setName={() => setName(`levelOfWork-${makeId(6)}_${dayjs().format('DD/MM/YYYY')}`)} />}>
                        <Button type='primary' className='bg-blue-400' shape='round'>
                          Export
                        </Button>
                      </Dropdown>
                    </div>
                    <Table bordered size='small' dataSource={table.values}>
                      {table.headings.map((heading, hI) => (
                        <ColumnGroup key={generateKey(`${heading.group}_${i}`)} title={heading.group}>
                          {heading.columns.map((col, cI) => {
                            if (cI === 0 && hI === 0) {
                              return (
                                <Column
                                  title={col.column.split('_')[1]}
                                  key={generateKey(`${col.column}_${i}`)}
                                  dataIndex={col.column}
                                  render={(record) => <Link to={`/tm-worklist/${record.replace(/ *\([^)]*\) */g, '').replace(/\s+/g, '-')}?type=low`} className='text-blue-400'>{record}</Link>}
                                />
                              )
                            } else {
                              return (
                                <Column
                                  title={col.column.split('_')[1]}
                                  key={generateKey(`${col.column}_${i}`)}
                                  dataIndex={col.column}
                                />
                              )
                            }
                          })}
                        </ColumnGroup>
                      ))}
                    </Table>
                  </React.Fragment>
                ))}
              </TabPane>
              <TabPane tab='Disciplines' key='disciplines'>
                {state.data.filter(x => x.key === 'disciplines').map((table, i) => (
                  <React.Fragment key={generateKey(`${table.key}_${i}`)}>
                    <div className='flex justify-between'>
                      <Title level={4}>{unCamelCase(table.key)}</Title>
                      <Dropdown className='mb-2.5' overlay={<ExportMenu setType={setType} setExecute={setExecute} setData={() => setPayloadToReport(state.data.filter(x => x.key === 'disciplines').map(table => (table.values))[0])} setName={() => setName(`disciplines-${makeId(6)}_${dayjs().format('DD/MM/YYYY')}`)} />}>
                        <Button type='primary' className='bg-blue-400' shape='round'>
                          Export
                        </Button>
                      </Dropdown>
                    </div>
                    <Table bordered size='small' dataSource={table.values}>
                      {table.headings.map((heading, hI) => (
                        <ColumnGroup key={generateKey(`${heading.group}_${i}`)} title={heading.group}>
                          {heading.columns.map((col, cI) => {
                            if (cI === 0 && hI === 0) {
                              return (
                                <Column
                                  title={col.column.split('_')[1]}
                                  key={generateKey(`${col.column}_${i}`)}
                                  dataIndex={col.column}
                                  render={(record) => <Link to={`/tm-worklist/${record.replace(/ *\([^)]*\) */g, '').replace(/\s+/g, '-')}?type=discipline`} className='text-blue-400'>{record}</Link>}
                                />
                              )
                            } else {
                              return (
                                <Column
                                  title={col.column.split('_')[1]}
                                  key={generateKey(`${col.column}_${i}`)}
                                  dataIndex={col.column}
                                />
                              )
                            }
                          })}
                        </ColumnGroup>
                      ))}
                    </Table>
                  </React.Fragment>
                ))}
              </TabPane>
              <TabPane tab='Locations' key='locations'>
                {state.data.filter(x => x.key === 'locations').map((table, i) => (
                  <React.Fragment key={generateKey(`${table.key}_${i}`)}>
                    <div className='flex justify-between'>
                      <Title level={4}>{unCamelCase(table.key)}</Title>
                      <Dropdown className='mb-2.5' overlay={<ExportMenu setType={setType} setExecute={setExecute} setData={() => setPayloadToReport(state.data.filter(x => x.key === 'locations').map(table => (table.values))[0])} setName={() => setName(`locations-${makeId(6)}_${dayjs().format('DD/MM/YYYY')}`)} />}>
                        <Button type='primary' className='bg-blue-400' shape='round'>
                          Export
                        </Button>
                      </Dropdown>
                    </div>
                    <Table bordered size='small' dataSource={table.values}>
                      {table.headings.map((heading, hI) => (
                        <ColumnGroup key={generateKey(`${heading.group}_${i}`)} title={heading.group}>
                          {heading.columns.map((col, cI) => {
                            if (cI === 0 && hI === 0) {
                              return (
                                <Column
                                  title={col.column.split('_')[1]}
                                  key={generateKey(`${col.column}_${i}`)}
                                  dataIndex={col.column}
                                  render={(record) => <Link to={`/tm-worklist/${record.replace(/ *\([^)]*\) */g, '').replace(/\s+/g, '-')}?type=location`} className='text-blue-400'>{record}</Link>}
                                />
                              )
                            } else {
                              return (
                                <Column
                                  title={col.column.split('_')[1]}
                                  key={generateKey(`${col.column}_${i}`)}
                                  dataIndex={col.column}
                                />
                              )
                            }
                          })}
                        </ColumnGroup>
                      ))}
                    </Table>
                  </React.Fragment>
                ))}
              </TabPane>
              <TabPane tab='Coaching' key='coaching'>
                {state.data.filter(x => x.key === 'coachings').map((table, i) => (
                  <React.Fragment key={generateKey(`${table.key}_${i}`)}>
                    <div className='flex justify-between'>
                      <Title level={4}>{unCamelCase(table.key)}</Title>
                      <Dropdown className='mb-2.5' overlay={<ExportMenu setType={setType} setExecute={setExecute} setData={() => setPayloadToReport(state.data.filter(x => x.key === 'coaching').map(table => (table.values))[0])} setName={() => setName(`coaching-${makeId(6)}_${dayjs().format('DD/MM/YYYY')}`)} />}>
                        <Button type='primary' className='bg-blue-400' shape='round'>
                          Export
                        </Button>
                      </Dropdown>
                    </div>
                    <Table bordered size='small' dataSource={table.values}>
                      {table.headings.map((heading, hI) => (
                        <ColumnGroup key={generateKey(`${heading.group}_${i}`)} title={heading.group}>
                          {heading.columns.map((col, cI) => {
                            if (cI === 0 && hI === 0) {
                              return (
                                <Column
                                  title={col.column.split('_')[1]}
                                  key={generateKey(`${col.column}_${i}`)}
                                  dataIndex={col.column}
                                  render={(record) => <Link to={`/tm-worklist/${record.replace(/ *\([^)]*\) */g, '').replace(/\s+/g, '-')}?type=coaching`} className='text-blue-400'>{record}</Link>}
                                />
                              )
                            } else {
                              return (
                                <Column
                                  title={col.column.split('_')[1]}
                                  key={generateKey(`${col.column}_${i}`)}
                                  dataIndex={col.column}
                                />
                              )
                            }
                          })}
                        </ColumnGroup>
                      ))}
                    </Table>
                  </React.Fragment>
                ))}
              </TabPane>
            </>
            )
          : (
            <Table bordered loading>
              <Title level={4}>Fetching Tables</Title>
              <Column />
              <Column />
              <Column />
              <Column />
              <Column />
            </Table>
            )}
      </Tabs>
    </div>
  )
}
// <Tabs defaultActiveKey={availTabs.includes(queryParam.get('tab')) ? queryParam.get('tab') : availTabs[0]} onChange={handleTabsChange}>
//   {state.initialized
//     ? (
//       <>
//         <TabPane tab='All ' key='all'>

//           {state.data.filter(x => x.key === 'levelOfWork').map((table, i) => (
//             <React.Fragment key={generateKey(`${table.key}_${i}`)}>
//               <Title level={4}>{unCamelCase(table.key)}</Title>
//               <Table bordered size='small' dataSource={table.values}>
//                 {table.headings.map((heading, hI) => (
//                   <ColumnGroup key={generateKey(`${heading.group}_${i}`)} title={heading.group}>
//                     {heading.columns.map((col, cI) => {
//                       if (cI === 0 && hI === 0) {
//                         return (
//                           <Column
//                             title={col.column.includes('empty') ? '' : col.column}
//                             key={generateKey(`${col.column}_${i}`)}
//                             dataIndex={col.column}
//                             render={(record) => <Link to={`/tm-worklist/${record.replace(/ *\([^)]*\) */g, '').replace(/\s+/g, '-')}?type=low`} className='text-blue-400'>{record}</Link>}
//                           />
//                         )
//                       } else {
//                         return (
//                           <Column
//                             title={col.column.includes('empty') ? '' : col.column}
//                             key={generateKey(`${col.column}_${i}`)}
//                             dataIndex={col.column}
//                           />
//                         )
//                       }
//                     })}
//                   </ColumnGroup>
//                 ))}
//               </Table>
//             </React.Fragment>
//           ))}
//           {state.data.filter(x => x.key === 'disciplines').map((table, i) => (
//             <React.Fragment key={generateKey(`${table.key}_${i}`)}>
//               <Title level={4}>{unCamelCase(table.key)}</Title>
//               <Table bordered size='small' dataSource={table.values}>
//                 {table.headings.map((heading, hI) => (
//                   <ColumnGroup key={generateKey(`${heading.group}_${i}`)} title={heading.group}>
//                     {heading.columns.map((col, cI) => {
//                       if (cI === 0 && hI === 0) {
//                         return (
//                           <Column
//                             title={col.column.includes('empty') ? '' : col.column}
//                             key={generateKey(`${col.column}_${i}`)}
//                             dataIndex={col.column}
//                             render={(record) => <Link to={`/tm-worklist/${record.replace(/ *\([^)]*\) */g, '').replace(/\s+/g, '-')}?type=discipline`} className='text-blue-400'>{record}</Link>}
//                           />
//                         )
//                       } else {
//                         return (
//                           <Column
//                             title={col.column.includes('empty') ? '' : col.column}
//                             key={generateKey(`${col.column}_${i}`)}
//                             dataIndex={col.column}
//                           />
//                         )
//                       }
//                     })}
//                   </ColumnGroup>
//                 ))}
//               </Table>
//             </React.Fragment>
//           ))}
//           {state.data.filter(x => x.key === 'locations').map((table, i) => (
//             <React.Fragment key={generateKey(`${table.key}_${i}`)}>
//               <Title level={4}>{unCamelCase(table.key)}</Title>
//               <Table bordered size='small' dataSource={table.values}>
//                 {table.headings.map((heading, hI) => (
//                   <ColumnGroup key={generateKey(`${heading.group}_${i}`)} title={heading.group}>
//                     {heading.columns.map((col, cI) => {
//                       if (cI === 0 && hI === 0) {
//                         return (
//                           <Column
//                             title={col.column.includes('empty') ? '' : col.column}
//                             key={generateKey(`${col.column}_${i}`)}
//                             dataIndex={col.column}
//                             render={(record) => <Link to={`/tm-worklist/${record.replace(/ *\([^)]*\) */g, '').replace(/\s+/g, '-')}?type=location`} className='text-blue-400'>{record}</Link>}
//                           />
//                         )
//                       } else {
//                         return (
//                           <Column
//                             title={col.column.includes('empty') ? '' : col.column}
//                             key={generateKey(`${col.column}_${i}`)}
//                             dataIndex={col.column}
//                           />
//                         )
//                       }
//                     })}
//                   </ColumnGroup>
//                 ))}
//               </Table>
//             </React.Fragment>
//           ))}
//         </TabPane>
//         <TabPane tab='Level of Work' key='low'>
//           {state.data.filter(x => x.key === 'levelOfWork').map((table, i) => (
//             <React.Fragment key={generateKey(`${table.key}_${i}`)}>
//               <Title level={4}>{unCamelCase(table.key)}</Title>
//               <Table bordered size='small' dataSource={table.values}>
//                 {table.headings.map((heading, hI) => (
//                   <ColumnGroup key={generateKey(`${heading.group}_${i}`)} title={heading.group}>
//                     {heading.columns.map((col, cI) => {
//                       if (cI === 0 && hI === 0) {
//                         return (
//                           <Column
//                             title={col.column.includes('empty') ? '' : col.column}
//                             key={generateKey(`${col.column}_${i}`)}
//                             dataIndex={col.column}
//                             render={(record) => <Link to={`/tm-worklist/${record.replace(/ *\([^)]*\) */g, '').replace(/\s+/g, '-')}?type=low`} className='text-blue-400'>{record}</Link>}
//                           />
//                         )
//                       } else {
//                         return (
//                           <Column
//                             title={col.column.includes('empty') ? '' : col.column}
//                             key={generateKey(`${col.column}_${i}`)}
//                             dataIndex={col.column}
//                           />
//                         )
//                       }
//                     })}
//                   </ColumnGroup>
//                 ))}
//               </Table>
//             </React.Fragment>
//           ))}
//         </TabPane>
//         <TabPane tab='Disciplines' key='disciplines'>
//           {state.data.filter(x => x.key === 'disciplines').map((table, i) => (
//             <React.Fragment key={generateKey(`${table.key}_${i}`)}>
//               <Title level={4}>{unCamelCase(table.key)}</Title>
//               <Table bordered size='small' dataSource={table.values}>
//                 {table.headings.map((heading, hI) => (
//                   <ColumnGroup key={generateKey(`${heading.group}_${i}`)} title={heading.group}>
//                     {heading.columns.map((col, cI) => {
//                       if (cI === 0 && hI === 0) {
//                         return (
//                           <Column
//                             title={col.column.includes('empty') ? '' : col.column}
//                             key={generateKey(`${col.column}_${i}`)}
//                             dataIndex={col.column}
//                             render={(record) => <Link to={`/tm-worklist/${record.replace(/ *\([^)]*\) */g, '').replace(/\s+/g, '-')}?type=discipline`} className='text-blue-400'>{record}</Link>}
//                           />
//                         )
//                       } else {
//                         return (
//                           <Column
//                             title={col.column.includes('empty') ? '' : col.column}
//                             key={generateKey(`${col.column}_${i}`)}
//                             dataIndex={col.column}
//                           />
//                         )
//                       }
//                     })}
//                   </ColumnGroup>
//                 ))}
//               </Table>
//             </React.Fragment>
//           ))}
//         </TabPane>
//         <TabPane tab='Locations' key='locations'>
//           {state.data.filter(x => x.key === 'locations').map((table, i) => (
//             <React.Fragment key={generateKey(`${table.key}_${i}`)}>
//               <Title level={4}>{unCamelCase(table.key)}</Title>
//               <Table bordered size='small' dataSource={table.values}>
//                 {table.headings.map((heading, hI) => (
//                   <ColumnGroup key={generateKey(`${heading.group}_${i}`)} title={heading.group}>
//                     {heading.columns.map((col, cI) => {
//                       if (cI === 0 && hI === 0) {
//                         return (
//                           <Column
//                             title={col.column.includes('empty') ? '' : col.column}
//                             key={generateKey(`${col.column}_${i}`)}
//                             dataIndex={col.column}
//                             render={(record) => <Link to={`/tm-worklist/${record.replace(/ *\([^)]*\) */g, '').replace(/\s+/g, '-')}?type=location`} className='text-blue-400'>{record}</Link>}
//                           />
//                         )
//                       } else {
//                         return (
//                           <Column
//                             title={col.column.includes('empty') ? '' : col.column}
//                             key={generateKey(`${col.column}_${i}`)}
//                             dataIndex={col.column}
//                           />
//                         )
//                       }
//                     })}
//                   </ColumnGroup>
//                 ))}
//               </Table>
//             </React.Fragment>
//           ))}
//         </TabPane>
//         <TabPane tab='Coaching' key='coaching'>
//           {state.data.filter(x => x.key === 'coachings').map((table, i) => (
//             <React.Fragment key={generateKey(`${table.key}_${i}`)}>
//               <Title level={4}>{unCamelCase(table.key)}</Title>
//               <Table bordered size='small' dataSource={table.values}>
//                 {table.headings.map((heading, hI) => (
//                   <ColumnGroup key={generateKey(`${heading.group}_${i}`)} title={heading.group}>
//                     {heading.columns.map((col, cI) => {
//                       if (cI === 0 && hI === 0) {
//                         return (
//                           <Column
//                             title={col.column.includes('empty') ? '' : col.column}
//                             key={generateKey(`${col.column}_${i}`)}
//                             dataIndex={col.column}
//                             render={(record) => <Link to={`/tm-worklist/${record.replace(/ *\([^)]*\) */g, '').replace(/\s+/g, '-')}?type=coaching`} className='text-blue-400'>{record}</Link>}
//                           />
//                         )
//                       } else {
//                         return (
//                           <Column
//                             title={col.column.includes('empty') ? '' : col.column}
//                             key={generateKey(`${col.column}_${i}`)}
//                             dataIndex={col.column}
//                           />
//                         )
//                       }
//                     })}
//                   </ColumnGroup>
//                 ))}
//               </Table>
//             </React.Fragment>
//           ))}
//         </TabPane>
//       </>
//       )
//     : (
//       <Table bordered loading>
//         <Title level={4}>Fetching Tables</Title>
//         <Column />
//         <Column />
//         <Column />
//         <Column />
//         <Column />
//       </Table>
//       )}
// </Tabs>
