import { Modal, Form, Input, Select, DatePicker, Radio, Upload, Button, Card, Tag, Divider, Spin, Result, Space } from 'antd'
import React, { useReducer, useState } from 'react'
import { UploadOutlined } from '@ant-design/icons'
import dayjs from 'dayjs'
import { useGetCoaching, useMutateCoaching } from '../../../hooks/api/useCoaching'
import { useParams } from 'react-router-dom'
import { nonHookUploader } from '../../../hooks/nonHookUploader'
import Paragraph from 'antd/lib/typography/Paragraph'
import Text from 'antd/lib/typography/Text'
import { useGetPerson } from '../../../hooks/api/usePerson'
import { useCanEdit } from '../../../hooks/useCanEdit'
import { DefaultEditor } from 'react-simple-wysiwyg'

const { RangePicker } = DatePicker
const { Option } = Select

function coachingReducer (state, action) {
  const { type, payload } = action
  switch (type) {
    case 'saved': {
      return {
        ...state,
        hasChanged: false
      }
    }
    case 'stage': {
      return {
        ...state,
        stage: payload
      }
    }
    default: {
      throw new Error(`Unsupported action type: ${type}`)
    }
  }
}

export const Coaching = () => {
  // eslint-disable-next-line
  const [state, dispatch] = useReducer(coachingReducer, { stage: null, initialized: false, hasChanged: false, changedFields: {} })
  const { employeeUid } = useParams()
  const coachingMutation = useMutateCoaching()
  const types = ['OLE Coaching', 'Executive Coaching', 'Leadership Program Coaching']

  // STATE
  const [form] = Form.useForm()
  const [visible, setVisible] = useState(false)
  const currentYear = dayjs().year()
  const [year, setYear] = useState(dayjs().year())

  const coachingQuery = useGetCoaching({ employeeUid, year })
  const personQuery = useGetPerson({ authenticated: true })
  const canEdit = useCanEdit(personQuery?.data, 'coaching', window.location.pathname)

  if (coachingQuery.isError) {
    return (
      <Result
        status='warning'
        title='We are unable to fetch coaching right now'
      />
    )
  }

  return (
    <>
      <Modal
        visible={visible}
        title='Coaching'
        okText='Save'
        cancelText='Cancel'
        onCancel={() => {
          dispatch({ type: 'stage', payload: null })
          setVisible(x => !x)
        }}
        width='55%'
        onOk={() => {
          form
            .validateFields()
            .then(async (values) => {
              await coachingMutation.mutateAsync({
                employeeUid,
                year,
                data: {
                  ...values,
                  companyPersonUid: employeeUid,
                  stage: state.stage,
                  reportUrl: values.reportUrl.file.imageUrl,
                  startDate: dayjs(values?.dates[0]).format(),
                  endDate: dayjs(values?.dates[1]).format()
                }
              })

              form.resetFields()
              setVisible(false)
            })
            .catch((info) => {
              
            })
        }}
      >
        <Form
          form={form}
          layout='vertical'
          name='coaching-form'
        >
          <Form.Item
            label='Select a Start and End Date'
            name='dates'
          >
            <RangePicker />
          </Form.Item>
          <Form.Item
            label='Coach'
            name='coach'
          >
            <Input placeholder='Coach' />
          </Form.Item>
          <Form.Item
            label='Type'
            name='type'
          >
            <Select placeholder='Type'>
              {types.map(type => (
                <Option key={type} value={type}>{type}</Option>
              ))}
            </Select>
          </Form.Item>
          <Form.Item
            label='Objectives'
            name='objectives'
          >
            <DefaultEditor />
          </Form.Item>
          <Form.Item name='progress' label='Progress'>
            <Radio.Group>
              <Radio value='Not Started'>Not Started</Radio>
              <Radio value='In Progress'>In Progress</Radio>
              <Radio value='Completed'>Completed</Radio>
            </Radio.Group>
          </Form.Item>
          <Form.Item name='reportUrl' label='Final Report'>
            <Upload
              customRequest={nonHookUploader}
            >
              <Button icon={<UploadOutlined />}>Click to Upload</Button>
            </Upload>
          </Form.Item>
        </Form>
      </Modal>
      <div className='flex justify-end align-center w-full mb-4'>
        <Space>
          <Text>Year: </Text>
          <Select onChange={value => setYear(value)} defaultValue={year} style={{ width: 120 }}>
            <Option value={currentYear}>{currentYear}</Option>
            <Option value={currentYear - 1}>{currentYear - 1}</Option>
            <Option value={currentYear - 2}>{currentYear - 2}</Option>
            <Option value={currentYear - 3}>{currentYear - 3}</Option>
          </Select>
        </Space>
      </div>
      {!coachingQuery.isLoading && coachingQuery.data
        ? (
          <>
            <Card className='mb-8' title='Objective Setting'>
              {coachingQuery.data?.find(x => x.stage === 'Objective Setting')
                ? (
                  <>
                    <div size='large' className='flex gap-4 justify-between card__item w-full mb-4'>
                      <div className='w-1/3'>
                        <p className='caption'>Date Range</p>
                        <RangePicker className='w-full' defaultValue={[dayjs(coachingQuery.data?.find(x => x.stage === 'Objective Setting').startDate), dayjs(coachingQuery.data?.find(x => x.stage === 'Objective Setting').endDate)]} />
                      </div>
                      <div className='w-1/3'>
                        <p className='caption'>Type</p>
                        <Paragraph>{coachingQuery.data?.find(x => x.stage === 'Objective Setting').type}</Paragraph>
                      </div>
                      <div className='w-1/3'>
                        <p className='caption'>Progress</p>
                        <Tag color='green'>{coachingQuery.data?.find(x => x.stage === 'Objective Setting').progress}</Tag>
                      </div>
                    </div>
                    <Divider />
                    <p className='caption'>Objectives</p>
                    <DefaultEditor value={coachingQuery.data?.find(x => x.stage === 'Objective Setting').objectives} disabled />
                    <Divider />

                    <p className='caption'>Coach</p>
                    <Paragraph>{coachingQuery.data?.find(x => x.stage === 'Objective Setting').coach}</Paragraph>
                    <p className='caption'>Final Report</p>
                    <Button href={coachingQuery.data?.find(x => x.stage === 'Objective Setting').reportUrl} className='pl-0' target='_blank' type='link'>Report Url</Button>
                  </>
                  )
                : (
                  <Button
                    disabled={!canEdit}
                    className='mb-8' type='dashed' block onClick={() => {
                      dispatch({ type: 'stage', payload: 'Objective Setting' })
                      setVisible(x => !x)
                    }}
                  >
                    + Add new coaching
                  </Button>
                  )}
            </Card>
            <Card className='mb-8' title='Midway Review'>
              {coachingQuery.data?.find(x => x.stage === 'Midway Review')
                ? (
                  <>
                    <div size='large' className='flex gap-4 justify-between card__item w-full mb-4'>
                      <div className='w-1/3'>
                        <p className='caption'>Date Range</p>
                        <RangePicker className='w-full' defaultValue={[dayjs(coachingQuery.data?.find(x => x.stage === 'Midway Review').startDate), dayjs(coachingQuery.data?.find(x => x.stage === 'Midway Review').endDate)]} />
                      </div>
                      <div className='w-1/3'>
                        <p className='caption'>Type</p>
                        <Paragraph>{coachingQuery.data?.find(x => x.stage === 'Midway Review').type}</Paragraph>
                      </div>
                      <div className='w-1/3'>
                        <p className='caption'>Progress</p>
                        <Tag color='green'>{coachingQuery.data?.find(x => x.stage === 'Midway Review').progress}</Tag>
                      </div>
                    </div>
                    <Divider />
                    <p className='caption'>Objectives</p>
                    <DefaultEditor value={coachingQuery.data?.find(x => x.stage === 'Midway Review').objectives} disabled />
                    <Divider />

                    <p className='caption'>Coach</p>
                    <Paragraph>{coachingQuery.data?.find(x => x.stage === 'Midway Review').coach}</Paragraph>
                    <p className='caption'>Final Report</p>
                    <Button href={coachingQuery.data?.find(x => x.stage === 'Midway Review').reportUrl} className='pl-0' target='_blank' type='link'>Report Url</Button>
                  </>
                  )
                : (
                  <Button
                    className='mb-8' type='dashed' block onClick={() => {
                      dispatch({ type: 'stage', payload: 'Midway Review' })
                      setVisible(x => !x)
                    }}
                    disabled={!canEdit}
                  >
                    + Add new coaching
                  </Button>
                  )}
            </Card>
            <Card className='mb-8' title='Close-out Review'>
              {coachingQuery.data?.find(x => x.stage === 'Close-out Review')
                ? (
                  <>
                    <div size='large' className='flex gap-4 justify-between card__item w-full mb-4'>
                      <div className='w-1/3'>
                        <p className='caption'>Date Range</p>
                        <RangePicker className='w-full' defaultValue={[dayjs(coachingQuery.data?.find(x => x.stage === 'Close-out Review').startDate), dayjs(coachingQuery.data?.find(x => x.stage === 'Close-out Review').endDate)]} />
                      </div>
                      <div className='w-1/3'>
                        <p className='caption'>Type</p>
                        <Paragraph>{coachingQuery.data?.find(x => x.stage === 'Close-out Review').type}</Paragraph>
                      </div>
                      <div className='w-1/3'>
                        <p className='caption'>Progress</p>
                        <Tag color='green'>{coachingQuery.data?.find(x => x.stage === 'Close-out Review').progress}</Tag>
                      </div>
                    </div>
                    <Divider />
                    <p className='caption'>Objectives</p>
                    <DefaultEditor value={coachingQuery.data?.find(x => x.stage === 'Close-out Review').objectives} disabled />
                    <Divider />

                    <p className='caption'>Coach</p>
                    <Paragraph>{coachingQuery.data?.find(x => x.stage === 'Close-out Review').coach}</Paragraph>
                    <p className='caption'>Final Report</p>
                    <Button href={coachingQuery.data?.find(x => x.stage === 'Close-out Review').reportUrl} className='pl-0' target='_blank' type='link'>Report Url</Button>
                  </>
                  )
                : (
                  <Button
                    className='mb-8' type='dashed' block onClick={() => {
                      dispatch({ type: 'stage', payload: 'Close-out Review' })
                      setVisible(x => !x)
                    }}
                    disabled={!canEdit}
                  >
                    + Add new coaching
                  </Button>
                  )}
            </Card>
            <Card className='mb-8' title='Development Coaching'>
              {coachingQuery.data?.find(x => x.stage === 'Development Coaching')
                ? (
                  <>
                    <div size='large' className='flex gap-4 justify-between card__item w-full mb-4'>
                      <div className='w-1/3'>
                        <p className='caption'>Date Range</p>
                        <RangePicker className='w-full' defaultValue={[dayjs(coachingQuery.data?.find(x => x.stage === 'Development Coaching').startDate), dayjs(coachingQuery.data?.find(x => x.stage === 'Development Coaching').endDate)]} />
                      </div>
                      <div className='w-1/3'>
                        <p className='caption'>Type</p>
                        <Paragraph>{coachingQuery.data?.find(x => x.stage === 'Development Coaching').type}</Paragraph>
                      </div>
                      <div className='w-1/3'>
                        <p className='caption'>Progress</p>
                        <Tag color='green'>{coachingQuery.data?.find(x => x.stage === 'Development Coaching').progress}</Tag>
                      </div>
                    </div>
                    <Divider />
                    <p className='caption'>Objectives</p>
                    <DefaultEditor value={coachingQuery.data?.find(x => x.stage === 'Development Coaching').objectives} disabled />
                    <Divider />

                    <p className='caption'>Coach</p>
                    <Paragraph>{coachingQuery.data?.find(x => x.stage === 'Development Coaching').coach}</Paragraph>
                    <p className='caption'>Final Report</p>
                    <Button href={coachingQuery.data?.find(x => x.stage === 'Development Coaching').reportUrl} className='pl-0' target='_blank' type='link'>Report Url</Button>
                  </>
                  )
                : (
                  <Button
                    className='mb-8' type='dashed' block onClick={() => {
                      dispatch({ type: 'stage', payload: 'Development Coaching' })
                      setVisible(x => !x)
                    }}
                    disabled={!canEdit}
                  >
                    + Add new coaching
                  </Button>
                  )}
            </Card>
          </>
          )
        : (
          <div className='w-full flex items-center justify-center py-24'>
            <Spin size='large' />
          </div>
          )}
    </>
  )
}
