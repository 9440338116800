import { Button, Card, Col, Collapse, Divider, Form, Input, message, Modal, Radio, Row, Space, Tag, Tooltip, Typography } from 'antd'
import React, { useMemo, useState } from 'react'
import { useParams } from 'react-router-dom'
import { useCreatePerformanceContract } from '../hooks/api/usePerformance'
import { useGetPerson } from '../hooks/api/usePerson'
import { isMe } from '../hooks/isMe'
import { saveAs } from 'file-saver'
import { useSendNotification } from '../hooks/useSendNotification'
import uuidv4 from '../hooks/uuid'
import dayjs from 'dayjs'

export const PMFinalModal = ({ visible, title, onCancel, contract, year, employeeQuery }) => {
  const [form] = Form.useForm()
  const { employeeUid } = useParams()
  const personQuery = useGetPerson({ authenticated: true })
  const contractMutation = useCreatePerformanceContract('Successfully updated assessment')
  const [notificationToSend, setNotificationToSend] = useState(null)
  const [dynamicAverage, setDynamicAverage] = useState(0)
  // const kraObserver = Form.useWatch('kra', form)
  useSendNotification(notificationToSend)

  const initialData = useMemo(() => {
    if (contract) {
      const me = isMe(personQuery?.data, employeeUid)
      const kra = contract?.indicators?.map((x, kraI) => {
        return {
          name: x.name,
          key: uuidv4(),
          weighting: x.weighting,
          objectives: x.objectives?.map((y, oi) => {
            if (!contract?.assessments?.manager || me) {
              return {
                name: y?.name,
                key: uuidv4(),
                weighting: y?.weighting,
                rating: 0,
                comment: ''
              }
            } else {
              return {
                name: y?.name,
                key: uuidv4(),
                weighting: y?.weighting,
                rating: contract?.assessments?.manager?.response?.[kraI]?.objectives?.[oi]?.rating ?? 0,
                comment: contract?.assessments?.manager?.response?.[kraI]?.objectives?.[oi]?.comment ?? ''
              }
            }
          }) || []
        }
      })
      let idp
      if (!contract?.assessments?.manager || me) {
        idp = [{
          name: isMe(personQuery?.data, employeeUid) ? 'My Individual Development Plan' : "Employee's Individual Development Plan",
          weighting: 10,
          key: uuidv4(),
          objectives: [
            {
              name: 'Met the objectives as set out in the IDP',
              weighting: 10,
              rating: 0,
              key: uuidv4(),
              comment: ''
            }
          ]
        }]
      } else {
        idp = [{
          name: isMe(personQuery?.data, employeeUid) ? 'My Individual Development Plan' : "Employee's Individual Development Plan",
          weighting: 10,
          key: uuidv4(),
          objectives: [
            {
              name: 'Met the objectives as set out in the IDP',
              weighting: 10,
              rating: contract?.assessments?.manager?.response[contract?.assessments?.manager?.response?.length - 1]?.objectives?.[0]?.rating ?? 0,
              key: uuidv4(),
              comment: contract?.assessments?.manager?.response[contract?.assessments?.manager?.response?.length - 1]?.objectives?.[0]?.comment ?? ''
            }
          ]
        }]
      }

      return {
        valueContributor: contract?.assessments?.manager?.valueContributor || '',
        overallComment: contract?.assessments?.manager?.overallComment || '',
        kra,
        idp,
        'grouped-values': contract?.assessments?.manager?.valueOneMet || true
      }
    }
  // eslint-disable-next-line
  }, [contract])

  const calcRatingMap = {
    2: { min: 0.01, max: 0.80 },
    3: { min: 0.81, max: 1.30 },
    4: { min: 1.31, max: 1.8 },
    5: { min: 1.81, max: 2 }
  }

  const ratingDescriptorMap = {
    1: 'Unsatisfactory',
    2: 'Developing',
    3: 'Solid',
    4: 'Strong',
    5: 'Leading'
  }

  const ratingResMap = {
    0: 'Not achieved',
    1: 'Achieved',
    2: 'Exceeded'
  }

  const setCalculatedRating = function (score) {
    if (isNaN(score) || !score) return 1
    const ratings = Object.keys(calcRatingMap)
    for (let index = 0; index < ratings.length; index++) {
      const item = calcRatingMap[ratings[index]]
      if (score >= item.min && score <= item.max) return ratings[index]
    }
  }

  const handleSubmit = async (values, isSilent = false) => {
    const mutatedValues = [...values?.kra, ...values?.idp]?.map(kra => ({
      ...kra,
      objectives: kra.objectives?.map(obj => ({
        ...obj,
        weightedRating: obj?.rating * (obj?.weighting / 100)
      }))
    }))

    const payload = { ...contract?.assessments }
    const me = isMe(personQuery?.data, employeeUid)

    let objectivesTotal = 0
    let notAchievedTotal = 0

    mutatedValues?.forEach((kra) => {
      objectivesTotal += kra.objectives?.length
      kra?.objectives?.forEach(obj => {
        if (obj?.rating === 0) notAchievedTotal++
      })
    })
    if (me) {
      payload.employee = {
        response: mutatedValues,
        average: mutatedValues?.reduce((prevKra, currKra) => {
          return prevKra + currKra?.objectives?.reduce((prev, curr) => prev + curr?.weightedRating, 0)
        }, 0),
        notAchievedAsPercentage: Math.round(notAchievedTotal / objectivesTotal * 100).toFixed(2)
      }
      payload.status = contract?.assessments ? 'Pending Calibration' : 'Submitted to Manager for Approval'
    } else {
      payload.status = contract?.assessments ? 'Pending Calibration' : 'Awaiting Employee Evaluation'
      payload.manager = {
        valueOneMet: values?.['grouped-values'],
        valueTwoMet: values?.['grouped-values'],
        valueContributor: values?.valueContributor,
        overallComment: values?.overallComment,
        response: mutatedValues,
        average: mutatedValues?.reduce((prevKra, currKra) => {
          return prevKra + currKra?.objectives?.reduce((prev, curr) => prev + curr?.weightedRating, 0)
        }, 0),
        notAchievedAsPercentage: Math.round(notAchievedTotal / objectivesTotal * 100).toFixed(2)
      }
    }

    await contractMutation.mutate({
      year,
      employeeUid,
      data: {
        id: contract?.id,
        assessments: payload
      }
    }, {
      onSettled: () => {
        onCancel()
      },
      onSuccess: () => {
        if (!isSilent) {
          if (me) {
            setNotificationToSend({
              type: 'field',
              field: 'notification',
              payload: {
                to: employeeQuery?.data?.uid === '04b90619-5599-4b29-86b5-c66d9f89bb28' ? 'dan@wixels.com' : employeeQuery?.data?.uid === '9d1ae6b2-504d-4ba4-b6ae-8d0a0d50ff4b' ? 'mark@wixels.com' : employeeQuery?.data?.employment?.jobManager?.companyPerson?.email,
                message: {
                  subject: `Thungela Performance Management: ${employeeQuery?.data?.person?.firstName} ${employeeQuery?.data?.person?.lastName} self-assessment awaiting your review`,
                  html: `<div style='padding: 20px; background-color; font-family: Arial, Helvetica, sans-serif;: rgb(247, 247, 247);border-radius:10px;max-width:640px;margin:0 auto;'><img src='https://storage.i-win.io/companies/105eb5de-d4de-4222-b464-f8256f90820f/banner.png' style='width:100%;object-fit:cover;max-height:150px;border-radius:10px;' /><p >Hello ${employeeQuery?.data?.employment?.jobManager?.companyPerson?.person?.firstName} ${employeeQuery?.data?.employment?.jobManager?.companyPerson?.person?.lastName}</p><br /><p ><strong>${employeeQuery?.data?.person?.firstName} ${employeeQuery?.data?.person?.lastName}</strong> has submitted their year-end self-assessment.</p><p >Review and evaluate it here: <strong style="font-size: 1.3em;"><a href='${process.env.REACT_APP_ENV === 'production' ? 'https://thungela-talent.web.app/employees' : 'https://thungela-talent-beta.web.app/employees'}/${employeeQuery?.data?.uid}?tab=performanceManagement'>${process.env.REACT_APP_ENV === 'production' ? 'https://thungela-talent.web.app/employees' : 'https://thungela-talent-beta.web.app/employees'}/${employeeQuery?.data?.uid}?tab=performanceManagement</a></strong></p></div>`
                }
              }
            })
          } else {
            setNotificationToSend({
              type: 'field',
              field: 'notification',
              payload: {
                to: [employeeQuery?.data?.email],
                message: {
                  subject: 'Thungela Performance Management: Final year end rating pending',
                  html: `<div style='padding: 20px; background-color; font-family: Arial, Helvetica, sans-serif;: rgb(247, 247, 247);border-radius:10px;max-width:640px;margin:0 auto;'><img src='https://storage.i-win.io/companies/105eb5de-d4de-4222-b464-f8256f90820f/banner.png' style='width:100%;object-fit:cover;max-height:150px;border-radius:10px;' /><p >Hello ${employeeQuery?.data?.person?.firstName}  ${employeeQuery?.data?.person?.lastName}</p><br /><p >You will receive your performance feedback and final year-end rating once calibration is completed</p></div>`
                }
              }
            })
          }
        } else {
          console.log('I am silent')
        }
      }
    })
  }

  const handleTValues = () => {
    const values = form.getFieldsValue(true)
    if (!values?.['grouped-values']) {
      message.warn('Please note, selecting no will deduct 1 point from the employees final rating')
    }
  }

  const getScoreWithDescriptor = () => {
    if (isMe(personQuery?.data, employeeUid)) return null
    const values = form?.getFieldsValue(true)
    const mutatedValues = [...values?.kra, ...values?.idp]?.map(kra => ({
      ...kra,
      objectives: kra.objectives?.map(obj => ({
        ...obj,
        weightedRating: obj?.rating * (obj?.weighting / 100)
      }))
    }))

    const weightedAvg = mutatedValues?.reduce((prevKra, currKra) => {
      return prevKra + currKra?.objectives?.reduce((prev, curr) => prev + curr?.weightedRating, 0)
    }, 0)

    const total = setCalculatedRating(Number(weightedAvg).toFixed(3) || 0)

    setDynamicAverage({
      weightedAvg,
      total: values?.['grouped-values'] ? total : total - 1
    })
  }

  return (
    <Modal
      visible={visible}
      title={title}
      onCancel={() => onCancel()}
      footer={null}
      okText='Save'
      width='90%'
    >
      {/* <Typography.Title className='mt-6' level={5}>Key Results Area</Typography.Title> */}
      <Form
        form={form}
        layout='vertical'
        name='snapshot'
        initialValues={initialData}
        preserve
        onChange={getScoreWithDescriptor}
      >

        <Divider orientation='left'>
          <Typography.Title style={{ margin: 0, color: 'rgb(96 165 250)' }} level={4}>Assessing {(isMe(personQuery?.data, employeeUid)) ? 'my' : 'Employee\'s'} Key Results Area (KRA)</Typography.Title>
        </Divider>
        <Card size='small' className='mb-6'>
          <Form.List name='kra'>
            {(fields) => (
              <Collapse ghost defaultActiveKey={initialData?.kra?.map((kra, i) => (`${kra.name}-${i}`))}>
                {fields.map(({ key, name, fieldKey, ...restField }) => {
                  return (
                    <Collapse.Panel
                      ghost
                      key={`${initialData?.kra[key]?.name}-${key}`}
                      //
                      header={
                        <div className='flex w-full justify-between items-center'>
                          <Space size={0} direction='vertical'>
                            <p className='caption'>KRA</p>
                            <Typography.Text strong>{initialData?.kra[key]?.name}</Typography.Text>
                          </Space>
                          <Tag color='green'>{initialData?.kra[key]?.weighting}%</Tag>
                        </div>
                              }
                    >
                      <Form.List name={[name, 'objectives']} key={initialData?.kra[key]?.key}>
                        {(objectives) => (
                          <Collapse ghost defaultActiveKey={['objective']}>
                            <Collapse.Panel key='objective' header={<Typography.Text strong>{(initialData?.kra[key]?.name === 'Development Plan') ? 'Individual Development Plan Progress' : 'Objectives'}</Typography.Text>}>
                              {objectives.map((obj, oi) => (
                                <Card
                                  className='mt-4 snap-obj-card'
                                  size='small'
                                  key={`${initialData?.kra[key]?.objectives[oi]?.key}`}
                                  title={<div className='w-3/4'>{initialData?.kra[key]?.objectives[oi]?.name}<p class='caption lower text-lg'>Employee Comment: {contract?.assessments?.employee?.response?.[key]?.objectives?.[oi]?.comment || ''}</p></div>}
                                  extra={
                                    <Space>
                                      {!isMe(personQuery?.data, employeeUid) && <Tag color='blue'>Employee Evaluation: {ratingResMap[contract?.assessments?.employee?.response?.[key]?.objectives[oi]?.rating]}</Tag>}
                                      <Tag color='green'>{initialData?.kra[key]?.objectives[oi]?.weighting}%</Tag>
                                    </Space>
                                  }
                                >
                                  <Form.Item
                                    name={[obj.name, 'rating']}
                                    rules={[
                                      {
                                        required: true,
                                        message: 'Objective Rating is required'
                                      }
                                    ]}
                                  >
                                    <Radio.Group
                                      optionType='button'
                                      buttonStyle='solid'
                                    >
                                      <Radio.Button value={0}>Not achieved</Radio.Button>
                                      <Radio.Button value={1}>Achieved</Radio.Button>
                                      <Radio.Button value={2}>Exceeded</Radio.Button>
                                    </Radio.Group>
                                  </Form.Item>
                                  <Form.Item
                                    name={[obj.name, 'comment']}
                                    label='Comment'
                                    rules={[
                                      {
                                        required: true,
                                        message: 'Objective comment is required'
                                      }
                                    ]}
                                  >
                                    <Input />
                                  </Form.Item>
                                </Card>
                              ))}
                            </Collapse.Panel>
                          </Collapse>
                        )}
                      </Form.List>
                    </Collapse.Panel>
                  )
                })}
              </Collapse>
            )}
          </Form.List>
        </Card>
        <Divider orientation='left'>
          <Typography.Title style={{ margin: 0, color: 'rgb(96 165 250)' }} level={4}>{isMe(personQuery?.data, employeeUid) ? 'Assessing my Individual Development Plan (10%)' : "Assessing Employee's Individual Development Plan"}</Typography.Title>
        </Divider>
        <Card size='small' className='mb-6'>
          <Form.List name='idp'>
            {(fields) => (
              <Collapse ghost defaultActiveKey={initialData?.idp?.map((idp, i) => (`${idp.name}-${i}`))}>
                {fields.map(({ key, name, fieldKey, ...restField }) => {
                  return (
                    <Collapse.Panel ghost key={`${initialData?.idp[key]?.name}-${key}`} header={<Typography.Text strong>{initialData?.idp[key]?.name}</Typography.Text>}>
                      <Form.List name={[name, 'objectives']} key={initialData?.idp[key]?.key}>
                        {(objectives) => (
                          <Collapse ghost defaultActiveKey={['objective']}>
                            <Collapse.Panel key='objective' header={<Typography.Text strong>{(initialData?.idp[key]?.name === 'Development Plan') ? 'Individual Development Plan Progress' : 'Objectives'}</Typography.Text>}>
                              {objectives.map((obj, oi) => (
                                <Card
                                  className='mt-4 snap-obj-card'
                                  size='small'
                                  key={`${initialData?.idp[key]?.objectives[oi]?.key}`}
                                  title={<div className='w-3/4'>{initialData?.idp[key]?.objectives[oi]?.name}<p class='caption lower text-lg'>Employee Comment: {contract?.assessments?.employee?.response?.[contract?.assessments?.employee?.response?.length - 1]?.objectives[0]?.comment}</p></div>}
                                  extra={!isMe(personQuery?.data, employeeUid) && <Tag color='blue'>Employee Evaluation: {ratingResMap[contract?.assessments?.employee?.response?.[contract?.assessments?.employee?.response?.length - 1]?.objectives[0]?.rating]}</Tag>}
                                >
                                  <Form.Item
                                    name={[obj.name, 'rating']}
                                    rules={[
                                      {
                                        required: true,
                                        message: 'Objective Rating is required'
                                      }
                                    ]}
                                  >
                                    <Radio.Group
                                      optionType='button'
                                      buttonStyle='solid'
                                    >
                                      <Radio.Button value={0}>Not achieved</Radio.Button>
                                      <Radio.Button value={1}>Achieved</Radio.Button>
                                      <Radio.Button value={2}>Exceeded</Radio.Button>
                                    </Radio.Group>
                                  </Form.Item>
                                  <Form.Item
                                    name={[obj.name, 'comment']}
                                    label='Comment'
                                    rules={[
                                      {
                                        required: true,
                                        message: 'Objective comment is required'
                                      }
                                    ]}
                                  >
                                    <Input />
                                  </Form.Item>
                                </Card>
                              ))}
                            </Collapse.Panel>
                          </Collapse>
                        )}
                      </Form.List>
                    </Collapse.Panel>
                  )
                })}
              </Collapse>
            )}
          </Form.List>
          <Divider orientation='left'>Development Plans</Divider>
          <Collapse ghost>
            {contract?.developmentPlans?.map(plan => (
              <Collapse.Panel key={JSON.stringify(plan)} header={<Typography.Text strong>{plan?.need}</Typography.Text>}>
                <Card className='relative mb-6 shadow-xl'>
                  <p className='caption'>Development Need</p>
                  <p>{plan?.need}</p>
                  <Row gutter={32} className='mt-4'>
                    <Col xs={24} md={12}>
                      <p className='caption'>Timeframe</p>
                      <Space className='mb-4'>
                        <p>{dayjs(plan?.dates?.[0]).format('DD/MM/YYYY')}</p>
                        -
                        <p>{dayjs(plan?.dates?.[1]).format('DD/MM/YYYY')}</p>
                      </Space>
                      <p className='caption'>Development Type</p>
                      <p className='mb-4'>{plan?.developmentType}</p>
                    </Col>
                    <Col xs={24} md={12}>
                      <p className='caption'>Support Required from Organisation / Management</p>
                      <p className='mb-4'>{plan?.resourcesNeeded}</p>
                      <p className='caption'>What Development Gaps will this address or what is your desired outcome?</p>
                      <p className='mb-4'>{plan?.developmentGaps}</p>
                    </Col>
                  </Row>
                  {/* <p className='caption'>Development Need</p> */}
                  <p className='caption'>Note Detail on Activity • <span className='text-blue-500'>What will I do to achieve this?</span></p>
                  <p>{plan?.note}</p>
                </Card>
              </Collapse.Panel>
            ))}
          </Collapse>
        </Card>
        {!isMe(personQuery?.data, employeeUid) && (
          <>
            <Divider orientation='left'><Typography.Title style={{ margin: 0, color: 'rgb(96 165 250)' }} level={4}>Were the following values demonstrated?</Typography.Title></Divider>
            <Card className='mb-6'>
              <Row className='mb-8' gutter={30}>
                {contract?.companyValues?.map((val, index) => (
                  <Col span={12} key={val?.value}>
                    <Card title={`Value ${index + 1}: ${val?.value}`}>
                      <Typography.Text type='secondary'>
                        <div dangerouslySetInnerHTML={{ __html: val?.comment }} />
                      </Typography.Text>
                    </Card>
                  </Col>
                ))}

              </Row>

              <Form.Item
                label={
                  <Typography.Text strong>Note: Managers to ensure that supporting justification is available to motivate the selection of Values Detractor</Typography.Text>
                    }
                rules={[
                  {
                    required: true,
                    message: 'Required'
                  }
                ]}
                name='grouped-values'
              >
                <Radio.Group
                  optionType='button'
                  buttonStyle='solid'
                  onChange={handleTValues}
                >
                  <Radio.Button value>Yes</Radio.Button>
                  <Radio.Button value={false}>No</Radio.Button>
                </Radio.Group>
              </Form.Item>
              <Form.Item
                name='valueContributor'
                label='Commentary on Thungela values'
                rules={[
                  {
                    required: true,
                    message: 'Commentary on Thungela values is required'
                  }
                ]}
              >
                <Input />
              </Form.Item>
            </Card>
            {!isMe(personQuery?.data, employeeUid) && (
              <>
                <Divider orientation='left'><Typography.Title style={{ margin: 0, color: 'rgb(96 165 250)' }} level={4}>Overall Comment</Typography.Title></Divider>
                <Card className='mb-8'>
                  <Form.Item
                    name='overallComment'
                    placeholder='Overall Comment'
                    rules={[
                      {
                        required: true,
                        message: 'Overall comment is required'
                      }
                    ]}
                  >
                    <Input />
                  </Form.Item>
                </Card>
              </>
            )}

            {!isMe(personQuery?.data, employeeUid) && (
              <>
                <Divider orientation='left'><Typography.Title style={{ margin: 0, color: 'rgb(96 165 250)' }} level={4}>Proposed Rating</Typography.Title></Divider>
                <Card>
                  <p className='caption lower'>Based on your evaluation of the employee’s performance, the employee’s calculated rating is:</p>
                  <p className='text-xl font-bold'>{ratingDescriptorMap[dynamicAverage.total]}</p>
                </Card>
              </>
            )}
          </>
        )}
        <div className='flex justify-end gap-4 mt-4'>
          <Button onClick={() => onCancel()}>Cancel</Button>
          {!isMe(personQuery?.data, employeeUid) && (
            <Tooltip title='Thungela Rating Scale and Decision Tree'>
              <Button onClick={() => saveAs('/rating_tree.pdf', 'Thungela Rating Scale and Decision Tree.pdf')} className='bg-blue-400' type='primary'>Thungela Rating Scale and Decision Tree</Button>
            </Tooltip>
          )}

          <Button
            loading={contractMutation.isLoading} type='primary' htmlType='submit' onClick={async () => {
              form.validateFields()
                .then(async () => {
                  await handleSubmit(form.getFieldsValue(true), contract?.assessments?.status === 'Pending Calibration')
                }).catch(() => {
                  message.warn('Please fill in all fields')
                })
            }} className='bg-blue-400'
          >Submit
          </Button>
        </div>
      </Form>
    </Modal>
  )
}
