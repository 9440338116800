import { getDownloadURL, ref, uploadBytesResumable } from '@firebase/storage'
import { config, storage } from '../firebase'

export const nonHookUploader = async ({ onError, onSuccess, file, onProgress }) => {
  const fileLocation = 'talent/' + file.name
  const storageRef = ref(storage, fileLocation)
  try {
    const uploadTask = uploadBytesResumable(storageRef, file)

    uploadTask.on('state_changed',
      (snapshot) => onProgress({ percent: (snapshot.bytesTransferred / snapshot.totalBytes) * 100 }),
      (error) => onError(error),
      () => {
        getDownloadURL(uploadTask.snapshot.ref).then((downloadURL) => {
          file.imageUrl = 'https://' + config.storageBucket + '/' + fileLocation
          onSuccess(null, downloadURL)
        })
      }
    )
  } catch (error) {
    onError(error)
  }
}
