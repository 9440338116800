import { Collapse, Divider, Empty, Select, Table, Card } from 'antd'
import React, { useMemo, useState } from 'react'
import { performanceTable } from '../constants/performanceTable'
import { useGetHods, useTeamTableByHOD } from '../hooks/api/usePerformance'
import uuidv4 from '../hooks/uuid'

const { Panel } = Collapse
const { Option } = Select

export const PerformanceTables = ({ employee, year, isPdf }) => {
  const [hodUid, setHodUid] = useState(() => window.localStorage.getItem('hod'))

  const hodsQuery = useGetHods()
  const teamTableQuery = useTeamTableByHOD({ year: year, hodUid, enabled: !!hodUid })

  const determineTeamTable = () => {
    const execsUids = [
      'e9b44e5a-7f5c-4db3-b3b6-41c7b35f74e4',
      'f207712d-3eaf-4fb6-859f-16075d48b40a',
      'a7a8ec2b-ce91-40a7-a57e-a4578ff53692',
      '7a7a9bb3-374d-4f3c-8c61-6877855e2b37',
      '2b44b632-7091-47e7-b272-ed4c8a8ab190',
      '60520a89-71c8-4a8b-b47a-59b900012ffa',
      '9dc10028-fdfe-41c8-a362-92fa4a131702'
    ]
    const hod = hodsQuery?.data?.find(x => x?.uid === hodUid)

    if (!hod) {
      return {
        columns: [],
        data: []
      }
    }
    if (!execsUids.includes(hod?.uid)) {
      return {
        columns: [],
        data: []
      }
    }
    return {
      columns: performanceTable?.functionalObjectives?.[hod?.uid]?.columns,
      data: performanceTable?.functionalObjectives?.[hod?.uid]?.data
    }
  }
  const teamTable = useMemo(() => {
    if (hodUid) {
      const execsUids = [
        'e9b44e5a-7f5c-4db3-b3b6-41c7b35f74e4',
        'f207712d-3eaf-4fb6-859f-16075d48b40a',
        'a7a8ec2b-ce91-40a7-a57e-a4578ff53692',
        '7a7a9bb3-374d-4f3c-8c61-6877855e2b37',
        '2b44b632-7091-47e7-b272-ed4c8a8ab190',
        '60520a89-71c8-4a8b-b47a-59b900012ffa',
        '9dc10028-fdfe-41c8-a362-92fa4a131702'
      ]
      if (execsUids?.includes(hodUid)) {
        return {
          columns: performanceTable?.functionalObjectives?.[hodUid]?.columns,
          data: performanceTable?.functionalObjectives?.[hodUid]?.data
        }
      } else if (!teamTableQuery.isLoading && teamTableQuery.data && !execsUids?.includes(hodUid)) {
        return {
          columns: performanceTable.teamObjectives.columns,
          data: teamTableQuery.data?.indicators?.length ? teamTableQuery.data?.indicators?.map(indicator => ({ ...indicator, key: uuidv4(), children: indicator?.objectives?.map(obj => ({ kra: obj?.name })) })) || [] : []
        }
      }
    }
  }, [teamTableQuery.isLoading, teamTableQuery.data, hodUid])

  if (isPdf) {
    return (
      <>
        <Card
    // eslint-disable-next-line
      extra={!isPdf && contract?.status?.replace(/\'/g, '') !== 'draft' && [
        // eslint-disable-next-line
        <Tag key='status' color='blue'>{contract?.status?.replace(/\'/g, '')}</Tag>
      ]}
          title={
            <div className='flex items-center gap-2'>
              <p>Team Objectives</p>
            </div>
}
        >
          {hodUid && (
            <>
              {!teamTableQuery.isLoading && teamTableQuery.data
                ? (
                  <Table
                    size={isPdf && 'small'}
                    bordered
                    pagination={false}
                    columns={performanceTable.teamObjectives.columns}
                    dataSource={teamTableQuery.data?.indicators?.map(indicator => ({ ...indicator, key: uuidv4(), children: indicator?.objectives?.map(obj => ({ kra: obj?.name })) })) || []}
                    expandable={{
                      defaultExpandAllRows: isPdf
                    }}
                  />
                  )
                : determineTeamTable().data?.length
                  ? (
                    <Table
                      size={isPdf && 'small'}
                      bordered
                      pagination={false}
                      columns={determineTeamTable().columns}
                      dataSource={determineTeamTable().data}
                      expandable={{
                        defaultExpandAllRows: isPdf
                      }}
                    />
                    )
                  : (
                    <Empty description={<p>Team Objectives are not yet created</p>} />
                    )}
            </>
          )}
          {!hodUid && (
            <>
              <Empty description={<p>No HOD selected</p>} />
            </>
          )}
        </Card>
      </>
    )
  } else {
    return (
      <>
        <Collapse ghost defaultActiveKey={isPdf && ['1', '2', '3']}>
          <Panel className='testPanel' header='Business Objectives' key='1'>
            <Table
              size={isPdf && 'small'}
              expandable={{
                defaultExpandAllRows: isPdf
              }}
              bordered
              pagination={false}
              columns={performanceTable.businessObjectives.columns}
              dataSource={performanceTable.businessObjectives.data}
            />
          </Panel>
          <Panel
            className='testPanel'
            header={employee?.talentCompanyPerson?.jobLocation === 'Coal Shared Services' ? 'Functional Objectives' : 'Asset Objectives'}
            key='2'
          >
            {/* <Table bordered pagination={false} columns={performanceTable.functionalObjectives.columns} dataSource={performanceTable.functionalObjectives.data} /> */}
            {employee.talentCompanyPerson?.jobLocation === 'Coal Shared Services'
              ? (
                <Table
                  size={isPdf && 'small'}
                  expandable={{
                    defaultExpandAllRows: isPdf
                  }}
                  bordered
                  pagination={false}
                  columns={
                    performanceTable?.functionalObjectives[employee?.talentCompanyPerson?.discipline]
                      ? performanceTable?.functionalObjectives[employee?.talentCompanyPerson?.discipline]?.columns
                      : []
                  }
                  dataSource={
                    performanceTable?.functionalObjectives[employee?.talentCompanyPerson?.discipline]
                      ? performanceTable?.functionalObjectives[employee?.talentCompanyPerson?.discipline]?.data
                      : []
                  }
                />
                )
              : (
                <Table
                  size={isPdf && 'small'}
                  expandable={{
                    defaultExpandAllRows: isPdf
                  }}
                  bordered
                  pagination={false}
                  columns={
                    performanceTable?.functionalObjectives[employee?.talentCompanyPerson?.jobLocation]
                      ? performanceTable?.functionalObjectives[employee?.talentCompanyPerson?.jobLocation]?.columns
                      : []
                  }
                  dataSource={
                    performanceTable?.functionalObjectives[employee?.talentCompanyPerson?.jobLocation]
                      ? performanceTable?.functionalObjectives[employee?.talentCompanyPerson?.jobLocation]?.data
                      : []
                  }
                />
                )}
          </Panel>
          <Panel className='testPanel' header='Team Objectives' key='3'>
            {!isPdf && (
              <>
                <p className='text-gray-400 text-sm mb-2'>Head of Department</p>
                <Select
                  className='mb-4 w-full'
                  onChange={e => {
                    setHodUid(e)
                    window.localStorage.setItem('hod', e)
                  }} loading={hodsQuery.isLoading} disabled={hodsQuery.isLoading || hodsQuery.isError} placeholder='Select HOD'
                >
                  {!hodsQuery.isLoading && hodsQuery.data && hodsQuery.data.map(hod => (
                    <Option key={hod.uid} value={hod.uid}>{hod.person.firstName} {hod.person.lastName}</Option>
                  ))}
                </Select>
              </>
            )}
            {hodUid && teamTable &&
            (
              <>
                <Table
                  size={isPdf && 'small'}
                  bordered
                  pagination={false}
                  columns={teamTable?.columns}
                  dataSource={teamTable?.data}
                  expandable={{
                    defaultExpandAllRows: isPdf
                  }}
                />
              </>
            )}
          </Panel>
        </Collapse>
        <Divider />
      </>
    )
  }
}
