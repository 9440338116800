import { Modal, Steps, Form, Collapse, Input, Typography, Card, Divider, Button, Col, Row, message } from 'antd'
import dayjs from 'dayjs'
import React, { useState, useMemo } from 'react'
import { useParams } from 'react-router-dom'
import { useCreatePerformanceContract } from '../hooks/api/usePerformance'
import { useGetPerson } from '../hooks/api/usePerson'
import { isMe } from '../hooks/isMe'
import { useSendNotification } from '../hooks/useSendNotification'
import uuidv4 from '../hooks/uuid'

export const SnapshotModal = ({ visible, title, onCancel, contract, year, query, snapIdx, employeeQuery }) => {
  // eslint-disable-next-line
  const [step, setStep] = useState(0)
  const [notificationToSend, setNotificationToSend] = useState(null)
  const [form] = Form.useForm()
  const { employeeUid } = useParams()
  const contractMutation = useCreatePerformanceContract('Successfully updated snapshot')
  const personQuery = useGetPerson({ authenticated: true })
  useSendNotification(notificationToSend)

  const initialData = useMemo(() => {
    if (contract) {
      const kraKey = contract?.indicators ? 'indicators' : 'kra'
      const kra = contract[kraKey]?.map(x => ({
        name: x.name,
        weighting: x.weighting,
        assessment: x.assessment || null,
        objectives: x.objectives?.map(y => ({
          name: y?.name,
          type: 'Objective',
          assessment: y?.assessment,
          weighting: y?.weighting
        })) || []
      }))
      const idpKey = contract?.developmentPlans ? 'developmentPlans' : 'idp'
      const idp = contract[idpKey]?.map(plan => ({ ...plan, assessment: plan.assessment }))
      // return data
      return {
        managerComment: contract?.managerComment || '',
        kra,
        idp
      }
    }
  }, [contract])

  const handleNext = () => {
    form
      .validateFields()
      .then(() => setStep(x => x + 1))
      .catch(() => {
        message.error('Please write an assessment for all KRAs and Objectives')
      })
  }

  const handleSubmit = async (payload, status = 'draft') => {
    let reviews = query?.reviews
    if (!reviews) {
      if (snapIdx > 0) {
        reviews = [null, { ...payload, status }]
      } else {
        reviews = [{ ...payload, status }]
      }
    } else {
      reviews[snapIdx] = { ...payload, status }
    }

    console.log(status)

    await contractMutation.mutate({
      year,
      employeeUid,
      data: {
        id: contract?.id,
        reviews
      }
    }, {
      onSettled: () => {
        onCancel()
      },
      onSuccess: () => {
        switch (status) {
          case 'Submitted Snapshot to Manager for Approval':
            setNotificationToSend({
              type: 'field',
              field: 'notification',
              payload: {
                to: employeeQuery?.data?.uid === '04b90619-5599-4b29-86b5-c66d9f89bb28' ? 'dan@wixels.com' : employeeQuery?.data?.employment?.jobManager?.companyPerson?.email,
                message: {
                  subject: `Thungela Performance Management: ${employeeQuery?.data?.person?.firstName} ${employeeQuery?.data?.person?.lastName} Snaphot ${snapIdx ? snapIdx + 1 : ''} awaiting your approval`,
                  html: `<div style='padding: 20px; background-color; font-family: Arial, Helvetica, sans-serif;: rgb(247, 247, 247);border-radius:10px;max-width:640px;margin:0 auto;'><img src='https://storage.i-win.io/companies/105eb5de-d4de-4222-b464-f8256f90820f/banner.png' style='width:100%;object-fit:cover;max-height:150px;border-radius:10px;' /><p >Hello ${employeeQuery?.data?.employment?.jobManager?.companyPerson?.person?.firstName}  ${employeeQuery?.data?.employment?.jobManager?.companyPerson?.person?.lastName}</p><br /><p ><strong>${employeeQuery?.data?.person?.firstName} ${employeeQuery?.data?.person?.lastName}</strong> has submitted their mid year snapshot.</p><p >Review and approve it here:  <strong style="font-size: 1.3em;"><a href='${process.env.REACT_APP_ENV === 'production' ? 'https://thungela-talent.web.app/employees' : 'https://thungela-talent-beta.web.app/employees'}/${employeeQuery?.data?.uid}?tab=performanceManagement'>${process.env.REACT_APP_ENV === 'production' ? 'https://thungela-talent.web.app/employees' : 'https://thungela-talent-beta.web.app/employees'}/${employeeQuery?.data?.uid}?tab=performanceManagement</a></strong></p></div>`
                }
              }
            })
            break

          case 'Snapshot Finalised':
            setNotificationToSend({
              type: 'field',
              field: 'notification',
              payload: {
                to: [employeeQuery?.data?.email],
                message: {
                  subject: 'Thungela Performance Management: Snapshot Finalised',
                  html: `<div style='padding: 20px; background-color; font-family: Arial, Helvetica, sans-serif;: rgb(247, 247, 247);border-radius:10px;max-width:640px;margin:0 auto;'><img src='https://storage.i-win.io/companies/105eb5de-d4de-4222-b464-f8256f90820f/banner.png' style='width:100%;object-fit:cover;max-height:150px;border-radius:10px;' /><p >Hello ${employeeQuery?.data?.person?.firstName}  ${employeeQuery?.data?.person?.lastName}</p><br /><p ><strong>${employeeQuery?.data?.employment?.jobManager?.companyPerson?.person?.firstName} ${employeeQuery?.data?.employment?.jobManager?.companyPerson?.person?.lastName}</strong> has approved your mid year snapshot.</p><p >Review it here:  <strong style="font-size: 1.3em;"><a href='${process.env.REACT_APP_ENV === 'production' ? 'https://thungela-talent.web.app/employees' : 'https://thungela-talent-beta.web.app/employees'}/${employeeQuery?.data?.uid}?tab=performanceManagement'>${process.env.REACT_APP_ENV === 'production' ? 'https://thungela-talent.web.app/employees' : 'https://thungela-talent-beta.web.app/employees'}/${employeeQuery?.data?.uid}?tab=performanceManagement</a></strong></p></div>`
                }
              }
            })
            break
          case 'Changes Requested':
            setNotificationToSend({
              type: 'field',
              field: 'notification',
              payload: {
                to: [employeeQuery?.data?.email],
                message: {
                  subject: 'Thungela Performance Management: Snapshot Changes Requested',
                  html: `<div style='padding: 20px; background-color; font-family: Arial, Helvetica, sans-serif;: rgb(247, 247, 247);border-radius:10px;max-width:640px;margin:0 auto;'><img src='https://storage.i-win.io/companies/105eb5de-d4de-4222-b464-f8256f90820f/banner.png' style='width:100%;object-fit:cover;max-height:150px;border-radius:10px;' /><p >Hello ${employeeQuery?.data?.person?.firstName}  ${employeeQuery?.data?.person?.lastName}</p><br /><p ><strong>${employeeQuery?.data?.employment?.jobManager?.companyPerson?.person?.firstName} ${employeeQuery?.data?.employment?.jobManager?.companyPerson?.person?.lastName}</strong> has requested changes to your mid year snapshot.</p><p >Follow this link:  <strong style="font-size: 1.3em;"><a href='${process.env.REACT_APP_ENV === 'production' ? 'https://thungela-talent.web.app/employees' : 'https://thungela-talent-beta.web.app/employees'}/${employeeQuery?.data?.uid}?tab=performanceManagement'>${process.env.REACT_APP_ENV === 'production' ? 'https://thungela-talent.web.app/employees' : 'https://thungela-talent-beta.web.app/employees'}/${employeeQuery?.data?.uid}?tab=performanceManagement</a></strong></p></div>`
                }
              }
            })
            break
          default:
            break
        }
      }
    })
  }

  return (
    <Modal
      visible={visible}
      title={title}
      onCancel={() => onCancel()}
      footer={null}
      okText='Save'
      width='90%'
    >
      <>
        <Steps current={step}>
          <Steps.Step title="Review KRA's" />
          <Steps.Step title="Review IDP's" />
        </Steps>
        <Form
          form={form}
          layout='vertical'
          name='snapshot'
          initialValues={initialData}
          preserve
        >
          {(() => {
            switch (step) {
              case 0:
                return (
                  <>
                    <Typography.Title className='mt-6' level={5}>Key Results Area</Typography.Title>
                    <Card size='small' className='mb-6'>
                      <Form.List name='kra'>
                        {(fields) => (
                          <Collapse ghost defaultActiveKey={contract?.indicators?.map((kra, i) => (`${kra.name}-${i}`))}>
                            {fields.map(({ key, name, fieldKey, ...restField }) => (
                              <Collapse.Panel ghost key={`${initialData?.kra[key]?.name}-${key}`} header={<Typography.Text strong>{initialData?.kra[key]?.name}</Typography.Text>}>
                                <Form.Item
                                  {...restField}
                                  name={[name, 'assessment']}
                                  label={isMe(personQuery?.data, employeeUid) ? 'KRA Comment' : 'Employees Comment'}
                                  rules={[
                                    {
                                      required: true,
                                      message: 'KRA Comment is required'
                                    }
                                  ]}
                                >
                                  <Input.TextArea autoSize style={{ color: 'black' }} disabled={!isMe(personQuery?.data, employeeUid)} bordered={!!isMe(personQuery?.data, employeeUid)} placeholder='KRA Comment' />
                                </Form.Item>
                                <Divider />
                                <Form.List name={[name, 'objectives']} key={uuidv4()}>
                                  {(objectives) => (
                                    <Collapse ghost defaultActiveKey={['objective']}>
                                      <Collapse.Panel key='objective' header={<Typography.Text strong>Objectives</Typography.Text>}>
                                        {objectives.map((obj, oi) => (
                                          <Card className='mt-4 snap-obj-card' size='small' title={initialData?.kra[key]?.objectives[oi]?.name} key={`${initialData?.kra[key]?.objectives[oi]?.name}-${oi}`}>
                                            <Form.Item
                                              name={[obj.name, 'assessment']}
                                              label={isMe(personQuery?.data, employeeUid) ? 'Objective Comment' : 'Employees Comment'}
                                              rules={[
                                                {
                                                  required: true,
                                                  message: 'Objective Comment is required'
                                                }
                                              ]}
                                            >
                                              <Input.TextArea autoSize style={{ color: 'black' }} disabled={!isMe(personQuery?.data, employeeUid)} bordered={!!isMe(personQuery?.data, employeeUid)} placeholder='Objective comment' />
                                            </Form.Item>
                                          </Card>
                                        ))}
                                      </Collapse.Panel>
                                    </Collapse>
                                  )}
                                </Form.List>
                              </Collapse.Panel>
                            ))}
                          </Collapse>
                        )}
                      </Form.List>
                    </Card>

                    {/* AS EMPLOYEE */}
                    {employeeUid === personQuery.data?.companyPerson?.uid && contract?.status === 'Snapshot Finalised' && contract?.status === 'Submitted Snapshot to Manager for Approval' && (
                      <>
                        <Typography.Title level={5}>Summarised Manager Feedback</Typography.Title>
                        <p>{contract?.managerComment}</p>
                      </>
                    )}
                    {/* AS MANAGER */}
                    {employeeUid !== personQuery.data?.companyPerson?.uid && contract?.status === 'Submitted Snapshot to Manager for Approval' && (
                      <Form.Item
                        label={<Typography.Title level={5}>Summarised Manager Feedback</Typography.Title>}
                        name='managerComment'
                      >
                        <Input.TextArea placeholder='Summarised Manager Feedback' />
                      </Form.Item>
                    )}
                    <div className='flex justify-end gap-4 mt-4'>
                      <Button onClick={() => onCancel()}>Cancel</Button>
                      <Button loading={contractMutation.isLoading} type='primary' htmlType='submit' onClick={() => handleSubmit(form.getFieldsValue(true))} className='bg-blue-400'>Save as Draft</Button>
                      <Button type='primary' htmlType='button' onClick={() => handleNext()} className='bg-blue-400'>Next</Button>
                    </div>
                  </>
                )
              case 1:
                return (
                  <>
                    <Form.List name='idp'>
                      {(fields) => (
                        <>
                          {fields.map(({ key, name, fieldKey, ...restField }) => {
                            const plan = initialData?.idp[key]
                            return (
                              <React.Fragment key={key}>
                                <Card className='relative mt-8 mb-6 shadow-xl'>
                                  <p className='caption'>Development Need</p>
                                  <Typography.Text>{plan.need}</Typography.Text>
                                  <Row gutter='32'>
                                    <Col xs={24} md={12}>
                                      <p className='caption mt-6'>Timeframe</p>
                                      <Typography.Text>{dayjs(plan?.startDate).format('DD/MM/YYYY')} - {dayjs(plan?.endDate).format('DD/MM/YYYY')}</Typography.Text>

                                      <p className='caption mt-6'>Development Type</p>
                                      <Typography.Text>{plan?.developmentType}</Typography.Text>
                                    </Col>
                                    <Col xs={24} md={12}>
                                      <p className='caption mt-6'>Support Required from Organisation / Management</p>
                                      <Typography.Text>{plan?.resourcesNeeded}</Typography.Text>
                                      <p className='caption mt-6'>What Development Gaps will this address or what is your desired outcome?</p>
                                      <Typography.Text>{plan?.developmentGaps}</Typography.Text>
                                    </Col>
                                  </Row>
                                  <p className='caption mt-6'>Note Detail on Activity • <span className='text-blue-500'>What will I do to achieve this?</span></p>
                                  <Typography.Text>{plan?.note}</Typography.Text>
                                </Card>
                                <Form.Item
                                  {...restField}
                                  name={[name, 'assessment']}
                                  label='IDP Comment'
                                  rules={[
                                    {
                                      required: true,
                                      message: 'IDP Comment is required'
                                    }
                                  ]}
                                >
                                  <Input disabled={personQuery.data?.companyPerson?.uid !== employeeUid} placeholder='IDP Comment' />
                                </Form.Item>
                                <Divider />
                              </React.Fragment>
                            )
                          })}
                        </>
                      )}
                    </Form.List>
                    {/* AS EMPLOYEE */}
                    {employeeUid === personQuery.data?.companyPerson?.uid && (contract?.status === 'Snapshot Finalised' || contract?.status === 'Changes Requested') && (
                      <>
                        <Typography.Title level={5}>Summarised Manager Feedback</Typography.Title>
                        <p>{contract?.managerComment}</p>
                      </>
                    )}
                    {/* AS MANAGER */}
                    {employeeUid !== personQuery.data?.companyPerson?.uid && contract?.status === 'Submitted Snapshot to Manager for Approval' && (
                      <Form.Item
                        label={<Typography.Title level={5}>Summarised Manager Feedback</Typography.Title>}
                        name='managerComment'
                      >
                        <Input.TextArea placeholder='Summarised Manager Feedback' />
                      </Form.Item>
                    )}
                    <div className='flex justify-end gap-4 mt-4'>
                      <Button onClick={() => setStep(x => x - 1)}>Previous</Button>
                      {/* IF IT IS A EMPLOYEE */}
                      {employeeUid === personQuery.data?.companyPerson?.uid && (
                        <>
                          <Button loading={contractMutation.isLoading} type='primary' htmlType='submit' onClick={() => handleSubmit(form.getFieldsValue(true))} className='bg-blue-400'>Save as Draft</Button>
                          <Button loading={contractMutation.isLoading} type='primary' htmlType='submit' onClick={() => handleSubmit(form.getFieldsValue(true), 'Submitted Snapshot to Manager for Approval')} className='bg-blue-400'>Submit to Manager for Approval</Button>
                        </>
                      )}
                      {/* IF IT IS A MANAGER */}
                      {employeeUid !== personQuery.data?.companyPerson?.uid && (
                        <>
                          {(contract?.status === 'draft' || contract?.status === 'Submitted Snapshot to Manager for Approval') && (
                            <>
                              <Button loading={contractMutation.isLoading} type='primary' htmlType='submit' onClick={() => handleSubmit(form.getFieldsValue(true), 'Changes Requested')} className='bg-blue-400'>Request Changes</Button>
                              <Button loading={contractMutation.isLoading} type='primary' htmlType='submit' onClick={() => handleSubmit(form.getFieldsValue(true), 'Snapshot Finalised')} className='bg-blue-400'>Approve Snapshot</Button>
                            </>
                          )}
                        </>
                      )}
                    </div>
                  </>
                )

              default: {
                return (
                  <div className='flex items-center justify-center text-center p-16'>
                    Contract Modal
                  </div>
                )
              }
            }
          })()}
        </Form>

        {/* <Typography.Title level={5}>Key Results Area(s)</Typography.Title>
        <KRATable tableData={kraData} setTableData={setKraData} />
        {idpData && idpData?.length
          ? (
            <>
              <Typography.Title className='my-6' level={5}>IDP(s)</Typography.Title>
              <IDPTable tableData={idpData} setTableData={setIdpData} />
            </>
            )
          : null}
        <Typography.Title className='my-6' level={5}>Summarised Manager Feedback</Typography.Title>
        <TextArea value={comment} onChange={e => setComment(e.currentTarget.value)} /> */}
      </>
    </Modal>
  )
}
