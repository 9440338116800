import { message } from 'antd'
import axios from 'axios'
import { getAuth, getIdToken } from 'firebase/auth'
import { useMutation, useQuery, useQueryClient } from 'react-query'

export const useGetEmployeeEngagement = ({ employeeUid }) => {

  return useQuery(
    ['employeeEngagement', employeeUid],
    async () => axios.get(
      `${process.env.REACT_APP_API_URL}/talent/employees/${employeeUid}/employeeEngagements`,
      {
        headers: {
          Authorization: `Bearer ${await getIdToken(getAuth().currentUser)}`,
          'X-Tenant-Id': process.env.REACT_APP_TENANT_ID
        }
      }
    )
      .then(res => res.data.data),
    {
      staleTime: 1000 * 60 * 5,
      enabled: !!employeeUid,
      retry: false,
      onError: (error) => {
        message.error(`Failed to fetch employee engagement: ${error.response.data.message}`)
      }
    }
  )
}

export const useUpdateEmployeeEngagement = () => {
  const queryClient = useQueryClient()
  return useMutation(
    async (body) => axios.put(
      `${process.env.REACT_APP_API_URL}/talent/employees/${body.employeeUid}/employeeEngagements`,
      body.data,
      {
        headers: {
          Authorization: `Bearer ${await getIdToken(getAuth().currentUser, true)}`,
          'X-Tenant-Id': process.env.REACT_APP_TENANT_ID
        }
      }
    )
      .then(res => res.data),
    {
      onError: (error) => {
        message.error(`Failed to update employee engagements: ${error.response.data.message}`)
      },
      onSuccess: () => {
        message.success('Successfully updated employee engagements')
        queryClient.invalidateQueries('employee')
      }
    }
  )
}
