import { Tag } from 'antd'
import { uniqueId } from 'lodash'

export const talentProfileTable = {
  school: {
    columns: [
      {
        title: 'Grade Passed',
        dataIndex: 'gradePassed',
        key: 'gradePassed'
      },
      {
        title: 'Year Obtained',
        dataIndex: 'yearObtained',
        key: 'yearObtained'
      },
      {
        title: 'School',
        dataIndex: 'school',
        key: 'school'
      },
      {
        title: 'Location',
        dataIndex: 'location',
        key: 'location'
      }
    ]
  },
  tertiary: {
    columns: [
      {
        title: 'Institution Name',
        dataIndex: 'institutionName',
        key: 'institutionName'
      },
      {
        title: 'Year Obtained',
        dataIndex: 'yearObtained',
        key: 'yearObtained'
      },
      {
        title: 'Description',
        dataIndex: 'degreeDescription',
        key: 'degreeDescription'
      },
      {
        title: 'Location',
        dataIndex: 'location',
        key: 'location'
      }
    ]
  },
  certs: {
    columns: [
      {
        title: 'Description',
        dataIndex: 'degreeDescription',
        key: 'degreeDescription'
      },
      {
        title: 'Year Obtained',
        dataIndex: 'yearObtained',
        key: 'yearObtained'
      }

    ]
  },
  legal: {
    columns: [
      {
        title: 'Name',
        dataIndex: 'name',
        key: 'name'
      }
    ]
  },
  mobility: {
    columns: [
      {
        title: 'Mobility Status',
        dataIndex: 'mobility',
        key: 'mobility'
      },
      {
        title: 'Mobility Comments',
        dataIndex: 'mobilityComments',
        key: 'mobilityComments'
      }
    ]
  },
  languages: {
    columns: [
      {
        title: 'Language',
        dataIndex: 'language',
        key: 'language'
      },
      {
        title: 'Spoken',
        dataIndex: 'Spoken',
        key: 'Spoken'
      },
      {
        title: 'Written',
        dataIndex: 'Written',
        key: 'Written'
      }
    ]
  },
  hixtory: {
    columns: [
      {
        title: 'Title',
        dataIndex: 'title',
        key: 'title'
      },
      {
        title: 'Timeframe',
        dataIndex: 'duration',
        key: 'duration',
        render: (duration) => (
          <Tag color='green'>{duration}</Tag>
        )
      },
      {
        title: 'Description',
        dataIndex: 'description',
        key: 'description',
        render: desc => (
          <div dangerouslySetInnerHTML={{ __html: desc }} />
        )
      },
      {
        title: 'Discipline',
        dataIndex: 'discipline',
        key: 'discipline'
      },
      {
        title: 'Areas of Experience',
        dataIndex: 'areasOfExp',
        key: 'areasOfExp',
        render: (aoe) => (
          <>
            {aoe.map(tag => (
              <Tag key={uniqueId()} color='blue'>{tag}</Tag>
            ))}
          </>
        )
      },
      {
        title: 'Business Unit / Function',
        dataIndex: 'function',
        key: 'function'
      },
      {
        title: 'Location',
        dataIndex: 'location',
        key: 'location'
      }
    ]
  }
}
