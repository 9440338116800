import { message } from 'antd'
import axios from 'axios'
import { getAuth, getIdToken } from 'firebase/auth'
import { useMutation, useQuery, useQueryClient } from 'react-query'

export const useGetTalentID = ({ employeeUid, year }) => {
  return useQuery(
    ['talentID', employeeUid, year],
    async () => axios.get(
      `${process.env.REACT_APP_API_URL}/talent/employees/${employeeUid}/talentId/${year}`,
      {
        headers: {
          Authorization: `Bearer ${await getIdToken(getAuth().currentUser, true)}`,
          'X-Tenant-Id': process.env.REACT_APP_TENANT_ID
        }
      }
    )
      .then(res => res.data),
    {
      enabled: !!employeeUid,
      retry: false,
      onError: (error) => {
        message.error(`Failed to fetch talent identification: ${error.response.data.message}`)
      }
    }
  )
}

export const useUpdateTalentID = () => {
  const queryClient = useQueryClient()
  return useMutation(
    async (body) => axios.put(
      `${process.env.REACT_APP_API_URL}/talent/employees/${body.employeeUid}/talentId/${body.year}`,
      body.data,
      {
        headers: {
          Authorization: `Bearer ${await getIdToken(getAuth().currentUser, true)}`,
          'X-Tenant-Id': process.env.REACT_APP_TENANT_ID
        }
      }
    )
      .then(res => res.data),
    {
      onSuccess: () => {
        queryClient.invalidateQueries('employee')
        message.success('Successfully updated talent identification')
      },
      onError: (error) => {
        message.error(`Failed to updated talent identification: ${error.response.data.message}`)
      }
    }
  )
}
