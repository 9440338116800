import { Card, Row, Typography, Divider, Tabs, Col, Empty, Spin, Space, Tag } from 'antd'
import React from 'react'
import { Link, useParams } from 'react-router-dom'
import { useGetSuccessionPlans } from '../../hooks/api/useSuccession'
import { useQueryParam } from '../../hooks/useQueryParam'
import { makeId } from '../../hooks/uuid'
import { ArrowLeftOutlined } from '@ant-design/icons'

export const Succession = () => {
  const successionPlansQuery = useGetSuccessionPlans({})
  const queryParam = useQueryParam()

  const { discipline } = useParams()

  function handleTabsChange (key) {
    const newUrl = window.location.protocol + '//' + window.location.host + window.location.pathname + '?tab=' + key
    window.history.pushState({ path: newUrl }, '', newUrl)
  }

  return (
    <div className='succession__page'>
      <Link to='/succession-planner' className='flex gap-6 items-center mt-8 mb-4'>
        <ArrowLeftOutlined className='text-xl' />
        <Typography.Title style={{ margin: 0 }}>Succession Plans ({discipline})</Typography.Title>
      </Link>
      <p className='caption'>Select a succession plan</p>
      <Divider />
      {!successionPlansQuery.isLoading
        ? (
          <Tabs defaultActiveKey={queryParam.get('tab') || '1'} onChange={handleTabsChange}>
            {[3, 4, 5, 6].map((gbf) => {
              const plans = successionPlansQuery.data.groupRoleSuccessionPlans?.filter(
                (x) => x?.discipline?.includes(discipline) && x?.payScaleGroups?.includes(`GBF${gbf}`)
              )

              if (!plans?.length) return null

              return (
                <Tabs.TabPane key={gbf} tab={`GBF ${gbf}`}>
                  <Row gutter={16}>
                    {plans
                      .sort((a, b) => (a.role < b.role ? -1 : a.role > b.role ? 1 : 0))
                      .map((plan) => (
                        <Col key={makeId(5)} xs={12} lg={8} xl={6}>
                          <Link to={`/succession-planner/${discipline}/${plan?.talentRole?.id}?tab=${gbf}`}>
                            <Card className='mb-8'>
                              <Space>
                                <p className='text-lg font-bold'>{plan.role}</p>
                                {plan?.isCritical ? <Tag color='orange'>Critical</Tag> : null}
                              </Space>
                              <p className='text-xs text-gray-400'>{plan?.discipline?.[0] ?? ''}</p>
                            </Card>
                          </Link>
                        </Col>
                      ))}
                    {!plans.length && <Empty />}
                  </Row>
                </Tabs.TabPane>
              )
            })}
          </Tabs>
          )
        : <Spin />}

    </div>
  )
}
