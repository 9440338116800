import { Avatar, Col, List, Space } from 'antd'
import React from 'react'
import { Link } from 'react-router-dom'

export const SuccessionGroup = ({ query, range, title, color, style }) => {
  const handlePeopleList = (rangeKey) => {
    if (!query?.data?.layout[rangeKey]) return []

    const arrWithNoDupes = Array.from(new Set(query?.data?.layout[rangeKey]))
    return arrWithNoDupes.map(uid => ({ ...query?.data?.employees[uid], uid: uid }))
  }

  return (
    <Col className='mb-8' xs={12} xl={6}>
      <List
        dataSource={handlePeopleList(range)}
        header={<div className={`absolute top-0 right-0 left-0 ${color} p-4 text-white font-bold text-lg rounded-lg`} style={style}>{title}</div>}
        className='p-4 pt-8'
        itemLayout='horizontal'
        renderItem={person => (
          <List.Item key={person.uid + Math.floor(Math.random() * 101)}>
            <List.Item.Meta
              avatar={<Avatar src={`https://api.dicebear.com/7.x/initials/svg?seed=${person.person?.firstName[0]}`} />}
              title={<Link to={`/employees/${person.uid}`}>{person.person?.firstName} {person.person?.lastName}</Link>}
              description={
                <Space size='small' direction='vertical'>
                  <p>{person.talentCompanyPerson.levelOfWork} | {person.sex} | {person.race}</p>
                  <p>{person.employment.jobTitle}</p>
                </Space>
    }
            />
          </List.Item>
        )}
      />
    </Col>
  )
}
