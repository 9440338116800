import { message } from 'antd'
import axios from 'axios'
import { getAuth, getIdToken } from 'firebase/auth'
import { useMutation, useQuery, useQueryClient } from 'react-query'

export const useGetWorklist = (filters) => {
  let params = {}

  Object.keys(filters).forEach(key => {
    if (filters[key]) {
      params = {
        ...params,
        [key]: filters[key]
      }
    }
  })

  const urlParams = new URLSearchParams(params).toString()

  return useQuery(
    ['worklist', params],
    async () => axios.get(
      `${process.env.REACT_APP_API_URL}/talent/worklist${urlParams.length ? '?' + urlParams : ''}`,
      {
        headers: {
          Authorization: `Bearer ${await getIdToken(getAuth().currentUser)}`,
          'X-Tenant-Id': process.env.REACT_APP_TENANT_ID
        }
      }
    )
      .then(res => res.data.data),
    {
      staleTime: 1000 * 60 * 5,
      retry: false,
      onError: (error) => {
        message.error(`Failed to fetch worklist: ${error.response.data.message}`)
      }
    }
  )
}
export const useGetPMWorklist = (filters) => {
  let params = {}

  Object.keys(filters).forEach(key => {
    if (filters[key]) {
      params = {
        ...params,
        [key]: filters[key]
      }
    }
  })

  const urlParams = new URLSearchParams(params).toString()

  return useQuery(
    ['pm-worklist', params],
    async () => axios.get(
      `${process.env.REACT_APP_API_URL}/talent/performance/reporting${urlParams.length ? '?' + urlParams : ''}`,
      {
        headers: {
          Authorization: `Bearer ${await getIdToken(getAuth().currentUser)}`,
          'X-Tenant-Id': process.env.REACT_APP_TENANT_ID
        }
      }
    )
      .then(res => res.data.data),
    {
      staleTime: 1000 * 60 * 5,
      retry: false,
      onError: (error) => {
        message.error(`Failed to fetch worklist: ${error.response.data.message}`)
      }
    }
  )
}

export const useUploadCSV = () => {
  const queryClient = useQueryClient()
  return useMutation(
    async (body) => axios.post(
      `${process.env.REACT_APP_API_URL}/talent/performance/reporting`,
      body.data,
      {
        headers: {
          Authorization: `Bearer ${await getIdToken(getAuth().currentUser)}`,
          'X-Tenant-Id': process.env.REACT_APP_TENANT_ID
        }
      }
    )
      .then(res => res.data),
    {
      onSuccess: () => {
        message.success('Successfully uploaded calibration CSV')
        queryClient.invalidateQueries('pm-worklist')
        queryClient.invalidateQueries('contracts')
      },
      onError: (error) => {
        message.error(`Failed to upload calibration csv: ${error.response.data.message}`)
      }
    }
  )
}
