import { PDFExport } from '@progress/kendo-react-pdf'
import { Button, Modal, Typography, Tag, Table } from 'antd'
import dayjs from 'dayjs'
import React, { useRef } from 'react'
import { PdfHeader } from './PdfHeader'
import { ProfileCard } from './ProfileCard'

export const CareerObjectivesPdf = ({ state, employeeQuery, preview, setPreview }) => {
  const pdfExportComponent = useRef(null)
  const exportPDFWithComponent = () => {
    if (pdfExportComponent.current) {
      pdfExportComponent.current.save()
    }
  }
  const data = [
    {
      role: state.longTermObjective,
      band: state.longTermCareerInterest,
      careerInterestDescription: state.longTermDescription,
      term: 'Long Term (3-5 Years)'
    },
    {
      role: state.shortTermObjective,
      band: state.shortTermCareerInterest,
      careerInterestDescription: state.shortTermDescription,
      term: 'Short Term (1-2 Years)'
    }
  ]

  const columns = [
    {
      title: 'Role',
      dataIndex: 'role',
      key: 'role'
    },
    {
      title: 'Term',
      dataIndex: 'term',
      key: 'term',
      render: tag => (
        <Tag color='green'>
          {tag}
        </Tag>
      )
    },
    {
      title: 'Band',
      dataIndex: 'band',
      key: 'band'
    },
    {
      title: 'Career interest Description',
      dataIndex: 'careerInterestDescription',
      key: 'careerInterestDescription',
      render: desc => (
        <div dangerouslySetInnerHTML={{ __html: desc }} />
      )
    }
  ]

  return (
    <Modal
      visible={preview}
      okText='Export as PDF'
      title='Career Objectives PDF'
      onCancel={() => setPreview(x => !x)}
      footer={[
        <Button key='cancel' onClick={() => setPreview(x => !x)}>Cancel</Button>,
        <Button onClick={exportPDFWithComponent} key='submit' type='primary' className='bg-blue-400'>Export as PDF</Button>
      ]}
      width='90%'
    >
      <PDFExport fileName={`Career-Objectives-${dayjs().format('DD/MM/YYYY')}`} ref={pdfExportComponent} margin='2cm'>
        <div className='p-6 relative' style={{ fontWeight: 'initial', fontVariant: 'initial', lineHeight: 'initial' }}>
          {/* <div className='p-2 rounded-md w-fit absolute top-2 right-2 z-50' style={{ backgroundColor: '#333333' }}><img src='/images/thungela.png' alt='brand logo' className='h-8  object-cover' /></div> */}
          <PdfHeader title='Career Objectives PDF' />
          <ProfileCard person={employeeQuery.data} />
          <Typography.Title
            style={{
              marginTop: '3rem'
            }} level={4}
          >Career Objectives
          </Typography.Title>
          <Table size='small' bordered pagination={false} columns={columns} dataSource={data} />
        </div>
      </PDFExport>
    </Modal>
  )
}
