import React, { useEffect, useReducer, useState } from 'react'
import { Affix, Button, Card, Result, Row, Spin } from 'antd'
import Paragraph from 'antd/lib/typography/Paragraph'
import { SaveOutlined } from '@ant-design/icons'
import { useGetCareerObjectives, useUpdateCareerObjectives } from '../../../hooks/api/useCareerObjectives'
import { useParams } from 'react-router'
import { useGetPerson } from '../../../hooks/api/usePerson'
import { useCanEdit } from '../../../hooks/useCanEdit'
import { useGetSingleEmplyee } from '../../../hooks/api/useEmployees'
import { CareerObjectivesPdf } from '../../../components/CareerObjectivesPdf'

function objectiveReducer (state, action) {
  const { type, payload, field } = action
  switch (type) {
    case 'initialize': {
      return {
        ...state,
        initialized: true,
        ...payload
      }
    }
    case 'field': {
      return {
        ...state,
        hasChanged: true,
        [field]: payload,
        changedFields: {
          ...state.changedFields,
          [field]: payload
        }
      }
    }
    case 'objectives': {
      return [...state, payload]
    }
    case 'saved': {
      return {
        ...state,
        hasChanged: false
      }
    }
    default: {
      throw new Error(`Unsupported action type: ${type}`)
    }
  }
}

export const CareerObjectives = () => {
  // CONSTANTS
  const { employeeUid } = useParams()

  // STATE
  const [state, dispatch] = useReducer(objectiveReducer, { initialized: false, hasChanged: false, changedFields: {} })
  const [saving, setSaving] = useState(false)
  const [preview, setPreview] = useState(false)

  // NETWORK REQS
  const careerObjsQuery = useGetCareerObjectives({ employeeUid })
  const careerObjMutation = useUpdateCareerObjectives()
  const personQuery = useGetPerson({ authenticated: true })
  const canEdit = useCanEdit(personQuery?.data, 'careerObjectives', window.location.pathname)
  const employeeQuery = useGetSingleEmplyee({ employeeUid })

  // EFFECTS
  useEffect(() => {
    if (!careerObjsQuery.isLoading) {
      let initialObj = {}
      if (careerObjsQuery.data) {
        initialObj = {
          shortTermCareerInterest: careerObjsQuery.data.shortTermCareerInterest || '',
          shortTermDescription: careerObjsQuery.data.shortTermDescription || '',
          shortTermObjective: careerObjsQuery.data.shortTermObjective || '',
          longTermCareerInterest: careerObjsQuery.data.longTermCareerInterest || '',
          longTermDescription: careerObjsQuery.data.longTermDescription || '',
          longTermObjective: careerObjsQuery.data.longTermObjective || ''
        }
      }
      dispatch({ type: 'initialize', payload: initialObj })
    }
  }, [careerObjsQuery.isLoading, careerObjsQuery.data, careerObjsQuery.isError])

  if (careerObjsQuery.isError) {
    return (
      <Result
        status='warning'
        title='We are unable to fetch career objectives right now'
      />
    )
  }

  return (
    <>
      {!careerObjsQuery.isLoading && state.initialized
        ? (
          <>
            {!employeeQuery.isLoading && employeeQuery.data && canEdit && (
              <Row className='px-4 mb-4' justify='end'>
                <Button type='primary' className='bg-blue-400' onClick={() => setPreview(true)}>Preview PDF</Button>
              </Row>
            )}
            <CareerObjectivesPdf preview={preview} setPreview={setPreview} state={state} employeeQuery={employeeQuery} />
            <Card className='mb-8' title='Short Term (1-2 Years)'>
              <div size='large' className='flex justify-between card__item w-full mb-4'>
                <div className='w-1/2' direction='vertical'>
                  <p className='caption'>Role</p>
                  <Paragraph className='editable__field' editable={canEdit && { onChange: (e) => dispatch({ type: 'field', field: 'shortTermObjective', payload: e }) }}>{state.shortTermObjective}</Paragraph>
                </div>
                <div className='w-1/2' direction='vertical'>
                  <p className='caption'>Band</p>
                  <Paragraph className='editable__field' editable={canEdit && { onChange: (e) => dispatch({ type: 'field', field: 'shortTermCareerInterest', payload: e }) }}>{state.shortTermCareerInterest}</Paragraph>
                </div>
              </div>
              <p className='caption'>Career interest Description</p>
              <Paragraph className='editable__field' editable={canEdit && { onChange: (e) => dispatch({ type: 'field', field: 'shortTermDescription', payload: e }) }}>{state.shortTermDescription}</Paragraph>
            </Card>
            <Card className='mb-8' title='Long Term (3-5 Years)'>
              <div size='large' className='flex justify-between card__item w-full mb-4'>
                <div className='w-1/2' direction='vertical'>
                  <p className='caption'>Role</p>
                  <Paragraph className='editable__field' editable={canEdit && { onChange: (e) => dispatch({ type: 'field', field: 'longTermObjective', payload: e }) }}>{state.longTermObjective}</Paragraph>
                </div>
                <div className='w-1/2' direction='vertical'>
                  <p className='caption'>Band</p>
                  <Paragraph className='editable__field' editable={canEdit && { onChange: (e) => dispatch({ type: 'field', field: 'longTermCareerInterest', payload: e }) }}>{state.longTermCareerInterest}</Paragraph>
                </div>
              </div>
              <p className='caption'>Career interest Description</p>
              <Paragraph className='editable__field' editable={canEdit && { onChange: (e) => dispatch({ type: 'field', field: 'longTermDescription', payload: e }) }}>{state.longTermDescription}</Paragraph>
            </Card>
            {state.hasChanged && (
              <Affix style={{ position: 'absolute', bottom: 65, right: 30 }} offsetBottom={65}>
                <Button
                  loading={saving}
                  onClick={async () => {
                    setSaving(true)
                    await careerObjMutation.mutateAsync({ employeeUid, data: state.changedFields })
                    dispatch({ type: 'saved' })
                    setSaving(false)
                  }}
                  type='primary' className='bg-blue-400'
                  shape='round'
                  size='large'
                  icon={<SaveOutlined />}
                >
                  {saving ? 'Saving' : 'Save'} Changes
                </Button>
              </Affix>
            )}
          </>
          )
        : (
          <div className='w-full flex items-center justify-center py-24'>
            <Spin size='large' />
          </div>
          )}
    </>
  )
}
