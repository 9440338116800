import { message } from 'antd'
import axios from 'axios'
import { getAuth, getIdToken } from 'firebase/auth'
import { useMutation, useQuery, useQueryClient } from 'react-query'

export const useGetReporting = (filters) => {
  let params = {}

  Object.keys(filters).forEach(key => {
    if (filters[key]) {
      params = {
        ...params,
        [key]: filters[key]
      }
    }
  })

  const urlParams = new URLSearchParams(params).toString()

  return useQuery(
    ['reporting', params],
    async () => axios.get(
      `${process.env.REACT_APP_API_URL}/talent/reporting${urlParams.length ? '?' + urlParams : ''}`,
      {
        headers: {
          Authorization: `Bearer ${await getIdToken(getAuth().currentUser)}`,
          'X-Tenant-Id': process.env.REACT_APP_TENANT_ID
        }
      }
    )
      .then(res => res.data.data),
    {
      staleTime: 1000 * 60 * 30,
      onError: (error) => {
        message.error(`Failed to fetch reporting: ${error.response.data.message}`)
      }
    }
  )
}

export const useGetReportingItem = ({ type }) => {
  return useQuery(
    ['reportingItem', type],
    async () => axios.get(
      `${process.env.REACT_APP_API_URL}/talent/reporting/${type}`,
      {
        headers: {
          Authorization: `Bearer ${await getIdToken(getAuth().currentUser)}`,
          'X-Tenant-Id': process.env.REACT_APP_TENANT_ID
        }
      }
    )
      .then(res => res.data.data),
    {
      staleTime: 1000 * 60 * 30,
      onError: (error) => {
        message.error(`Failed to fetch reporting item: ${error.response.data.message}`)
      }
    }
  )
}
export const useGetReportingDump = (filters) => {
  let params = {}

  Object.keys(filters).forEach(key => {
    if (filters[key]) {
      params = {
        ...params,
        [key]: filters[key]
      }
    }
  })

  const urlParams = new URLSearchParams(params).toString()

  return useQuery(
    ['reportingDump', filters],
    async () => axios.get(
      `${process.env.REACT_APP_API_URL}/talent/reporting/dump${urlParams.length ? '?' + urlParams : ''}`,
      {
        headers: {
          Authorization: `Bearer ${await getIdToken(getAuth().currentUser)}`,
          'X-Tenant-Id': process.env.REACT_APP_TENANT_ID
        }
      }
    )
      .then(res => res.data.data),
    {
      retry: false,
      staleTime: 1000 * 60,
      onError: (error) => {
        message.error(`Failed to fetch reporting dump: ${error.response.data.message}`)
      }
    }
  )
}

export const useCreateReportingNote = () => {
  const queryClient = useQueryClient()
  return useMutation(
    async (body) => axios.post(
      `${process.env.REACT_APP_API_URL}/talent/reporting/note`,
      body.data,
      {
        headers: {
          Authorization: `Bearer ${await getIdToken(getAuth().currentUser, true)}`,
          'X-Tenant-Id': process.env.REACT_APP_TENANT_ID
        }
      }
    )
      .then(res => res.data),
    {
      onError: (error) => {
        message.error(`Failed to create reporting note: ${error.response.data.message}`)
      },
      onSuccess: () => {
        queryClient.invalidateQueries('reportingItem')
        queryClient.invalidateQueries('reporting')
        message.success('Successfully created reporting note')
      }
    }
  )
}
