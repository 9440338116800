import { Button, Space, Select, Tabs, Typography, Row, Col, Statistic, Card, Tooltip, Skeleton, Empty, Radio, Divider } from 'antd'
import React, { useReducer, useRef, useState } from 'react'
import { useQueryParam } from '../../hooks/useQueryParam'
import { useGetLookup } from '../../hooks/api/useLookups'
import { useGetReporting, useGetReportingDump } from '../../hooks/api/useReporting'
import dayjs from 'dayjs'
import { PDFExport } from '@progress/kendo-react-pdf'
import { useSimpleExporter } from '../../hooks/useExporter'
import uuidv4 from '../../hooks/uuid'
import { PdfHeader } from '../../components/PdfHeader'

const { Option } = Select
const { TabPane } = Tabs
const currentYear = dayjs().year()

const determineQuarter = (currYear, selectedYear) => {
  let q = null

  if (selectedYear === currYear) {
    if (dayjs().isAfter(`${currentYear}-03-31`, 'month')) q = '1'
    if (dayjs().isAfter(`${currentYear}-06-30`, 'month')) q = '2'
    if (dayjs().isAfter(`${currentYear}-09-30`, 'month')) q = '3'
  } else if (selectedYear && selectedYear < currYear) {
    q = '4'
  }

  return q
}

const reportingReducer = (state, action) => {
  const { type, field, payload } = action
  switch (type) {
    case 'field': {
      return {
        ...state,
        [field]: payload,
        quarter: field === 'year' ? determineQuarter(currentYear, payload) : state.quarter
      }
    }
    case 'clear': {
      return {
        ...state,
        department: null,
        discipline: null,
        location: null
      }
    }
    default: {
      throw new Error(`Unsupported action type: ${type}`)
    }
  }
}

export const Reporting = () => {
  const [state, dispatch] = useReducer(reportingReducer, { location: null, discipline: null, department: null, year: null, quarter: null })
  const [execute, setExecute] = useState(false)

  const pdfDashboard = useRef(null)
  const pdfScorecard = useRef(null)

  const availTabs = ['dashboard', 'scorecard']
  const queryParam = useQueryParam()

  const currentYear = dayjs().year()
  const fakeData = Array.from({ length: 3 }, (_, index) => currentYear - index - 1)

  function handleTabsChange (key) {
    const newUrl = window.location.protocol + '//' + window.location.host + window.location.pathname + '?tab=' + key
    window.history.pushState({ path: newUrl }, '', newUrl)
  }

  const reportingQuery = useGetReporting({ ...state })
  const reportingDump = useGetReportingDump({ year: state?.year })
  const locationLookups = useGetLookup({ group: 'Dynamic', key: 'JobLocation' })
  const disciplineLookups = useGetLookup({ group: 'Dynamic', key: 'Discipline' })
  const departmentLookups = useGetLookup({ group: 'Dynamic', key: 'Department' })

  useSimpleExporter({
    execute,
    name: 'reporting_data',
    callback: () => {
      setExecute(false)
    },
    payload: reportingDump?.data
  })

  const exportDashPdf = () => {
    if (pdfDashboard.current) {
      pdfDashboard.current.save()
    }
  }
  const exportScorecardPdf = () => {
    if (pdfScorecard.current) {
      pdfScorecard.current.save()
    }
  }

  function groupItemsByGroup (items) {
    if (!items) return null
    return items.reduce((groups, item) => {
      const { group, ...rest } = item
      if (!groups[group]) {
        groups[group] = []
      }
      groups[group].push(rest)
      return groups
    }, {})
  }

  return (
    <div className='reporting__page'>
      {/* <PageHeader style={{ paddingLeft: 0 }} title='Thungela Talent Report' /> */}
      <div className='w-full flex justify-between mb-6 mt-8'>
        <Space direction='horizontal'>
          <Space direction='vertical'>
            <p className='text-gray-400'>Department</p>
            <Select onChange={(value) => dispatch({ type: 'field', field: 'department', payload: value })} placeholder='Select Department' value={state.department} loading={departmentLookups.isLoading} disabled={departmentLookups.isError || !departmentLookups.data} style={{ minWidth: 150 }}>
              <Option value={null}>None</Option>
              {!departmentLookups.isLoading && departmentLookups.data && departmentLookups.data.map(option => (
                <Option key={option} value={option}>{option}</Option>
              ))}
            </Select>
          </Space>
          <Space direction='vertical'>
            <p className='text-gray-400'>Discipline</p>
            <Select onChange={(value) => dispatch({ type: 'field', field: 'discipline', payload: value })} placeholder='Select Discipline' value={state.discipline} loading={disciplineLookups.isLoading} disabled={disciplineLookups.isError || !disciplineLookups.data} style={{ minWidth: 150 }}>
              <Option value={null}>None</Option>
              {!disciplineLookups.isLoading && disciplineLookups.data && disciplineLookups.data.map(option => (
                <Option key={option} value={option}>{option}</Option>
              ))}
            </Select>
          </Space>
          <Space direction='vertical'>
            <p className='text-gray-400'>Location</p>
            <Select onChange={(value) => dispatch({ type: 'field', field: 'location', payload: value })} placeholder='Select location' value={state.location} loading={locationLookups.isLoading} disabled={locationLookups.isError || !locationLookups.data} style={{ minWidth: 150 }}>
              <Option value={null}>None</Option>
              {!locationLookups.isLoading && locationLookups.data && locationLookups.data.map(option => (
                <Option key={option} value={option}>{option}</Option>
              ))}
            </Select>
          </Space>
          {(state.location || state.discipline || state.department) && <Button className='mt-7' shape='round' onClick={() => dispatch({ type: 'clear' })}>Clear</Button>}
        </Space>
        <Space>
          {!reportingDump?.isLoading && reportingDump?.data
            ? (
              <Space direction='vertical'>
                <p className='text-gray-400'>Talent Data</p>
                <Button onClick={() => setExecute(true)} className='bg-blue-400' type='primary'>
                  Download
                </Button>
              </Space>
              )
            : null}
          {!reportingQuery?.isLoading && reportingQuery?.data
            ? (
              <Space direction='vertical'>
                <p className='text-gray-400'>Download Pdf</p>
                <Button onClick={exportDashPdf} className='bg-blue-400' type='primary'>Thungela Talent Report</Button>
              </Space>
              )
            : null}
          {!reportingQuery?.isLoading && reportingQuery?.data
            ? (
              <Space direction='vertical'>
                <p className='text-gray-400'>Scorecard Pdf</p>
                <Button onClick={exportScorecardPdf} className='bg-blue-400' type='primary'>Scorecard Report</Button>
              </Space>
              )
            : null}
          <Space direction='vertical'>
            <p className='text-gray-400'>Year</p>
            <Select onChange={(value) => dispatch({ type: 'field', field: 'year', payload: value })} defaultValue={state.year} style={{ width: 120 }}>
              <Option value={null}>Current</Option>
              <Option value={currentYear}>{currentYear}</Option>
              <Option value={currentYear - 1}>{currentYear - 1}</Option>
              <Option value={currentYear - 2}>{currentYear - 2}</Option>
              <Option value={currentYear - 3}>{currentYear - 3}</Option>
            </Select>
          </Space>
          {state.year && (
            <Space direction='vertical'>
              <p className='text-gray-400'>Quarter</p>
              <Radio.Group
                onChange={(e) => dispatch({ type: 'field', field: 'quarter', payload: e.target.value })}
                defaultValue={determineQuarter(currentYear, state.year)}
                buttonStyle='solid'
                value={state.quarter}
              >
                <Tooltip title={state.year === currentYear && dayjs().isBefore(`${currentYear}-04-01`, 'month') ? `Please select 'Current' to view ${currentYear}'s data` : ''}>
                  <Radio.Button disabled={fakeData.includes(state.year) || (state.year === currentYear && dayjs().isBefore(`${currentYear}-04-01`, 'month'))} value='1'>First</Radio.Button>
                  <Radio.Button disabled={fakeData.includes(state.year) || (state.year === currentYear && dayjs().isBefore(`${currentYear}-07-01`, 'month'))} value='2'>Second</Radio.Button>
                  <Radio.Button disabled={fakeData.includes(state.year) || (state.year === currentYear && dayjs().isBefore(`${currentYear}-10-01`, 'month'))} value='3'>Third</Radio.Button>
                  <Radio.Button disabled={state.year === currentYear} value='4'>Fourth</Radio.Button>
                </Tooltip>
              </Radio.Group>

            </Space>
          )}
        </Space>
      </div>
      {!reportingQuery.isLoading && reportingQuery?.data
        ? (
          <>
            {!reportingQuery.isError && reportingQuery.data?.length > 0
              ? (
                <>
                  <Tabs defaultActiveKey={availTabs.includes(queryParam.get('tab')) ? queryParam.get('tab') : availTabs[0]} onChange={handleTabsChange}>
                    <TabPane key='dashboard' tab='Dashboard View'>
                      <PDFExport fileName={`Reporting-Dashboard-${dayjs().format('DD/MM/YYYY')}`} ref={pdfDashboard} landscape margin='1cm'>
                        <div style={{ fontWeight: 'initial', fontVariant: 'initial', lineHeight: 'initial' }}>

                          <PdfHeader title='Thungela Talent Report' />
                          <Row className='mb-4' gutter={['16', '16']}>
                            <Col xs={24} xl={12}>
                              {reportingQuery?.data?.filter(x => x?.tab === 'Dashboard View' && x?.reportKey === 'talentPools')?.map((report, ri) => (
                                <React.Fragment key={report?.reportKey}>

                                  <Typography.Title className='mt-4 text-red-600' level={4}>{report?.name}</Typography.Title>
                                  <p className='caption mb-6'>
                                    {report?.description}
                                  </p>

                                  <Card title={`Actual: ${report?.resultAsString}`} extra={`Target: ${report?.target * 100} %`} style={{ marginBottom: '1rem' }} size='small'>
                                    <div className='flex items-center justify-center'>
                                      <div className='flex items-center justify-center flex-col rounded-full h-[12.2rem] w-[12.2rem] brown-row '>
                                        <p>{report?.bubbles?.[0]?.label}</p>
                                        <p className='font-bold text-lg'>{report?.bubbles?.[0]?.value}%</p>
                                      </div>
                                      <div className='z-10 -ml-6 mt-6 flex items-center justify-center flex-col rounded-full h-40 w-40 yellow-row '>
                                        <p>{report?.bubbles?.[1]?.label}</p>
                                        <p className='font-bold text-lg'>{report?.bubbles?.[1]?.value}%</p>
                                      </div>
                                      <div className='-ml-6 flex items-center justify-center flex-col rounded-full h-[12.2rem] w-[12.2rem] gray-row '>
                                        <p>{report?.bubbles?.[2]?.label}</p>
                                        <p className='font-bold text-lg'>{report?.bubbles?.[2]?.value}%</p>
                                      </div>
                                    </div>
                                  </Card>

                                </React.Fragment>
                              ))}
                            </Col>
                            <Col xs={24} xl={12}>
                              {reportingQuery?.data?.filter(x => x?.tab === 'Dashboard View' && x?.reportKey === 'totalCapability')?.map((report, ri) => (
                                <React.Fragment key={report?.reportKey}>
                                  <Col span={24}>
                                    <Typography.Title className='mt-4 text-red-600' level={4}>{report?.name}</Typography.Title>
                                    <p className='caption mb-6'>
                                      Total Employees Capable in Role by Band
                                    </p>
                                    {report?.target
                                      ? (
                                        <Space className='mb-6'>
                                          <Card>
                                            <Statistic title='Actual' value={report?.result} />
                                          </Card>
                                          <Card>
                                            <Statistic title='Target' value={`${Math.abs(report?.target * 100)}%`} />
                                          </Card>
                                        </Space>
                                        )
                                      : null}
                                  </Col>
                                  {report?.tables?.map((table, ti) => (
                                    <Col key={uuidv4()} xs={24} lg={report?.tables?.length > 1 ? 12 : 24}>
                                      <Card title='Total Capability Table' style={{ marginBottom: '1rem' }} size='small'>
                                        <div className='ant-table-wrapper ant-table-container ant-table ant-table-small ant-table-bordered ant-table-content'>
                                          <table style={{ tableLayout: 'auto' }}>
                                            <thead className='ant-table-thead'>
                                              <tr>
                                                {table?.headings?.map((heading, hi) => (
                                                  <th className='ant-table-cell brown-row' key={`${ti}-${hi}`}>{heading}</th>
                                                ))}
                                              </tr>
                                            </thead>
                                            <tbody className='ant-table-tbody'>
                                              {table?.data?.map((row, ri) => (
                                                <tr className='ant-table-row ant-table-row-level-0' key={`${ti}-${ri}`}>
                                                  {row?.map((col, ci) => {
                                                    const style = table.styles.find(s => (s.r === '*' || s.r === ri) && (s.c === '*' || s.c === ci))
                                                    const className = style ? `ant-table-cell ${style.highlight}-row` : 'ant-table-cell'
                                                    return (
                                                      <td key={`${ti}-${ri}-${ci}`} className={className}>{col} <span className='text-xs'>%</span></td>
                                                    )
                                                  })}
                                                </tr>
                                              ))}
                                            </tbody>
                                          </table>
                                        </div>
                                      </Card>
                                    </Col>
                                  ))}
                                </React.Fragment>
                              ))}
                            </Col>
                            {reportingQuery?.data?.filter(x => x?.tab === 'Dashboard View' && x?.reportKey === 'hdp')?.map((report, ri) => (
                              <React.Fragment key={report?.reportKey}>
                                <Col span={24}>
                                  <Typography.Title className='mt-4 text-red-600' level={4}>{report?.name}</Typography.Title>
                                  <p className='caption mb-6'>
                                    {report?.description}
                                  </p>
                                  {report?.target
                                    ? (
                                      <Space className='mb-6'>
                                        <Card>
                                          <Statistic title='Actual' value={report?.resultAsString} />
                                        </Card>
                                        <Card>
                                          <Statistic title='Target' value={`${Math.abs(report?.target * 100)}%`} />
                                        </Card>
                                      </Space>
                                      )
                                    : null}
                                </Col>
                                {report?.tables?.map((table, ti) => (
                                  <Col key={uuidv4()} xs={24} lg={report?.tables?.length > 1 ? 12 : 24}>
                                    <Card title={ti === 0 ? 'HDP For all employees' : 'HDP for Talent'} style={{ marginBottom: '1rem' }} size='small'>
                                      <div className='ant-table-wrapper ant-table-container ant-table ant-table-small ant-table-bordered ant-table-content'>
                                        <table style={{ tableLayout: 'auto' }}>
                                          <thead className='ant-table-thead'>
                                            <tr>
                                              {table?.headings?.map((heading, hi) => (
                                                <th className='ant-table-cell brown-row' key={`${ti}-${hi}`}>{heading}</th>
                                              ))}
                                            </tr>
                                          </thead>
                                          <tbody className='ant-table-tbody'>
                                            {table?.data?.map((row, ri) => (
                                              <tr className='ant-table-row ant-table-row-level-0' key={`${ti}-${ri}`}>
                                                {row?.map((col, ci) => {
                                                  console.log('row::: ', row)
                                                  const style = table.styles.find(s => (s.r === '*' || s.r === ri) && (s.c === '*' || s.c === ci))
                                                  const className = style ? `ant-table-cell ${style.highlight}-row` : 'ant-table-cell'
                                                  return (
                                                    <td key={`${ti}-${ri}-${ci}`} className={className}>{col} {row?.length - 1 === ci ? <span className='text-xs'>%</span> : ''}</td>
                                                  )
                                                })}
                                              </tr>
                                            ))}
                                          </tbody>
                                        </table>
                                      </div>
                                    </Card>
                                  </Col>
                                ))}
                                {ri < reportingQuery?.data?.filter(x => x?.tab === 'Dashboard View')?.length - 1 ? <Divider /> : null}
                              </React.Fragment>
                            ))}
                            {reportingQuery?.data?.filter(x => x?.tab === 'Dashboard View' && x?.reportKey === 'genderDiversity')?.map((report, ri) => (
                              <React.Fragment key={report?.reportKey}>
                                <Col span={24}>
                                  <Typography.Title className='mt-4 text-red-600' level={4}>{report?.name}</Typography.Title>
                                  <p className='caption mb-6'>
                                    {report?.description}
                                  </p>
                                  {report?.target
                                    ? (
                                      <Space className='mb-6'>
                                        <Card>
                                          <Statistic title='Actual' value={report?.resultAsString} />
                                        </Card>
                                        <Card>
                                          <Statistic title='Target' value={`${Math.abs(report?.target * 100)}%`} />
                                        </Card>
                                      </Space>
                                      )
                                    : null}
                                </Col>
                                {report?.tables?.map((table, ti) => (
                                  <Col key={uuidv4()} xs={24} lg={report?.tables?.length > 1 ? 12 : 24}>
                                    <Card style={{ marginBottom: '1rem' }} size='small'>
                                      <div className='ant-table-wrapper ant-table-container ant-table ant-table-small ant-table-bordered ant-table-content'>
                                        <table style={{ tableLayout: 'auto' }}>
                                          <thead className='ant-table-thead'>
                                            <tr>
                                              {table?.headings?.map((heading, hi) => (
                                                <th className='ant-table-cell brown-row' key={`${ti}-${hi}`}>{heading}</th>
                                              ))}
                                            </tr>
                                          </thead>
                                          <tbody className='ant-table-tbody'>
                                            {table?.data?.map((row, ri) => (
                                              <tr className='ant-table-row ant-table-row-level-0' key={`${ti}-${ri}`}>
                                                {row?.map((col, ci) => {
                                                  const style = table.styles.find(s => (s.r === '*' || s.r === ri) && (s.c === '*' || s.c === ci))
                                                  const className = style ? `ant-table-cell ${style.highlight}-row` : 'ant-table-cell'
                                                  return (
                                                    <td key={`${ti}-${ri}-${ci}`} className={className}>{col} {row?.length - 1 === ci ? <span className='text-xs'>%</span> : ''}</td>
                                                  )
                                                })}
                                              </tr>
                                            ))}
                                          </tbody>
                                        </table>
                                      </div>
                                    </Card>
                                  </Col>
                                ))}
                                {ri < reportingQuery?.data?.filter(x => x?.tab === 'Dashboard View')?.length - 1 ? <Divider /> : null}
                              </React.Fragment>
                            ))}
                          </Row>
                        </div>

                      </PDFExport>
                    </TabPane>
                    <TabPane key='scorecard' tab='Talent Scorecard'>
                      <PDFExport fileName={`talent-scorecard-${dayjs().format('DD/MM/YYYY')}`} ref={pdfScorecard} landscape margin='1cm'>
                        <div style={{ fontWeight: 'initial', fontVariant: 'initial', lineHeight: 'initial' }}>

                          <PdfHeader title='Talent Scorecard' />
                          {Object.keys(groupItemsByGroup(reportingQuery?.data?.filter(x => x?.tab === 'Talent Scorecard'))).map((key, i) => {
                            return (
                              <React.Fragment key={key}>
                                <Card className='suc-card suc-card--lightYellow mb-8 mt-4' title={<Typography.Title className='mt-4 text-red-600' level={4}>{key}</Typography.Title>} size='small'>
                                  <div className='ant-table-wrapper ant-table-container ant-table ant-table-small ant-table-bordered ant-table-content'>
                                    <table style={{ tableLayout: 'auto' }}>
                                      <colgroup>
                                        <col style={{ width: '15%' }} />
                                        <col style={{ width: '15%' }} />
                                        <col style={{ width: '15%' }} />
                                        <col style={{ width: '15%' }} />
                                      </colgroup>
                                      <thead className='ant-table-thead'>
                                        <tr>
                                          <th className='ant-table-cell'>Current</th>
                                          <th className='ant-table-cell'>Metric</th>
                                          <th className='ant-table-cell'>Target</th>
                                          <th className='ant-table-cell'>Progress</th>
                                        </tr>
                                      </thead>
                                      <tbody className='ant-table-tbody'>
                                        {groupItemsByGroup(reportingQuery?.data?.filter(x => x?.tab === 'Talent Scorecard'))[key]?.map((row, rowI) => {
                                          const result = (Number(row.result) - row.target) * 100
                                          let resultClassName = ''
                                          if (result > 0) {
                                            resultClassName = 'bg-[#1A5C43] text-white'
                                          } else if (result === 0) {
                                            resultClassName = 'bg-[#1A5C43] text-white'
                                          } else if (result > -10) {
                                            resultClassName = 'bg-[#FDB932] text-white'
                                          } else {
                                            resultClassName = 'bg-[#BF4D28] text-white'
                                          }
                                          return (
                                            <tr key={`${row?.name}${row?.target}${rowI}`} className='ant-table-row ant-table-row-level-0'>
                                              <td className='ant-table-cell'>{row?.resultAsString}</td>
                                              <td className='ant-table-cell'>{row?.name}</td>
                                              <td className='ant-table-cell'>{row?.targetAsString ? row?.targetAsString : `${Math.abs(row?.target * 100)}%`}</td>

                                              {(() => {
                                                if (row?.reportKey === 'avgTimeInRole') {
                                                  if (Number(row?.result) < row?.target) {
                                                    return (
                                                      <td className='ant-table-cell bg-[#1A5C43] text-white'>
                                                        On Track
                                                      </td>
                                                    )
                                                  } else {
                                                    return (
                                                      <td className='ant-table-cell bg-[#FDB932] text-white'>
                                                        Not On Track
                                                      </td>
                                                    )
                                                  }
                                                }
                                                return (
                                                  <td className={`ant-table-cell ${resultClassName}`}>
                                                    {result === 0 ? 'On Track' : `${result > 0 ? '+' : ''}${Math.round(result)}%`}
                                                  </td>
                                                )
                                              })()}
                                            </tr>
                                          )
                                        })}
                                      </tbody>
                                    </table>
                                  </div>
                                </Card>
                                {i < Object.keys(groupItemsByGroup(reportingQuery?.data?.filter(x => x?.tab === 'Talent Scorecard'))).length - 1 ? <Divider /> : null}
                              </React.Fragment>
                            )
                          })}
                        </div>
                      </PDFExport>

                    </TabPane>

                  </Tabs>
                </>
                )
              : (
                <Empty />
                )}

          </>
          )
        : (
          <Row className='mb-4 w-full' gutter={['16', '16']}>
            <Col md={24} lg={12}>
              <Card className='mb-8'>
                <Skeleton loading active />
              </Card>
            </Col>
            <Col md={24} lg={12}>
              <Card className='mb-8'>
                <Skeleton loading active />
              </Card>
            </Col>
          </Row>
          )}

    </div>
  )
}
