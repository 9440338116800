import { Col, Form, Input, Modal, Row } from 'antd'
import { Button } from 'antd/lib/radio'
import React from 'react'
import { MinusCircleOutlined } from '@ant-design/icons'
import { useCreateTeamTable } from '../hooks/api/usePerformance'
import { useGetPerson } from '../hooks/api/usePerson'
import uuidv4 from '../hooks/uuid'

export const TeamObjectiveModal = ({ visible, title = 'Team Objectives', onCancel, year }) => {
  const [form] = Form.useForm()
  const teamMutation = useCreateTeamTable()
  const personQuery = useGetPerson({ authenticated: true })

  return (
    <Modal
      visible={visible}
      title={title}
      onCancel={() => onCancel()}
      okButtonProps={
        {
          className: 'bg-blue-400',
          onClick: () => {
            form
              .validateFields()
              .then(async (values) => {
                await teamMutation.mutateAsync({
                  year,
                  employeeUid: personQuery.data?.companyPerson?.uid,
                  data: { indicators: values.objectives }
                }, {
                  onSuccess: () => {
                    onCancel()
                  }
                })
              })
          }
        }
      }
      width='55%'
    >
      <Form
        name='team objectives form'
        form={form}
        layout='vertical'
      >
        <Form.List name='objectives'>
          {(fields, { add, remove }) => (
            <>
              {fields.map(({ key, name, fieldKey, ...restField }) => (
                <div key={key} className='relative'>
                  <Row gutter='32'>
                    <Col span={11}>
                      <Form.Item
                        {...restField}
                        name={[name, 'kra']}
                        fieldKey={[fieldKey, 'kra']}
                        label='Key Result Area (KRA)'
                        rules={[{ required: true }]}
                      >
                        <Input />
                      </Form.Item>
                    </Col>
                    <Col span={11}>
                      <Form.Item
                        {...restField}
                        name={[name, 'description']}
                        fieldKey={[fieldKey, 'description']}
                        label='Description of Key Results Area'
                        rules={[{ required: true }]}
                      >
                        <Input />
                      </Form.Item>
                    </Col>
                    <Col span={2} className='mt-7'>
                      <MinusCircleOutlined onClick={() => remove(name)} />
                    </Col>
                  </Row>
                  <Form.List name={[name, 'objectives']}>
                    {(objectives, { add: create, remove: destroy }) => (
                      <>
                        <Row gutter='32'>
                          {objectives.map(obj => (
                            <Col className='flex items-center gap-4' xs={24} md={12} key={uuidv4()}>
                              <Form.Item
                                {...obj}
                                label='Objective'
                                name={[obj.name, 'name']}
                                fieldKey={[obj.fieldKey, 'name']}
                                className='w-3/4'
                              >
                                <Input placeholder='Objective name' className='w-full' />
                              </Form.Item>
                              <MinusCircleOutlined onClick={() => remove(obj.name)} />
                            </Col>
                          ))}
                        </Row>
                        <Form.Item>
                          <Button type='dashed' onClick={() => create()} block>
                            + Add Objective
                          </Button>
                        </Form.Item>
                      </>
                    )}
                  </Form.List>
                </div>
              ))}
              <Form.Item>
                <Button type='dashed' onClick={() => add()} block>
                  + Add Team Objective
                </Button>
              </Form.Item>
            </>
          )}
        </Form.List>
      </Form>
    </Modal>
  )
}
