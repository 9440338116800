import { useState, useEffect } from 'react'
export const useCanEdit = (person, tab, url = '') => {
  const [canEdit, setCanEdit] = useState(false)
  const forEmployees = ['talentProfile', 'developmentPlan', 'careerObjectives', 'performanceManagement']
  const forManagers = ['employeeEngagements', 'careerObjectives']

  useEffect(() => {
    let role = 'employee'
    if (person) {
      const uidFromUrl = url?.split('/')[2]
      if (uidFromUrl !== person.companyPerson.uid) {
        if (person.companyPerson.admin?.roles.includes('talent.manager') || person.companyPerson.admin?.roles.includes('talent.performanceManager')) role = 'talentManager'
        else if (person.companyPerson.admin?.roles.includes('talent.HRManager')) role = 'HRManager'
        else if (person.isManager) role = 'manager'
      }
      switch (role) {
        case 'talentManager': {
          return setCanEdit(true)
        }
        case 'manager': {
          if (forManagers.includes(tab)) return setCanEdit(true)
          break
        }
        case 'employee': {
          if (forEmployees.includes(tab)) return setCanEdit(true)
          break
        }
        case 'HRManager' : {
          setCanEdit(false)
          break
        }
        default: {
          throw new Error('Unsupported role')
        }
      }
    }
    // eslint-disable-next-line
  }, [person, tab])
  return canEdit
}
