import { Comment, Col, Row, Space } from 'antd'
import React from 'react'
import { makeId } from '../hooks/uuid'

export const SuccessionGroupPdf = ({ state, range, title, color, columns, style }) => {
  const handlePeopleList = (rangeKey) => {
    if (!state.layout[rangeKey]) return []

    const arrWithNoDupes = Array.from(new Set(state.layout[rangeKey]))
    return arrWithNoDupes.map(uid => ({ ...state.employees[uid], uid: uid }))
  }

  return (
    <Col className='mb-8' span={columns}>
      <div className={`w-full ${color} p-4 text-white font-bold text-lg rounded-lg`} style={style}>{title}</div>
      <Row wrap gutter={['16']}>
        {handlePeopleList(range).map(person => (
          <Col key={makeId(5)} span={columns === 24 ? 8 : columns === 12 ? 12 : 24}>
            <Comment
              author={person.person.firstName + ' ' + person.person.lastName}
              avatar={`https://avatars.dicebear.com/api/initials/${person.person.firstName[0]}.svg`}
              content={
                <Space size='small' direction='vertical'>
                  <p>{person.talentCompanyPerson.levelOfWork} | {person.sex} | {person.race}</p>
                  <p>{person.employment.jobTitle}</p>
                </Space>
                  }
            />
          </Col>
        ))}
      </Row>
    </Col>
  )
}
