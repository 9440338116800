import { Breadcrumb, Card, Divider, Empty, PageHeader, Pagination, Skeleton, Spin } from 'antd'
import React, { useReducer } from 'react'
import { useParams } from 'react-router-dom'
import { useQueryParam } from '../../hooks/useQueryParam'
import { useGetEmployees } from '../../hooks/api/useEmployees'
import { EmployeeCard } from '../../components/EmployeeCard'

const worklistReducer = (state, action) => {
  const { type, payload } = action
  switch (type) {
    case 'page': {
      return {
        ...state,
        page: payload
      }
    }
    case 'clear': {
      // client.invalidateQueries('employees')
      return {
        ...state,
        page: 1
      }
    }
    default: {
      throw new Error(`Unsupported action type: ${type}`)
    }
  }
}

export const WorklistByFilter = () => {
  const [state, dispatch] = useReducer(worklistReducer, { page: 1 })

  const { type } = useParams()
  const queryParam = useQueryParam()

  const employeesQuery = useGetEmployees({ [queryParam.get('type')]: type.replace(/-/g, ' ') })

  return (
    <div className='worklist__page'>
      <PageHeader className='pl-0 pb-0' title={queryParam.get('type').replace(/-/g, ' ')} />
      <Breadcrumb>
        <Breadcrumb.Item>
          <a href='/tm-worklist'>TM Worklist</a>
        </Breadcrumb.Item>
        <Breadcrumb.Item>
          {queryParam.get('type').replace(/-/g, ' ')}
        </Breadcrumb.Item>
        <Breadcrumb.Item>
          {type}
        </Breadcrumb.Item>
      </Breadcrumb>
      <Divider />
      {employeesQuery.isFetching && (
        <div className='w-full flex justify-center py-4'>
          <Spin tip='Fetching employees...' />
        </div>
      )}
      {!employeesQuery.isLoading && employeesQuery.data.data
        ? (
          <>
            {employeesQuery.data.data !== undefined && employeesQuery.data.data?.length
              ? (
                <div className='pb-16'>

                  {employeesQuery?.data.data.map((employee, i) => (
                    <EmployeeCard key={i} employee={employee} index={i} />
                  ))}

                  <Pagination defaultPageSize={50} showTotal={total => `Total: ${total} employees`} className='mt-8' defaultCurrent={state.page} onChange={(page) => dispatch({ type: 'page', payload: page })} total={employeesQuery.data.numberOfResults} hideOnSinglePage showSizeChanger={false} />
                </div>
                )
              : (
                <Empty />
                )}
          </>
          )
        : (
          <>
            <Card className='mb-8'>
              <Skeleton loading avatar active />
            </Card>
            <Card className='mb-8'>
              <Skeleton loading avatar active />
            </Card>
          </>
          )}
    </div>
  )
}
