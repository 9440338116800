import { Form, Input, InputNumber, Select } from 'antd'
import { createContext, useContext, useEffect, useRef } from 'react'

const EditableContext = createContext(null)

export const EditableRow = ({ index, ...props }) => {
  const [form] = Form.useForm()
  return (
    <Form form={form} component={false}>
      <EditableContext.Provider value={form}>
        <tr {...props} />
      </EditableContext.Provider>
    </Form>
  )
}

export const EditableCell = ({
  title,
  editable,
  children,
  inputType,
  dataIndex,
  record,
  handleSave,
  ...restProps
}) => {
  const inputRef = useRef(null)
  const form = useContext(EditableContext)
  useEffect(() => {
    if (editable) {
      form.setFieldsValue({
        [dataIndex]: record[dataIndex] ?? ''
      })
    }
    // eslint-disable-next-line
  }, [])
  const ratingDescriptorMap = {
    1: 'Unsatisfactory',
    2: 'Developing',
    3: 'Solid',
    4: 'Strong',
    5: 'Leading'
  }

  const save = async () => {
    try {
      const values = await form.validateFields()
      console.log('values::: ', values)
      // toggleEdit()
      switch (title) {
        case 'Values Demonstrated': {
          values['Final Rating'] = record['Company Rating'] + (values[title] === 'Yes' ? 0 : -1)
          // values['Final Rating'] = record['Final Rating'] + (values[title] === 'Yes' ? 0 : -1)

          break
        }
        case 'Operational Rating': {
          values['Discipline Rating'] = values[title]
          values['Company Rating'] = values[title]
          values['Final Rating'] = values[title] + (record['Values Demonstrated'] === 'Yes' ? 0 : -1)
          values['Rating Descriptor'] = ratingDescriptorMap[values[title]]
          break
        }
        case 'Discipline Rating': {
          values['Company Rating'] = values[title]
          values['Final Rating'] = values[title] + (record['Values Demonstrated'] === 'Yes' ? 0 : -1)
          values['Rating Descriptor'] = ratingDescriptorMap[values[title]]
          break
        }
        case 'Company Rating': {
          values['Final Rating'] = values[title] + (record['Values Demonstrated'] === 'Yes' ? 0 : -1)
          values['Rating Descriptor'] = ratingDescriptorMap[values[title]]
          break
        }
        default:
          break
      }
      handleSave({ ...record, ...values })
    } catch (errInfo) {
      console.log('Save failed:', errInfo)
    }
  }

  let childNode = children

  if (editable) {
    childNode = (
      <Form.Item
        style={{
          margin: 0
        }}
        name={dataIndex}
        rules={[
          // {
          //   required: true,
          //   message: `${title} is required.`
          // }
        ]}
      >
        {inputType === 'number'
          ? <InputNumber max={5} min={1} ref={inputRef} onPressEnter={save} />
          : inputType === 'select'
            ? (
              <Select ref={inputRef} onPressEnter={save}>
                <Select.Option value='Yes'>Yes</Select.Option>
                <Select.Option value='No'>No</Select.Option>
              </Select>
              )
            : inputType === 'textarea'
              // ? <Input ref={inputRef} onPressEnter={save} />
              ? <Input.TextArea ref={inputRef} onPressEnter={save} autoSize />
              : <Input ref={inputRef} onPressEnter={save} />}

      </Form.Item>
    )
  }
  return <td {...restProps}>{childNode}</td>
}
