import { PDFExport } from '@progress/kendo-react-pdf'
import { Button, Card, List, Modal, Row, Space } from 'antd'
import Paragraph from 'antd/lib/typography/Paragraph'
import dayjs from 'dayjs'
import React, { useEffect, useRef, useState } from 'react'
import { SuccessionGroupPdf } from './SuccessionGroupPdf'

export const SuccessionPdf = ({ state, preview, setPreview }) => {
  const [cols, setCols] = useState(6)

  const pdfExportComponent = useRef(null)
  const exportPDFWithComponent = () => {
    if (pdfExportComponent.current) {
      pdfExportComponent.current.save()
    }
  }

  useEffect(() => {
    let highVal = 0
    Object.keys(state.layout).forEach(key => {
      if (state.layout[key].length > highVal) highVal = state.layout[key].length
    })

    if (highVal > 5) setCols(12)
    if (highVal > 11) setCols(24)
    // eslint-disable-next-line
  }, [])

  return (
    <Modal
      visible={preview}
      okText='Export as PDF'
      title='PDF Preview'
      onCancel={() => setPreview(x => !x)}
      footer={[
        <Button key='cancel' onClick={() => setPreview(x => !x)}>Cancel</Button>,
        <Button onClick={exportPDFWithComponent} key='submit' type='primary' className='bg-blue-400'>Export as PDF</Button>
      ]}
      width='80%'
    >
      <PDFExport fileName={`Succession-Plan-${dayjs().format('DD/MM/YYYY')}`} ref={pdfExportComponent} landscape margin='1cm'>
        <Card extra={<div className='p-2 rounded-md' style={{ backgroundColor: '#333333' }}><img src='/images/thungela.png' alt='brand logo' className='h-8 object-cover' /></div>} title={<Space direction='vertical'>{state.role} <p className='text-gray-400 text-sm font-normal'>{state?.incumbents?.sort((a, b) => dayjs(b.startDate) - dayjs(a.startDate))[0]?.companyPerson?.talentCompanyPerson?.jobLocation} | {state?.discipline[0]}</p></Space>}>
          <p className='mb-4 font-bold'>Role Description</p>
          <Paragraph>{state?.roleDescription}</Paragraph>

          <p className='mt-6 mb-4 font-bold'>Incumbents</p>
          <List
            bordered
            size='small'
            dataSource={state?.incumbents}
            renderItem={item => <List.Item>{item?.person?.firstName + ' ' + item?.person?.lastName}</List.Item>}
          />
          <Row className='mt-8' gutter={['32']}>
            <SuccessionGroupPdf columns={cols} state={state} range='0-12 Months' title='Ready Now' style={{ backgroundColor: '#1a5c43' }} />
            <SuccessionGroupPdf columns={cols} state={state} range='1-3 Years' title='Ready in 1 - 3 years' style={{ backgroundColor: '#fdb933' }} />
            <SuccessionGroupPdf columns={cols} state={state} range='3-5 Years' title='Ready in 3 - 5 years' style={{ backgroundColor: '#5691c6' }} />
            <SuccessionGroupPdf columns={cols} state={state} range='6-8 Years' title='Ready in 6 - 8 years' color='bg-black' />
            {/* <Col className='mb-8' span={cols}>
              <div className='w-full bg-green-300 p-4 text-white font-bold text-lg rounded-lg'>Ready Now</div>
              <Row wrap gutter={['16']}>
                {handlePeopleList('0-12 Months').map(person => (
                  <Col key={makeId(5)} span={cols === 24 ? 8 : cols === 12 ? 12 : 24}>
                    <Comment
                      author={person.person.lastName}
                      avatar={`https://avatars.dicebear.com/api/initials/${person.person.firstName[0]}.svg`}
                      content={
                        <Space size='small' direction='vertical'>
                          <p>LoW {person.talentCompanyPerson.levelOfWork} | {person.sex} | {person.race}</p>
                          <p>{person.employment.jobTitle}</p>
                        </Space>
                  }
                    />
                  </Col>
                ))}
              </Row>
            </Col>
            <Col className='mb-8' span={cols}>
              <div className='w-full bg-yellow-300 p-4 text-white font-bold text-lg rounded-lg'>Ready in 1 - 3 years</div>
              <Row wrap gutter={['16']}>
                {handlePeopleList('1-3 Years').map(person => (
                  <Col key={makeId(5)} span={cols === 24 ? 6 : cols === 12 ? 12 : 24}>
                    <Comment
                      author={person.person.lastName}
                      avatar={`https://avatars.dicebear.com/api/initials/${person.person.firstName[0]}.svg`}
                      content={
                        <Space size='small' direction='vertical'>
                          <p>LoW {person.talentCompanyPerson.levelOfWork} | {person.sex} | {person.race}</p>
                          <p>{person.employment.jobTitle}</p>
                        </Space>
                  }
                    />
                  </Col>
                ))}
              </Row>
            </Col>
            <Col className='mb-8' span={cols}>
              <div className='w-full bg-blue-300 p-4 text-white font-bold text-lg rounded-lg'>Ready in 3-5 years</div>
              <Row wrap gutter={['16']}>
                {handlePeopleList('3-5 Years').map(person => (
                  <Col key={makeId(5)} span={cols === 24 ? 6 : cols === 12 ? 12 : 24}>
                    <Comment
                      author={person.person.lastName}
                      avatar={`https://avatars.dicebear.com/api/initials/${person.person.firstName[0]}.svg`}
                      content={
                        <Space size='small' direction='vertical'>
                          <p>LoW {person.talentCompanyPerson.levelOfWork} | {person.sex} | {person.race}</p>
                          <p>{person.employment.jobTitle}</p>
                        </Space>
                  }
                    />
                  </Col>
                ))}
              </Row>
            </Col>
            <Col className='mb-8' span={cols}>
              <div className='w-full bg-black p-4 text-white font-bold text-lg rounded-lg'>Ready in 6-8 years</div>
              <Row wrap gutter={['16']}>
                {handlePeopleList('6-8 Years').map(person => (
                  <Col key={makeId(5)} span={cols === 24 ? 6 : cols === 12 ? 12 : 24}>
                    <Comment
                      author={person.person.lastName}
                      avatar={`https://avatars.dicebear.com/api/initials/${person.person.firstName[0]}.svg`}
                      content={
                        <Space size='small' direction='vertical'>
                          <p>LoW {person.talentCompanyPerson.levelOfWork} | {person.sex} | {person.race}</p>
                          <p>{person.employment.jobTitle}</p>
                        </Space>
                  }
                    />
                  </Col>
                ))}
              </Row>
            </Col> */}
          </Row>
        </Card>
      </PDFExport>
    </Modal>
  )
}
