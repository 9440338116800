import { Breadcrumb, Button, PageHeader, Form, Statistic, List, Avatar, Modal, Input, Card, Spin } from 'antd'
import React, { useEffect, useState } from 'react'
import { Link, useHistory, useParams } from 'react-router-dom'
// import { DownloadOutlined } from '@ant-design/icons'
import {
  ComposedChart,
  Area,
  XAxis,
  YAxis,
  ResponsiveContainer,
  Tooltip
} from 'recharts'
import { useCreateReportingNote, useGetReportingItem } from '../../hooks/api/useReporting'
import { secondsToHms } from '../../hooks/secondsToHms'
import { unCamelCase } from '../../hooks/unCamelCase'
// const { Option } = Select

export const ReportingItem = () => {
  const [visible, setVisible] = useState(false)

  const [form] = Form.useForm()
  const { type } = useParams()

  const { push } = useHistory()

  // REQS
  const reportingItemQuery = useGetReportingItem({ type })
  const reportinNoteMutation = useCreateReportingNote()

  const [line] = useState(() => {
    const months = ['January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December']
    const arr = []

    for (let i = 0; i < months.length; i++) {
      arr.push({
        month: months[i],
        value: 0
      })
    }
    return arr
  })

  useEffect(() => {
    if (!type) push('/reporting')
    // eslint-disable-next-line
  }, [])

  const handleSubmit = (values) => {
    form
      .validateFields()
      .then(values => {
        reportinNoteMutation.mutateAsync({
          data: {
            companyUid: '2f6280d7-ccec-4c96-8efd-2f749437f9f7',
            reportKey: type,
            comments: values.note
          }
        })
        setVisible(false)
      })
  }

  return (
    <div className='reporting__page'>
      <Modal
        visible={visible}
        title='Add note'
        onCancel={() => setVisible(false)}
        width='55%'
        onOk={handleSubmit}
      >
        <Form
          form={form}
          layout='vertical'
          name='form_modal'
          onFinish={handleSubmit}
        >
          <Form.Item
            name='note'
            label='Note'
          >
            <Input placeholder='Enter note...' />
          </Form.Item>
        </Form>
      </Modal>
      <PageHeader className='pl-0 pb-0' onBack={() => push('/reporting')} title='Thungela Talent Report' />
      <Breadcrumb>
        <Breadcrumb.Item>
          <Link to='/reporting'>Talent Report</Link>
        </Breadcrumb.Item>
        <Breadcrumb.Item
          className='capitalize'
        >
          {type === 'hdsa' ? 'HDSA' : unCamelCase(type)}
        </Breadcrumb.Item>
      </Breadcrumb>
      <p className='caption my-8'>{type === 'hdsa' ? 'HDSA' : type.replace(/-/g, ' ')}</p>
      {!reportingItemQuery.isLoading && reportingItemQuery.data
        ? (
          <>
            {(() => {
              switch (type) {
                case 'average-time-in-role':
                  return (
                    <List
                      itemLayout='horizontal'
                      dataSource={[
                        {
                          title: 'John Doe'
                        }
                      ]}
                      size='small'
                      renderItem={(item, i) => (
                        <Card>
                          <List.Item>
                            <List.Item.Meta
                              avatar={<Avatar src={`https://avatars.dicebear.com/api/identicon/${i}.svg`} />}
                              title={<Link className='font-bold' to='/'>{item.title}</Link>}
                              description='Engineer | Isobonelo'
                            />
                            <Statistic title='Avg Time' value={secondsToHms(112893)} />
                          </List.Item>
                        </Card>
                      )}
                    />
                  )
                default:
                  return (
                    <div className='relative'>
                      <p className='absolute top-32 translate-x-2/4 left-2/4 font-bold text-xl text-gray-500'>Not enough data</p>
                      <ResponsiveContainer width='100%' height={400}>
                        <ComposedChart data={line}>
                          <defs>
                            <linearGradient id='color' x1='0' y1='0' x2='0' y2='1'>
                              <stop offset='0%' stopColor='#2451b7' stopOpacity={0.4} />
                              <stop offset='100%' stopColor='#2451b7' stopOpacity={0} />
                            </linearGradient>
                          </defs>
                          {/* <CartesianGrid stroke='#f5f5f5' /> */}
                          <XAxis axisLine={false} dataKey='month' />
                          <YAxis tickLine={false} axisLine={false} />
                          <Area type='monotone' dataKey='value' stroke='#2451b7' fill='url(#color)' />
                          <Tooltip content={<CustomTooltip />} />
                        </ComposedChart>
                      </ResponsiveContainer>
                    </div>
                  )
              }
            })()}

            <div className='flex item-center justify-between mt-16 mb-4'>
              <p className='caption'>Notes</p>
              <Button onClick={() => setVisible(true)} type='primary' className='bg-blue-400'>Add</Button>
            </div>
            <div className='operational__card'>
              <List
                itemLayout='horizontal'
                dataSource={reportingItemQuery.data?.notes}
                renderItem={item => (
                  <List.Item>
                    <List.Item.Meta
                      title={<Link to='/'>{item?.person}</Link>}
                      description={item?.comments}
                    />
                  </List.Item>
                )}
              />
            </div>
          </>
          )
        : (
          <div className='w-full flex items-center justify-center py-24'>
            <Spin size='large' />
          </div>
          )}
      <div className='h-64' />
    </div>
  )
}

function CustomTooltip ({ active, payload, label }) {
  if (active) {
    return (
      <div className='p-4 rounded-md shadow-lg bg-white'>
        <Statistic suffix='%' title={label} value={payload[0].value} />
      </div>
    )
  }
  return null
}
