import { useAuthSignOut } from '@react-query-firebase/auth'
import { message } from 'antd'
import axios from 'axios'
import { getAuth, getIdToken } from 'firebase/auth'
import { useQuery } from 'react-query'
import { auth } from '../../firebase'

export const useGetPerson = ({ authenticated }) => {
  const { mutate: signOutMutation } = useAuthSignOut(auth)
  return useQuery(
    ['me', authenticated],
    async () => axios.get(
      `${process.env.REACT_APP_API_URL}/people/me`,
      {
        headers: {
          Authorization: `Bearer ${await getIdToken(getAuth().currentUser)}`,
          'X-Tenant-Id': process.env.REACT_APP_TENANT_ID
        }
      }
    )
      .then(res => res.data.data),
    {
      staleTime: 1000 * 60 * 5,
      enabled: !!authenticated,
      retry: false,
      onError: (error) => {
        message.error(`Failed to fetch person: ${error.response?.data?.message}`)
        signOutMutation()
      }
    }
  )
}
