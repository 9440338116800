import { Dropdown, Menu, Modal, Space } from 'antd'
import React, { useState } from 'react'
import { NavLink, Redirect, useLocation } from 'react-router-dom'
import { useAuthSignOut } from '@react-query-firebase/auth'
import { useGetPerson } from '../hooks/api/usePerson'
import { auth } from '../firebase'
import { UserOutlined } from '@ant-design/icons'
import Avatar from 'antd/lib/avatar/avatar'

export const Navbar = () => {
  const personQuery = useGetPerson({ authenticated: !useLocation().pathname.includes('auth') })
  const [visible, setVisible] = useState(false)

  const { mutate: signOutMutation } = useAuthSignOut(auth)

  const menu = (
    <Menu>
      <Menu.Item>
        <NavLink to={`/employees/${personQuery?.data?.companyPerson.uid}`}>
          Profile
        </NavLink>
      </Menu.Item>
      <Menu.Item>
        <p onClick={signOutMutation}>
          Sign Out
        </p>
      </Menu.Item>
    </Menu>
  )

  return (

    <>
      <Modal
        visible={visible}
        cancelText='Cancel'
        onCancel={() => setVisible(x => !x)}
        footer={null}
        width='55%'
        className='modal modal--noPadding'
      >
        <iframe title='support form' src='https://forms.monday.com/forms/embed/4773d1d85846f8e46ec444ef3b64e46b?r=use1' width='100%' style={{ border: 0, height: '82vh', boxShadow: '5px 5px 56px 0px rgba(0,0,0,0.25)' }} />
      </Modal>
      <div className='blur-z w-screen fixed -top-4 h-24 blur-xl  bg-white' />
      <nav className='w-screen flex items-center p-3 sticky top-4 mt-4 z-50 shadow-md' style={{ backgroundColor: '#333333', width: '98vw', marginLeft: '1vw', borderRadius: 'clamp(0.5rem, 2vh, 0.8rem)' }}>
        <div className='brand flex pl-3 w-1/5 h-8'>
          <img src='/images/thungela.png' alt='brand logo' className='h-8 object-cover' />
        </div>
        {!personQuery.isLoading && personQuery.data && (
          <>
            <Space className='justify-center w-3/5 flex-1' direction='horizontal' size='large'>
              {personQuery.data.companyPerson.admin?.roles.includes('talent.manager')
                ? (
                  <>
                    <NavLink to='/employees' className='px-4 py-2 text-white hover:text-yellow-300' activeClassName='text-yellow-300 border-b-2 border-yellow-300'>
                      Employees
                    </NavLink>
                    <NavLink to='/tm-worklist' className='px-4 py-2 text-white hover:text-yellow-300' activeClassName='text-yellow-300 border-b-2 border-yellow-300'>
                      TM Worklist
                    </NavLink>
                    <NavLink to='/pm-worklist' className='px-4 py-2 text-white hover:text-yellow-300' activeClassName='text-yellow-300 border-b-2 border-yellow-300'>
                      PM Worklist
                    </NavLink>
                    <NavLink to='/reporting' className='px-4 py-2 text-white hover:text-yellow-300' activeClassName='text-yellow-300 border-b-2 border-yellow-300'>
                      Reporting
                    </NavLink>
                    <NavLink to='/succession-planner' className='px-4 py-2 text-white hover:text-yellow-300' activeClassName='text-yellow-300 border-b-2 border-yellow-300'>
                      Succession Planner
                    </NavLink>
                  </>
                  )
                : personQuery.data.companyPerson.admin?.roles.includes('talent.performanceManager') || personQuery.data.companyPerson.admin?.roles.includes('talent.HRManager')
                  ? (
                    <>
                      <NavLink to='/employees' className='px-4 py-2 text-white hover:text-yellow-300' activeClassName='text-yellow-300 border-b-2 border-yellow-300'>
                        Employees
                      </NavLink>
                      <NavLink to='/pm-worklist' className='px-4 py-2 text-white hover:text-yellow-300' activeClassName='text-yellow-300 border-b-2 border-yellow-300'>
                        PM Worklist
                      </NavLink>
                    </>
                    )
                  : personQuery.data.isManager
                    ? (
                      <>
                        <NavLink to='/employees' className='px-4 py-2 text-white hover:text-yellow-300' activeClassName='text-yellow-300 border-b-2 border-yellow-300'>
                          Employees
                        </NavLink>
                        <NavLink to={`/employees/${personQuery.data.companyPerson.uid}`} className='px-4 py-2 text-white hover:text-yellow-300' activeClassName='text-yellow-300 border-b-2 border-yellow-300'>
                          Profile
                        </NavLink>
                      </>
                      )
                    : (
                      <NavLink to={`/employees/${personQuery.data.companyPerson.uid}`} className='px-4 py-2 text-white hover:text-yellow-300' activeClassName='text-yellow-300 border-b-2 border-yellow-300'>
                        Profile
                        <Redirect to={`/employees/${personQuery.data.companyPerson.uid}`} />
                      </NavLink>
                      )}

            </Space>
            {/* to='/profile' activeClassName='profile__link profile__link--active' */}
            <div className='flex items-center  justify-end  w-1/5 text-white '>
              <Space onClick={() => setVisible(true)} className='mr-3 items-center hover:text-yellow-300 cursor-pointer'>Help?</Space>
              <Dropdown overlay={menu} placement='bottomRight'>
                <Space className='hover:text-yellow-300 cursor-pointer'>
                  <Avatar src={personQuery.data?.imageUrl} icon={<UserOutlined />} />
                  {personQuery.data?.firstName}
                </Space>
              </Dropdown>
            </div>
          </>
        )}
      </nav>
    </>
  )
}
