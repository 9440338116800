import { Affix, Button, Collapse, DatePicker, Form, Input, Result, Row, Space, Spin } from 'antd'
import React, { useEffect, useReducer, useState } from 'react'
import { MinusCircleOutlined, PlusOutlined, SaveOutlined } from '@ant-design/icons'
import { useGetEmployeeEngagement, useUpdateEmployeeEngagement } from '../../../hooks/api/useEmployeeEngagements'
import { useParams } from 'react-router'
import dayjs from 'dayjs'
import { useGetPerson } from '../../../hooks/api/usePerson'
import { useCanEdit } from '../../../hooks/useCanEdit'
import Paragraph from 'antd/lib/typography/Paragraph'

const { Panel } = Collapse

function engagementReducer (state, action) {
  const { type, payload, field } = action
  switch (type) {
    case 'initialize': {
      return {
        ...state,
        initialized: true,
        ...payload
      }
    }
    case 'field': {
      return {
        ...state,
        hasChanged: true,
        [field]: payload,
        changedFields: {
          ...state.changedFields,
          [field]: payload
        }
      }
    }
    case 'comment': {
      return {
        ...state,
        hasChanged: true,
        [field.split('-')[0]]: {
          ...state[field.split('-')[0]],
          [field.split('-')[1]]: payload
        },
        changedFields: {
          ...state.changedFields,
          [field.split('-')[0]]: {
            ...state[field.split('-')[0]],
            [field.split('-')[1]]: payload
          }
        }
      }
    }
    case 'saved': {
      return {
        ...state,
        hasChanged: false
      }
    }
    default: {
      throw new Error(`Unsupported action type: ${type}`)
    }
  }
}

export const EmployeeEgagements = () => {
  // CONSTANTS
  const { employeeUid } = useParams()

  const [state, dispatch] = useReducer(engagementReducer, { initialized: false, hasChanged: false, changedFields: {} })
  const [saving, setSaving] = useState(false)

  // NETWORK REQUESTS
  const empEngagementsQuery = useGetEmployeeEngagement({ employeeUid })
  const empEngagementsMutation = useUpdateEmployeeEngagement()
  const personQuery = useGetPerson({ authenticated: true })
  const canEdit = useCanEdit(personQuery?.data, 'employeeEngagements', window.location.pathname)

  // EFFECTS
  useEffect(() => {
    if (!empEngagementsQuery.isLoading) {
      let initialObj = {}
      if (empEngagementsQuery.data) {
        initialObj = {
          managerCommentsAOP: empEngagementsQuery.data.managerCommentsAOP || { employeeVisible: '', managerVisible: '' },
          managerCommentsActionItemsDoc: empEngagementsQuery.data.managerCommentsActionItemsDoc || [],
          managerOnceRemovedCommentsAOP: empEngagementsQuery.data.managerOnceRemovedCommentsAOP || { employeeVisible: '', managerVisible: '' },
          managerOnceRemovedCommentsActionItemsDoc: empEngagementsQuery.data.managerOnceRemovedCommentsActionItemsDoc || [],
          cdpCommentsAOP: empEngagementsQuery.data.cdpCommentsAOP || { employeeVisible: '', managerVisible: '' },
          cdpCommentsActionItemsDoc: empEngagementsQuery.data.cdpCommentsActionItemsDoc || [],
          coachingCommentsAOP: empEngagementsQuery.data.coachingCommentsAOP || { employeeVisible: '', managerVisible: '' },
          coachingCommentsActionItemsDoc: empEngagementsQuery.data.coachingCommentsActionItemsDoc || []
        }
      }
      dispatch({ type: 'initialize', payload: initialObj })
    }

    // eslint-disable-next-line
  }, [empEngagementsQuery.isLoading, empEngagementsQuery.data, empEngagementsQuery.isError])

  return (
    <>
      {!empEngagementsQuery.isLoading && state.initialized
        ? (
          <>
            {!empEngagementsQuery.isError
              ? (
                <>
                  <Collapse ghost defaultActiveKey={['1']}>
                    <Panel header='Manager Comments' key='1' className='testPanel'>
                      <Row justify='space-between' className='operational__card'>
                        {canEdit && (
                          <div className='card__item card__item--spaceBottom w-full'>
                            <p className='caption'>Manager Comments</p>
                            <Paragraph placeholder='Testing placeholder' className='editable__field' editable={canEdit && { onChange: (e) => dispatch({ type: 'comment', field: 'managerCommentsAOP-managerVisible', payload: e }) }}>{canEdit ? state?.managerCommentsAOP?.managerVisible : state?.managerCommentsAOP?.managerVisible || 'No comment'}</Paragraph>
                          </div>
                        )}
                        <div className='card__item card__item--spaceBottom w-full'>
                          <p className='caption'>{canEdit ? 'Employee Visible Comments' : 'Comments'}</p>
                          <Paragraph placeholder='Testing placeholder' className='editable__field' editable={canEdit && { onChange: (e) => dispatch({ type: 'comment', field: 'managerCommentsAOP-employeeVisible', payload: e }) }}>{canEdit ? state?.managerCommentsAOP?.employeeVisible : state?.managerCommentsAOP?.employeeVisible || 'No comment'}</Paragraph>
                        </div>
                        {canEdit && (
                          <div className='card__item'>
                            <p className='caption'>Action Items</p>
                            <Form
                              name='managerCommentsActionItemsDoc'
                              onFinish={values => dispatch({ type: 'field', field: 'managerCommentsActionItemsDoc', payload: values.managerCommentsActionItemsDoc })}
                              initialValues={{ managerCommentsActionItemsDoc: state.managerCommentsActionItemsDoc?.map(x => ({ ...x, date: dayjs(x?.date) })) }}
                              autoComplete='off'
                            >
                              <Form.List name='managerCommentsActionItemsDoc'>
                                {(fields, { add, remove }) => {
                                  return (
                                    <>
                                      {fields.map(({ key, name, fieldKey, ...restField }) => (
                                        <Space key={key} style={{ display: 'flex', marginBottom: 8 }} align='baseline'>
                                          <Form.Item
                                            {...restField}
                                            name={[name, 'actionItem']}
                                            fieldKey={[fieldKey, 'actionItem']}
                                            rules={[{ required: true, message: 'Missing Action Item' }]}
                                          >
                                            <Input disabled={!canEdit} placeholder='Action Item' />
                                          </Form.Item>
                                          <Form.Item
                                            {...restField}
                                            name={[name, 'date']}
                                            fieldKey={[fieldKey, 'date']}
                                          >
                                            <DatePicker disabled={!canEdit} />
                                          </Form.Item>
                                          <MinusCircleOutlined onClick={() => remove(name)} />
                                        </Space>
                                      ))}
                                      <Form.Item>
                                        <Button disabled={!canEdit} type='dashed' onClick={() => add()} block icon={<PlusOutlined />}>
                                          Add Action Item
                                        </Button>
                                      </Form.Item>
                                    </>
                                  )
                                }}
                              </Form.List>
                              <Form.Item>
                                <Button type='primary' className='bg-blue-400' htmlType='submit'>
                                  Save
                                </Button>
                              </Form.Item>
                            </Form>
                          </div>
                        )}
                      </Row>
                    </Panel>
                    <Panel header='MOR Comments' key='2' className='testPanel'>
                      <Row justify='space-between' className='operational__card'>
                        {canEdit && (
                          <div className='card__item card__item--spaceBottom w-full'>
                            <p className='caption'>Manager Comments</p>
                            <Paragraph placeholder='Testing placeholder' className='editable__field' editable={canEdit && { onChange: (e) => dispatch({ type: 'comment', field: 'managerOnceRemovedCommentsAOP-managerVisible', payload: e }) }}>{canEdit ? state?.managerOnceRemovedCommentsAOP?.managerVisible : state?.managerOnceRemovedCommentsAOP?.managerVisible || 'No comment'}</Paragraph>
                          </div>
                        )}
                        <div className='card__item card__item--spaceBottom w-full'>
                          <p className='caption'>{canEdit ? 'Employee Visible Comments' : 'Comments'}</p>
                          <Paragraph placeholder='Testing placeholder' className='editable__field' editable={canEdit && { onChange: (e) => dispatch({ type: 'comment', field: 'managerOnceRemovedCommentsAOP-employeeVisible', payload: e }) }}>{canEdit ? state?.managerOnceRemovedCommentsAOP?.employeeVisible : state?.managerOnceRemovedCommentsAOP?.employeeVisible || 'No comment'}</Paragraph>
                        </div>
                        {canEdit && (
                          <div className='card__item'>
                            <p className='caption'>Action Items</p>
                            <Form
                              name='managerOnceRemovedCommentsActionItemsDoc'
                              onFinish={values => dispatch({ type: 'field', field: 'managerOnceRemovedCommentsActionItemsDoc', payload: values.managerOnceRemovedCommentsActionItemsDoc })}
                              initialValues={{ managerOnceRemovedCommentsActionItemsDoc: state?.managerOnceRemovedCommentsActionItemsDoc?.map(x => ({ ...x, date: dayjs(x?.date) })) }}
                              autoComplete='off'
                            >
                              <Form.List name='managerOnceRemovedCommentsActionItemsDoc'>
                                {(fields, { add, remove }) => (
                                  <>
                                    {fields.map(({ key, name, fieldKey, ...restField }) => (
                                      <Space key={key} style={{ display: 'flex', marginBottom: 8 }} align='baseline'>
                                        <Form.Item
                                          {...restField}
                                          name={[name, 'actionItem']}
                                          fieldKey={[fieldKey, 'actionItem']}
                                          rules={[{ required: true, message: 'Missing Action Item' }]}
                                        >
                                          <Input disabled={!canEdit} placeholder='Action Item' />
                                        </Form.Item>
                                        <Form.Item
                                          {...restField}
                                          name={[name, 'date']}
                                          fieldKey={[fieldKey, 'date']}
                                        >
                                          <DatePicker disabled={!canEdit} />
                                        </Form.Item>
                                        <MinusCircleOutlined onClick={() => remove(name)} />
                                      </Space>
                                    ))}
                                    <Form.Item>
                                      <Button disabled={!canEdit} type='dashed' onClick={() => add()} block icon={<PlusOutlined />}>
                                        Add Action Item
                                      </Button>
                                    </Form.Item>
                                  </>
                                )}
                              </Form.List>
                              <Form.Item>
                                <Button type='primary' className='bg-blue-400' htmlType='submit'>
                                  Save
                                </Button>
                              </Form.Item>
                            </Form>
                          </div>
                        )}
                      </Row>
                    </Panel>
                    <Panel header='CDP Comments' key='3' className='testPanel'>
                      <Row justify='space-between' className='operational__card'>
                        {canEdit && (
                          <div className='card__item card__item--spaceBottom w-full'>
                            <p className='caption'>Manager Comments</p>
                            <Paragraph placeholder='Testing placeholder' className='editable__field' editable={canEdit && { onChange: (e) => dispatch({ type: 'comment', field: 'cdpCommentsAOP-managerVisible', payload: e }) }}>{canEdit ? state?.cdpCommentsAOP?.managerVisible : state?.cdpCommentsAOP?.managerVisible || 'No comment'}</Paragraph>
                          </div>
                        )}
                        <div className='card__item card__item--spaceBottom w-full'>
                          <p className='caption'>{canEdit ? 'Employee Visible Comments' : 'Comments'}</p>
                          <Paragraph placeholder='Testing placeholder' className='editable__field' editable={canEdit && { onChange: (e) => dispatch({ type: 'comment', field: 'cdpCommentsAOP-employeeVisible', payload: e }) }}>{canEdit ? state?.cdpCommentsAOP?.employeeVisible : state?.cdpCommentsAOP?.employeeVisible || 'No comment'}</Paragraph>
                        </div>
                        {canEdit && (
                          <div className='card__item'>
                            <p className='caption'>Action Items</p>
                            <Form name='cdpCommentsActionItemsDoc' onFinish={values => dispatch({ type: 'field', field: 'cdpCommentsActionItemsDoc', payload: values.cdpCommentsActionItemsDoc })} initialValues={{ cdpCommentsActionItemsDoc: state?.cdpCommentsActionItemsDoc?.map(x => ({ ...x, date: dayjs(x.date) })) }} autoComplete='off'>
                              <Form.List name='cdpCommentsActionItemsDoc'>
                                {(fields, { add, remove }) => (
                                  <>
                                    {fields.map(({ key, name, fieldKey, ...restField }) => (
                                      <Space key={key} style={{ display: 'flex', marginBottom: 8 }} align='baseline'>
                                        <Form.Item
                                          {...restField}
                                          name={[name, 'actionItem']}
                                          fieldKey={[fieldKey, 'actionItem']}
                                          rules={[{ required: true, message: 'Missing Action Item' }]}
                                        >
                                          <Input disabled={!canEdit} placeholder='Action Item' />
                                        </Form.Item>
                                        <Form.Item
                                          {...restField}
                                          name={[name, 'date']}
                                          fieldKey={[fieldKey, 'date']}
                                        >
                                          <DatePicker disabled={!canEdit} />
                                        </Form.Item>
                                        <MinusCircleOutlined onClick={() => remove(name)} />
                                      </Space>
                                    ))}
                                    <Form.Item>
                                      <Button disabled={!canEdit} type='dashed' onClick={() => add()} block icon={<PlusOutlined />}>
                                        Add Action Item
                                      </Button>
                                    </Form.Item>
                                  </>
                                )}
                              </Form.List>
                              <Form.Item>
                                <Button type='primary' className='bg-blue-400' htmlType='submit'>
                                  Save
                                </Button>
                              </Form.Item>
                            </Form>
                          </div>
                        )}
                      </Row>
                    </Panel>
                    {/* <Panel header='Coaching Comments' key='4' className='testPanel'>
                      <Row justify='space-between' className='operational__card'>
                        {canEdit && (
                          <div className='card__item card__item--spaceBottom w-full'>
                            <p className='caption'>Manager Comments</p>
                            <Paragraph placeholder='Testing placeholder' className='editable__field' editable={canEdit && { onChange: (e) => dispatch({ type: 'comment', field: 'coachingCommentsAOP-managerVisible', payload: e }) }}>{canEdit ? state?.coachingCommentsAOP?.managerVisible : state?.coachingCommentsAOP?.managerVisible || 'No comment'}</Paragraph>
                          </div>
                        )}
                        <div className='card__item card__item--spaceBottom w-full'>
                          <p className='caption'>{canEdit ? 'Employee Visible Comments' : 'Comments'}</p>
                          <Paragraph placeholder='Testing placeholder' className='editable__field' editable={canEdit && { onChange: (e) => dispatch({ type: 'comment', field: 'coachingCommentsAOP-employeeVisible', payload: e }) }}>{canEdit ? state?.coachingCommentsAOP?.employeeVisible : state?.coachingCommentsAOP?.employeeVisible || 'No comment'}</Paragraph>
                        </div>
                        {canEdit && (
                          <div className='card__item w-full'>
                            <p className='caption'>Action Items</p>
                            <Form name='coachingCommentsActionItemsDoc' onFinish={values => dispatch({ type: 'field', field: 'coachingCommentsActionItemsDoc', payload: values.coachingCommentsActionItemsDoc })} initialValues={{ coachingCommentsActionItemsDoc: state?.coachingCommentsActionItemsDoc?.map(x => ({ ...x, date: dayjs(x.date) })) }} autoComplete='off'>
                              <Form.List name='coachingCommentsActionItemsDoc'>
                                {(fields, { add, remove }) => (
                                  <>
                                    {fields.map(({ key, name, fieldKey, ...restField }) => (
                                      <Space key={key} style={{ display: 'flex', marginBottom: 8 }} align='baseline'>
                                        <Form.Item
                                          {...restField}
                                          name={[name, 'actionItem']}
                                          fieldKey={[fieldKey, 'actionItem']}
                                          rules={[{ required: true, message: 'Missing Action Item' }]}
                                        >
                                          <Input disabled={!canEdit} placeholder='Action Item' />
                                        </Form.Item>
                                        <Form.Item
                                          {...restField}
                                          name={[name, 'date']}
                                          fieldKey={[fieldKey, 'date']}
                                        >
                                          <DatePicker disabled={!canEdit} />
                                        </Form.Item>
                                        <MinusCircleOutlined onClick={() => remove(name)} />
                                      </Space>
                                    ))}
                                    <Form.Item>
                                      <Button disabled={!canEdit} type='dashed' onClick={() => add()} block icon={<PlusOutlined />}>
                                        Add Action Item
                                      </Button>
                                    </Form.Item>
                                  </>
                                )}
                              </Form.List>
                              <Form.Item>
                                <Button type='primary' className='bg-blue-400' htmlType='submit'>
                                  Save
                                </Button>
                              </Form.Item>
                            </Form>
                          </div>
                        )}
                      </Row>
                    </Panel> */}
                  </Collapse>
                  {state.hasChanged && (
                    <Affix style={{ position: 'absolute', bottom: 65, right: 30 }} offsetBottom={65}>
                      <Button
                        loading={saving}
                        onClick={async () => {
                          setSaving(true)
                          await empEngagementsMutation.mutateAsync({ employeeUid, data: state.changedFields })
                          dispatch({ type: 'saved' })
                          setSaving(false)
                        }}
                        type='primary' className='bg-blue-400'
                        shape='round'
                        size='large'
                        icon={<SaveOutlined />}
                      >
                        {saving ? 'Saving' : 'Save'} Changes
                      </Button>
                    </Affix>
                  )}
                </>
                )
              : (
                <Result
                  status='warning'
                  title='We are unable to fetch employee engagements right now'
                />
                )}

          </>
          )
        : (
          <div className='w-full flex items-center justify-center py-24'>
            <Spin size='large' />
          </div>
          )}

    </>
  )
}
