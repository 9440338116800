import { message } from 'antd'
import axios from 'axios'
import { getAuth, getIdToken } from 'firebase/auth'
import { useMutation, useQuery, useQueryClient } from 'react-query'

export const useGetCoaching = ({ employeeUid, year }) => {
  return useQuery(
    ['coaching', employeeUid, year],
    async () => axios.get(
      // `${process.env.REACT_APP_API_URL}/talent/employees/${employeeUid}/coaching`,
      `${process.env.REACT_APP_API_URL}/talent/employees/${employeeUid}/coaching/${year}`,
      {
        headers: {
          Authorization: `Bearer ${await getIdToken(getAuth().currentUser)}`,
          'X-Tenant-Id': process.env.REACT_APP_TENANT_ID
        }
      }
    )
      .then(res => res.data.data),
    {
      staleTime: 1000 * 60 * 5,
      enabled: !!employeeUid,
      retry: false,
      onError: (error) => {
        message.error(`Failed to fetch coaching: ${error.response.data.message}`)
      }
    }
  )
}

export const useMutateCoaching = () => {
  const queryClient = useQueryClient()
  return useMutation(
    (body) => axios.put(
      `${process.env.REACT_APP_API_URL}/talent/employees/${body.employeeUid}/coaching/${body.year}`,
      body.data,
      {
        headers: {
          Authorization: `Bearer ${queryClient.getQueryData('token')?.token ?? ''}`,
          'X-Tenant-Id': process.env.REACT_APP_TENANT_ID
        }
      }
    )
      .then(res => res.data),
    {
      onSuccess: () => {
        message.success('Successfully updated coaching')
        queryClient.invalidateQueries('coaching')
      },
      onError: (error) => {
        message.error(`Failed to update coaching: ${error.response.data.message}`)
      }
    }
  )
}
