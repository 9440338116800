export function unCamelCase (string) {
  if (string) {
    return string
      .replace(/([A-Z])/g, ' $1')
      .replace(/^./, function (str) { return str.toUpperCase() })
  }
  return ''
}

export function toCamelCase (string) {
  if (string) {
    return string.replace(/\s+(.)/g, function (match, group) {
      return group.toUpperCase()
    })
  }
  return ''
}
