import { message } from 'antd'
import axios from 'axios'
import { getAuth, getIdToken } from 'firebase/auth'
import { useQuery } from 'react-query'

export function sleeper (ms) {
  return function (x) {
    return new Promise(resolve => setTimeout(() => resolve(x), ms))
  }
}

export const useGetEmployees = (filters) => {
  let params = {}

  Object.keys(filters).forEach(key => {
    if (filters[key]) {
      params = {
        ...params,
        [key]: filters[key]
      }
    }
  })

  const urlParams = new URLSearchParams(params).toString()

  return useQuery(
    ['employees', params],
    async () => axios.get(
      `${process.env.REACT_APP_API_URL}/talent/employees${urlParams.length ? '?' + urlParams : ''}`,
      {
        headers: {
          Authorization: `Bearer ${await getIdToken(getAuth().currentUser)}`,
          'X-Tenant-Id': process.env.REACT_APP_TENANT_ID
        }
      }
    )
      .then(res => res.data),
    {
      staleTime: 1000 * 60 * 5,
      onError: (error) => {
        message.error(`Failed to fetch employee: ${error.response.data.message}`)
      }
    }
  )
}
export const useGetSingleEmplyee = ({ employeeUid }) => {
  return useQuery(
    ['employee', employeeUid],
    async () => axios.get(
      `${process.env.REACT_APP_API_URL}/talent/employees/${employeeUid}`,
      {
        headers: {
          Authorization: `Bearer ${await getIdToken(getAuth().currentUser)}`,
          'X-Tenant-Id': process.env.REACT_APP_TENANT_ID
        }
      }
    )
      .then(res => res.data.data),
    {
      staleTime: 1000 * 60 * 5,
      enabled: !!employeeUid,
      onError: (error) => {
        message.error(`Failed to fetch employee: ${error.response.data.message}`)
      }
    }
  )
}
