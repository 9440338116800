import { message } from 'antd'
import axios from 'axios'
import { getAuth, getIdToken } from 'firebase/auth'
import { useMutation, useQuery, useQueryClient } from 'react-query'

export const useGetPerformanceContracts = ({ employeeUid, year }) => {
  return useQuery(
    ['contracts', year, employeeUid],
    async () => axios.get(
      `${process.env.REACT_APP_API_URL}/talent/employees/${employeeUid}/${year}/performance`,
      {
        headers: {
          Authorization: `Bearer ${await getIdToken(getAuth().currentUser)}`,
          'X-Tenant-Id': process.env.REACT_APP_TENANT_ID
        }
      }
    )
      .then(res => res.data.data),
    {
      staleTime: 1000 * 60 * 5,
      retry: false,
      onError: (error) => {
        message.error(`Failed to fetch perforance contracts: ${error.response?.data?.message}`)
      }
    }
  )
}
export const useGetHods = () => {
  return useQuery(
    'hods',
    async () => axios.get(
      `${process.env.REACT_APP_API_URL}/talent/employees/hod`,
      {
        headers: {
          Authorization: `Bearer ${await getIdToken(getAuth().currentUser)}`,
          'X-Tenant-Id': process.env.REACT_APP_TENANT_ID
        }
      }
    )
      .then(res => res.data.data),
    {
      staleTime: 1000 * 60 * 5,
      retry: false,
      onError: (error) => {
        message.error(`Failed to fetch perforance contracts: ${error.response?.data?.message}`)
      }
    }
  )
}
export const useTeamTableByHOD = ({ enabled, hodUid, year }) => {
  return useQuery(
    ['teamTable', hodUid, year],
    async () => axios.get(
      `${process.env.REACT_APP_API_URL}/talent/employees/${hodUid}/${year}/performance/team`,
      {
        headers: {
          Authorization: `Bearer ${await getIdToken(getAuth().currentUser)}`,
          'X-Tenant-Id': process.env.REACT_APP_TENANT_ID
        }
      }
    )
      .then(res => res.data.data),
    {
      staleTime: 1000 * 60 * 5,
      retry: false,
      enabled: !!enabled,
      onError: (error) => {
        message.error(`Failed to fetch perforance contracts: ${error.response?.data?.message}`)
      }
    }
  )
}

export const useCreatePerformanceContract = (messageString) => {
  const queryClient = useQueryClient()
  return useMutation(
    async (body) => axios.put(
      `${process.env.REACT_APP_API_URL}/talent/employees/${body.employeeUid}/${body.year}/performance`,
      body.data,
      {
        headers: {
          Authorization: `Bearer ${await getIdToken(getAuth().currentUser)}`,
          'X-Tenant-Id': process.env.REACT_APP_TENANT_ID
        }
      }
    )
      .then(res => res.data),
    {
      onSuccess: () => {
        message.success(messageString || 'Successfully updated performance contract')
        queryClient.invalidateQueries('contracts')
        queryClient.invalidateQueries('pm-worklist')
      },
      onError: (error) => {
        message.error(`Failed to create performance contract: ${error.response.data.message}`)
      }
    }
  )
}
export const useCreateTeamTable = () => {
  const queryClient = useQueryClient()
  return useMutation(
    async (body) => axios.put(
      `${process.env.REACT_APP_API_URL}/talent/employees/${body.employeeUid}/${body.year}/performance/team`,
      body.data,
      {
        headers: {
          Authorization: `Bearer ${await getIdToken(getAuth().currentUser)}`,
          'X-Tenant-Id': process.env.REACT_APP_TENANT_ID
        }
      }
    )
      .then(res => res.data),
    {
      onSuccess: () => {
        message.success('Successfully set team objectives table')
        queryClient.invalidateQueries('teamTable')
      },
      onError: (error) => {
        message.error(`Failed to set team objectives table: ${error.response.data.message}`)
      }
    }
  )
}
