import { Button, Input, message, Form, Modal, Divider } from 'antd'
import React, { useEffect, useReducer, useState } from 'react'
import { auth } from '../../firebase'
import { useAuthSignInWithEmailAndPassword, useAuthSignOut } from '@react-query-firebase/auth'
import { useGetPerson } from '../../hooks/api/usePerson'
import { UserOutlined, LockOutlined } from '@ant-design/icons'
import { Link } from 'react-router-dom'

function authReducer (state, action) {
  const { type, payload } = action
  switch (type) {
    case 'authenticate': {
      return {
        ...state,
        authenticated: true,
        type: payload,
        preAuthPassed: payload === 'email'
      }
    }
    case 'passPreAuth': {
      return {
        ...state,
        preAuthPassed: true
      }
    }
    case 'type': {
      return {
        ...state,
        type: payload
      }
    }
    case 'email': {
      return {
        ...state
      }
    }
    case 'microsoft': {
      return {
        ...state
      }
    }
    default: {
      throw new Error(`Unsupported action type: ${type}`)
    }
  }
}

export const SignInEmail = () => {
  const [state, dispatch] = useReducer(authReducer, { authenticated: false, preAuthPassed: false, type: null })
  const [loading, setLoading] = useState(false)
  const [visible, setVisible] = useState(false)

  auth.tenantId = process.env.REACT_APP_TENANT_ID
  const { mutate: signOutMutation } = useAuthSignOut(auth)
  const emailMutation = useAuthSignInWithEmailAndPassword(auth, {
    onError: (error) => {
      console.error(error)
      message.error(`Error Authenticating: ${error.message}`)
    },
    onSuccess: () => dispatch({ type: 'authenticate', payload: 'email' })
  })
  const personQuery = useGetPerson({ authenticated: !!(state.authenticated && state.preAuthPassed) })

  useEffect(() => {
    if (state.authenticated && state.preAuthPassed && !personQuery.isLoading) {
      if (personQuery.data && !personQuery.isError) {
        message.success('Successfully Authenticated')
        setLoading(false)
        setVisible(true)
        // window.location.pathname = '/'
      } else {
        message.error('Failed to Authenticate')
        setLoading(false)
        signOutMutation()
      }
    }
    // eslint-disable-next-line
  }, [state.authenticated, state.preAuthPassed, personQuery.isLoading, personQuery.data, personQuery.isError])

  return (
    <>
      <Modal
        visible={visible}
        cancelText='Cancel'
        footer={null}
        width='55%'
        className='modal modal--noPadding'
        closable={false}
      >
        <div className='p-8'>
          <h1 className='text-xl'>Thungela Talent Management</h1>
          <p className='mb-12'>Welcome, we would like to save you a few clicks. Let us know what you're here to do and we'll take you right there.</p>
          <Link to={personQuery?.data?.isManager ? `/employees/${personQuery?.data?.companyPerson?.uid}` : '/'}>
            <Button className='bg-blue-400' type='primary' block>Take me to Talent</Button>
          </Link>
          <Divider><p className='text-center my-4 text-sm text-gray-400'>Or</p></Divider>
          <Link to={`/employees/${personQuery?.data?.companyPerson?.uid}?tab=performanceManagement`}>
            <Button block>Take me to Performance Management</Button>
          </Link>
        </div>
      </Modal>
      <div className='w-screen h-screen flex items-center justify-center md:justify-start bg-cover bg-no-repeat bg-center text-gray-700' style={{ position: 'fixed', top: 0, zIndex: 50, backgroundImage: 'url(https://images.unsplash.com/photo-1626710507764-146db0a81e44?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=2070&q=80)' }}>
        <Form
          name='login'
          className=' w-full md:w-2/4 max-w-screen-md mx-16 rounded-lg p-12 bg-white border-2 border-gray-100 shadow-xl'
          initialValues={{ remember: true }}
          onFinish={values => {
            setLoading(true)
            dispatch({ type: 'type', payload: 'email' })
            emailMutation.mutate({ email: values.email, password: values.password })
          }}
        >
          <Form.Item
            name='email'
            rules={[{ required: true, message: 'Please input your email!' }]}
          >
            <Input prefix={<UserOutlined className='site-form-item-icon' />} placeholder='Email' />
          </Form.Item>
          <Form.Item
            name='password'
            rules={[{ required: true, message: 'Please input your Password!' }]}
          >
            <Input
              prefix={<LockOutlined className='site-form-item-icon' />}
              type='password'
              placeholder='Password'
            />
          </Form.Item>
          <Form.Item className='flex justify-between'>
            <a className='text-blue-500' href='/auth/signin'>
              Forgot password
            </a>
          </Form.Item>

          <Form.Item>
            <Button loading={loading} type='primary' htmlType='submit' className='w-full bg-blue-400'>
              Log in
            </Button>
          </Form.Item>
        </Form>
      </div>
    </>
  )
}
