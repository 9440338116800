import { Button, message, Divider, Modal } from 'antd'
import React, { useEffect, useReducer, useState } from 'react'
import { auth, provider } from '../../firebase'
import { useAuthSignInWithPopup, useAuthSignOut } from '@react-query-firebase/auth'
import { useGetPerson } from '../../hooks/api/usePerson'
import { WindowsFilled } from '@ant-design/icons'
import { usePreAuthMutation } from '../../hooks/api/usePreAuth'
import { Link } from 'react-router-dom'

function authReducer (state, action) {
  const { type, payload } = action
  switch (type) {
    case 'authenticate': {
      return {
        ...state,
        authenticated: true,
        type: payload
      }
    }
    case 'passPreAuth': {
      return {
        ...state,
        authenticated: true,
        preAuthPassed: true
      }
    }
    case 'type': {
      return {
        ...state,
        type: payload
      }
    }
    default: {
      throw new Error(`Unsupported action type: ${type}`)
    }
  }
}

export const Signin = () => {
  const [state, dispatch] = useReducer(authReducer, { authenticated: false, preAuthPassed: false, type: null })
  const [loading, setLoading] = useState(false)
  const [visible, setVisible] = useState(false)
  const [prompt, setPrompt] = useState(false)

  auth.tenantId = process.env.REACT_APP_TENANT_ID
  const { mutate: signOutMutation } = useAuthSignOut(auth)
  const personQuery = useGetPerson({ authenticated: !!(state.authenticated && state.preAuthPassed) })
  const preAuthMutation = usePreAuthMutation()
  const microsoftMutation = useAuthSignInWithPopup(auth)

  const handleMicrosoftAuth = async () => {
    setLoading(true)
    dispatch({ type: 'type', payload: 'microsoft' })
    const popupauth = await microsoftMutation.mutateAsync({
      provider
    })
    await preAuthMutation.mutateAsync({
      data: {
        email: popupauth.user.email,
        authUid: popupauth.user.uid
      }
    })
    if (preAuthMutation.isError) {
      message.error('Failed to sign in using credentials provided')
      setLoading(false)
      signOutMutation()
    } else {
      dispatch({ type: 'passPreAuth' })
    }
  }

  useEffect(() => {
    if (!microsoftMutation.isLoading && microsoftMutation.isError) {
      setLoading(false)
    }
  }, [microsoftMutation.isLoading, microsoftMutation.isError])

  useEffect(() => {
    if (state.authenticated && state.preAuthPassed && !personQuery.isLoading) {
      if (personQuery.data && !personQuery.isError) {
        message.success('Successfully Authenticated')
        setLoading(false)
        setPrompt(true)
        // personQuery?.data?.isManager ? window.location.pathname = `/employees/${personQuery.data.companyPerson.uid}` : window.location.pathname = '/'
      } else {
        message.error('Failed to Authenticate')
        setLoading(false)
        signOutMutation()
      }
    }
    // eslint-disable-next-line
  }, [state.authenticated, state.preAuthPassed, personQuery.isLoading, personQuery.data, personQuery.isError])

  return (
    <>
      <Modal
        visible={visible}
        cancelText='Cancel'
        onCancel={() => setVisible(x => !x)}
        footer={null}
        width='55%'
        className='modal modal--noPadding'
      >
        <iframe title='support form' src='https://forms.monday.com/forms/embed/519b2763998961efac2b114903453d9f?r=use1' width='100%' style={{ border: 0, height: '82vh', boxShadow: '5px 5px 56px 0px rgba(0,0,0,0.25)' }} />
      </Modal>
      <Modal
        visible={prompt}
        cancelText='Cancel'
        onCancel={() => setPrompt(x => !x)}
        footer={null}
        width='55%'
        className='modal modal--noPadding'
      >
        <div className='p-8'>
          <h1 className='text-xl'>Thungela Talent Management</h1>
          <p className='mb-12'>Welcome, we would like to save you a few clicks. Let us know what you're here to do and we'll take you right there.</p>
          <Link to={personQuery?.data?.isManager ? '/' : `/employees/${personQuery?.data?.companyPerson?.uid}`}>
            <Button className='bg-blue-400' type='primary' block>Take me to Talent</Button>
          </Link>
          <Divider><p className='my-4 text-sm text-center text-gray-400'>Or</p></Divider>
          <Link to={`/employees/${personQuery?.data?.companyPerson?.uid}?tab=performanceManagement`}>
            <Button block>Take me to Performance Management</Button>
          </Link>
        </div>
      </Modal>
      <div className='flex items-center justify-center w-screen h-screen text-gray-700 bg-center bg-no-repeat bg-cover md:justify-start' style={{ position: 'fixed', top: 0, zIndex: 50, backgroundImage: 'url(https://images.unsplash.com/photo-1626710507764-146db0a81e44?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=2070&q=80)' }}>
        <div className='w-full max-w-screen-md p-12 mx-16 bg-white border-2 border-gray-100 rounded-lg shadow-xl md:w-2/4'>
          <img width={100} height={100} className='mb-2' src='/Thungela-icon.svg' alt='' />
          <h1 className='mb-2 text-xl font-bold'>Thungela Talent Management</h1>
          <p className='mb-6 text-gray-500'>Welcome to the Thungela Talent Management portal. <Button onClick={() => setVisible(true)} style={{ margin: 0, padding: 0 }} type='link'>Click here</Button> for help.</p>
          <Divider />
          <Button loading={loading} onClick={handleMicrosoftAuth} icon={<WindowsFilled />} type='primary' className='bg-blue-400' block htmlType='button'>Sign in with work account</Button>
        </div>
      </div>
    </>
  )
}
