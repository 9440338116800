import { Select, Space, Typography, Button, Modal, Form, Steps, Divider, Input, InputNumber, Row, Col, Tooltip, Result, Spin, Collapse, Timeline, DatePicker, message, Card, Table, Tag } from 'antd'
import React, { useState, useReducer, useEffect } from 'react'
import { MinusCircleOutlined, EditOutlined } from '@ant-design/icons'
import dayjs from 'dayjs'
import uuidv4 from '../../../hooks/uuid'
import { useGetLookup } from '../../../hooks/api/useLookups'
import { DefaultEditor } from 'react-simple-wysiwyg'
import { useCreatePerformanceContract, useGetPerformanceContracts } from '../../../hooks/api/usePerformance'
import { useParams } from 'react-router-dom'
import { ContractCard, planColumns } from '../../../components/ContractCard'
import { SnapshotModal } from '../../../components/SnapshotModal'
import { useGetPerson } from '../../../hooks/api/usePerson'
import { saveAs } from 'file-saver'
import { PerformancePdf } from '../../../components/PerformancePdf'
import { useGetSingleEmplyee } from '../../../hooks/api/useEmployees'
import { TeamObjectiveModal } from '../../../components/TeamObjectiveModal'
import { useCanEdit } from '../../../hooks/useCanEdit'
import { PerformanceTables } from '../../../components/PerformanceTables'
import { performanceTable } from '../../../constants/performanceTable'
import { isMe } from '../../../hooks/isMe'
import { PMFinalModal } from '../../../components/PMFinalModal'
import { useSendNotification } from '../../../hooks/useSendNotification'
import weekday from 'dayjs/plugin/weekday'
import localeData from 'dayjs/plugin/localeData'

dayjs.extend(weekday)
dayjs.extend(localeData)

const { Text } = Typography
const { Option } = Select
const { Panel } = Collapse
const { RangePicker } = DatePicker

function removeTags (str = false) {
  if (!str || !str.length) { return '' } else { str = str.toString() }

  // HTML tags
  str = str.replace(/(<([^>]+)>)/ig, '')
  // Line breaks in text area
  return str.replace(/\n/g, '')
}

function performanceReducer (state, action) {
  const { type, payload, field } = action
  switch (type) {
    case 'saved': {
      return {
        ...state,
        hasChanged: false
      }
    }
    case 'field': {
      return {
        ...state,
        [field]: payload,
        hasChanged: true
      }
    }
    case 'reset-changed': {
      return {
        ...state,
        hasChanged: false
      }
    }
    case 'edit-contract': {
      const mutatedContract = {
        id: payload.id,
        kras: payload.indicators,
        developmentPlans: payload?.developmentPlans?.map(plan => {
          console.log('plan::: ', dayjs(plan?.startDate))
          return {
            ...plan,
            dates: [plan?.startDate ? dayjs(plan?.startDate) : null, plan?.endDate ? dayjs(plan.endDate) : null]
          }
        }),
        value_first: (payload.companyValues) ? payload.companyValues[0]?.value : '',
        comment_first: (payload.companyValues) ? payload.companyValues[0]?.comment : '',
        value_second: (payload.companyValues) ? payload.companyValues[1]?.value : '',
        comment_second: (payload.companyValues) ? payload.companyValues[1]?.comment : ''
      }

      return {
        ...state,
        contractToEdit: mutatedContract,
        visible: true
      }
    }
    default: {
      throw new Error(`Unsupported action type: ${type}`)
    }
  }
}

export const PerformanceReview = () => {
  const [state, dispatch] = useReducer(
    performanceReducer,
    {
      step: 0,
      visible: false,
      finalVisible: false,
      snapVisible: false,
      teamVisible: false,
      snapToEdit: null,
      contractToEdit: null,
      preReviewComment: '',
      hasChanged: false,
      notification: null
    }
  )

  const [canSubmitToManager, setCanSubmitToManager] = useState(false)
  const [year, setYear] = useState(dayjs().year())
  const [preview, setPreview] = useState(false)
  const [snapToReview, setSnapToReview] = useState(null)
  const [snapIdx, setSnapIdx] = useState(0)

  const { employeeUid } = useParams()
  const currentYear = dayjs().year()
  const [form] = Form.useForm()

  const thungelaValuesLookups = useGetLookup({ group: 'TalentDevelopmentPlan', key: 'ThungelaValues' })
  const contractsQuery = useGetPerformanceContracts({ employeeUid, year })
  const personQuery = useGetPerson({ authenticated: true })
  const contractMutation = useCreatePerformanceContract()
  const employeeQuery = useGetSingleEmplyee({ employeeUid })
  const canEdit = useCanEdit(personQuery?.data, 'performanceManagement', window.location.pathname)
  useSendNotification(state?.notification)

  const handleSubmit = async (status, formData, isContract = false, isSilent = false) => {
    let contract = {
      status
    }
    if (isContract) {
      contract = {
        ...contract,
        formData
      }
    } else {
      contract.preReviewComment = state.preReviewComment
      contract.companyValues = []
      if (formData.value_first?.length || removeTags(formData.comment_first).length) {
        contract.companyValues[0] = {
          value: formData.value_first || '',
          comment: formData.comment_first || ''
        }
      }
      if (formData.value_second?.length || removeTags(formData.comment_second).length) {
        contract.companyValues[1] = {
          value: formData.value_second || '',
          comment: formData.comment_second || ''
        }
      }
      if (Array.isArray(formData.kras) && formData.kras?.length && typeof formData.kras[0] !== 'undefined') {
        contract.indicators = formData.kras.map(indicator => ({
          name: indicator.name,
          weighting: indicator.weighting,
          objectives: indicator?.objectives
        }))
      }
      if (Array.isArray(formData.developmentPlans) && formData.developmentPlans?.length && typeof formData.developmentPlans[0] !== 'undefined') {
        contract.developmentPlans = formData.developmentPlans.map(plan => {
          if (Array.isArray(plan?.dates) && plan?.dates[0] && plan?.dates.length === 2 && plan?.dates[1]) {
            return {
              ...plan,
              startDate: dayjs(plan?.dates[0]).format(),
              endDate: dayjs(plan?.dates[1]).format()
            }
          } else {
            delete plan.dates
          }
          return plan
        })
      }
    }

    const submittedContract = await contractMutation.mutateAsync({
      year,
      employeeUid,
      data: contract
    })

    if (submittedContract?.data && !isSilent) {
      switch (status) {
        case 'Submitted to Manager for Approval': {
          dispatch({
            type: 'field',
            field: 'notification',
            payload: {
              payload: {
                to: employeeQuery?.data?.uid === '04b90619-5599-4b29-86b5-c66d9f89bb28' ? 'dan@wixels.com' : employeeQuery?.data?.employment?.jobManager?.companyPerson?.email,
                message: {
                  subject: 'Thungela Performance Management: Contract awaiting your approval',
                  html: `<div style='padding: 20px; background-color; font-family: Arial, Helvetica, sans-serif;: rgb(247, 247, 247);border-radius:10px;max-width:640px;margin:0 auto;'><img src='https://storage.i-win.io/companies/105eb5de-d4de-4222-b464-f8256f90820f/banner.png' style='width:100%;object-fit:cover;max-height:150px;border-radius:10px;' /><h2 >Hello ${employeeQuery?.data?.employment?.jobManager?.companyPerson?.person?.firstName}  ${employeeQuery?.data?.employment?.jobManager?.companyPerson?.person?.lastName}</h2><br /><p >You've just been requested to approve <strong>${employeeQuery?.data?.person?.firstName} ${employeeQuery?.data?.person?.lastName}'s</strong> contract.</p><p >Please follow this link to get started:  <strong style="font-size: 1.3em;"><a href='${process.env.REACT_APP_ENV === 'production' ? 'https://thungela-talent.web.app/employees' : 'https://thungela-talent-beta.web.app/employees'}/${employeeQuery?.data?.uid}?tab=performanceManagement'>${process.env.REACT_APP_ENV === 'production' ? 'https://thungela-talent.web.app/employees' : 'https://thungela-talent-beta.web.app/employees'}/${employeeQuery?.data?.uid}?tab=performanceManagement</a></strong></p></div>`
                }
              }
            }
          })
          break
        }
        case 'Contract Finalised':
          dispatch({
            type: 'field',
            field: 'notification',
            payload: {
              payload: {
                to: [employeeQuery?.data?.email],
                message: {
                  subject: 'Thungela Performance Management: Contract Finalised',
                  html: `<div style='padding: 20px; background-color; font-family: Arial, Helvetica, sans-serif;: rgb(247, 247, 247);border-radius:10px;max-width:640px;margin:0 auto;'><img src='https://storage.i-win.io/companies/105eb5de-d4de-4222-b464-f8256f90820f/banner.png' style='width:100%;object-fit:cover;max-height:150px;border-radius:10px;' /><h2 >Hello ${employeeQuery?.data?.person?.firstName}  ${employeeQuery?.data?.person?.lastName}</h2><br /><p ><strong>${employeeQuery?.data?.employment?.jobManager?.companyPerson?.person?.firstName} ${employeeQuery?.data?.employment?.jobManager?.companyPerson?.person?.lastName}</strong> has finalised your contract.</p><p >Please follow this link to get proceed:  <strong style="font-size: 1.3em;"><a href='${process.env.REACT_APP_ENV === 'production' ? 'https://thungela-talent.web.app/employees' : 'https://thungela-talent-beta.web.app/employees'}/${employeeQuery?.data?.uid}?tab=performanceManagement'>${process.env.REACT_APP_ENV === 'production' ? 'https://thungela-talent.web.app/employees' : 'https://thungela-talent-beta.web.app/employees'}/${employeeQuery?.data?.uid}?tab=performanceManagement</a></strong></p></div>`
                }
              }
            }
          })
          break
        case 'Changes Requested':
          dispatch({
            type: 'field',
            field: 'notification',
            payload: {
              payload: {
                to: [employeeQuery?.data?.email],
                message: {
                  subject: 'Thungela Performance Management: Contract Changes Requested',
                  html: `<div style='padding: 20px; background-color; font-family: Arial, Helvetica, sans-serif;: rgb(247, 247, 247);border-radius:10px;max-width:640px;margin:0 auto;'><img src='https://storage.i-win.io/companies/105eb5de-d4de-4222-b464-f8256f90820f/banner.png' style='width:100%;object-fit:cover;max-height:150px;border-radius:10px;' /><h2 >Hello ${employeeQuery?.data?.person?.firstName}  ${employeeQuery?.data?.person?.lastName}</h2><br /><p ><strong>${employeeQuery?.data?.employment?.jobManager?.companyPerson?.person?.firstName} ${employeeQuery?.data?.employment?.jobManager?.companyPerson?.person?.lastName}</strong> has requested changes to your contract.</p><p >Please follow this link to get proceed:  <strong style="font-size: 1.3em;"><a href='${process.env.REACT_APP_ENV === 'production' ? 'https://thungela-talent.web.app/employees' : 'https://thungela-talent-beta.web.app/employees'}/${employeeQuery?.data?.uid}?tab=performanceManagement'>${process.env.REACT_APP_ENV === 'production' ? 'https://thungela-talent.web.app/employees' : 'https://thungela-talent-beta.web.app/employees'}/${employeeQuery?.data?.uid}?tab=performanceManagement</a></strong></p></div>`
                }
              }
            }
          })
          break
        default:
          break
      }
      dispatch({ type: 'field', field: 'visible', payload: false })
      dispatch({ type: 'field', field: 'contractToEdit', payload: null })
      dispatch({ type: 'field', field: 'step', payload: 0 })
      form.resetFields()
    }
  }

  useEffect(() => {
    if (state.contractToEdit) {
      form.setFieldsValue(state.contractToEdit)
    }
    // eslint-disable-next-line
  }, [state.contractToEdit])

  useEffect(() => {
    if (!contractsQuery.isLoading && contractsQuery.data) {
      dispatch({ type: 'field', field: 'preReviewComment', payload: contractsQuery.data.preReviewComment })
    }
  }, [contractsQuery.isLoading, contractsQuery.data])

  useEffect(() => {
    if (!contractsQuery.isLoading && contractsQuery?.data && contractsQuery?.data?.status === 'draft' && personQuery?.data?.companyPerson?.uid === employeeUid) {
      let canSubmit = true
      if (contractsQuery?.data.indicators?.length) {
        let indicatorsValid = true
        for (let index = 0; index < contractsQuery?.data.indicators.length; index++) {
          if (!contractsQuery?.data.indicators[index].name?.length ||
            !contractsQuery?.data.indicators[index].weighting > 0 ||
            !contractsQuery?.data.indicators[index].objectives?.length) {
            indicatorsValid = false
            break
          }
        }
        canSubmit = indicatorsValid
      } else {
        canSubmit = false
      }
      if (canSubmit && contractsQuery?.data.developmentPlans?.length) {
        let devPlansValid = true
        for (let index = 0; index < contractsQuery?.data.developmentPlans.length; index++) {
          if (!contractsQuery?.data.developmentPlans[index].need?.length ||
            !contractsQuery?.data.developmentPlans[index].note?.length ||
            !contractsQuery?.data.developmentPlans[index].endDate?.length ||
            !contractsQuery?.data.developmentPlans[index].startDate?.length ||
            !contractsQuery?.data.developmentPlans[index].developmentGaps?.length ||
            !contractsQuery?.data.developmentPlans[index].developmentType?.length ||
            !contractsQuery?.data.developmentPlans[index].resourcesNeeded?.length) {
            devPlansValid = false
            break
          }
        }
        canSubmit = devPlansValid
      } else {
        canSubmit = false
      }
      if (canSubmit && contractsQuery?.data.companyValues?.length === 2) {
        if (contractsQuery?.data.companyValues[0].value?.length && contractsQuery?.data.companyValues[0].comment?.length &&
          contractsQuery?.data.companyValues[1].value?.length && contractsQuery?.data.companyValues[1].comment?.length) {
          canSubmit = true
        } else canSubmit = false
      } else {
        canSubmit = false
      }
      setCanSubmitToManager(canSubmit)
    } else setCanSubmitToManager(false)
  }, [contractsQuery.isLoading, contractsQuery.data, personQuery.data.companyPerson.uid, employeeUid])

  const IDPValid = (devPlans) => {
    let isValid = true

    if (Array.isArray(devPlans) && devPlans.length) {
      for (let index = 0; index < devPlans.length; index++) {
        if (!devPlans[0]?.need?.length ||
          !devPlans[index]?.dates?.length ||
          !devPlans[index]?.resourcesNeeded?.length ||
          !devPlans[index]?.developmentType?.length ||
          !devPlans[index]?.developmentGaps?.length ||
          !removeTags(devPlans[index]?.note).length) {
          isValid = false
          break
        }
      }
    } else isValid = false
    return isValid
  }

  const validateOnNext = (type, values = {}, dispatchNext) => {
    console.log(values)
    switch (type) {
      case 1: {
        let isError = false
        let kraTotal = 0
        values.kras?.forEach(item => {
          kraTotal += item.weighting
        })
        if (kraTotal < 90) {
          isError = true
          message.error('KRA Weighting must total 90')
        } else if (kraTotal > 90) {
          isError = true
          message.error('KRA Weighting cannot be greater than 90')
        }
        values.kras?.forEach(item => {
          if (!item?.name || item?.name === '') {
            isError = true
            message.error('KRA Name is required')
          }
          if (!item.objectives?.length) {
            isError = true
            message.error(`Objective(s) must be set for ${item.name}`)
          } else {
            let objTotal = 0
            item.objectives.forEach(obj => {
              objTotal += obj?.weighting

              if (!obj?.name || obj?.name === '') {
                isError = true
                message.error('Objective names are required')
              }
              if (!obj?.weighting || obj?.weighting === 0) {
                isError = true
                message.error('Objective weightings cannot be 0')
              }
            })

            if (objTotal < item.weighting) {
              isError = true
              message.error(`Objective Weighting must total ${item.weighting}`)
            }
            if (objTotal > item.weighting) {
              isError = true
              message.error(`Objective Weighting cannot be greater than ${item.weighting}`)
            }
          }
        })

        if (dispatchNext && !isError) return dispatch({ type: 'field', field: 'step', payload: state.step + 1 })
        return !isError
      }
      case 2: {
        let isError = false
        if (!values.value_second || !values.value_second) {
          isError = true
          message.error('Please select two values')
        }
        if ((!values.comment_first || !removeTags(values.comment_first).length) || (!values.comment_second || !removeTags(values.comment_second).length)) {
          isError = true
          message.error('Value commitment is required')
        }

        if (dispatchNext && !isError) dispatch({ type: 'field', field: 'step', payload: state.step + 1 })
        return !isError
      }
      default:
        break
    }
  }

  const handleClose = () => {
    if (employeeUid === personQuery.data?.companyPerson?.uid && contractsQuery.data?.status !== 'Submitted to Manager for Approval' && contractsQuery.data?.status !== 'Contract Finalised' && state.hasChanged) {
      handleSubmit('draft', form.getFieldsValue(true))
    }
    dispatch({ type: 'reset-changed' })
    dispatch({ type: 'field', field: 'visible', payload: false })
  }
  function disabledDate (current) {
    const start = year + '-01-01'
    const end = year + '-12-31'
    if (current < dayjs(start)) {
      return true
    } else if (current > dayjs(end)) {
      return true
    } else {
      return false
    }
  }

  return (
    <>
      <Modal
        visible={state.visible}
        title='Create contract'
        onCancel={() => handleClose()}
        width='55%'
        footer={null}
      >
        <Steps current={state.step}>
          <Steps.Step title='Goal Alignment' />
          <Steps.Step title={(contractsQuery.data?.status === 'Submitted to Manager for Approval') ? 'Review KRA\'s' : 'Set KRA\'s'} />
          <Steps.Step title={(contractsQuery.data?.status === 'Submitted to Manager for Approval') ? 'Review Values' : 'Select your values'} />
          <Steps.Step title={(contractsQuery.data?.status === 'Submitted to Manager for Approval') ? 'Review IDP\'s' : 'Set IDP\'s'} />
          {/* <Steps.Step title='Review' /> */}
        </Steps>
        <Form
          form={form}
          layout='vertical'
          name='contract-form'
          className='relative pb-8'
        >
          {(() => {
            switch (state.step) {
              case 0: {
                return (
                  <div className='relative'>
                    {!employeeQuery.isLoading && employeeQuery.data && (
                      <PerformanceTables employee={employeeQuery.data} year={year} />
                    )}
                    <div className='flex justify-end'>
                      <Button className='bg-blue-400' type='primary' onClick={() => dispatch({ type: 'field', field: 'step', payload: state.step + 1 })}>
                        {personQuery.data.companyPerson.uid !== employeeUid ? 'Next' : "Set KRA's"}
                      </Button>
                    </div>
                  </div>
                )
              }
              case 1:
                return (
                  <>
                    <Collapse className='mt-6' ghost>
                      <Panel key='performance-list' header='Individual Performance Contracting'>
                        <Timeline>
                          <Timeline.Item>Performance goals are role-based goals which the individual can directly contribute to and is specific to the individual’s role profile. </Timeline.Item>
                          <Timeline.Item>Please select Key Results Area's and individual performance goals that are aligned with the business objectives and with your team objectives. </Timeline.Item>
                          <Timeline.Item>Goals must be SMART: Specific, Measurable, Achievable, Realistic, and Timely </Timeline.Item>
                          <Timeline.Item>Continuous feedback is integral to the process of performance management and progress check-in. Information gathered from regular conversations between line manager and employee, forms the building blocks to the performance evaluation process. </Timeline.Item>
                          <Timeline.Item>Maximum of 8 Key Results Area's allowed with a minimum weighting of 5% per ojbective   </Timeline.Item>
                        </Timeline>
                      </Panel>
                    </Collapse>
                    <Form.List name='kras'>
                      {(kras, { add: addKras, remove: removeKras }) => (
                        <>
                          <Card className='relative mb-6 shadow-m'>
                            <Row gutter='32'>
                              <Col span={18}>
                                <p>Development plan weighting</p>
                              </Col>
                              <Col span={6}>
                                <p>10%</p>
                              </Col>
                            </Row>
                          </Card>
                          {kras.map(({ key, name, fieldKey, ...restField }, i) => (
                            <React.Fragment key={uuidv4()}>
                              <Divider orientation='left'>Set KRA</Divider>
                              <Card className='relative mb-6 shadow-m' key={uuidv4()}>
                                <Row gutter='32'>
                                  <Col span={18}>
                                    <Form.Item
                                      {...restField}
                                      label='Key Results Area Name'
                                      name={[name, 'name']}
                                      fieldKey={[fieldKey, 'name']}
                                      rules={[
                                        { required: true, message: 'KRA Name is required' }
                                      ]}
                                    >
                                      <Input disabled={!canEdit || contractsQuery.data?.status === 'Submitted to Manager for Approval'} />
                                    </Form.Item>

                                  </Col>
                                  <Col span={6}>
                                    <Form.Item
                                      {...restField}
                                      name={[name, 'weighting']}
                                      fieldKey={[fieldKey, 'weighting']}
                                      label='Weighting'
                                      hasFeedback
                                      rules={[
                                        { required: true, message: 'KRA Weighting is required' },
                                        ({ getFieldsValue }) => ({
                                          validator (_, value) {
                                            let total = 0
                                            getFieldsValue(true).kras.forEach(item => {
                                              total += item.weighting
                                            })
                                            if (total > 90) {
                                              return Promise.reject(new Error('KRA Weighting total cannot be greater than 90'))
                                            }
                                            return Promise.resolve()
                                          }
                                        })
                                      ]}
                                    >
                                      <InputNumber disabled={!canEdit || contractsQuery.data?.status === 'Submitted to Manager for Approval'} min={1} step={5} max={90} style={{ width: '100%' }} />
                                    </Form.Item>
                                  </Col>
                                </Row>
                                <>
                                  {canEdit && contractsQuery.data?.status !== 'Submitted to Manager for Approval' && (
                                    <Tooltip title='Delete Key Results Area'>
                                      <Button type='primary' shape='circle' className='absolute top-0 bg-blue-400 right-2' size='small' icon={<MinusCircleOutlined />} onClick={() => removeKras(name)} />
                                    </Tooltip>
                                  )}
                                </>
                                <Divider orientation='left'>Add KRA Objectives</Divider>
                                <Form.List name={[name, 'objectives']} key={uuidv4()}>
                                  {(kraObjs, { add, remove }) => (
                                    <>
                                      <Row gutter={['32']}>
                                        {kraObjs.map(obj => (
                                          <Col xs={24} md={12} key={uuidv4()}>
                                            <div className='flex items-center w-full gap-6'>
                                              <Form.Item
                                                key={uuidv4()}
                                                {...obj}
                                                className='w-full'
                                                label='Objective'
                                                name={[obj.name, 'name']}
                                                fieldKey={[obj.fieldKey, 'name']}
                                                rules={[
                                                  { required: true, message: 'Objective name is required' }
                                                ]}
                                              >
                                                <Input disabled={!canEdit || contractsQuery.data?.status === 'Submitted to Manager for Approval'} placeholder='Objective name' className='w-full' />
                                              </Form.Item>
                                              <Form.Item
                                                {...obj}
                                                name={[obj.name, 'weighting']}
                                                fieldKey={[obj.fieldKey, 'weighting']}
                                                label='Weighting'
                                                hasFeedback
                                                rules={[
                                                  { required: true, message: 'Objective Weighting is required' },
                                                  ({ getFieldsValue }) => ({
                                                    validator (_, value) {
                                                      const parentKra = getFieldsValue(true).kras[i]
                                                      let total = 0
                                                      parentKra.objectives.forEach(item => {
                                                        total += item.weighting
                                                      })
                                                      if (total > parentKra.weighting) {
                                                        return Promise.reject(new Error(`Objective Weighting total cannot be greater than ${parentKra.weighting}`))
                                                      }
                                                      return Promise.resolve()
                                                    }
                                                  })
                                                ]}
                                              >
                                                <InputNumber step={5} disabled={!canEdit || contractsQuery.data?.status === 'Submitted to Manager for Approval'} placeholder='Weighting' />
                                              </Form.Item>
                                              {canEdit && contractsQuery.data?.status !== 'Submitted to Manager for Approval' && (
                                                <MinusCircleOutlined disabled={!canEdit || contractsQuery.data?.status === 'Submitted to Manager for Approval'} onClick={() => remove(obj.name)} />
                                              )}
                                            </div>
                                          </Col>
                                        ))}
                                      </Row>
                                      <Form.Item>
                                        <Button disabled={!canEdit || contractsQuery.data?.status === 'Submitted to Manager for Approval'} type='dashed' onClick={() => add({ name: '', weighting: 0 })} block>
                                          + Add Objective
                                        </Button>
                                      </Form.Item>
                                    </>
                                  )}
                                </Form.List>
                              </Card>
                            </React.Fragment>
                          ))}
                          <Form.Item>
                            <Button disabled={!canEdit || contractsQuery.data?.status === 'Submitted to Manager for Approval' || kras.length === 8} type='dashed' onClick={() => addKras({ name: '', weighting: 0 })} block>
                              + Add Key Results Area(s)
                            </Button>
                          </Form.Item>
                        </>
                      )}
                    </Form.List>
                    <>
                      {contractsQuery.data?.status === 'Changes Requested' && state.preReviewComment && state.preReviewComment !== '' && (
                        <>
                          <p className='mt-6 mb-2'>Changes requested Comment</p>
                          <p>{state.preReviewComment}</p>
                        </>
                      )}
                    </>
                    <Space className='flex justify-end'>
                      <Button onClick={() => dispatch({ type: 'field', field: 'step', payload: state.step - 1 })}>Previous</Button>

                      {contractsQuery.data?.status !== 'Submitted to Manager for Approval' && contractsQuery.data?.status !== 'Contract Finalised' && (
                        <Button
                          loading={contractMutation.isLoading} onClick={() => {
                            handleSubmit('draft', form.getFieldsValue(true))
                          }}
                        >
                          Save as draft
                        </Button>
                      )}
                      {contractsQuery.data?.status === 'Contract Finalised' && (personQuery.data.companyPerson.admin?.roles.includes('talent.manager') || personQuery.data.companyPerson.admin?.roles.includes('talent.performanceManager')) && (
                        <Button
                          loading={contractMutation.isLoading}
                          onClick={() => {
                            handleSubmit('Contract Finalised', form.getFieldsValue(true), false, true)
                          }}
                        >
                          Update Contract
                        </Button>
                      )}
                      <Button className='bg-blue-400' type='primary' onClick={() => validateOnNext(state.step, form.getFieldsValue(true), true)}>
                        {personQuery.data.companyPerson.uid !== employeeUid ? 'Next' : 'Select your values'}
                      </Button>
                    </Space>
                  </>
                )
              case 2:
                return (
                  <>
                    <p className='mt-6 text-gray-400'>Select two value behaviour commitments that you will focus on as you carry out your work activities in relation to your role.</p>
                    <Divider orientation='left'>Select your values</Divider>
                    <Form.Item
                      name='value_first'
                      label='Thungela Value - One'
                      hasFeedback
                      rules={[
                        { required: true, message: 'Thungela value is required' },
                        ({ getFieldValue }) => ({
                          validator (_, value) {
                            if (!value || getFieldValue('value_second') !== value) {
                              return Promise.resolve()
                            }
                            return Promise.reject(new Error('You cannot select the same value'))
                          }
                        })
                      ]}
                    >
                      <Select
                        loading={thungelaValuesLookups.isLoading}
                        disabled={!canEdit || contractsQuery.data?.status === 'Submitted to Manager for Approval' || thungelaValuesLookups.isError || !thungelaValuesLookups.data}
                      >
                        {!thungelaValuesLookups.isLoading && thungelaValuesLookups.data && thungelaValuesLookups.data.map(option => (
                          <Option key={option} value={option}>{option}</Option>
                        ))}
                      </Select>
                    </Form.Item>
                    <Form.Item
                      name='comment_first'
                      label='Value Commitment'
                      rules={[
                        {
                          required: true,
                          message: 'Value comment is required'
                        }
                      ]}
                    >
                      <DefaultEditor disabled={!canEdit || contractsQuery.data?.status === 'Submitted to Manager for Approval'} />
                    </Form.Item>
                    <Divider />
                    <Form.Item
                      name='value_second'
                      label='Thungela Value - Two'
                      hasFeedback
                      rules={[
                        { required: true, message: 'Thungela value is required' },
                        ({ getFieldValue }) => ({
                          validator (_, value) {
                            if (!value || getFieldValue('value_first') !== value) {
                              return Promise.resolve()
                            }
                            return Promise.reject(new Error('You cannot select the same value'))
                          }
                        })
                      ]}
                    >
                      <Select
                        loading={thungelaValuesLookups.isLoading}
                        disabled={!canEdit || contractsQuery.data?.status === 'Submitted to Manager for Approval' || thungelaValuesLookups.isError || !thungelaValuesLookups.data}
                      >
                        {!thungelaValuesLookups.isLoading && thungelaValuesLookups.data && thungelaValuesLookups.data.map(option => (
                          <Option key={option} value={option}>{option}</Option>
                        ))}
                      </Select>
                    </Form.Item>
                    <Form.Item
                      name='comment_second'
                      label='Value Commitment'
                      rules={[
                        {
                          required: true,
                          message: 'Value comment is required'
                        }
                      ]}
                    >
                      <DefaultEditor disabled={!canEdit || contractsQuery.data?.status === 'Submitted to Manager for Approval'} />
                    </Form.Item>
                    <>
                      {contractsQuery.data?.status === 'Changes Requested' && state.preReviewComment && state.preReviewComment !== '' && (
                        <>
                          <p className='mt-6 mb-2'>Changes requested Comment</p>
                          <p>{state.preReviewComment}</p>
                        </>
                      )}
                    </>
                    <Space className='flex justify-end'>
                      <Button onClick={() => dispatch({ type: 'field', field: 'step', payload: state.step - 1 })}>Previous</Button>
                      <Tooltip title='Download Thungela Values Card'>
                        <Button onClick={() => saveAs('/images/values-card.png', 'values-card.png')} className='bg-blue-400' type='primary'>Thungela Values</Button>
                      </Tooltip>
                      <>
                        {contractsQuery.data?.status !== 'Submitted to Manager for Approval' && (
                          <Button
                            loading={contractMutation.isLoading} onClick={() => {
                              handleSubmit('draft', form.getFieldsValue(true))
                            }}
                          >
                            Save as draft
                          </Button>
                        )}
                      </>
                      <Button className='bg-blue-400' type='primary' onClick={() => validateOnNext(state.step, form.getFieldsValue(true), true)}>
                        {personQuery.data.companyPerson.uid !== employeeUid ? 'Next' : "Set IDP's"}
                      </Button>
                      {/* <Button className='bg-blue-400' type='primary' onClick={() => dispatch({ type: 'save-form', field: 'form2', payload: form2.getFieldsValue(true), goNext: true })}>Next</Button> */}
                    </Space>
                  </>
                )
              case 3: {
                return (
                  <>
                    <Form.List name='developmentPlans'>
                      {(plans, { add, remove }) => (
                        <>
                          <Divider orientation='left'>Create your development plan</Divider>
                          {plans.map(({ key, name, fieldKey, ...restField }) => (
                            <Card className='relative mt-8 mb-6 shadow-xl' key={key}>
                              {canEdit && contractsQuery.data?.status !== 'Submitted to Manager for Approval' && (
                                <Button onClick={() => remove(name)} type='primary' size='small' shape='circle' className='absolute top-0 right-0 z-10 bg-blue-400'>x</Button>
                              )}
                              <Form.Item
                                {...restField}
                                name={[name, 'need']}
                                fieldKey={[fieldKey, 'need']}
                                label='Development Need'
                                rules={[{ required: true, message: 'Missing development need' }]}
                              >
                                <Input disabled={!canEdit || contractsQuery.data?.status === 'Submitted to Manager for Approval'} />
                              </Form.Item>

                              <Row gutter='32'>
                                <Col xs={24} md={12}>
                                  <Form.Item
                                    {...restField}
                                    label='Timeframe'
                                    name={[name, 'dates']}
                                    rules={[{ required: true, message: 'Missing date' }]}
                                  >
                                    <RangePicker valuePropName='date' disabledDate={disabledDate} disabled={!canEdit || contractsQuery.data?.status === 'Submitted to Manager for Approval'} />
                                  </Form.Item>
                                  <Form.Item
                                    label='Development Type'
                                    {...restField}
                                    name={[name, 'developmentType']}
                                    fieldKey={[fieldKey, 'developmentType']}
                                    rules={[{ required: true, message: 'Missing development type' }]}
                                  >
                                    <Select disabled={!canEdit || contractsQuery.data?.status === 'Submitted to Manager for Approval'} placeholder='Development Area'>
                                      <Option value='Experiential Development'>
                                        <Tooltip title='On the job development e.g. acting, short-term exposures, projects, mentoring, coaching, secondments, etc'>
                                          Experiential Development
                                        </Tooltip>
                                      </Option>
                                      <Option value='Leadership Skills'>
                                        <Tooltip title='Effective leadership skills to manage teams, respond to our business (safety, productivity, ESG and operational excellence) goals in in line with our strategic objectives'>
                                          Leadership Skills
                                        </Tooltip>
                                      </Option>
                                      <Option value='Technical Skills'>
                                        <Tooltip title='Proficiency in the practical application of knowledge. The ability to carry out specific activities using appropriate tools and processes, in order to achieve specific business outcomes. It encompasses discipline specific and commercial skills e.g. Specialist know-how, Certification, Commercial Know-How, Mastery of Best Practice'>
                                          Technical Skills
                                        </Tooltip>
                                      </Option>
                                      <Option value='Formal Educational or Learning'>
                                        <Tooltip title='Formal studies in obtaining qualifications i.e. certificate, degree, post graduate / masters / doctorate studies'>
                                          Formal Educational or Learning
                                        </Tooltip>
                                      </Option>
                                      <Option value='Social Processing Skills '>
                                        <Tooltip title='The ability to read social situations, understand the underlying social processes and use that understanding to influence behaviour in a way that contributes to our Purpose and is aligned to our values. This includes Self-awareness, Collaboration and Team work'>
                                          Social Processing Skills
                                        </Tooltip>
                                      </Option>
                                    </Select>
                                  </Form.Item>

                                </Col>
                                <Col xs={24} md={12}>
                                  <Form.Item
                                    label='Support Required from Organisation / Management'
                                    {...restField}
                                    name={[name, 'resourcesNeeded']}
                                    fieldKey={[fieldKey, 'resourcesNeeded']}
                                    rules={[{ required: true, message: 'Missing resources & support needed' }]}
                                  >
                                    <Input autoComplete='off' disabled={!canEdit || contractsQuery.data?.status === 'Submitted to Manager for Approval'} placeholder='Support required' />
                                  </Form.Item>
                                  <Form.Item
                                    {...restField}
                                    name={[name, 'developmentGaps']}
                                    fieldKey={[fieldKey, 'developmentGaps']}
                                    label='What Development Gaps will this address or what is your desired outcome?'
                                    rules={[{ required: true, message: 'Missing development gaps' }]}
                                  >
                                    <Input.TextArea disabled={!canEdit || contractsQuery.data?.status === 'Submitted to Manager for Approval'} placeholder='Development gaps' />
                                  </Form.Item>
                                </Col>
                              </Row>
                              <Form.Item
                                {...restField}
                                name={[name, 'note']}
                                fieldKey={[fieldKey, 'note']}
                                label={<p>Note Detail on Activity • <span className='text-blue-500'>What will I do to achieve this?</span></p>}
                                rules={[{ required: true, message: 'Missing note' }]}
                              >
                                <Input.TextArea disabled={!canEdit || contractsQuery.data?.status === 'Submitted to Manager for Approval'} placeholder='Comments' />
                              </Form.Item>
                            </Card>
                          ))}
                          <Form.Item>
                            <Button disabled={!canEdit || contractsQuery.data?.status === 'Submitted to Manager for Approval'} type='dashed' onClick={() => add()} block>
                              + Add Action Item
                            </Button>
                          </Form.Item>

                        </>
                      )}
                    </Form.List>
                    <>
                      {contractsQuery.data?.status === 'Changes Requested' && state.preReviewComment && state.preReviewComment !== '' && (
                        <>
                          <p className='mt-6 mb-2'>Changes requested Comment</p>
                          <p>{state.preReviewComment}</p>
                        </>
                      )}
                    </>
                    <>
                      {contractsQuery.data?.status === 'Submitted to Manager for Approval' && employeeUid !== personQuery.data?.companyPerson?.uid && (
                        <>
                          <p className='mt-6 mb-2'>Approval/Request changes Comment</p>
                          <Input.TextArea className='mb-4' value={state.preReviewComment} onChange={e => dispatch({ type: 'field', field: 'preReviewComment', payload: e.currentTarget.value })} />
                        </>
                      )}
                    </>
                    <Space className='flex justify-end'>
                      <Button onClick={() => dispatch({ type: 'field', field: 'step', payload: state.step - 1 })}>Previous</Button>
                      {/* <Button className='bg-blue-400' type='primary' onClick={() => dispatch({ type: 'field', field: 'step', payload: state.step + 1 })}>Next</Button> */}
                      {employeeUid === personQuery.data?.companyPerson?.uid
                        ? (
                          <>
                            {contractsQuery.data?.status !== 'Submitted to Manager for Approval'
                              ? (
                                <>
                                  <Button
                                    loading={contractMutation.isLoading} onClick={() => {
                                      handleSubmit('draft', form.getFieldsValue(true))
                                    }}
                                  >
                                    Save as draft
                                  </Button>
                                  <Button
                                    // disabled={!IDPValid(form.getFieldsValue(true).developmentPlans)}
                                    loading={contractMutation.isLoading} className='bg-blue-400' type='primary'
                                    onClick={() => {
                                      if (IDPValid(form.getFieldsValue(true).developmentPlans)) {
                                        handleSubmit('Submitted to Manager for Approval', form.getFieldsValue(true))
                                      } else {
                                        message.error('Please complete your IDP')
                                      }
                                    }}
                                  >
                                    Submit to Manager for Approval
                                  </Button>
                                </>
                                )
                              : (
                                <Button className='bg-blue-400' type='primary' onClick={() => dispatch({ type: 'field', field: 'visible', payload: false })}>Done</Button>
                                )}
                          </>
                          )
                        : (
                          <>
                            {contractsQuery.data?.status === 'Submitted to Manager for Approval'
                              ? (
                                <>
                                  <Button
                                    loading={contractMutation.isLoading} className='bg-blue-400' danger type='primary' onClick={() => {
                                      handleSubmit('Changes Requested', form.getFieldsValue(true))
                                    }}
                                  >Request Changes
                                  </Button>
                                  <Button
                                    loading={contractMutation.isLoading} className='bg-blue-400' type='primary' onClick={() => {
                                      handleSubmit('Contract Finalised', form.getFieldsValue(true))
                                    }}
                                  >Approve Contract
                                  </Button>
                                </>
                                )
                              : (
                                <>
                                  <Button className='bg-blue-400' type='primary' onClick={() => dispatch({ type: 'field', field: 'visible', payload: false })}>Done</Button>
                                </>
                                )}
                          </>
                          )}
                    </Space>
                  </>
                )
              }
              default: {
                return (
                  <div className='flex items-center justify-center p-16 text-center'>
                    Contract Modal
                  </div>
                )
              }
            }
          })()}
        </Form>
      </Modal>
      <SnapshotModal
        visible={state.snapVisible}
        title='Create snapshot'
        onCancel={() => dispatch({ type: 'field', field: 'snapVisible', payload: false })}
        contract={snapToReview || contractsQuery.data}
        year={year}
        query={contractsQuery?.data}
        snapIdx={snapIdx}
        employeeQuery={employeeQuery}
      />

      <PMFinalModal
        visible={state.finalVisible}
        title={(isMe(personQuery?.data, employeeUid)) ? 'Create self assessment' : 'Create Year End Performance Evaluation'}
        onCancel={() => dispatch({ type: 'field', field: 'finalVisible', payload: false })}
        contract={contractsQuery.data}
        year={year}
        employeeQuery={employeeQuery}
      />

      <TeamObjectiveModal
        visible={state.teamVisible}
        onCancel={() => dispatch({ type: 'field', field: 'teamVisible', payload: false })}
        year={year}
      />

      <PerformancePdf state={contractsQuery.data} year={year} preview={preview} setPreview={setPreview} employeeQuery={employeeQuery} />
      <div className='flex justify-end w-full mb-4 align-center'>
        <Space>
          <Text>Year: </Text>
          <Select
            onChange={value => {
              setYear(value)
              form.resetFields()
            }}
            defaultValue={year} style={{ width: 120 }}
          >
            <Option value={currentYear}>{currentYear}</Option>
            <Option value={currentYear - 1}>{currentYear - 1}</Option>
            <Option value={currentYear - 2}>{currentYear - 2}</Option>
            <Option value={currentYear - 3}>{currentYear - 3}</Option>
          </Select>
          {(!employeeQuery.isLoading && !contractsQuery.isLoading) && (employeeQuery.data && contractsQuery.data) && (
            <Tooltip title='Download Performance Management Contract'>
              <Button onClick={() => setPreview(true)} className='bg-blue-400' type='primary'>Download Contract</Button>
            </Tooltip>
          )}
          {!personQuery.isLoading && personQuery?.data?.companyPerson?.talentCompanyPerson?.isHOD && (<Button className='bg-blue-400' onClick={() => dispatch({ type: 'field', field: 'teamVisible', payload: true })} type='primary'>Create Team Objective</Button>)}
          {/* <Tooltip title='Download Decision Tree'>
            <Button onClick={() => saveAs('/images/tree.png', 'decision-tree.png')} className='bg-blue-400' type='primary' shape='circle' icon={<DeploymentUnitOutlined />} />
          </Tooltip> */}
        </Space>
      </div>
      {!employeeQuery.isLoading && employeeQuery.data && (
        <PerformanceTables employee={employeeQuery.data} year={year} />
      )}
      {!contractsQuery.isLoading && !personQuery.isLoading
        ? (
          <>
            {!contractsQuery.isError && !personQuery.isError
              ? (
                <>
                  {contractsQuery.data && personQuery.data
                    ? (
                      <>
                        <Collapse
                          ghost defaultActiveKey={
                          ((contractsQuery.data?.status?.replace(/'/g, '') === 'Contract Finalised') &&
                          (contractsQuery?.data?.reviews?.[0]?.status !== 'Snapshot Finalised' || contractsQuery?.data?.reviews?.[1]?.status !== 'Snapshot Finalised') &&
                          (contractsQuery?.data?.assessments?.status !== 'Pending Calibration') && ['snapshots', 'final']) ||
                          ((contractsQuery.data?.status?.replace(/'/g, '') === 'Contract Finalised') &&
                          (contractsQuery?.data?.reviews?.[0]?.status === 'Snapshot Finalised' && contractsQuery?.data?.reviews?.[1]?.status === 'Snapshot Finalised') &&
                          (contractsQuery?.data?.assessments?.status !== 'Pending Calibration') && ['final']) ||
                          ((contractsQuery.data?.status?.replace(/'/g, '') !== 'Contract Finalised') &&
                          (contractsQuery?.data?.reviews?.[0]?.status !== 'Snapshot Finalised' || contractsQuery?.data?.reviews?.[1]?.status !== 'Snapshot Finalised') &&
                          (contractsQuery?.data?.assessments?.status !== 'Pending Calibration') && ['contract', 'snapshots', 'final']) ||
                          ((contractsQuery.data?.status?.replace(/'/g, '') !== 'Contract Finalised') && ['contract']) ||
                          ((contractsQuery?.data?.reviews?.[0]?.status !== 'Snapshot Finalised' || contractsQuery?.data?.reviews?.[1]?.status !== 'Snapshot Finalised') && ['snapshots']) ||
                          (contractsQuery?.data?.assessments?.status !== 'Pending Calibration' && ['final'])
                        }
                        >
                          <Collapse.Panel
                            key='contract'
                            extra={contractsQuery.data?.status?.replace(/'/g, '') !== 'draft' && [
                              <Tag key='status' color='blue'>{contractsQuery.data?.status?.replace(/'/g, '')}</Tag>
                            ]}
                            header={
                              <div className='flex gap-2'>
                                <Typography.Title style={{ margin: 0, color: 'rgb(96 165 250)' }} level={4}>Performance Management Contract</Typography.Title>
                                {/* {personQuery.data?.companyPerson?.uid === employeeUid && contractsQuery.data?.status !== 'Submitted to Manager for Approval' && !contractsQuery.data?.assessments && <Button onClick={() => dispatch({ type: 'edit-contract', payload: contractsQuery.data })} type='primary' shape='circle' className='bg-blue-400' icon={<EditOutlined />} />} */}
                                {personQuery.data && personQuery.data?.companyPerson?.uid !== employeeUid && !contractsQuery.data?.assessments && (
                                  <Button
                                    disabled
                                    onClick={(e) => {
                                      dispatch({ type: 'edit-contract', payload: contractsQuery.data })
                                      e.stopPropagation()
                                    }}
                                    type='primary'
                                    className='bg-blue-400'
                                  >
                                    {contractsQuery.data?.status === 'Submitted to Manager for Approval' ? 'Review Contract' : 'Preview'}
                                  </Button>
                                )}
                                {personQuery.data && personQuery.data?.companyPerson?.uid !== employeeUid && contractsQuery.data?.assessments && (personQuery.data.companyPerson.admin?.roles.includes('talent.manager') || personQuery.data.companyPerson.admin?.roles.includes('talent.performanceManager')) && (
                                  <Button
                                    disabled
                                    onClick={(e) => {
                                      dispatch({ type: 'edit-contract', payload: contractsQuery.data })
                                      e.stopPropagation()
                                    }}
                                    type='primary'
                                    className='bg-blue-400'
                                  >
                                    Update Contract
                                  </Button>
                                )}
                              </div>
                          }
                          >
                            <ContractCard canSubmitToManager={canSubmitToManager} handleSubmit={handleSubmit} person={personQuery.data} year={year} contract={contractsQuery.data} onEdit={() => dispatch({ type: 'edit-contract', payload: contractsQuery.data })} />
                          </Collapse.Panel>
                          <Collapse.Panel
                            key='snapshots'
                            header={
                              <Typography.Title style={{ color: 'rgb(96 165 250)' }} level={4}>Mid Year Snapshot</Typography.Title>
                            }
                            extra={
                              <Space>
                                {contractsQuery?.data?.reviews?.[0]?.status && <Tag key='status' color='blue'>{contractsQuery?.data?.reviews?.[0]?.status}</Tag>}
                              </Space>
                            }
                          >
                            <Card
                              className='mb-8'
                              title={
                                <div className='flex items-center gap-2'>
                                  <p>Mid Year Snapshot</p>
                                  {personQuery.data?.companyPerson?.uid === employeeUid && (
                                    <>
                                      {
                                  contractsQuery.data?.reviews &&
                              contractsQuery.data?.reviews[0] &&
                              contractsQuery.data?.reviews[0]?.status !== 'Submitted Snapshot to Manager for Approval' &&
                              contractsQuery.data?.reviews[0]?.status !== 'Snapshot Finalised' &&
                              !contractsQuery.data?.assessments &&
                                <Button
                                  disabled
                                  onClick={() => {
                                    setSnapToReview({ ...contractsQuery.data?.reviews[0] })
                                    setSnapIdx(0)
                                    dispatch({ type: 'field', field: 'snapVisible', payload: true })
                                  }}
                                  type='primary'
                                  shape='circle'
                                  className='bg-blue-400'
                                  icon={<EditOutlined />}
                                />
}
                                    </>
                                  )}
                                  {personQuery.data?.companyPerson?.uid !== employeeUid && (
                                    <>
                                      {contractsQuery.data?.reviews && contractsQuery.data?.reviews[0] && contractsQuery.data?.reviews[0]?.status !== 'Snapshot Finalised' && !contractsQuery.data?.assessments && (
                                        <Button
                                          disabled
                                          onClick={() => {
                                            setSnapToReview({ ...contractsQuery.data?.reviews[0] })
                                            setSnapIdx(0)
                                            dispatch({ type: 'field', field: 'snapVisible', payload: true })
                                          }}
                                          type='primary' className='bg-blue-400'
                                        >{contractsQuery.data?.reviews[0]?.status === 'Submitted Snapshot to Manager for Approval' ? 'Review Snapshot' : 'Preview'}
                                        </Button>
                                      )}
                                    </>
                                  )}
                                </div>
                          }
                            >
                              {contractsQuery.data.reviews && contractsQuery.data.reviews[0]
                                ? (
                                  <>
                                    <Typography.Title level={5}>Key Results Area(s)</Typography.Title>
                                    <Table bordered className='mb-8' pagination={false} rowKey='id' columns={performanceTable.kra.columns} dataSource={contractsQuery.data.reviews[0].kra.map(kra => ({ ...kra, id: uuidv4(), children: kra.objectives.map(obj => ({ ...obj, empty: null })) }))} />
                                    <Typography.Title level={5}>IDP(s)</Typography.Title>
                                    <Table bordered pagination={false} rowKey='id' columns={performanceTable.idp.columns} dataSource={contractsQuery.data.reviews[0].idp.map(kra => ({ ...kra, id: uuidv4() }))} />
                                    {contractsQuery?.data?.reviews && contractsQuery?.data?.reviews?.length && contractsQuery?.data?.reviews[0]?.status === 'Snapshot Finalised'
                                      ? (
                                        <>
                                          <Typography.Title className='mt-8' level={5}>Manager Comment</Typography.Title>
                                          <Typography.Text>{contractsQuery?.data?.reviews[0]?.managerComment}</Typography.Text>
                                        </>
                                        )
                                      : null}
                                  </>
                                  )
                                : isMe(personQuery?.data, employeeUid)
                                  ? (
                                    <Button
                                      disabled
                                      // disabled={contractsQuery.data?.status !== 'Contract Finalised'}
                                      className='mb-8' type='dashed' block onClick={() => {
                                        dispatch({ type: 'field', field: 'snapVisible', payload: true })
                                        setSnapIdx(0)
                                      }}
                                    >
                                      + Add Mid Year Snapshot
                                    </Button>
                                    )
                                  : null}
                            </Card>
                          </Collapse.Panel>
                          <Collapse.Panel key='final' header={<Typography.Title style={{ margin: 0, color: 'rgb(96 165 250)' }} level={4}>Year End Review</Typography.Title>} extra={contractsQuery?.data?.assessments?.status && <Tag key='status' color='blue'>{contractsQuery?.data?.assessments?.status}</Tag>}>
                            <Card className='mb-8'>
                              {contractsQuery?.data?.assessments
                                ? (
                                  <>
                                    <Typography.Title level={5}>
                                      {isMe(personQuery?.data, employeeUid)
                                        ? 'My Self Assessment'
                                        : 'Employee\'s Self Assessment'}
                                    </Typography.Title>
                                    {contractsQuery?.data?.assessments?.employee &&
                                      (
                                        <>
                                          <Table
                                            bordered
                                            className='mb-8'
                                            pagination={false}
                                            columns={performanceTable.final.employee.columns}
                                            dataSource={contractsQuery?.data?.assessments?.employee?.response?.slice(0, -1)?.map((kra, ki) => ({ ...kra, name: `${ki + 1}. ${kra?.name}`, id: uuidv4(), key: `emp-${uuidv4()}`, children: kra.objectives.map((obj, oi) => ({ ...obj, name: '', objective: `${ki + 1}.${oi + 1}. ${obj?.name}`, empty: null, key: `emp-${uuidv4()}` })) }))}
                                          />
                                          <Typography.Title level={5}>Assessment of my Individual Development Plan (IDP)</Typography.Title>
                                          <Table
                                            bordered
                                            className='mb-8'
                                            pagination={false}
                                            columns={performanceTable.final.employee.columns}
                                            dataSource={contractsQuery?.data?.assessments?.employee?.response?.[contractsQuery?.data?.assessments?.employee?.response?.length - 1] && [contractsQuery?.data?.assessments?.employee?.response?.[contractsQuery?.data?.assessments?.employee?.response?.length - 1]]?.map(kra => ({ ...kra, id: uuidv4(), key: `emp-${uuidv4()}`, children: kra?.objectives.map(obj => ({ ...obj, name: '', objective: obj?.name, empty: null, key: `emp-${uuidv4()}` })) }))}
                                          />
                                        </>
                                      )}

                                    {!contractsQuery?.data?.assessments?.employee && isMe(personQuery?.data, employeeUid)
                                      ? (
                                        <Button
                                          disabled
                                          className='mb-8'
                                          type='dashed'
                                          block
                                          onClick={() => dispatch({ type: 'field', field: 'finalVisible', payload: true })}
                                        >
                                          + Create Self Assessment
                                        </Button>
                                        )
                                      : !contractsQuery?.data?.assessments?.employee && !isMe(personQuery?.data, employeeUid)
                                          ? <Typography.Text>Employee has not yet submitted their self assessment</Typography.Text>
                                          : ''}

                                    {/* <Table size='small' bordered className='mb-8' pagination={false} columns={planColumns} dataSource={contractsQuery?.data?.developmentPlans} /> */}

                                    {!isMe(personQuery?.data, employeeUid) && contractsQuery?.data?.assessments?.manager?.response && (
                                      <>
                                        <Divider />
                                        <Typography.Title level={5}>Manager Evaluation</Typography.Title>
                                        <Table
                                          bordered
                                          className='mb-8'
                                          pagination={false}
                                          columns={performanceTable.final?.manager.columns}
                                          dataSource={contractsQuery?.data?.assessments?.manager?.response?.slice(0, -1)?.map((kra, ki) => ({ ...kra, name: `${ki + 1}. ${kra?.name}`, id: uuidv4(), key: `man-${uuidv4()}`, children: kra.objectives.map((obj, oi) => ({ ...obj, name: '', objective: `${ki + 1}.${oi + 1}. ${obj?.name}`, empty: null, key: `man-${uuidv4()}` })) }))}
                                        />
                                        <Typography.Title level={5}>Assessment of employee's Individual Development Plan (IDP)</Typography.Title>
                                        <Table
                                          bordered
                                          className='mb-8'
                                          pagination={false}
                                          columns={performanceTable.final?.manager.columns}
                                          dataSource={[contractsQuery?.data?.assessments?.manager?.response?.[contractsQuery?.data?.assessments?.manager?.response?.length - 1]]?.map(kra => ({ ...kra, id: uuidv4(), key: `man-${uuidv4()}`, children: kra.objectives.map(obj => ({ ...obj, name: '', objective: obj?.name, empty: null, key: `man-${uuidv4()}` })) }))}
                                        />
                                        <Table size='small' bordered className='mb-8' pagination={false} columns={planColumns} dataSource={contractsQuery?.data?.developmentPlans} />
                                        <Typography.Title className='mt-8' level={5}>Overall Comment</Typography.Title>
                                        <Typography.Text>{contractsQuery?.data?.assessments?.manager?.overallComment}</Typography.Text>
                                      </>
                                    )}
                                    {!isMe(personQuery?.data, employeeUid) && contractsQuery?.data?.assessments?.status === 'Submitted to Manager for Approval' && (
                                      <Button
                                        disabled
                                        className='mb-8' type='dashed' block onClick={() => dispatch({ type: 'field', field: 'finalVisible', payload: true })}
                                      >
                                        + Start Year End Performance Evaluation
                                      </Button>
                                    )}
                                    {!isMe(personQuery?.data, employeeUid) && contractsQuery?.data?.assessments?.status === 'Pending Calibration' && (personQuery.data.companyPerson.admin?.roles.includes('talent.manager') || personQuery.data.companyPerson.admin?.roles.includes('talent.performanceManager')) && (
                                      <Button
                                        disabled
                                        className='my-8' type='dashed' block onClick={() => dispatch({ type: 'field', field: 'finalVisible', payload: true })}
                                      >
                                        + Update Year End Performance Evaluation
                                      </Button>
                                    )}
                                  </>
                                  )
                                : (
                                  <Button
                                    disabled
                                    // disabled={contractsQuery.data?.status !== 'Contract Finalised'}
                                    className='mb-8' type='dashed' block onClick={() => {
                                      if (contractsQuery.data?.status !== 'Contract Finalised') {
                                        message.warn('Contract must be finalised to create a final review')
                                      } else {
                                        dispatch({ type: 'field', field: 'finalVisible', payload: true })
                                      }
                                    }}
                                  >
                                    {isMe(personQuery?.data, employeeUid)
                                      ? '+ Create Self Assessment'
                                      : '+ Start Year End Performance Evaluation'}
                                  </Button>
                                  )}
                            </Card>
                          </Collapse.Panel>
                        </Collapse>

                      </>
                      )
                    : (
                      <Button
                        className='mb-6'
                        type='dashed'
                        block
                        onClick={() => dispatch({ type: 'field', field: 'visible', payload: true })}
                        disabled
                        // disabled={!canEdit}
                      >
                        + Add Contract
                      </Button>
                      )}
                </>
                )
              : (
                <Result
                  status='warning'
                  title='We are unable to fetch employee performance review right now'
                />
                )}
          </>
          )
        : (
          <div className='flex items-center justify-center w-full py-24'>
            <Spin size='large' />
          </div>
          )}
    </>
  )
}
