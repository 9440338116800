import React, { useMemo } from 'react'
import { Card, Button, Divider, Table, Tag, Typography } from 'antd'
import { generateKey } from '../hooks/generateKey'
import uuidv4 from '../hooks/uuid'
import { EditOutlined } from '@ant-design/icons'
import { useParams } from 'react-router-dom'
import dayjs from 'dayjs'

export const planColumns = [
  {
    title: 'Development Need',
    dataIndex: 'need'
  },
  {
    title: 'Note',
    dataIndex: 'note'
  },
  {
    title: 'Development Type',
    dataIndex: 'developmentType',
    render: tag => (
      <Tag color='orange'>{tag}</Tag>
    )
  },
  {
    title: 'Development Gaps',
    dataIndex: 'developmentGaps'
  },
  {
    title: 'Resources & Support ',
    dataIndex: 'resourcesNeeded'
  },
  {
    title: 'Timeframe',
    dataIndex: 'timeframe'
  }
]

export const ContractCard = ({ contract, onEdit, year, person, isPdf }) => {
  const { employeeUid } = useParams()

  const kras = useMemo(() => {
    if (contract) {
      const kras = contract.indicators?.map(x => ({
        key: uuidv4(),
        name: x.name,
        weighting: x.weighting,
        full: true,
        children: x.objectives?.map(y => ({
          key: uuidv4(),
          empty: null,
          name: y?.name,
          type: 'Objective',
          assessment: '',
          weighting: y?.weighting
        })) || []
      }))
      return kras
    }
  }, [contract])

  const plans = useMemo(() => {
    if (contract) {
      const plans = contract.developmentPlans?.map(x => {
        const obj = {
          key: uuidv4(),
          ...x
        }
        if (x?.startDate && x?.endDate) {
          return {
            ...obj,
            timeframe: `${dayjs(x?.startDate).format('DD MMM')} - ${dayjs(x?.endDate).format('DD MMM')}`
          }
        }
        return obj
      })
      return plans
    }
  }, [contract])

  const columns = [
    {
      title: '',
      dataIndex: 'empty'
    },
    {
      title: 'Name',
      dataIndex: 'name'
    },
    {
      title: 'Weighting',
      dataIndex: 'weighting'
    }
  ]

  const valueColumns = [
    {
      title: 'Value',
      dataIndex: 'value'
    },
    {
      title: 'Commitment',
      dataIndex: 'comment',
      render: (data) => <div dangerouslySetInnerHTML={{ __html: data }} />
    }
  ]

  return (
    <Card
    // eslint-disable-next-line
      extra={isPdf && contract?.status?.replace(/\'/g, '') !== 'draft' && [
        // eslint-disable-next-line
        <Tag key='status' color='blue'>{contract?.status?.replace(/\'/g, '')}</Tag>
      ]}
      title={
        isPdf &&
          <div className='flex items-center gap-2'>
            <Typography.Title style={{ margin: 0, color: 'rgb(96 165 250)' }} level={4}>Performance Management Contract</Typography.Title>
            {contract?.assessments?.status !== 'Pending Calibration' && (
              <>
                {onEdit && person?.companyPerson?.uid === employeeUid && contract?.status !== 'Submitted to Manager for Approval' && <Button onClick={() => onEdit()} type='primary' shape='circle' className='bg-blue-400' icon={<EditOutlined />} />}
                {/* eslint-disable-next-line */}
              {onEdit && person && person?.companyPerson?.uid !== employeeUid && <Button onClick={() => onEdit()} type='primary' className='bg-blue-400'>{contract?.status === 'Submitted to Manager for Approval' ? 'Review Contract' : 'Preview'} </Button>}
              </>
            )}
          </div>
}
    >
      <Typography.Title level={5}>Key Results Area's</Typography.Title>
      <Table
        size={isPdf && 'small'}
        bordered className='mb-8' pagination={false} columns={columns} dataSource={kras || []} expandable={{
          defaultExpandAllRows: isPdf
        }}
      />
      <Typography.Title level={5}>Values</Typography.Title>
      {isPdf
        ? (
          <>
            <Table size={isPdf && 'small'} bordered className='mb-8' pagination={false} columns={valueColumns} dataSource={contract?.companyValues || []} />
          </>
          )
        : (
            contract?.companyValues?.map(item => (
              <Tag key={generateKey(item?.value || '')} color='green'>{item?.value}</Tag>
            ))
          )}
      <Divider />
      <Typography.Title level={5}>IDP's</Typography.Title>
      <Table size={isPdf && 'small'} bordered className='mb-8' pagination={false} columns={planColumns} dataSource={plans || []} />
      {/* {canSubmitToManager && (
        <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
          <Button
            className='bg-blue-400 right-align' type='primary' onClick={() => {
              handleSubmit('Submitted to Manager for Approval', contract, true)
            }}
          >Submit to Manager for Approval
          </Button>
        </div>
      )} */}
    </Card>
  )
}
