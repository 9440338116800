import { Tag } from 'antd'
import React from 'react'

export function TagFactory ({ status, children, icon = null }) {
  const [color] = React.useState(() => {
    switch (status) {
      case 'Not Started':
      case 'not-started':
      case 'expiring':
      case 'Expiring':
        return 'orange'

      case 'requested':
      case 'Requested':
      case 'In Progress':
      case 'in-progress':
        return 'blue'

      case 'declined':
      case 'Declined':
      case 'failed':
      case 'Failed':
      case 'Expired':
      case 'expired':
      case 'Outstanding':
        return 'red'

      case 'Completed':
      case 'completed':
      default:
        return 'green'
    }
  })
  return (
    <Tag icon={icon} color={color}>
      {children}
    </Tag>
  )
}
