import { Card, Col, Divider, Image, Row, Space } from 'antd'
import Text from 'antd/lib/typography/Text'
import Title from 'antd/lib/typography/Title'
import dayjs from 'dayjs'
import React from 'react'
import { Link } from 'react-router-dom'
// import { randomName } from '../hooks/randomName'
import useWindowDimensions from '../hooks/useWindowDimensions'
// import { makeId } from '../hooks/uuid'

export const EmployeeCard = ({ employee, index }) => {
  const { width } = useWindowDimensions()

  return (
    <Link key={employee.uid} to={`/employees/${employee.uid}`}>
      <Card>
        <Row>
          <Col className='mb-4' xs={24} sm={12} md={8} lg={8} xl={8}>
            <Space className='w-full hasImage' align='start' direction='horizontal'>
              {width > 768 && (
                <Image
                  className='w-16 h-16 object-cover rounded-full'
                  src={employee.person.imageUrl || `https://api.dicebear.com/7.x/initials/svg?seed=${employee.person.firstName[0]}`}
                  alt='person'
                />
              )}
              <div className='md:ml-0 lg:ml-4 hasLongContent'>
                <Title style={{ margin: 0 }} level={4}>
                  {employee.person.firstName}{' '}{employee.person.lastName}
                  {/* {randomName()} */}
                </Title>
                <p className='email'>{employee?.email}</p>

                <Space className='mt-4 w-full' direction='vertical'>
                  <Space direction='horizontal'>
                    <Text type='secondary'>Employee ID: </Text>
                    <Text strong>
                      {/* {uuidv4().split('-')[0]} */}
                      {/* {makeId(6)} */}
                      {employee.employment?.employeeNumber || 'Unset'}
                    </Text>
                  </Space>
                  <Space direction='horizontal'>
                    <Text type='secondary'>Date of Hire: </Text>
                    <Text strong>
                      {employee?.joiningDate
                        ? dayjs(employee?.employment?.startDate).format(
                            'DD/MM/YYYY'
                          )
                        : ''}
                    </Text>
                  </Space>
                  {/* <Space direction='horizontal'>
                    <Text type='secondary'>Location: </Text>
                    <Text strong>
                      {employee.employment?.jobLocation || 'Unset'}
                    </Text>
                  </Space> */}
                  <Space direction='horizontal'>
                    <Text type='secondary'>Region: </Text>
                    <Text strong>
                      {employee.employment.countryCode || 'Unset'}
                    </Text>
                  </Space>
                </Space>
              </div>
            </Space>
          </Col>
          <Col className='mb-4' xs={24} sm={12} md={8} lg={8} xl={8}>
            <Title style={{ margin: 0 }} level={4}>
              {employee.employment.jobTitle || 'Unset'}
              {/* {disciplines[Math.floor(Math.random() * disciplines.length)]} */}
            </Title>
            {employee.employment.startDate && (
              <Text>
                {employee?.attributes?.timeInRole
                  ? (
                    <>
                      {dayjs(employee?.attributes?.timeInRole).format(
                        'DD/MM/YYYY'
                      )}{' '}
                      - Present (
                      {dayjs(employee?.attributes?.timeInRole).from(
                        dayjs(),
                        true
                      )}
                      )
                    </>
                    )
                  : (
                    <>
                      {dayjs(employee.employment.startDate).format(
                        'DD/MM/YYYY'
                      )}{' '}
                      - Present (
                      {dayjs(employee.employment.startDate).from(
                        dayjs(),
                        true
                      )}
                      )
                    </>
                    )}

              </Text>
            )}

            <Space className='mt-4 w-full' direction='vertical'>
              <Space direction='horizontal'>
                <Text type='secondary'>Discipline: </Text>
                <Text ellipsis strong>
                  {employee.attributes?.GRFSubDisciplineName}
                </Text>
              </Space>
              <Space direction='horizontal'>
                <Text type='secondary'>Location: </Text>
                <Text ellipsis strong>
                  {employee.talentCompanyPerson?.jobLocation || employee.attributes?.angloLocationName || 'Not set'}
                </Text>
              </Space>
              <Space direction='horizontal'>
                <Text type='secondary'>Manager: </Text>
                {employee.employment?.jobManager
                  ? (
                    <Text ellipsis strong>{employee.employment?.jobManager?.companyPerson.person.firstName} {employee.employment?.jobManager?.companyPerson.person.lastName}</Text>
                    )
                  : (
                    <Text ellipsis strong>
                      Unset
                    </Text>
                    )}
              </Space>
              <Space direction='horizontal'>
                <Text type='secondary'>Manager Position: </Text>
                {employee.employment?.jobManager
                  ? (
                    <Text ellipsis strong>
                      {employee.employment?.jobManager?.jobTitle}
                    </Text>
                    )
                  : (
                    <Text ellipsis strong>
                      Unset
                    </Text>
                    )}
              </Space>
            </Space>
          </Col>
          <Col className='mb-4' xs={24} sm={24} md={8} lg={8} xl={8}>
            <Title style={{ margin: 0 }} level={4}>
              Employee Profile
            </Title>
            <Space style={{ width: '100%' }} direction='horizontal'>
              <p to='/'>Profile</p>
              <Divider type='vertical' />
              <p to='/'>Talent ID</p>
            </Space>
            <Space className='mt-4 w-full' direction='vertical'>
              <Space direction='horizontal'>
                <Text type='secondary'>Talent Profile: </Text>
                <Text
                  className={
                  employee.talentCompanyPersonCycleStatus.careerProfile === 'Not Started'
                    ? 'statusTodo'
                    : employee.talentCompanyPersonCycleStatus.careerProfile === 'In Progress'
                      ? 'statusInProgress'
                      : employee.talentCompanyPersonCycleStatus.careerProfile === 'Completed'
                        ? 'statusDone'
                        : 'statusTodo'
} ellipsis strong
                >
                  {employee.talentCompanyPersonCycleStatus.careerProfile}
                </Text>
              </Space>
              <Space direction='horizontal'>
                <Text type='secondary'>Talent Identification: </Text>
                <Text
                  className={
                  employee.talentCompanyPersonCycleStatus.talentIdentification === 'Not Started'
                    ? 'statusTodo'
                    : employee.talentCompanyPersonCycleStatus.talentIdentification === 'In Progress'
                      ? 'statusInProgress'
                      : employee.talentCompanyPersonCycleStatus.talentIdentification === 'Completed'
                        ? 'statusDone'
                        : 'statusTodo'
} ellipsis strong
                >
                  {employee.talentCompanyPersonCycleStatus.talentIdentification}
                </Text>
              </Space>
              <Space direction='horizontal'>
                <Text type='secondary'>Employee Engagements: </Text>
                <Text
                  className={
                  employee.talentCompanyPersonCycleStatus.careerConversation === 'Not Started'
                    ? 'statusTodo'
                    : employee.talentCompanyPersonCycleStatus.careerConversation === 'In Progress'
                      ? 'statusInProgress'
                      : employee.talentCompanyPersonCycleStatus.careerConversation === 'Completed'
                        ? 'statusDone'
                        : 'statusTodo'
}
                  ellipsis
                  strong
                >
                  {employee.talentCompanyPersonCycleStatus.careerConversation}
                </Text>
              </Space>
            </Space>
          </Col>
        </Row>
      </Card>
    </Link>
  )
}
