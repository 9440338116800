import React from 'react'
import { Col, Row, Image, Tabs, Space, Tag, Divider, Button, PageHeader, Skeleton, Result } from 'antd'
import { TalentID } from './sections/TalentID'
import Title from 'antd/lib/typography/Title'
import Text from 'antd/lib/typography/Text'
import { EmployeeEgagements } from './sections/EmployeeEgagements'
import { useHistory, useParams } from 'react-router-dom'
import { TalentCV } from './sections/TalentCV'
import { CareerObjectives } from './sections/CareerObjectives'
import { Coaching } from './sections/Coaching'
import dayjs from 'dayjs'
import relativeTime from 'dayjs/plugin/relativeTime'
import { useGetSingleEmplyee } from '../../hooks/api/useEmployees'
import { useQueryParam } from '../../hooks/useQueryParam'
import { useGetPerson } from '../../hooks/api/usePerson'
import { PerformanceReview } from './sections/PerformanceReview'
dayjs.extend(relativeTime)

const { TabPane } = Tabs

export const Employee = () => {
  const { push, goBack } = useHistory()
  const { employeeUid } = useParams()
  const queryParam = useQueryParam()

  const employeeQuery = useGetSingleEmplyee({ employeeUid })
  const personQuery = useGetPerson({ authenticated: true })

  function handleTabsChange (key) {
    const newUrl = window.location.protocol + '//' + window.location.host + window.location.pathname + '?tab=' + key
    window.history.pushState({ path: newUrl }, '', newUrl)
  }

  // const lowArr = ['1', '2', '3', '4', 'NA']
  // const bandArr = ['GBF1', 'GBF2', 'GBF3', 'GBF4', 'GBF5', 'GBF6']
  // const disciplines = ['Business Support Services', 'Corporate Relations', 'Discoveries & Geosciences', 'Engineering', 'Finance', 'General Management', 'Human Resources', 'Information Management', 'Legal & CosSec', 'Marketing', 'Mining', 'Processing', 'Project Management', 'Risk Assurance', 'Safety & Sustainable Dev', 'Sales & Trading', 'SIB & Construction Management', 'Strategy & Business Development', 'Supply Chain']

  // const age = Math.floor(Math.random() * 60) + 20

  return (
    <div className='employees__page'>
      {!personQuery.isLoading && personQuery.data
        ? (
          <>
            {(personQuery.data.isManager || personQuery.data.companyPerson.admin?.roles.includes('talent.manager'))
              ? (

                <PageHeader
                  style={{ paddingLeft: 0 }}
                  onBack={() => push('/employees')}
                  title='Employees'
                />
                )
              : (
                <div className='w-full h-8' />
                )}
          </>
          )
        : (
          <div className='w-full h-8' />
          )}
      <Row gutter={32} className='page'>
        <Col span={6} className='employee__card employee__card--col'>
          {!employeeQuery.isLoading
            ? (
              <>
                {employeeQuery.data
                  ? (
                    <>
                      <Image preview={false} className='card__image' width={125} height={125} src={employeeQuery.data.person.imageUrl || `https://api.dicebear.com/7.x/initials/svg?seed=${employeeQuery.data.person.firstName[0]}`} />
                      <Title className='name' level={3}>{employeeQuery.data.person.firstName + ' ' + employeeQuery.data.person.lastName}</Title>
                      {/* <Title className='name' level={3}>{employeeQuery.data.person.firstName}</Title> */}
                      {/* <Text>{disciplines[Math.floor(Math.random() * disciplines.length)]} | {dayjs(employeeQuery.data.employment?.startDate).from(dayjs(), true)}</Text> */}
                      <Text>{employeeQuery.data.employment.jobTitle} | {dayjs(employeeQuery.data.attributes?.timeInRole ? employeeQuery.data.attributes?.timeInRole : employeeQuery.data.employment?.startDate).from(dayjs(), true)}</Text>
                      <div className='extras'>
                        <div className='details'>
                          <p className='caption'>LoW</p>
                          <p className='amount'>{employeeQuery.data.talentCompanyPerson?.levelOfWork?.split(' ')[1] || 'NA'}</p>
                          {/* <p className='amount'>{lowArr[Math.floor(Math.random() * lowArr.length)]}</p> */}
                        </div>
                        <div className='details'>
                          <p className='caption'>BAND</p>
                          {/* <p className='amount'>{bandArr[Math.floor(Math.random() * bandArr.length)]}</p> */}
                          <p className='amount'>{employeeQuery.data.talentCompanyPerson?.payScaleGroup || 'NA'}</p>
                        </div>
                      </div>
                      <Space align='center' wrap>
                        <Tag color='orange'>{employeeQuery.data?.talentCompanyPerson?.jobLocation}</Tag>
                        <Tag color='red'>{employeeQuery.data?.attributes?.department}</Tag>
                        <Tag color='blue'>{employeeQuery.data?.attributes?.GRFSubDisciplineName}</Tag>
                      </Space>
                      <ul className='employee__details'>
                        <Divider />
                        <li>
                          <p type='secondary'>Email: </p><p className='email'>{employeeQuery.data.email || 'Unset'}</p>
                        </li>
                        <li>
                          <p type='secondary'>Gender: </p><p className='capitalize'>{employeeQuery.data.sex || 'Unset'}</p>
                        </li>
                        <li>
                          <p type='secondary'>Race: </p><p className='capitalize'>{employeeQuery.data.race || 'Unset'}</p>
                        </li>
                        <li>
                          <p type='secondary'>Age: </p><p>{dayjs().diff(dayjs(employeeQuery.data?.dateOfBirth), 'years')}</p>
                        </li>
                        <li>
                          <p type='secondary'>Date of hire: </p><p>{employeeQuery.data?.employment?.startDate ? dayjs(employeeQuery.data?.employment?.startDate).format('DD/MM/YYYY') : 'Unset'}</p>
                        </li>
                        <li>

                          <p type='secondary'>Years to retirement: </p><p>{(employeeQuery.data?.attributes?.retirementDate) ? dayjs(employeeQuery.data?.attributes?.retirementDate).diff(dayjs(), 'years') : 60 - dayjs().diff(dayjs(employeeQuery.data?.dateOfBirth), 'years')}</p>
                          {/* <p type='secondary'>Years to retirement: </p><p>{60 - dayjs().diff(dayjs(employeeQuery.data?.dateOfBirth), 'years')}</p> */}
                        </li>
                        <Divider />
                        {/* <li>
                    <p type='secondary'>Location: </p><p>{employeeQuery.data.employment.jobLocation}</p>
                  </li> */}
                        {/* <Divider /> */}
                        <li className='mb-2'>
                          <p className='noMargin' type='secondary '>Manager: </p><Button href={employeeQuery.data.employment?.jobManager?.companyPerson?.uid} size='small' type='link'>{employeeQuery.data.employment?.jobManager?.companyPerson?.person?.firstName + ' ' + employeeQuery.data.employment?.jobManager?.companyPerson?.person?.lastName}</Button>
                        </li>
                        <li className='mb-2'>
                          <p className='noMargin' type='secondary'>MoR: </p><Button href={employeeQuery.data.employment?.jobManager?.jobManager?.companyPerson?.uid} type='link' size='small'>{(employeeQuery.data.employment?.jobManager?.jobManager?.companyPerson?.person) ? employeeQuery.data.employment?.jobManager?.jobManager?.companyPerson?.person?.firstName + ' ' + employeeQuery.data.employment?.jobManager?.jobManager?.companyPerson?.person.lastName : 'None'}</Button>
                        </li>
                      </ul>
                    </>
                    )
                  : (
                    <>
                      <Skeleton.Avatar className='mb-4' style={{ width: '7rem', height: '7rem' }} />
                      <Skeleton />
                      <Skeleton />
                    </>
                    )}

              </>
              )
            : (
              <>
                <Skeleton.Avatar active className='mb-4' style={{ width: '7rem', height: '7rem' }} />
                <Skeleton active />
                <Skeleton active />
              </>
              )}

        </Col>
        <Col span={18}>
          {!employeeQuery.isLoading && employeeQuery.data && !personQuery.isLoading && personQuery.data &&
            (
              <>
                {!employeeQuery.isError
                  ? (
                    <>
                      <Row className='pl-8' align='center' justify='space-between'>
                        {personQuery.data.companyPerson.uid === employeeUid
                          ? <Title>Your Profile</Title>
                          : <Title>{employeeQuery?.data?.person?.firstName}’s Profile</Title>}
                      </Row>
                      <Tabs className='white__tabs px-8' defaultActiveKey={queryParam.get('tab') || '1'} onChange={handleTabsChange}>
                        {!window.location.pathname.includes(personQuery.data.companyPerson.uid)
                          ? (
                            <>
                              {personQuery.data.companyPerson.admin?.roles.includes('talent.manager')
                                ? (
                                  <>
                                    <TabPane tab='Talent ID' key='1'>
                                      <TalentID />
                                    </TabPane>
                                    <TabPane tab='Employee engagements' key='employeeEngagements'>
                                      <EmployeeEgagements />
                                    </TabPane>
                                    <TabPane tab='Talent Profile' key='talentProfile'>
                                      <TalentCV />
                                    </TabPane>
                                    <TabPane tab='Career Objectives' key='careerObjectives'>
                                      <CareerObjectives />
                                    </TabPane>

                                    <TabPane tab='Coaching' key='coaching'>
                                      <Coaching />
                                    </TabPane>
                                    <TabPane tab='Performance Management' key='performanceManagement'>
                                      <PerformanceReview />
                                    </TabPane>
                                  </>
                                  )

                                : personQuery?.data?.companyPerson.admin?.roles.includes('talent.performanceManager')
                                  ? (
                                    <>
                                      <TabPane tab='Talent Profile' key='talentProfile'>
                                        <TalentCV />
                                      </TabPane>
                                      <TabPane tab='Performance Management' key='performanceManagement'>
                                        <PerformanceReview />
                                      </TabPane>
                                    </>
                                    )
                                  : personQuery.data.isManager
                                    ? (
                                      <>
                                        <TabPane tab='Employee engagements' key='1'>
                                          <EmployeeEgagements />
                                        </TabPane>
                                        <TabPane tab='Talent Profile' key='talentProfile'>
                                          <TalentCV />
                                        </TabPane>
                                        <TabPane tab='Performance Management' key='performanceManagement'>
                                          <PerformanceReview />
                                        </TabPane>
                                      </>
                                      )
                                    : personQuery?.data?.companyPerson.admin?.roles.includes('talent.HRManager')
                                      ? (
                                        <TabPane tab='Performance Management' key='performanceManagement'>
                                          <PerformanceReview />
                                        </TabPane>
                                        )
                                      : (
                                        <>
                                          <TabPane tab='Talent Profile' key='1'>
                                            <TalentCV />
                                          </TabPane>
                                          <TabPane tab='Career Objectives' key='careerObjectives'>
                                            <CareerObjectives />
                                          </TabPane>
                                          <TabPane tab='Employee engagements' key='employeeEngagements'>
                                            <EmployeeEgagements />
                                          </TabPane>
                                          <TabPane tab='Performance Management' key='performanceManagement'>
                                            <PerformanceReview />
                                          </TabPane>
                                        </>
                                        )}
                            </>
                            )
                          : (
                            <>
                              <TabPane tab='Talent Profile' key='1'>
                                <TalentCV />
                              </TabPane>
                              <TabPane tab='Career Objectives' key='careerObjectives'>
                                <CareerObjectives />
                              </TabPane>
                              <TabPane tab='Employee engagements' key='employeeEngagements'>
                                <EmployeeEgagements />
                              </TabPane>
                              <TabPane tab='Performance Management' key='performanceManagement'>
                                <PerformanceReview />
                              </TabPane>
                            </>
                            )}

                      </Tabs>
                    </>
                    )
                  : (
                    <Result
                      status='warning'
                      title='Unable to fetch employee'
                      extra={<Button onClick={goBack} type='primary' className='bg-blue-400' key='console'>Go Back</Button>}
                    />
                    )}

              </>
            )}
        </Col>
      </Row>
    </div>
  )
}
