import { message } from 'antd'
import axios from 'axios'
import { getAuth, getIdToken } from 'firebase/auth'
import { useQuery } from 'react-query'

export function sleeper (ms) {
  return function (x) {
    return new Promise(resolve => setTimeout(() => resolve(x), ms))
  }
}

export const useGetLookupGroup = ({ group }) => {
  return useQuery(
    group,
    async () => axios.get(
      `${process.env.REACT_APP_API_URL}/talent/lookups/${group}`,
      {
        headers: {
          Authorization: `Bearer ${await getIdToken(getAuth().currentUser)}`,
          'X-Tenant-Id': process.env.REACT_APP_TENANT_ID
        }
      }
    )
      .then(res => res.data.data),
    {
      staleTime: 1000 * 60 * 60,
      onError: () => {
        message.error(`Failed to fetch lookup group: ${group}`)
      }
    }
  )
}
export const useGetLookup = ({ key, group }) => {
  return useQuery(
    `${group}-${key}`,
    async () => axios.get(
      `${process.env.REACT_APP_API_URL}/talent/lookups/${group}/${key}`,
      {
        headers: {
          Authorization: `Bearer ${await getIdToken(getAuth().currentUser)}`,
          'X-Tenant-Id': process.env.REACT_APP_TENANT_ID
        }
      }
    )
      .then(res => res.data.data),
    {
      staleTime: 1000 * 60 * 60,
      onError: () => {
        message.error(`Failed to fetch ${key} lookup from ${group}`)
      }
    }
  )
}
