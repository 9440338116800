
export const headers = {
  employees: {
    GRFDisciplineName: 'attributes_GRFDisciplineName',
    GRFGenericRoleName: 'attributes_GRFGenericRoleName',
    GRFSubDisciplineName: 'attributes_GRFSubDisciplineName',
    angloBusinessUnitName: 'attributes_angloBusinessUnitName',
    angloLocationName: 'attributes_angloLocationName',
    angloRegion: 'attributes_angloRegion',
    angloSubBusinessUnitName: 'attributes_angloSubBusinessUnitName',
    departments: 'attributes_department',
    maritalStatus: 'attributes_maritalStatus',
    minorityCode: 'attributes_minorityCode',
    organisationUnitDescription: 'attributes_organisationUnitDescription',
    teamDescription: 'attributes_teamDescription',
    dateOfBirth: 'dateOfBirth',
    email: 'email',
    firstName: 'person_firstName',
    lastName: 'person_lastName',
    countryCode: 'employment_countryCode',
    employeeNumber: 'employment_employeeNumber',
    externalJobId: 'employment_externalJobId',
    jobManager_GRFDisciplineName: 'employment_jobManager_companyPerson_attributes_GRFDisciplineName',
    jobManager_GRFGenericRoleName: 'employment_jobManager_companyPerson_attributes_GRFGenericRoleName',
    jobManager_GRFSubDisciplineName: 'employment_jobManager_companyPerson_attributes_GRFSubDisciplineName',
    jobManager_angloBusinessUnitName: 'employment_jobManager_companyPerson_attributes_angloBusinessUnitName',
    jobManager_angloLocationName: 'employment_jobManager_companyPerson_attributes_angloLocationName',
    jobManager_angloRegion: 'employment_jobManager_companyPerson_attributes_angloRegion',
    jobManager_angloSubBusinessUnitName: 'employment_jobManager_companyPerson_attributes_angloSubBusinessUnitName',
    jobManager_department: 'employment_jobManager_companyPerson_attributes_department',
    jobManager_maritalStatus: 'employment_jobManager_companyPerson_attributes_maritalStatus',
    jobManager_minorityCode: 'employment_jobManager_companyPerson_attributes_minorityCode',
    jobManager_organisationUnitDescription: 'employment_jobManager_companyPerson_attributes_organisationUnitDescription',
    jobManager_teamDescription: 'employment_jobManager_companyPerson_attributes_teamDescription',
    jobManager_dateOfBirth: 'employment_jobManager_companyPerson_dateOfBirth',
    jobManager_email: 'employment_jobManager_companyPerson_email',
    jobManager_externalUserId: 'employment_jobManager_companyPerson_externalUserId',
    jobManager_hasDisability: 'employment_jobManager_companyPerson_hasDisability',
    jobManager_identificationNumber: 'employment_jobManager_companyPerson_identificationNumber',
    jobManager_joiningDate: 'employment_jobManager_companyPerson_joiningDate',
    jobManager_lastUpdatedByUid: 'employment_jobManager_companyPerson_lastUpdatedByUid',
    jobManager_nationality: 'employment_jobManager_companyPerson_nationality',
    jobManager_person_firstName: 'employment_jobManager_companyPerson_person_firstName',
    jobManager_person_lastName: 'employment_jobManager_companyPerson_person_lastName',
    jobManager_race: 'employment_jobManager_companyPerson_race',
    jobManager_sex: 'employment_jobManager_companyPerson_sex',
    jobManager_actionC: 'employment_jobManager_companyPerson_talentCompanyPerson_actionC',
    jobManager_actionD: 'employment_jobManager_companyPerson_talentCompanyPerson_actionD',
    jobManager_actionR: 'employment_jobManager_companyPerson_talentCompanyPerson_actionR',
    jobManager_company: 'employment_jobManager_companyPerson_talentCompanyPerson_company',
    jobManager_created: 'employment_jobManager_companyPerson_talentCompanyPerson_created',
    jobManager_deleted: 'employment_jobManager_companyPerson_talentCompanyPerson_deleted',
    jobManager_discipline: 'employment_jobManager_companyPerson_talentCompanyPerson_discipl',
    jobManager_jobLocation: 'employment_jobManager_companyPerson_talentCompanyPerson_jobLoca',
    jobManager_levelOfWord: 'employment_jobManager_companyPerson_talentCompanyPerson_levelOf',
    jobManager_payScaleGroup: 'employment_jobManager_companyPerson_talentCompanyPerson_payScal',
    jobManager_tel: 'employment_jobManager_companyPerson_tel',
    jobManager_countryCode: 'employment_jobManager_countryCode',
    jobManager_employeeNumber: 'employment_jobManager_employeeNumber',
    jobManager_externalJobId: 'employment_jobManager_externalJobId',
    jobManager_jobTitle: 'employment_jobManager_jobTitle',
    jobManager_startDate: 'employment_jobManager_startDate',
    jobManager_type: 'employment_jobManager_type',
    jobTitle: 'employment_jobTitle',
    lastUpdatedByUid: 'employment_lastUpdatedByUid',
    startDate: 'employment_startDate',
    type: 'employment_type',
    uid: 'employment_uid',
    updatedAt: 'employment_updatedAt',
    externalUserId: 'externalUserId',
    hasDisability: 'hasDisability',
    identificationNumber: 'identificationNumber',
    isDefault: 'isDefault',
    joiningDate: 'joiningDate',
    nationality: 'nationality',
    race: 'race',
    sex: 'sex',
    careerConversation: 'talentCompanyPersonCycleStatus_careerConversation',
    careerProfile: 'talentCompanyPersonCycleStatus_careerProfile',
    companyPersonUid: 'talentCompanyPersonCycleStatus_companyPersonUid',
    createdAt: 'talentCompanyPersonCycleStatus_createdAt',
    developmentPlanning: 'talentCompanyPersonCycleStatus_developmentPlanning',
    talentIdentification: 'talentCompanyPersonCycleStatus_talentIdentification',
    actionCode: 'talentCompanyPerson_actionCode',
    actionDescription: 'talentCompanyPerson_actionDescription',
    actionReasonCode: 'talentCompanyPerson_actionReasonCode',
    actionReasonDescription: 'talentCompanyPerson_actionReasonDescription',
    deletedAt: 'talentCompanyPerson_deletedAt',
    department: 'talentCompanyPerson_department',
    discipline: 'talentCompanyPerson_discipline',
    jobLocation: 'talentCompanyPerson_jobLocation',
    levelOfWork: 'talentCompanyPerson_levelOfWork',
    payScaleGroup: 'talentCompanyPerson_payScaleGroup',
    tel: 'tel'
  },
  worklist: {
    low: {
      Employees: 'Employees_',
      LevelOfWork: 'LevelOfWork_',
      CareerProfile_Completed: 'CareerProfile_Completed',
      CareerProfile_InProgress: 'CareerProfile_InProgress',
      CareerProfile_Unattended: 'CareerProfile_Unattended',
      DevelopmentPlanning_ActionsAdded: 'DevelopmentPlanning_ActionsAdded',
      DevelopmentPlanning_IDPCompleted: 'DevelopmentPlanning_IDPCompleted',
      TalentIdentification_Completed: 'TalentIdentification_Completed',
      TalentIdentification_InProgress: 'TalentIdentification_InProgress',
      TalentIdentification_Reviewed: 'TalentIdentification_Reviewed',
      TalentIdentification_Unattended: 'TalentIdentification_Unattended'
    },
    disciplines: {
      Disciplines: 'Disciplines_',
      Employees: 'Employees_',
      CareerProfile_Completed: 'CareerProfile_Completed',
      CareerProfile_InProgress: 'CareerProfile_InProgress',
      CareerProfile_Unattended: 'CareerProfile_Unattended',
      DevelopmentPlanning_ActionsAdded: 'DevelopmentPlanning_ActionsAdded',
      DevelopmentPlanning_IDPCompleted: 'DevelopmentPlanning_IDPCompleted',
      TalentID_Completed: 'TalentIdentification_Completed',
      TalentID_InProgress: 'TalentIdentification_InProgress',
      TalentID_Reviewed: 'TalentIdentification_Reviewed',
      TalentID_Unattended: 'TalentIdentification_Unattended'
    },
    locations: {
      CareerProfile_Completed: 'CareerProfile_Completed',
      CareerProfile_InProgress: 'CareerProfile_InProgress',
      CareerProfile_Unattended: 'CareerProfile_Unattended',
      DevelopmentPlanning_ActionsAdded: 'DevelopmentPlanning_ActionsAdded',
      DevelopmentPlanning_IDPCompleted: 'DevelopmentPlanning_IDPCompleted',
      Employees: 'Employees_',
      Locations: 'Locations_',
      TalentID_Completed: 'TalentIdentification_Completed',
      TalentID_InProgress: 'TalentIdentification_InProgress',
      TalentID_Reviewed: 'TalentIdentification_Reviewed',
      TalentID_Unattended: 'TalentIdentification_Unattended'
    },
    coaching: {
      Coach: 'Coach_',
      Date: 'Date_',
      Employee: 'Employee_',
      Objectives: 'Objectives_',
      Progress: 'Progress_',
      Stage: 'Stage_',
      Type: 'Type_'
    }
  },
  reporting: {
    talentBenchStrength: {
      '% Capable In Role value': 'value',
      '% Capable In Role target': 'target',
      '% Capable In Role description': 'description',
      '% Talent In Population value': 'value',
      '% Talent In Population target': 'target',
      '% Talent In Population description': 'description',
      '% Not At Level value': 'value',
      '% Not At Level target': 'target',
      '% Not At Level description': 'description',
      '% High Potential Talent Pool Depth value': 'value',
      '% High Potential Talent Pool Depth target': 'target',
      '% High Potential Talent Pool Depth description': 'description',
      '% High Potential value': 'value',
      '% High Potential target': 'target',
      '% High Potential description': 'description',
      '% Emerging Talent value': 'value',
      '% Emerging Talent target': 'target',
      '% Emerging Talent description': 'description',
      '% Executive Pipeline value': 'value',
      '% Executive Pipeline target': 'target',
      '% Executive Pipeline description': 'description'
    },
    talentMovement: {
      'Avg Time In Role value': 'value',
      'Avg Time In Role target': 'target',
      'Avg Time In Role description': 'description',
      'Talent moves / Secondments value': 'value',
      'Talent moves / Secondments target': 'target',
      'Talent moves / Secondments description': 'description',
      '% Talent Promotion Rate value': 'value',
      '% Talent Promotion Rate target': 'target',
      '% Talent Promotion Rate description': 'description',
      '% Talent Voluntary Turnover value': 'value',
      '% Talent Voluntary Turnover target': 'target',
      '% Talent Voluntary Turnover description': 'description',
      '% Internal Appointments value': 'value',
      '% Internal Appointments target': 'target',
      '% Internal Appointments description': 'description'
    },
    successionManagement: {
      '% Successors Ready for Promotion value': 'value',
      '% Successors Ready for Promotion target': 'target',
      '% Successors Ready for Promotion description': 'description',
      '% Successors Ready in 1-3 Years value': 'value',
      '% Successors Ready in 1-3 Years target': 'target',
      '% Successors Ready in 1-3 Years description': 'description',
      '% Successors Ready in 3-5 Years value': 'value',
      '% Successors Ready in 3-5 Years target': 'target',
      '% Successors Ready in 3-5 Years description': 'description',
      '% Successors Ready in 6-8 Years value': 'value',
      '% Successors Ready in 6-8 Years target': 'target',
      '% Successors Ready in 6-8 Years description': 'description',
      '% Succession Plans for Critical Roles value': 'value',
      '% Succession Plans for Critical Roles target': 'target',
      '% Succession Plans for Critical Roles description': 'description',
      'Female Successors value': 'value',
      'Female Successors target': 'target',
      'Female Successors description': 'description',
      '% Critical roles occupied by Talent value': 'value',
      '% Critical roles occupied by Talent target': 'target',
      '% Critical roles occupied by Talent description': 'description',
      '% Multiple Succession Plans value': 'value',
      '% Multiple Succession Plans target': 'target',
      '% Multiple Succession Plans description': 'description'
    },
    genderDiversity: {
      '% Female value': 'value',
      '% Female target': 'target',
      '% Female description': 'description',
      '% Female Talent value': 'value',
      '% Female Talent target': 'target',
      '% Female Talent description': 'description',
      '% Female Talent Appointment Rate value': 'value',
      '% Female Talent Appointment Rate target': 'target',
      '% Female Talent Appointment Rate description': 'description',
      '% Female Talent promotion rate value': 'value',
      '% Female Talent promotion rate target': 'target',
      '% Female Talent promotion rate description': 'description',
      '% Talent HDSA value': 'value',
      '% Talent HDSA target': 'target',
      '% Talent HDSA description': 'description',
      '% HDSA value': 'value',
      '% HDSA target': 'target',
      '% HDSA description': 'description'
    }
  },
  worklistData: {
    levelOfWork: {
      headings: [
        'Level of Work_',
        'Employees_',
        'Career Profile_Unattended',
        'Career Profile_In Progress',
        'Career Profile_Completed',
        'Talent Identification_Unattended',
        'Talent Identification_In Progress',
        'Talent Identification_Completed',
        'Talent Identification_Reviewed',
        'Development Planning_IDP Completed',
        'Development Planning_Actions Added'
      ],
      values: [
        [
          'LoW 1',
          '13329',
          '13323',
          '0006',
          '0000',
          '13326',
          '0003',
          '0000',
          '0002',
          '0000',
          '0003'
        ],
        [
          'LoW 2',
          '0411',
          '0406',
          '0005',
          '0000',
          '0411',
          '0000',
          '0000',
          '0000',
          '0000',
          '0003'
        ],
        [
          'LoW 3',
          '0097',
          '0097',
          '0000',
          '0000',
          '0096',
          '0001',
          '0000',
          '0001',
          '0000',
          '0000'
        ],
        [
          'LoW 4',
          '0020',
          '0020',
          '0000',
          '0000',
          '0020',
          '0000',
          '0000',
          '0000',
          '0000',
          '0000'
        ],
        [
          'LoW 5',
          '0004',
          '0004',
          '0000',
          '0000',
          '0004',
          '0000',
          '0000',
          '0000',
          '0000',
          '0000'
        ]
      ]
    },
    disciplines: {
      headings: [
        'Disciplines_',
        'Employees_',
        'Career Profile_Unattended',
        'Career Profile_In Progress',
        'Career Profile_Completed',
        'Talent Identification_Unattended',
        'Talent Identification_In Progress',
        'Talent Identification_Completed',
        'Talent Identification_Reviewed',
        'Development Planning_IDP Completed',
        'Development Planning_Actions Added'
      ],
      values: [
        [
          'Business Support Services',
          '2902',
          '2902',
          '0000',
          '0000',
          '2902',
          '0000',
          '0000',
          '0000',
          '0000',
          '0000'
        ],
        [
          'Corporate Relations',
          '0022',
          '0022',
          '0000',
          '0000',
          '0021',
          '0001',
          '0000',
          '0001',
          '0000',
          '0000'
        ],
        [
          'Data Analytics',
          '0010',
          '0010',
          '0000',
          '0000',
          '0010',
          '0000',
          '0000',
          '0000',
          '0000',
          '0000'
        ],
        [
          'Discovery & Geosciences',
          '0042',
          '0042',
          '0000',
          '0000',
          '0042',
          '0000',
          '0000',
          '0000',
          '0000',
          '0000'
        ],
        [
          'Engineering',
          '3799',
          '3798',
          '0001',
          '0000',
          '3797',
          '0002',
          '0000',
          '0001',
          '0000',
          '0001'
        ],
        [
          'Finance',
          '0084',
          '0084',
          '0000',
          '0000',
          '0084',
          '0000',
          '0000',
          '0000',
          '0000',
          '0000'
        ],
        [
          'General Management',
          '0398',
          '0398',
          '0000',
          '0000',
          '0398',
          '0000',
          '0000',
          '0000',
          '0000',
          '0000'
        ],
        [
          'Human Resources',
          '0186',
          '0178',
          '0008',
          '0000',
          '0186',
          '0000',
          '0000',
          '0000',
          '0000',
          '0003'
        ],
        [
          'Information Management',
          '0038',
          '0038',
          '0000',
          '0000',
          '0038',
          '0000',
          '0000',
          '0000',
          '0000',
          '0000'
        ],
        [
          'Legal & CosSec',
          '0006',
          '0006',
          '0000',
          '0000',
          '0006',
          '0000',
          '0000',
          '0000',
          '0000',
          '0000'
        ],
        [
          'Management',
          '0002',
          '0002',
          '0000',
          '0000',
          '0002',
          '0000',
          '0000',
          '0000',
          '0000',
          '0000'
        ],
        [
          'Marketing',
          '0010',
          '0010',
          '0000',
          '0000',
          '0010',
          '0000',
          '0000',
          '0000',
          '0000',
          '0000'
        ],
        [
          'Mining',
          '5106',
          '5105',
          '0001',
          '0000',
          '5106',
          '0000',
          '0000',
          '0000',
          '0000',
          '0000'
        ],
        [
          'Operations',
          '0001',
          '0001',
          '0000',
          '0000',
          '0001',
          '0000',
          '0000',
          '0000',
          '0000',
          '0000'
        ],
        [
          'Processing',
          '0464',
          '0464',
          '0000',
          '0000',
          '0464',
          '0000',
          '0000',
          '0000',
          '0000',
          '0001'
        ],
        [
          'Programme / Project Management',
          '0007',
          '0007',
          '0000',
          '0000',
          '0007',
          '0000',
          '0000',
          '0000',
          '0000',
          '0000'
        ],
        [
          'Programming',
          '0001',
          '0000',
          '0001',
          '0000',
          '0000',
          '0001',
          '0000',
          '0001',
          '0000',
          '0001'
        ],
        [
          'Risk Assurance',
          '0006',
          '0006',
          '0000',
          '0000',
          '0006',
          '0000',
          '0000',
          '0000',
          '0000',
          '0000'
        ],
        [
          'Safety',
          '0042',
          '0042',
          '0000',
          '0000',
          '0042',
          '0000',
          '0000',
          '0000',
          '0000',
          '0000'
        ],
        [
          'Sales & Trading',
          '0022',
          '0022',
          '0000',
          '0000',
          '0022',
          '0000',
          '0000',
          '0000',
          '0000',
          '0000'
        ],
        [
          'SIB & Construction  Management',
          '0046',
          '0046',
          '0000',
          '0000',
          '0046',
          '0000',
          '0000',
          '0000',
          '0000',
          '0000'
        ],
        [
          'Strategy & Business Development',
          '0002',
          '0002',
          '0000',
          '0000',
          '0002',
          '0000',
          '0000',
          '0000',
          '0000',
          '0000'
        ],
        [
          'Supply Chain',
          '0143',
          '0143',
          '0000',
          '0000',
          '0143',
          '0000',
          '0000',
          '0000',
          '0000',
          '0000'
        ],
        [
          'Sustainable Development',
          '0346',
          '0346',
          '0000',
          '0000',
          '0346',
          '0000',
          '0000',
          '0000',
          '0000',
          '0000'
        ],
        [
          'NA',
          '0176',
          '0176',
          '0000',
          '0000',
          '0176',
          '0000',
          '0000',
          '0000',
          '0000',
          '0000'
        ]
      ]
    },
    locations: {
      headings: [
        'Locations_',
        'Employees_',
        'Career Profile_Unattended',
        'Career Profile_In Progress',
        'Career Profile_Completed',
        'Talent Identification_Unattended',
        'Talent Identification_In Progress',
        'Talent Identification_Completed',
        'Talent Identification_Reviewed',
        'Development Planning_IDP Completed',
        'Development Planning_Actions Added'
      ],
      values: [
        [
          '144 Oxford Rd Jhb',
          '0015',
          '0015',
          '0000',
          '0000',
          '0015',
          '0000',
          '0000',
          '0000',
          '0000',
          '0000'
        ],
        [
          '25 Bath Ave Rosebank',
          '0125',
          '0124',
          '0001',
          '0000',
          '0124',
          '0001',
          '0000',
          '0001',
          '0000',
          '0001'
        ],
        [
          '45 Main St Jhb',
          '0001',
          '0001',
          '0000',
          '0000',
          '0001',
          '0000',
          '0000',
          '0000',
          '0000',
          '0000'
        ],
        [
          '55 Marshall St Jhb',
          '0011',
          '0011',
          '0000',
          '0000',
          '0011',
          '0000',
          '0000',
          '0000',
          '0000',
          '0000'
        ],
        [
          'Centralised Services Complex',
          '1566',
          '1565',
          '0001',
          '0000',
          '1566',
          '0000',
          '0000',
          '0000',
          '0000',
          '0000'
        ],
        [
          'Fourways',
          '0002',
          '0001',
          '0001',
          '0000',
          '0001',
          '0001',
          '0000',
          '0001',
          '0000',
          '0001'
        ],
        [
          'Goedehoop',
          '0009',
          '0009',
          '0000',
          '0000',
          '0009',
          '0000',
          '0000',
          '0000',
          '0000',
          '0000'
        ],
        [
          'Goedehoop Colliery',
          '1766',
          '1763',
          '0003',
          '0000',
          '1766',
          '0000',
          '0000',
          '0000',
          '0000',
          '0002'
        ],
        [
          'Greenside',
          '0012',
          '0012',
          '0000',
          '0000',
          '0012',
          '0000',
          '0000',
          '0000',
          '0000',
          '0000'
        ],
        [
          'Greenside Colliery',
          '2091',
          '2091',
          '0000',
          '0000',
          '2091',
          '0000',
          '0000',
          '0000',
          '0000',
          '0001'
        ],
        [
          'Isibonelo',
          '0010',
          '0010',
          '0000',
          '0000',
          '0010',
          '0000',
          '0000',
          '0000',
          '0000',
          '0000'
        ],
        [
          'Isibonelo Colliery',
          '1564',
          '1563',
          '0001',
          '0000',
          '1563',
          '0001',
          '0000',
          '0000',
          '0000',
          '0001'
        ],
        [
          'Johannesburg',
          '0002',
          '0002',
          '0000',
          '0000',
          '0002',
          '0000',
          '0000',
          '0000',
          '0000',
          '0000'
        ],
        [
          'Khwezela Colliery',
          '2459',
          '2456',
          '0003',
          '0000',
          '2459',
          '0000',
          '0000',
          '0000',
          '0000',
          '0000'
        ],
        [
          'Kleinkopje',
          '0033',
          '0033',
          '0000',
          '0000',
          '0033',
          '0000',
          '0000',
          '0000',
          '0000',
          '0000'
        ],
        [
          'Mafube',
          '0021',
          '0021',
          '0000',
          '0000',
          '0021',
          '0000',
          '0000',
          '0000',
          '0000',
          '0000'
        ],
        [
          'Mafube Colliery',
          '1925',
          '1925',
          '0000',
          '0000',
          '1924',
          '0001',
          '0000',
          '0001',
          '0000',
          '0000'
        ],
        [
          'Witbank',
          '0007',
          '0007',
          '0000',
          '0000',
          '0007',
          '0000',
          '0000',
          '0000',
          '0000',
          '0000'
        ],
        [
          'Zibulo',
          '0044',
          '0044',
          '0000',
          '0000',
          '0044',
          '0000',
          '0000',
          '0000',
          '0000',
          '0000'
        ],
        [
          'Zibulo Colliery',
          '2153',
          '2152',
          '0001',
          '0000',
          '2153',
          '0000',
          '0000',
          '0000',
          '0000',
          '0000'
        ],
        [
          'NA',
          '0045',
          '0045',
          '0000',
          '0000',
          '0045',
          '0000',
          '0000',
          '0000',
          '0000',
          '0000'
        ]
      ]
    },
    coachings: {
      headings: [
        'Employee_',
        'Date_',
        'Coach_',
        'Objectives_',
        'Stage_',
        'Type_',
        'Progress_'
      ],
      values: [
        [
          'Bhuti BrendonBhuti Brendon Thabethe',
          '2022-01-22T12:10:27.000Z',
          'John',
          'To win',
          'Objective Setting',
          'OLE Coaching',
          'Not Started'
        ],
        [
          'Bhuti BrendonBhuti Brendon Thabethe',
          '2022-01-29T12:10:58.000Z',
          'Type',
          'To lose',
          'Development Coaching',
          'Executive Coaching',
          'In Progress'
        ],
        [
          'DanDan Sivewright',
          '2022-01-21T08:04:48.000Z',
          'John Doe',
          '123',
          'Objective Setting',
          'Executive Coaching',
          'Not Started'
        ],
        [
          'DanDan Sivewright',
          '2022-02-25T08:06:57.000Z',
          'Jane Doe',
          '123',
          'Midway Review',
          'OLE Coaching',
          'Not Started'
        ],
        [
          'MarkMark Sivewright',
          '2022-02-21T10:56:00.000Z',
          'Mark Sivewright',
          'More Objs',
          'Objective Setting',
          'Executive Coaching',
          'Not Started'
        ]
      ]
    }
  },
  reportingData: {
    isLoading: false,
    data: [
      {
        label: 'Talent Bench Strength',
        key: 'talentBenchStrength',
        cards: [
          {
            key: 'capableInRole',
            label: '% Capable In Role',
            value: 0,
            notes: [
              {
                uid: '3202cc54-3859-4621-8284-5c6c655f130c',
                personUid: 'a766857b-d08e-42d0-bce0-d2741d22f599',
                companyUid: '2f6280d7-ccec-4c96-8efd-2f749437f9f7',
                timestamp: '2022-01-27',
                reportingKey: 'talentBenchStrength_capableInRole',
                comments: 'New Comment',
                createdAt: '2022-01-27T11:29:58.808Z',
                updatedAt: '2022-01-27T11:29:58.808Z',
                deletedAt: null
              }
            ],
            target: 0.95,
            targetMode: 'GTE',
            description: '% of employees who have been identified as capable in role (At Level, Capable of Moving 1+ levels, Not yet Rated)',
            color: 'RED',
            showDashboard: true
          },
          {
            key: 'talentInPopulation',
            label: '% Talent In Population',
            value: 0,
            notes: [],
            target: 0.25,
            targetMode: 'GTE',
            description: '% of employees who have been identified as capable of moving 1 or 2+ levels in the specified population',
            color: 'RED',
            showDashboard: true
          },
          {
            key: 'notAtLevel',
            label: '% Not At Level',
            value: 0,
            notes: [],
            target: 0.05,
            targetMode: 'LT',
            description: '% of employees who have been identified as not at level divided by the entire population',
            color: 'GREEN',
            showDashboard: true
          },
          {
            key: 'highPotTalentPoolDepth',
            label: '% High Potential Talent Pool Depth',
            value: 0,
            notes: [],
            target: 0.25,
            targetMode: 'GTE',
            description: 'Executive Pipeline population (Band 4 Talent population) over the total Executive Leadership population (all employees B3+)',
            color: 'RED',
            showDashboard: true
          },
          {
            key: 'talentReadyProm',
            label: '% High Potential',
            value: 0,
            notes: [],
            target: 0.25,
            targetMode: 'GTE',
            description: '% of band 5 that have been identified as capable of moving 1 or 2 levels higher (High Potentials) divided by band 5 population',
            color: 'RED',
            showDashboard: false
          },
          {
            key: 'emergingTalent',
            label: '% Emerging Talent',
            value: 0,
            notes: [],
            target: 0.25,
            targetMode: 'GTE',
            description: '% of band 6 that have been identified as capable of moving 1 or 2 levels higher (Emerging Talent) divided by band 6 population',
            color: 'RED',
            showDashboard: false
          },
          {
            key: 'executivePipeline',
            label: '% Executive Pipeline',
            value: 0,
            notes: [],
            target: 0.25,
            targetMode: 'GTE',
            description: '% of band 4 & 3 that have been identified as capable of moving 1 or 2 levels higher (Executive pipeline) divided by band 4&3 population',
            color: 'RED',
            showDashboard: false
          }
        ]
      },
      {
        label: 'Talent Movement',
        key: 'talentMovement',
        cards: [
          {
            key: 'avgTimeInRole',
            label: 'Average Time In Role For Talent Population',
            value: 0,
            notes: [],
            target: 1,
            targetMode: 'GTE',
            description: 'Average time in role employees identified as Talent (capable of moving 1 or 2+ levels)',
            color: 'RED',
            showDashboard: true
          },
          {
            key: 'talentMoves',
            label: 'Talent moves / Secondments',
            value: 0,
            notes: [],
            target: 1,
            targetMode: 'GTE',
            description: '% of talent that have moved or been seconded into a different role divided by talent population',
            color: 'RED',
            showDashboard: true
          },
          {
            key: 'talentPromRate',
            label: '% Talent Promotion Rate',
            value: 0,
            notes: [],
            target: 1,
            targetMode: 'GTE',
            description: 'Promotions given to employees identified as Talent, over the total promotion events',
            color: 'RED',
            showDashboard: true
          },
          {
            key: 'talentVoluntaryTurnover',
            label: '% Talent Voluntary Turnover',
            value: 0,
            notes: [],
            target: 1,
            targetMode: 'GTE',
            description: '% of employees who have voluntarily left the Company that were identified as Talent',
            color: 'RED',
            showDashboard: true
          },
          {
            key: 'internalAppB4B5FromTalentPop',
            label: '% Internal Appointments to Band 4 and Band 5 from Talent population',
            value: 0,
            notes: [],
            target: 1,
            targetMode: 'GTE',
            description: '% of employees who have been internally appointed to Band 4 and Band 5 Talent population',
            color: 'RED',
            showDashboard: false
          }
        ]
      },
      {
        label: 'Gender Diversity',
        key: 'genderDiversity',
        cards: [
          {
            key: 'females',
            label: '% Female',
            value: 0.16124846824767533,
            notes: [],
            target: 0.3,
            targetMode: 'GTE',
            description: '% of females within entire population',
            color: 'RED',
            showDashboard: true
          },
          {
            key: 'femaleTalent',
            label: '% Female Talent',
            value: 0.00007208246233691343,
            notes: [],
            target: 0.35,
            targetMode: 'GTE',
            description: '% of employees identified as capable of moving 1 or 2 levels that are female',
            color: 'RED',
            showDashboard: true
          },
          {
            key: 'femaleTalentAppointmentRate',
            label: '% Female Talent Appointment Rate',
            value: 0,
            notes: [],
            target: 0.2,
            targetMode: 'GTE',
            description: '% of employees who have been appointed (transfers, secondments etc.) that have been identified as Talent and that are female',
            color: 'RED',
            showDashboard: true
          },
          {
            key: 'femaleTalentPromRate',
            label: '% Female Talent promotion rate',
            value: 0,
            notes: [],
            target: 0.2,
            targetMode: 'GTE',
            description: 'Rate of promotions given to female employees identified as Talent, over the total population',
            color: 'RED',
            showDashboard: true
          },
          {
            key: 'talentHDSA',
            label: '% Talent HDSA',
            value: 0,
            notes: [],
            target: 0.6,
            targetMode: 'GTE',
            description: '% of employees identified as Talent (capable of moving 1 or 2 levels) who have also been identified as HDSA',
            color: 'RED',
            showDashboard: false
          },
          {
            key: 'HDSA',
            label: '% HDSA',
            value: 0.16009514885028472,
            notes: [],
            target: 0.6,
            targetMode: 'GTE',
            description: '% of employees been identified as HDSA',
            color: 'RED',
            showDashboard: false
          }
        ]
      },
      {
        label: 'Succession Management',
        key: 'successionManagement',
        cards: [
          {
            key: 'successorReadyForProm',
            label: '% Successors Ready for Promotion',
            target: 0.2,
            targetMode: 'GTE',
            description: '% of employees identified as successors who are ready to move into this role within 12 months',
            showDashboard: true,
            error: {}
          },
          {
            key: 'successorReadyForProm1-3Y',
            label: '% Successors Ready in 1-3 Years',
            target: 0.2,
            targetMode: 'GTE',
            description: '% of employees identified as successors who are ready to move into this role within 1-3 Years',
            showDashboard: true,
            error: {}
          },
          {
            key: 'successorReadyForProm3-5Y',
            label: '% Successors Ready in 3-5 Years',
            target: 0.2,
            targetMode: 'GTE',
            description: '% of employees identified as successors who are ready to move into this role within 3-5 Years',
            showDashboard: true,
            error: {}
          },
          {
            key: 'successorReadyForProm6-8Y',
            label: '% Successors Ready in 6-8 Years',
            target: 0.2,
            targetMode: 'GTE',
            description: '% of employees identified as successors who are ready to move into this role within 6-8 Years',
            showDashboard: true,
            error: {}
          },
          {
            key: 'successionPlansForCriticalRoles',
            label: '% Succession Plans for Critical Roles',
            target: 1,
            targetMode: 'GTE',
            description: '% of Critical roles with completed Succession Plans',
            showDashboard: false,
            error: {}
          },
          {
            key: 'femaleSuccessors',
            label: 'Female Successors',
            target: 0.35,
            targetMode: 'GTE',
            description: '% of employees identified as successors who are female',
            showDashboard: false,
            error: {}
          },
          {
            key: 'criticalRolesOccupiedByTalent',
            label: '% Critical roles occupied by Talent',
            target: 0.6,
            targetMode: 'GTE',
            description: '% of critical roles occupied by employees identified as Talent (capable of moving 1 or 2+ levels)',
            showDashboard: false,
            error: {}
          },
          {
            key: 'multipleSuccessionPlans',
            label: '% Multiple Succession Plans',
            target: 0.1,
            targetMode: 'GTE',
            description: '% of employees identified on more than 3 succession plans, out of the total successor population',
            showDashboard: false,
            error: {}
          }
        ]
      }
    ]
  }
}
