import { initializeApp } from 'firebase/app'
import { getFirestore } from 'firebase/firestore'
import { getAuth, OAuthProvider } from 'firebase/auth'
import { getStorage } from 'firebase/storage'

let config = {
  // apiKey: 'AIzaSyAkmV38MiDdnDg2_mhs_qvP3aYZ8WKvsWg',
  // authDomain: 'iwin-io-beta.firebaseapp.com',
  // projectId: 'iwin-io-beta',
  // storageBucket: 'storage.beta.i-win.io',
  // messagingSenderId: '801849951924',
  // appId: '1:801849951924:web:9c7a8a08be6edb2c7339e1',
  // measurementId: 'G-CP1WPV7NBS'
  apiKey: 'AIzaSyBGk6fk0TsxWObe7xq_vx6UrzviyuFQdg0',
  authDomain: 'iwin-io.firebaseapp.com',
  databaseURL: 'https://iwin-io.firebaseio.com',
  projectId: 'iwin-io',
  storageBucket: 'storage.i-win.io',
  messagingSenderId: '344734987580',
  appId: '1:344734987580:web:6e0d25da38772fc6f3da39',
  measurementId: 'G-0ZYX1CE80Z'
}

if (process.env.REACT_APP_ENV === 'production') {
  config = {
    apiKey: 'AIzaSyBGk6fk0TsxWObe7xq_vx6UrzviyuFQdg0',
    authDomain: 'iwin-io.firebaseapp.com',
    databaseURL: 'https://iwin-io.firebaseio.com',
    projectId: 'iwin-io',
    storageBucket: 'storage.i-win.io',
    messagingSenderId: '344734987580',
    appId: '1:344734987580:web:6e0d25da38772fc6f3da39',
    measurementId: 'G-0ZYX1CE80Z'
  }
}

const firebaseApp = initializeApp(config)
const db = getFirestore()
const auth = getAuth()
const storage = getStorage(firebaseApp)

const provider = new OAuthProvider('microsoft.com')
provider.setCustomParameters({
  tenant: 'ec16a4da-5a1a-40ad-9d16-995b415e6baf'
})
export { auth, storage, provider, config }
export default db
