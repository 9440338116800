import dayjs from 'dayjs'
import { useEffect } from 'react'
import { makeId } from './uuid'
import * as XLSX from 'xlsx'
import * as FileSaver from 'file-saver'
import writeXlsxFile from 'write-excel-file'

import { message } from 'antd'

export const depthOf = (object) => {
  let level = 1
  for (const key in object) {
    if (!Object.prototype.hasOwnProperty.call(object, key)) continue

    if (typeof object[key] === 'object') {
      const depth = depthOf(object[key]) + 1
      level = Math.max(depth, level)
    }
  }
  return level
}

export const flattenObject = (obj, parent) => {
  const flattened = {}

  Object.keys(obj).forEach((key) => {
    const value = obj[key]

    if (typeof value === 'object' && value !== null && !Array.isArray(value)) {
      if (parent) Object.assign(flattened, flattenObject(value, parent + '_' + key))
      else Object.assign(flattened, flattenObject(value, key))
    } else if (parent) {
      flattened[`${parent}_${key}`] = value
    } else {
      flattened[key] = value
    }
  })

  return flattened
}

function findMostKeys (arr) {
  if (!arr || !arr.length) {
    return []
  }

  let mostKeysObj = arr[0]
  arr.forEach(obj => {
    if (Object.keys(obj).length > Object.keys(mostKeysObj).length) {
      mostKeysObj = obj
    }
  })

  return Object.keys(mostKeysObj).map(key => String(key))
}

export const useJsonExporter = ({ execute = false, payload = null, name = `report-${makeId(6)}_${dayjs().format('DD/MM/YYYY')}`, type = 'xlsx', callback = () => (null), headers = {} }) => {
  useEffect(() => {
    if (execute) {
      if (payload) {
        const mutatedPayload = payload.map(item => {
          const flatObj = flattenObject(item)
          const objToReturn = {}
          Object.keys(headers).forEach(key => {
            objToReturn[key] = flatObj[headers[key]]
          })
          return objToReturn
        })
        const ws = XLSX.utils.json_to_sheet(mutatedPayload)
        const wb = { Sheets: { data: ws }, SheetNames: ['data'] }
        const excelBuffer = XLSX.write(wb, { bookType: type, type: 'array' })
        const data = new window.Blob([excelBuffer], { type: `.${type}` })
        FileSaver.saveAs(data, `${name}.${type}`)
        message.success('Successfully exported data')
      } else {
        message.error('Unknown error occurred, failed to export')
      }
    }
    return () => callback()
    // eslint-disable-next-line
  }, [execute])
}
export const useSimpleExporter = ({ execute = false, payload = null, name = `report-${makeId(6)}_${dayjs().format('DD/MM/YYYY')}`, callback = () => (null) }) => {
  useEffect(() => {
    if (execute) {
      if (payload) {
        async function download () {
          await writeXlsxFile(payload, {
            schema: findMostKeys(payload).map(key => ({
              column: key,
              type: String,
              value: row => `${!row?.[key] ? '' : row?.[key]}`,
              width: 60,
              height: 30,
              wrap: true
            })),
            headerStyle: {
              backgroundColor: '#28211F',
              color: '#ffffff',
              fontWeight: 'bold',
              height: 30,
              leftBorderColor: '#28211F',
              rightBorderColor: '#28211F'
            },
            fontFamily: 'Arial',
            fileName: 'file.xlsx'
          })
        }
        download()
        message.success('Successfully exported data')
      } else {
        message.error('Unknown error occurred, failed to export')
      }
    }
    return () => callback()
    // eslint-disable-next-line
  }, [execute])
}
