import { Button, Row, Spin, Affix, Typography, Space, Select, message } from 'antd'
import Paragraph from 'antd/lib/typography/Paragraph'
import React, { useState, useEffect, useReducer } from 'react'
import { Link, useHistory, useParams } from 'react-router-dom'
import { SuccessionPdf } from '../../components/SuccessionPdf'
import { useGetSingleSuccessionPlan, useUpdateSuccessionPlan } from '../../hooks/api/useSuccession'
import { SaveOutlined, ArrowLeftOutlined } from '@ant-design/icons'
import { useQueryParam } from '../../hooks/useQueryParam'
import { SuccessionGroup } from '../../components/SuccessionGroup'
import { useGetLookup } from '../../hooks/api/useLookups'

function successionReducer (state, action) {
  const { type, payload, field } = action
  switch (type) {
    case 'initialize': {
      return {
        ...state,
        initialized: true,
        ...payload
      }
    }
    case 'field': {
      return {
        ...state,
        hasChanged: true,
        [field]: payload,
        changedFields: {
          ...state.changedFields,
          [field]: payload
        }
      }
    }
    case 'saved': {
      return {
        ...state,
        hasChanged: false
      }
    }
    default: {
      throw new Error(`Unsupported action type: ${type}`)
    }
  }
}

export const SuccessionRole = () => {
  const [state, dispatch] = useReducer(successionReducer, { initialized: false, location: null, hasChanged: false, changedFields: {} })
  const [preview, setPreview] = useState(false)
  const [saving, setSaving] = useState(false)
  const queryParams = useQueryParam()
  const locationLookups = useGetLookup({ group: 'Dynamic', key: 'JobLocation' })
  const { role: roleUid, type, discipline } = useParams()
  const { push } = useHistory()
  console.log('state::: ', state)

  const planQuery = useGetSingleSuccessionPlan({ planId: roleUid, location: queryParams.get('location') || null })

  const successionMutation = useUpdateSuccessionPlan()

  useEffect(() => {
    if (!planQuery.isLoading && planQuery.data) {
      const initialObj = {
        ...planQuery.data,
        roleDescription: planQuery.data?.talentRole?.roleDescription || ''
      }
      dispatch({ type: 'initialize', payload: initialObj })
    } else if (!planQuery.isLoading && planQuery?.data === null) {
      message.warn(`${state?.role} has no data for the ${state?.location} location`)
    }
    // eslint-disable-next-line
  }, [planQuery.isLoading, planQuery.data, roleUid, type])

  return (
    <div className='succession__page'>
      <Row justify='space-between' align='middle'>
        <Link to={`/succession-planner/${discipline}${queryParams.get('tab') ? `?tab=${queryParams.get('tab')}` : ''}`} className='flex gap-6 items-center mt-8 mb-4'>
          <ArrowLeftOutlined className='text-xl' />
          <Typography.Title style={{ margin: 0 }}>Succession Planner</Typography.Title>
        </Link>
        <div className='flex gap-6 items-end'>
          <Space direction='vertical'>
            <p className='text-gray-400'>Location</p>
            <Select
              onChange={(value) => {
                dispatch({ type: 'field', field: 'location', payload: value })
                if (value) {
                  push(`${window.location.pathname}?location=${value}`)
                } else {
                  push(window.location.pathname)
                }
              }} placeholder='Select location' value={state.location} loading={locationLookups.isLoading} disabled={locationLookups.isError || !locationLookups.data} style={{ minWidth: 150 }}
            >
              <Select.Option value={null}>None</Select.Option>
              {!locationLookups.isLoading && locationLookups.data && locationLookups.data.map(option => (
                <Select.Option key={option} value={option}>{option}</Select.Option>
              ))}
            </Select>
          </Space>
          {!planQuery.isLoading && state.initialized && (<Button onClick={() => setPreview(true)} key='submit' type='primary' className='bg-blue-400'>Export as PDF</Button>)}
        </div>
      </Row>

      {!planQuery.isLoading && state.initialized
        ? (
          <>
            <SuccessionPdf preview={preview} setPreview={setPreview} state={state} />
            <div className='rounded-md bg-white shadow mb-8 py-7 px-4 flex'>
              <div className='w-1/2'>
                <Paragraph style={{ margin: 0 }} editable className='text-2xl font-bold flex items-center'>{state.role}</Paragraph>
                <p className='text-xs text-gray-400 mb-4'>Pay Scale Group: {state.incumbents[0]?.talentCompanyPerson?.payScaleGroup || 'NA'} | {state.location && `${state.location} |`} {state?.discipline[0]}</p>
                <p className='caption mb-0'>Role Description</p>
                <Paragraph editable={{ onChange: (e) => dispatch({ type: 'field', field: 'roleDescription', payload: e }) }} className='mb-4 mt-0'>{state?.roleDescription}</Paragraph>
              </div>
              <div className='w-1/2'>
                <p className='caption mb-2'>Incumbents</p>

                {planQuery?.data?.incumbents?.length
                  ? (
                    <ul className='list-disc pl-5'>
                      {planQuery?.data?.incumbents.map(inc => (
                        <li key={inc.uid}>{inc?.person?.firstName + ' ' + inc?.person?.lastName}</li>
                      ))}
                    </ul>
                    )
                  : `No incumbents for ${state?.location}`}

              </div>
            </div>
            <Row gutter={['32']}>
              <SuccessionGroup query={planQuery} range='0-12 Months' title='Ready Now' style={{ backgroundColor: '#1a5c43' }} />
              <SuccessionGroup query={planQuery} range='1-3 Years' title='Ready in 1 - 3 years' style={{ backgroundColor: '#fdb933' }} />
              <SuccessionGroup query={planQuery} range='3-5 Years' title='Ready in 3 - 5 years' style={{ backgroundColor: '#5691c6' }} />
              <SuccessionGroup query={planQuery} range='6-8 Years' title='Ready in 6 - 8 years' color='bg-black' />
            </Row>
            {state.hasChanged && (
              <Affix style={{ position: 'absolute', bottom: 65, right: 30 }} offsetBottom={65}>
                <Button
                  loading={saving}
                  onClick={async () => {
                    setSaving(true)
                    await successionMutation.mutateAsync({ talentRoleId: planQuery.data?.talentRoleId, data: { ...state.changedFields, roleTitle: planQuery.data?.role } })
                    setTimeout(() => {
                      dispatch({ type: 'saved' })
                      setSaving(false)
                    }, 500)
                  }}
                  type='primary' className='bg-blue-400'
                  shape='round'
                  size='large'
                  icon={<SaveOutlined />}
                >
                  {saving ? 'Saving' : 'Save'} Changes
                </Button>
              </Affix>
            )}
          </>
          )
        : (
          <Spin />
          )}
    </div>
  )
}
