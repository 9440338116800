import { message } from 'antd'
import axios from 'axios'
import { getAuth, getIdToken } from 'firebase/auth'
import { useMutation, useQuery, useQueryClient } from 'react-query'

export const useGetSuccessionPlans = (filters) => {
  let params = {}

  Object.keys(filters).forEach(key => {
    if (filters[key]) {
      params = {
        ...params,
        [key]: filters[key]
      }
    }
  })

  const urlParams = new URLSearchParams(params).toString()

  return useQuery(
    ['successions', params],
    async () => axios.get(
      `${process.env.REACT_APP_API_URL}/talent/successionPlanning${urlParams.length ? '?' + urlParams : ''}`,
      {
        headers: {
          Authorization: `Bearer ${await getIdToken(getAuth().currentUser)}`,
          'X-Tenant-Id': process.env.REACT_APP_TENANT_ID
        }
      }
    )
      .then(res => res.data.data),
    {
      staleTime: 1000 * 60 * 5,
      onError: (error) => {
        message.error(`Failed to fetch succession plans: ${error.response.data.message}`)
      }
    }
  )
}

export const useGetSingleSuccessionPlan = ({ planId, location }) => {
  return useQuery(
    ['plan', planId, location],
    async () => axios.get(
      `${process.env.REACT_APP_API_URL}/talent/successionPlanning/${planId}${location ? `?location=${location}` : ''}`,
      {
        headers: {
          Authorization: `Bearer ${await getIdToken(getAuth().currentUser)}`,
          'X-Tenant-Id': process.env.REACT_APP_TENANT_ID
        }
      }
    )
      .then(res => res.data.data),
    {
      staleTime: 1000 * 60 * 5,
      enabled: !!planId,
      onError: (error) => {
        message.error(`Failed to fetch succession plan: ${error.response.data.message}`)
      }
    }
  )
}

export const useUpdateSuccessionPlan = () => {
  const queryClient = useQueryClient()
  return useMutation(
    (body) => axios.put(
      `${process.env.REACT_APP_API_URL}/talent/successionPlanning/roles/${body.talentRoleId}`,
      body.data,
      {
        headers: {
          Authorization: `Bearer ${queryClient.getQueryData('token')?.token ?? ''}`,
          'X-Tenant-Id': process.env.REACT_APP_TENANT_ID
        }
      }
    )
      .then(res => res.data),
    {
      onSuccess: () => {
        // queryClient.invalidateQueries('successions')
        message.success('Successfully updated succession plan')
      },
      onError: (error) => {
        message.error(`Failed to updated succession plan: ${error.response.data.message}`)
      }
    }
  )
}

export const useCreateTalentRole = () => {
  const queryClient = useQueryClient()
  return useMutation(
    async (body) => axios.post(
      `${process.env.REACT_APP_API_URL}/talent/successionPlanning/${body.planId}/roles`,
      body.data,
      {
        headers: {
          Authorization: `Bearer ${await getIdToken(getAuth().currentUser, true)}`,
          'X-Tenant-Id': process.env.REACT_APP_TENANT_ID
        }
      }
    )
      .then(res => res.data),
    {
      onSuccess: () => {
        queryClient.invalidateQueries('plan')
        // message.success('Successfully updated succession plan')
      },
      onError: () => {
        // message.error(`Failed to updated succession plan: ${error.response.data.message}`)
      }
    }
  )
}
