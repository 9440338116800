import { message } from 'antd'
import axios from 'axios'
import { getAuth, getIdToken } from 'firebase/auth'
import { useMutation } from 'react-query'

export const usePreAuthMutation = () => {
  return useMutation(
    async (body) => axios.post(
      `${process.env.REACT_APP_API_URL}/auth/signinWithAd`,
      body.data,
      {
        headers: {
          Authorization: `Bearer ${await getIdToken(getAuth().currentUser, true)}`,
          'X-Tenant-Id': process.env.REACT_APP_TENANT_ID
        }
      }
    )
      .then(res => res.data),
    {
      onError: (error) => {
        message.error(`Failed to run pre-auth: ${error.response.data.message}`)
      }
    }
  )
}
