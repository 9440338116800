import { Card, Skeleton, Row, Col, Statistic, Typography, Divider, Tag, Space, Badge } from 'antd'
import React from 'react'
import { Link } from 'react-router-dom'
import { useGetLookup } from '../../hooks/api/useLookups'
import { useGetSuccessionPlans } from '../../hooks/api/useSuccession'

export const Disciplines = () => {
  const successionPlansQuery = useGetSuccessionPlans({})
  const disciplineLookups = useGetLookup({ group: 'Dynamic', key: 'Discipline' })

  return (
    <div className='succession__page'>
      <Typography.Title className='mt-8 mb-0'>Disciplines</Typography.Title>
      <p className='caption'>Select a dicpline to view the active succession plans</p>
      <Divider />
      <Typography.Title level={3}>Colour Key</Typography.Title>
      <Space direction='vertical'>
        <Badge color='rgb(26, 92, 67)' text='0-12 Months' />
        <Badge color='rgb(253, 185, 50)' text='1-3 Years' />
        <Badge color='rgb(86, 145, 198)' text='3-5 Years' />
        <Badge color='rgb(0, 0, 0)' text='6-8 Years' />
      </Space>
      <Divider />
      {!successionPlansQuery.isLoading && !disciplineLookups?.isLoading
        ? (
          <Row gutter={16}>
            {disciplineLookups.data?.sort((a, b) => {
              return (a.role < b.role) ? -1 : (a.role > b.role) ? 1 : 0
            })?.map((discipline, i) => {
              const color =
                  [
                    ...(successionPlansQuery?.data?.successionPlans || [])
                      ?.filter(x => x?.discipline?.includes(discipline))
                      ?.flatMap(item => Object.values(item?.colorByPayScaleGroup) || [])
                  ]?.sort((a, b) => (a === b ? 0 : (a > b ? 1 : -1)))
                    ?.reduce((acc, val, _, arr) => (arr?.filter(v => v === val).length > acc.count ? { color: val, count: arr?.filter(v => v === val).length } : acc), { color: '', count: 0 })
                    ?.color

              const disciplinePlans = successionPlansQuery?.data?.successionPlans?.filter(x => x?.discipline?.includes(discipline))
              const disciplineGroupPlans = successionPlansQuery?.data?.groupRoleSuccessionPlans?.filter(x => x?.discipline?.includes(discipline))

              const criticalCount = [...disciplinePlans, ...disciplineGroupPlans].filter(x => x?.isCritical)?.length

              return (
                <Col className='mb-5' key={discipline} xs={12} lg={8}>
                  <Link to={`/succession-planner/${discipline}`}>
                    <Card className={`suc-card suc-card--${color}`} hoverable title={discipline} extra={criticalCount > 0 ? [<Tag key='criticalCount' color='orange'>Critical: {criticalCount}</Tag>] : null}>
                      <Row>
                        <Col span={12}>
                          <Statistic title='Individual' valueStyle={{ color: disciplinePlans.length === 0 ? '#cf1322' : '#000000' }} value={disciplinePlans.length} />
                        </Col>
                        <Col span={12}>
                          <Statistic title='Group' value={disciplineGroupPlans.length} />
                        </Col>
                      </Row>
                    </Card>
                  </Link>
                </Col>
              )
            })}
          </Row>
          )
        : (
          <>
            <Card className='mb-8'>
              <Skeleton loading avatar active />
            </Card>
            <Card className='mb-8'>
              <Skeleton loading avatar active />
            </Card>
          </>
          )}

    </div>
  )
}
