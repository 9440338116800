import { generateKey } from '../hooks/generateKey'
import { makeId } from '../hooks/uuid'

// DONE 10 May 2024
const coporateAffairs = {
  columns: [
    {
      title: 'Key Result Area',
      dataIndex: 'kra',
      align: 'left',
      key: 'kra'
    },
    {
      title: 'Objectives',
      dataIndex: 'description',
      align: 'left',
      key: 'description'
    },
    {
      title: 'Weighting',
      dataIndex: 'weighting',
      align: 'left',
      key: 'weighting'
    }
  ],
  data: [
    {
      kra: 'Sustainability',
      description: 'To proactively champion responsible mine closure, establishing social projects that will help sustain the livelihoods of the communities we operate in collaborating with various departments.',
      weighting: 25,
      key: makeId(5),
      children: [
        { key: makeId(5), description: 'Ensure the transition of communities after mine closure', weighting: 5 },
        { key: makeId(5), description: 'Embed ESG targets, reporting and projects', weighting: 5 },
        { key: makeId(5), description: 'Drive and implement the life beyond coal project', weighting: 5 },
        { key: makeId(5), description: 'Deliver year-end ESG report', weighting: 5 },
        { key: makeId(5), description: 'Drive the development of the ESG narrative', weighting: 5 }
      ]
    },
    {
      kra: 'Transformation',
      description: 'To embed inclusive and equitable practice as a way of doing business, ensuring economic participation for diverse groups',
      weighting: 25,
      key: makeId(5),
      children: [
        { key: makeId(5), description: 'Delivery of the transformation strategy and comply with set targets', weighting: 8.33 },
        { key: makeId(5), description: 'Development and implementation of the Thungela ESD program', weighting: 8.33 },
        { key: makeId(5), description: 'Deploy systems and processes to support the sites with transformation reporting, assurance and compliance', weighting: 8.33 }
      ]
    },
    {
      kra: 'Social Performance',
      description: 'To elevate socio-economic development to create positive impact while managing risks.',
      weighting: 25,
      key: makeId(5),
      children: [
        { key: makeId(5), description: 'Developing and delivering the SED and 5-yr SLP plans', weighting: 4.16 },
        { key: makeId(5), description: 'Develop and implement Thungela enterprise and supplier development programme', weighting: 4.16 },
        { key: makeId(5), description: 'Empowering host communities through the Community Trust', weighting: 4.16 },
        { key: makeId(5), description: 'Managing stakeholder relationships, risks and impacts of our operations', weighting: 4.16 },
        { key: makeId(5), description: 'Progressive reporting and significant incidents and impact management: ESG, BBBEE and SLP annual reports', weighting: 4.16 },
        { key: makeId(5), description: 'Develop and implement Thungela Socio-Economic Development approach, attain, regulator SLPs approval and operationalise the Nkulo Community Partnership Trust', weighting: 4.16 }
      ]
    },
    {
      kra: 'Communication',
      description: 'Position Thungela internationally as a responsible producer of coal that creates value responsibly and connects with employees, communities and stakeholders through various communication and channels.',
      weighting: 25,
      key: makeId(5),
      children: [
        { key: makeId(5), description: 'Development and implementation of a 3 yr internal communications strategy', weighting: 4.16 },
        { key: makeId(5), description: 'Develop & advocate ESG narrative', weighting: 4.16 },
        { key: makeId(5), description: 'Embed Thungela style and brand guidelines', weighting: 4.16 },
        { key: makeId(5), description: 'Embed internal culture and values, partnering with HR', weighting: 4.16 },
        { key: makeId(5), description: 'Communication: expand social engagement channels, embed purpose, culture and values', weighting: 4.16 },
        { key: makeId(5), description: 'Year-End (2021) financial and sustainability report: media engagement and messaging annual results', weighting: 4.16 }
      ]
    }
  ]
}
// DONE - 10 May 2024
const technical = {
  columns: [
    {
      title: 'Key Result Area',
      dataIndex: 'kra',
      align: 'left',
      key: 'kra'
    },
    {
      title: 'Objectives',
      dataIndex: 'description',
      align: 'left',
      key: 'description'
    },
    {
      title: 'Weighting',
      dataIndex: 'weighting',
      align: 'left',
      key: 'weighting'
    }
  ],
  data: [
    {
      key: makeId(5),
      kra: 'Drive our ESG aspirations.',
      weighting: 25,
      children: [
        { key: makeId(5), description: 'Explore the various underground FOG technologies and package for Steercom and Exco presentation by Aug 2024', weighting: 5 },
        { key: makeId(5), description: 'Reduce personal respirable coal dust and silica exposure Coal: 10% or less> 1.5mg/m3; Silica: All samples below 0.05mg/m3', weighting: 5 },
        { key: makeId(5), description: 'Complete ZB solar plant and support in the selection and criteria of 10MVa offtake agreement', weighting: 5 },
        { key: makeId(5), description: 'Complete the planting of 300 000 trees', weighting: 5 },
        { key: makeId(5), description: 'Technical assurance process compliance, Update L2 assurance to identify CC deficiencies, apply stop and fix and support to deal with critical findings', weighting: 5 }
      ]
    },
    {
      key: makeId(5),
      kra: 'Ensham Integration and value extraction',
      weighting: 25,
      children: [
        { key: makeId(5), description: 'Complete RDP process and establish project and BD pipeline Q2 2024', weighting: 6.25 },
        { key: makeId(5), description: 'Deliver on a cost-effective solution for the fault development in 2024 business plan with a medium-term view on a permanent resourcing plan', weighting: 6.25 },
        { key: makeId(5), description: 'Rehabilitation planning process and updating of liability model - Minimal impact on balance sheet for 2024 and land on a Theiss business model', weighting: 6.25 },
        { key: makeId(5), description: 'Setup a productivity improvement program to support the BP improvements', weighting: 6.25 }
      ]
    },
    {
      key: makeId(5),
      kra: 'Maximise the full potential of our assets',
      weighting: 15,
      children: [
        { key: makeId(5), description: 'Support in delivering the BP productivity numbers through set routines - Quarterly updates and re-run benchmark exercise prior to Board Strat session in June', weighting: 5 },
        { key: makeId(5), description: 'Support and involvement in the Khw BD -Complete work by Q3 for DB forum and Exco approval', weighting: 5 },
        { key: makeId(5), description: 'World class Asset maintenance execution -Back to basic ito of routines and resourcing (assurance check and reset if need be by Q2) Sub-assembly management (10% reduction in repair cost U/G)', weighting: 5 }
      ]
    },
    {
      key: makeId(5),
      kra: 'Optimise capital allocation',
      weighting: 30,
      children: [
        { key: makeId(5), description: 'Elders - Complete the Operational readiness and transition planning by Aug. Deliver first coal from the contractor by March. De-risk Eskom main supply ahead on Sep date. Plant Wash solution to be delivered by end Q3', weighting: 10 },
        { key: makeId(5), description: 'Zibulo NS - Within schedule and cost for 2024. Eskom de-risk intervention', weighting: 10 },
        { key: makeId(5), description: 'Gas Project- Complete production right application and business case Q3', weighting: 10 }
      ]
    },
    {
      key: makeId(5),
      kra: 'Create future diversification options',
      weighting: 5,
      children: [
        { key: makeId(5), description: 'Technical support for BD opportunities - Resource planning and allocation of support', weighting: 5 }
      ]
    }
  ]
}
// DONE 10 May 2024
const hr = {
  columns: [
    {
      title: 'Key Result Area',
      dataIndex: 'kra',
      align: 'left',
      key: 'kra'
    },
    {
      title: 'Objectives',
      dataIndex: 'description',
      align: 'left',
      key: 'description'
    },
    {
      title: 'Weighting',
      dataIndex: 'weighting',
      align: 'left',
      key: 'weighting'
    }
  ],
  data: [
    {
      key: makeId(5),
      kra: 'Attract and select',
      description: 'To determine the talent / skill profile for the business',
      weighting: 30,
      children: [
        { key: makeId(5), description: 'Achieve inclusion and diversity Targets for 2024' },
        { key: makeId(5), description: 'Support the business in M&A (Staffing, integration of new businesses)' },
        { key: makeId(5), description: 'Stabilise People Services Transitioned from Anglo(Pay roll)' }
      ]
    },
    {
      key: makeId(5),
      kra: 'Enable and retain',
      description: 'To help leaders create an enabling work environment, by focusing our employee experience along the three pillars energised company, job enrichment and affinity',
      weighting: 35,
      children: [
        { key: makeId(5), description: 'Embed Thungela values' },
        { key: makeId(5), description: 'Embed Team effectiveness at Exco and Mine Leadership level' },
        { key: makeId(5), description: 'Roll out supervisory Development to the remaining sites' }
      ]
    },
    {
      key: makeId(5),
      kra: 'Develop and perform',
      description: 'To creation of talent pools that guide the type and degree of investment in specific segments of our workforce',
      weighting: 35,
      children: [
        { key: makeId(5), description: 'Embed Leadership academy' },
        { key: makeId(5), description: 'Mitigate succession risk for critical roles' },
        { key: makeId(5), description: 'Embed owner mindset through EPP' }
      ]
    }
  ]
}
// DONE - 10 May 2024
const she = {
  columns: [
    {
      title: 'Key Result Area',
      dataIndex: 'kra',
      align: 'left',
      key: 'kra'
    },
    {
      title: 'Objectives',
      dataIndex: 'description',
      align: 'left',
      key: 'description'
    },
    {
      title: 'Weighting',
      dataIndex: 'weighting',
      align: 'left',
      key: 'weighting'
    }
  ],
  data: [
    {
      weighting: 40,
      key: makeId(5),
      kra: 'Run a fatality and injury free business',
      children: [
        {
          key: makeId(5),
          description: 'Implement a 3rd Party Critical Control program',
          weighting: 13.3
        },
        {
          key: makeId(5),
          description: 'Run an effective Culture change process',
          weighting: 13.3
        },
        {
          key: makeId(5),
          description: 'Clear Standards, Governance compliance checks and assurance audits',
          weighting: 13.3
        }
      ]
    },
    {
      weighting: 30,
      key: makeId(5),
      kra: 'Ensure a Healthy workforce',
      children: [
        { key: makeId(5), weighting: 10, description: 'Ensure a program to meet and exceed government 95x95x95 targets are met' },
        { key: makeId(5), weighting: 10, description: 'Prep hospital - fit for business of the future business case' },
        { key: makeId(5), weighting: 10, description: 'Overview of wellness plans and Initiatives' }
      ]
    },
    {
      weighting: 30,
      key: makeId(5),
      kra: 'Environmental Governance',
      children: [
        { key: makeId(5), weighting: 4, description: 'Ensure effective governance systems in place' },
        { key: makeId(5), weighting: 2, description: 'Facilitate effective license and permitting process - both internal and with authorities' }
      ]
    }
  ]
}
// DONE - 10 May 2024
const marketing = {
  columns: [
    {
      title: 'Key Result Area',
      dataIndex: 'kra',
      align: 'left',
      key: 'kra'
    },
    {
      title: 'Objectives',
      dataIndex: 'description',
      align: 'left',
      key: 'description'
    },
    {
      title: 'Weighting',
      dataIndex: 'weighting',
      align: 'left',
      key: 'weighting'
    }
  ],
  data: [
    {
      kra: 'Strategic intent of Product and Geographical Diversification: Full implementation, well controlled, of the new marketing model on a principal basis by June 2024',
      key: makeId(5),
      weighting: 40,
      children: [
        { key: makeId(5), description: 'AAML transition agreements signed and implemented by end March 2024. ' },
        { key: makeId(5), description: 'ECS and TMI principal agreements in place with TOPL and DBX office operational by end May 2024. ' },
        { key: makeId(5), description: 'Marketing model value uplift for TMI for FY24 achieved. ' }
      ]
    },
    {
      kra: 'Secure routes to market: Improved TFR rail performance and rail term rail contracts for SA and Aus are critical for our business.',
      key: makeId(5),
      weighting: 40,
      children: [
        { key: makeId(5), description: 'Extend present LTA to end March 2025.' },
        { key: makeId(5), description: 'Ensure TOPL equitable position is on average positive. ' },
        { key: makeId(5), description: 'Support TFR’s efforts on consistency (50Mtpa tempo) and improvement (55Mtpa tempo)' },
        { key: makeId(5), description: 'TOPL’s rail outcome for FY24 of min 12.25Mt and stretch of 12.5Mt+' },
        { key: makeId(5), description: 'New TFR LTA term sheet in place by end December 2025, or LTA extended by additional 1 year' },
        { key: makeId(5), description: 'New term agreement, with improved commercials, for ECS with Aurizon in place by end June 2025. ' }
      ]
    },
    {
      kra: 'Integrated marketing model',
      key: makeId(5),
      weighting: 20,
      children: [
        { key: makeId(5), description: 'Domestic marketing strategy finalized and presented in Board strategy session in July.' },
        { key: makeId(5), description: 'Effective risk management of Sasol CSA position or extended value accretive CSA.' },
        { key: makeId(5), description: 'TOPL and ECS demurrage at budget less 20%.' },
        { key: makeId(5), description: 'Value accretive SA FOT domestic sales – subject to rail and stockpile availability.' },
        { key: makeId(5), description: 'SA MRD sales at FY23 levels plus 10% (quantity and value)' }
      ]
    }
  ]
}

const finance = {
  columns: [
    {
      title: 'Key Result Area',
      dataIndex: 'kra',
      align: 'left',
      key: 'kra'
    },
    {
      title: 'Objectives',
      dataIndex: 'description',
      align: 'left',
      key: 'description'
    },
    {
      title: 'Weighting',
      dataIndex: 'weighting',
      align: 'left',
      key: 'weighting'
    }
  ],
  data: [
    {
      kra: 'Elimination of fatalities',
      children: [
        {
          key: makeId(5),
          description: 'Elimination of fatalities'
        }
      ],
      key: makeId(5)
    },
    {
      kra: 'Deliver ESG aspirations',
      key: makeId(5)
    },
    {
      kra: 'Maximise value from existing assets',
      children: [
        {
          key: makeId(5),
          description: 'Deliver cost savings'
        },
        {
          key: makeId(5),
          description: 'Advance gas project study'
        }
      ],
      key: makeId(5)
    },
    {
      kra: 'Create diversification options',
      children: [
        {
          key: makeId(5),
          description: 'Ensham integration'
        },
        {
          key: makeId(5),
          description: 'Build M&A opportunities pipeline'
        }
      ],
      key: makeId(5)
    },
    {
      kra: 'Optimise capital allocation',
      children: [
        {
          key: makeId(5),
          description: 'Capital allocation strategy'
        },
        {
          key: makeId(5),
          description: 'Balance sheet flexibility'
        }
      ],
      key: makeId(5)
    }
  ]
}
const coo = {
  columns: [
    {
      title: 'Key Result Area',
      dataIndex: 'kra',
      align: 'left',
      key: 'kra'
    },
    {
      title: 'Objectives',
      dataIndex: 'description',
      align: 'left',
      key: 'description'
    },
    {
      title: 'Weighting',
      dataIndex: 'weighting',
      align: 'left',
      key: 'weighting'
    }
  ],
  data: [
    {
      kra: '1. #ThungelaTogether Intervention',
      key: makeId(5),
      weighting: 20,
      description: 'Successful implementation of the #ThungelaTogether intervention across all operational sites in Thungela'
    },
    {
      kra: '2. #ThungelaTogether Revenue Stream',
      key: makeId(5),
      weighting: 20,
      description: 'Enable various Revenue Stream (Free On Truck) opportunities across Thungela Saleable Stockpiles: 1.986mt'
    },
    {
      kra: '3. #Thungela Together SIB Capital Reduction',
      key: makeId(5),
      weighting: 20,
      description: 'Achieve and Improve Thungela SIB Capital Target: R1.567m'
    },
    {
      kra: '4. #Thungela Together Cash Cost Reduction',
      key: makeId(5),
      weighting: 20,
      description: 'Achieve and Improve Thungela Cash Cost Target: R12.291m'
    },
    {
      kra: '5. #Thungela Together ROM Production',
      key: makeId(5),
      weighting: 20,
      description: 'Achieve Thungela ROM Production Target 24.83mt'
    }
  ]
}
const Thungela = {
  columns: [
    {
      title: 'Performance Category',
      dataIndex: 'category',
      align: 'right',
      key: 'category'
    },
    {
      title: 'Weighting',
      dataIndex: 'weighting',
      align: 'left',
      key: 'weighting'
    },
    {
      title: 'Peformance Measure',
      dataIndex: 'measure',
      align: 'left',
      key: 'measure'
    },
    {
      title: 'On-Target',
      dataIndex: 'target',
      align: 'left',
      key: 'target'
    },
    {
      title: 'Threshold',
      dataIndex: 'threshold',
      align: 'left',
      key: 'threshold'
    },
    {
      title: 'Stretch',
      dataIndex: 'stretch',
      align: 'left',
      key: 'stretch'
    }
  ],
  data: [
    {
      category: 'Safety & Health',
      key: makeId(5),
      weighting: 10,
      children: [
        { key: makeId(5), category: 'TFCFR', weighting: 5, measure: '% Improvement on avg of prev 3 years', target: 1.37, threshold: 1.2741, stretch: 1.41 },
        { key: makeId(5), category: 'HIV & Treatment', weighting: 5, measure: '% of Employees who know their HIV status', target: 95, threshold: 88.35, stretch: 97.85 }
      ]
    },
    {
      category: 'Environment, Social & Governance',
      key: makeId(5),
      weighting: 10,
      children: [
        { key: makeId(5), category: 'Level 4-5 Environmental incidents', weighting: 2.5, measure: '# of incidents', target: 0, threshold: 1, stretch: 0 },
        { key: makeId(5), category: 'Energy Efficiency', weighting: 2.5, measure: '% annual improvement against 2025 Target', target: 2, threshold: 1.86, stretch: 2.06 },
        { key: makeId(5), category: 'Inclusive Procurement', weighting: 2.5, measure: '% of addressable spend', target: 15, threshold: 13.95, stretch: 15.45 },
        { key: makeId(5), category: 'Inclusion & Diversity', weighting: 2.5, measure: '% HDP in Management', target: 60, threshold: 55.8, stretch: 61.8 }
      ]
    },
    {
      category: 'Production',
      key: makeId(5),
      weighting: 30,
      children: [
        { key: makeId(5), category: 'Export Saleable Production', weighting: '20', measure: '# of Export Saleable Production (Mt)', target: '13.8', threshold: '12.8', stretch: '14.2' },
        { key: makeId(5), category: 'FOB Cost / Export Ton', weighting: '10', measure: 'FOB Cost per Export Ton (R/t)', target: '1,104', threshold: '1,182', stretch: '1,071' }
      ]
    },
    {
      category: 'Finance',
      key: makeId(5),
      weighting: 20,
      children: [
        // category
        // weighting
        // measure
        // target
        // threshold
        // stretch
        {
          key: makeId(5),
          category: 'Adjusted EBITDA',
          weighting: '10',
          measure: 'Adjusted EBITDA (Rm)',
          target: '22,327',
          threshold: '19,424 ',
          stretch: '23,890 '
        },
        {
          key: makeId(5),
          category: 'Adjusted Operating Free Cash Flow',
          weighting: '10',
          measure: 'Adjusted Operating Free Cash Flow (Rm)',
          target: '14,540',
          threshold: '12,650',
          stretch: '15,558'
        }
      ]
    }
  ]
}
const Goedehoop = {
  columns: [
    {
      title: 'Performance Category',
      dataIndex: 'category',
      align: 'right',
      key: 'category'
    },
    {
      title: 'Weighting',
      dataIndex: 'weighting',
      align: 'left',
      key: 'weighting'
    },
    {
      title: 'Peformance Measure',
      dataIndex: 'measure',
      align: 'left',
      key: 'measure'
    },
    {
      title: 'On-Target',
      dataIndex: 'target',
      align: 'left',
      key: 'target'
    },
    {
      title: 'Threshold',
      dataIndex: 'threshold',
      align: 'left',
      key: 'threshold'
    },
    {
      title: 'Stretch',
      dataIndex: 'stretch',
      align: 'left',
      key: 'stretch'
    }
  ],
  data: [
    {
      category: 'Safety & Health',
      weighting: 10,
      key: makeId(5),
      children: [
        {
          category: 'TRCFR',
          weighting: 5,
          measure: '% Improvement on avg of prev 3 years',
          target: 1.59,
          threshold: 1.4787,
          stretch: 1.64,
          key: makeId(5)
        },
        {
          category: 'HIV & Treatment',
          weighting: 5,
          measure: '% of Employees who know their HIV status',
          target: 95,
          threshold: 88.35,
          stretch: 97.85,
          key: makeId(5)
        }
      ]
    },
    {
      category: 'Environment, Social & Governance',
      weighting: 10,
      key: makeId(5),
      children: [
        {
          category: 'Level 4-5 Environmental incidents',
          weighting: 2,
          measure: '# of incidents',
          target: 0,
          threshold: 1,
          stretch: 0,
          key: makeId(5)
        },
        {
          category: 'Energy Efficiency',
          weighting: 2,
          measure: '% annual improvement against 2025 Target',
          target: 2,
          threshold: 1.86,
          stretch: 2.06,
          key: makeId(5)
        },
        {
          category: 'Inclusive Procurement',
          weighting: 2,
          measure: '% of addressable spend',
          target: 15,
          threshold: 13.95,
          stretch: 15.45,
          key: makeId(5)
        },
        {
          category: 'Inclusion & Diversity',
          weighting: 2,
          measure: '% HDP in Management',
          target: 60,
          threshold: 55.8,
          stretch: 61.8,
          key: makeId(5)
        },
        {
          category: 'Personal dust exposure exceedences',
          weighting: 2,
          measure: '% improvement from prior year on recorded instances of 2mg/cm3 exceedences',
          target: 5,
          threshold: 4.65,
          stretch: 5.15,
          key: makeId(5)
        }
      ]
    },
    {
      category: 'Production',
      weighting: 35,
      key: makeId(5),
      children: [
        {
          category: 'ROM Production',
          weighting: 20,
          measure: '# of ex-pit ROM Production (Mt)',
          target: 3.7,
          threshold: 3.5,
          stretch: 3.9,
          key: makeId(5)
        },
        {
          category: 'On Mine Cash Cost',
          weighting: 15,
          measure: 'FOR Cash Cost (R million)',
          target: 2.097,
          threshold: 2.244,
          stretch: 2.034,
          key: makeId(5)
        }
      ]
    },
    {
      category: 'Asset Finance',
      weighting: 5,
      key: makeId(5),
      children: [
        {
          category: 'Sustaining Capital',
          weighting: 5,
          measure: 'SIB & Stripping and Dev. Capital expenditure (R million)',
          target: 21,
          threshold: 22,
          stretch: 20,
          key: makeId(5)
        }
      ]
    },
    {
      category: 'Company Finance',
      weighting: 10,
      key: makeId(5),
      children: [
        {
          category: 'Adjusted EBITDA',
          weighting: 5,
          measure: 'Adjusted EBITDA (Rm)',
          target: 22.327,
          threshold: 23.890,
          stretch: 20.764,
          key: makeId(5)
        },
        {
          category: 'Adjusted Operating Free Cash Flow',
          weighting: 5,
          measure: 'Adjusted Operating Free Cash Flow (Rm)',
          target: 14.540,
          threshold: 15.558,
          stretch: 13.522,
          key: makeId(5)
        }
      ]
    }
  ]
}
const Greenside = {
  columns: [
    {
      title: 'Performance Category',
      dataIndex: 'category',
      align: 'right',
      key: 'category'
    },
    {
      title: 'Weighting',
      dataIndex: 'weighting',
      align: 'left',
      key: 'weighting'
    },
    {
      title: 'Peformance Measure',
      dataIndex: 'measure',
      align: 'left',
      key: 'measure'
    },
    {
      title: 'On-Target',
      dataIndex: 'target',
      align: 'left',
      key: 'target'
    },
    {
      title: 'Threshold',
      dataIndex: 'threshold',
      align: 'left',
      key: 'threshold'
    },
    {
      title: 'Stretch',
      dataIndex: 'stretch',
      align: 'left',
      key: 'stretch'
    }
  ],
  data: [
    {
      category: 'Safety & Health',
      weighting: 10,
      key: makeId(5),
      children: [
        {
          category: 'TRCFR',
          weighting: 5,
          measure: '% Improvement on avg of prev 3 years',
          target: 1.51,
          threshold: 1.4043,
          stretch: 1.56,
          key: makeId(5)
        },
        {
          category: 'HIV & Treatment',
          weighting: 5,
          measure: '% of Employees who know their HIV status',
          target: 95,
          threshold: 88.35,
          stretch: 97.85,
          key: makeId(5)
        }
      ]
    },
    {
      category: 'Environment, Social & Governance',
      weighting: 10,
      key: makeId(5),
      children: [
        {
          category: 'Level 4-5 Environmental incidents',
          weighting: 2,
          measure: '# of incidents',
          target: 0,
          threshold: 1,
          stretch: 0,
          key: makeId(5)
        },
        {
          category: 'Energy Efficiency',
          weighting: 2,
          measure: '% annual improvement against 2025 Target',
          target: 2,
          threshold: 1.86,
          stretch: 2.06,
          key: makeId(5)
        },
        {
          category: 'Inclusive Procurement',
          weighting: 2,
          measure: '% of addressable spend',
          target: 15,
          threshold: 13.95,
          stretch: 15.45,
          key: makeId(5)
        },
        {
          category: 'Inclusion & Diversity',
          weighting: 2,
          measure: '% HDP in Management',
          target: 60,
          threshold: 55.8,
          stretch: 61.8,
          key: makeId(5)
        },
        {
          category: 'Personal dust exposure exceedences',
          weighting: 2,
          measure: '% improvement from prior year on recorded instances of 2mg/cm3 exceedences',
          target: 5,
          threshold: 4.65,
          stretch: 5.15,
          key: makeId(5)
        }
      ]
    },
    {
      category: 'Production',
      weighting: 35,
      key: makeId(5),
      children: [
        {
          category: 'ROM Production',
          weighting: 20,
          measure: '# of ex-pit ROM Production (Mt)',
          target: 3.3,
          threshold: 3.1,
          stretch: 3.4,
          key: makeId(5)
        },
        {
          category: 'On Mine Cash Cost',
          weighting: 15,
          measure: 'FOR Cash Cost (R million)',
          target: 1.945,
          threshold: 2.081,
          stretch: 1.887,
          key: makeId(5)
        }
      ]
    },
    {
      category: 'Asset Finance',
      weighting: 5,
      key: makeId(5),
      children: [
        {
          category: 'Sustaining Capital',
          weighting: 5,
          measure: 'SIB & Stripping and Dev. Capital expenditure (R million)',
          target: 115,
          threshold: 123,
          stretch: 107,
          key: makeId(5)
        }
      ]
    },
    {
      category: 'Company Finance',
      weighting: 10,
      key: makeId(5),
      children: [
        {
          category: 'Adjusted EBITDA',
          weighting: 5,
          measure: 'Adjusted EBITDA (Rm)',
          target: 22.327,
          threshold: 23.890,
          stretch: 20.764,
          key: makeId(5)
        },
        {
          category: 'Adjusted Operating Free Cash Flow',
          weighting: 5,
          measure: 'Adjusted Operating Free Cash Flow (Rm)',
          target: 14.540,
          threshold: 15.558,
          stretch: 13.522,
          key: makeId(5)
        }
      ]
    }
  ]
}
const Zibulo = {
  columns: [
    {
      title: 'Performance Category',
      dataIndex: 'category',
      align: 'right',
      key: 'category'
    },
    {
      title: 'Weighting',
      dataIndex: 'weighting',
      align: 'left',
      key: 'weighting'
    },
    {
      title: 'Peformance Measure',
      dataIndex: 'measure',
      align: 'left',
      key: 'measure'
    },
    {
      title: 'On-Target',
      dataIndex: 'target',
      align: 'left',
      key: 'target'
    },
    {
      title: 'Threshold',
      dataIndex: 'threshold',
      align: 'left',
      key: 'threshold'
    },
    {
      title: 'Stretch',
      dataIndex: 'stretch',
      align: 'left',
      key: 'stretch'
    }
  ],
  data: [
    {
      category: 'Safety & Health',
      weighting: 10,
      key: makeId(5),
      children: [
        {
          category: 'TRCFR',
          weighting: 5,
          measure: '% Improvement on avg of prev 3 years',
          target: 0.83,
          threshold: 0.7719,
          stretch: 0.85,
          key: makeId(5)
        },
        {
          category: 'HIV & Treatment',
          weighting: 5,
          measure: '% of Employees who know their HIV status',
          target: 95,
          threshold: 88.35,
          stretch: 97.85,
          key: makeId(5)
        }
      ]
    },
    {
      category: 'Environment, Social & Governance',
      weighting: 10,
      key: makeId(5),
      children: [
        {
          category: 'Level 4-5 Environmental incidents',
          weighting: 2,
          measure: '# of incidents',
          target: 0,
          threshold: 1,
          stretch: 0,
          key: makeId(5)
        },
        {
          category: 'Energy Efficiency',
          weighting: 2,
          measure: '% annual improvement against 2025 Target',
          target: 2,
          threshold: 1.86,
          stretch: 2.06,
          key: makeId(5)
        },
        {
          category: 'Inclusive Procurement',
          weighting: 2,
          measure: '% of addressable spend',
          target: 15,
          threshold: 13.95,
          stretch: 15.45,
          key: makeId(5)
        },
        {
          category: 'Inclusion & Diversity',
          weighting: 2,
          measure: '% HDP in Management',
          target: 60,
          threshold: 55.8,
          stretch: 61.8,
          key: makeId(5)
        },
        {
          category: 'Personal dust exposure exceedences',
          weighting: 2,
          measure: '% improvement from prior year on recorded instances of 2mg/cm3 exceedences',
          target: 5,
          threshold: 4.65,
          stretch: 5.15,
          key: makeId(5)
        }
      ]
    },
    {
      category: 'Production',
      weighting: 35,
      key: makeId(5),
      children: [
        {
          category: 'ROM Production',
          weighting: 20,
          measure: '# of ex-pit ROM Production (Mt)',
          target: 5.8,
          threshold: 5.4,
          stretch: 6.0,
          key: makeId(5)
        },
        {
          category: 'On Mine Cash Cost',
          weighting: 15,
          measure: 'FOR Cash Cost (R million)',
          target: 3.108,
          threshold: 3.326,
          stretch: 3.015,
          key: makeId(5)
        }
      ]
    },
    {
      category: 'Asset Finance',
      weighting: 5,
      key: makeId(5),
      children: [
        {
          category: 'Sustaining Capital',
          weighting: 5,
          measure: 'SIB & Stripping and Dev. Capital expenditure (R million)',
          target: 385,
          threshold: 412,
          stretch: 358,
          key: makeId(5)
        }
      ]
    },
    {
      category: 'Company Finance',
      weighting: 10,
      key: makeId(5),
      children: [
        {
          category: 'Adjusted EBITDA',
          weighting: 5,
          measure: 'Adjusted EBITDA (Rm)',
          target: 22.327,
          threshold: 23.890,
          stretch: 20.764,
          key: makeId(5)
        },
        {
          category: 'Adjusted Operating Free Cash Flow',
          weighting: 5,
          measure: 'Adjusted Operating Free Cash Flow (Rm)',
          target: 14.540,
          threshold: 15.558,
          stretch: 13.522,
          key: makeId(5)
        }
      ]
    }
  ]
}
const Kwezela = {
  columns: [
    {
      title: 'Performance Category',
      dataIndex: 'category',
      align: 'right',
      key: 'category'
    },
    {
      title: 'Weighting',
      dataIndex: 'weighting',
      align: 'left',
      key: 'weighting'
    },
    {
      title: 'Peformance Measure',
      dataIndex: 'measure',
      align: 'left',
      key: 'measure'
    },
    {
      title: 'On-Target',
      dataIndex: 'target',
      align: 'left',
      key: 'target'
    },
    {
      title: 'Threshold',
      dataIndex: 'threshold',
      align: 'left',
      key: 'threshold'
    },
    {
      title: 'Stretch',
      dataIndex: 'stretch',
      align: 'left',
      key: 'stretch'
    }
  ],
  data: [
    {
      category: 'Safety & Health',
      weighting: 10,
      key: makeId(5),
      children: [
        {
          category: 'TRCFR',
          weighting: 5,
          measure: '% Improvement on avg of prev 3 years',
          target: 1.34,
          threshold: 1.2462,
          stretch: 1.38,
          key: makeId(5)
        },
        {
          category: 'HIV & Treatment',
          weighting: 5,
          measure: '% of Employees who know their HIV status',
          target: 95,
          threshold: 88.35,
          stretch: 97.85,
          key: makeId(5)
        }
      ]
    },
    {
      category: 'Environment, Social & Governance',
      weighting: 10,
      key: makeId(5),
      children: [
        {
          category: 'Level 4-5 Environmental incidents',
          weighting: 2,
          measure: '# of incidents',
          target: 0,
          threshold: 1,
          stretch: 0,
          key: makeId(5)
        },
        {
          category: 'Energy Efficiency',
          weighting: 2,
          measure: '% annual improvement against 2025 Target',
          target: 2,
          threshold: 1.86,
          stretch: 2.06,
          key: makeId(5)
        },
        {
          category: 'Inclusive Procurement',
          weighting: 2,
          measure: '% of addressable spend',
          target: 15,
          threshold: 13.95,
          stretch: 15.45,
          key: makeId(5)
        },
        {
          category: 'Inclusion & Diversity',
          weighting: 2,
          measure: '% HDP in Management',
          target: 60,
          threshold: 55.8,
          stretch: 61.8,
          key: makeId(5)
        },
        {
          category: 'Volumes moved in rehab activities',
          weighting: 2,
          measure: 'Million m3 per annum placed to rehabilitate opencast pits',
          target: 18.6,
          threshold: 17.3,
          stretch: 19.2,
          key: makeId(5)
        }
      ]
    },
    {
      category: 'Production',
      weighting: 35,
      key: makeId(5),
      children: [
        {
          category: 'ROM Production',
          weighting: 20,
          measure: 'ROM Production',
          target: 4.5,
          threshold: 4.2,
          stretch: 4.6,
          key: makeId(5)
        },
        {
          category: 'On Mine Cash Cost',
          weighting: 15,
          measure: 'On Mine Cash Cost',
          target: 2.031,
          threshold: 2.173,
          stretch: 1.970,
          key: makeId(5)
        }
      ]
    },
    {
      category: 'Asset Finance',
      weighting: 5,
      key: makeId(5),
      children: [
        {
          category: 'Sustaining Capital',
          weighting: 5,
          measure: 'SIB & Stripping and Dev. Capital expenditure (R million)',
          target: 176,
          threshold: 188,
          stretch: 164,
          key: makeId(5)
        }
      ]
    },
    {
      category: 'Company Finance',
      weighting: 10,
      key: makeId(5),
      children: [
        {
          category: 'Adjusted EBITDA',
          weighting: 5,
          measure: 'Adjusted EBITDA (Rm)',
          target: 22.327,
          threshold: 23.890,
          stretch: 20.764,
          key: makeId(5)
        },
        {
          category: 'Adjusted Operating Free Cash Flow',
          weighting: 5,
          measure: 'Adjusted Operating Free Cash Flow (Rm)',
          target: 14.540,
          threshold: 15.558,
          stretch: 13.522,
          key: makeId(5)
        }
      ]
    }
  ]
}
const Mafube = {
  columns: [
    {
      title: 'Performance Category',
      dataIndex: 'category',
      align: 'right',
      key: 'category'
    },
    {
      title: 'Weighting',
      dataIndex: 'weighting',
      align: 'left',
      key: 'weighting'
    },
    {
      title: 'Peformance Measure',
      dataIndex: 'measure',
      align: 'left',
      key: 'measure'
    },
    {
      title: 'On-Target',
      dataIndex: 'target',
      align: 'left',
      key: 'target'
    },
    {
      title: 'Threshold',
      dataIndex: 'threshold',
      align: 'left',
      key: 'threshold'
    },
    {
      title: 'Stretch',
      dataIndex: 'stretch',
      align: 'left',
      key: 'stretch'
    }
  ],
  data: [
    {
      category: 'Safety & Health',
      key: makeId(5),
      weighting: 10,
      children: [
        { key: makeId(5), category: 'TRCFR', weighting: 5, measure: '% Improvement on avg of prev 3 years', target: 1.49, threshold: 1.3857, stretch: 1.53 },
        { key: makeId(5), category: 'HIV & Treatment', weighting: 5, measure: '% of Employees who know their HIV status', target: 95, threshold: 88.35, stretch: 97.85 }
      ]
    },
    {
      category: 'Environment, Social & Governance',
      key: makeId(5),
      weighting: 10,
      children: [
        { key: makeId(5), category: 'Level 4-5 Environmental incidents', weighting: 2, measure: '# of incidents', target: 0, threshold: 1, stretch: 0 },
        { key: makeId(5), category: 'Energy Efficiency', weighting: 2, measure: '% annual improvement against 2025 Target', target: 2, threshold: 1.86, stretch: 2.06 },
        { key: makeId(5), category: 'Inclusive Procurement', weighting: 2, measure: '% of addressable spend', target: 15, threshold: 13.95, stretch: 15.45 },
        { key: makeId(5), category: 'Inclusion & Diversity', weighting: 2, measure: '% HDP in Management', target: 60, threshold: 55.8, stretch: 61.8 },
        { key: makeId(5), category: 'Volumes moved in rehab activities', weighting: 2, measure: 'Million m3 per annum placed to rehabilitate opencast pits', target: 12.1, threshold: 11.3, stretch: 12.5 }
      ]
    },
    {
      category: 'Production',
      key: makeId(5),
      weighting: 35,
      children: [
        { key: makeId(5), category: 'ROM Production', weighting: '20', measure: '# of ex-pit ROM Production (Mt)', target: 2.9, threshold: 2.7, stretch: 3.0 },
        { key: makeId(5), category: 'On Mine Cash Cost', weighting: '15', measure: 'FOR Cash Cost (R million)', target: 1.205, threshold: 1.289, stretch: 1.169 }
      ]
    },
    {
      category: 'Asset Finance',
      key: makeId(5),
      weighting: 5,
      children: [
        {
          key: makeId(5),
          category: 'Sustaining Capital',
          weighting: '5',
          measure: 'SIB & Stripping and Dev. Capital expenditure (R million)',
          target: 158,
          threshold: 169,
          stretch: 147
        }
      ]
    },
    {
      category: 'Company Finance',
      key: makeId(5),
      weighting: 10,
      children: [
        {
          key: makeId(5),
          category: 'Adjusted EBITDA',
          weighting: '5',
          measure: 'Adjusted EBITDA (Rm)',
          target: 22.327,
          threshold: 23.890,
          stretch: 20.764
        },
        {
          key: makeId(5),
          category: 'Adjusted Operating Free Cash Flow',
          weighting: '5',
          measure: 'Adjusted Operating Free Cash Flow (Rm)',
          target: 14.540,
          threshold: 15.558,
          stretch: 13.522
        }
      ]
    }
  ]
}
const Isibonelo = {
  columns: [
    {
      title: 'Performance Category',
      dataIndex: 'category',
      align: 'right',
      key: 'category'
    },
    {
      title: 'Weighting',
      dataIndex: 'weighting',
      align: 'left',
      key: 'weighting'
    },
    {
      title: 'Peformance Measure',
      dataIndex: 'measure',
      align: 'left',
      key: 'measure'
    },
    {
      title: 'On-Target',
      dataIndex: 'target',
      align: 'left',
      key: 'target'
    },
    {
      title: 'Threshold',
      dataIndex: 'threshold',
      align: 'left',
      key: 'threshold'
    },
    {
      title: 'Stretch',
      dataIndex: 'stretch',
      align: 'left',
      key: 'stretch'
    }
  ],
  data: [
    {
      category: 'Safety & Health',
      weighting: 10,
      key: makeId(5),
      children: [
        { key: makeId(5), category: 'TRCFR', weighting: 5, measure: '% Improvement on avg of prev 3 years', target: 2.31, threshold: 2.1483, stretch: 2.38 },
        { key: makeId(5), category: 'HIV & Treatment', weighting: 5, measure: '% of Employees who know their HIV status', target: 95, threshold: 88.35, stretch: 97.85 }
      ]
    },
    {
      category: 'Environment, Social & Governance',
      key: makeId(5),
      weighting: 10,
      children: [
        { key: makeId(5), category: 'Level 4-5 Environmental incidents', weighting: 2, measure: '# of incidents', target: 0, threshold: 1, stretch: 0 },
        { key: makeId(5), category: 'Energy Efficiency', weighting: 2, measure: '% annual improvement against 2025 Target', target: 2, threshold: 1.86, stretch: 2.06 },
        { key: makeId(5), category: 'Inclusive Procurement', weighting: 2, measure: '% of addressable spend', target: 15, threshold: 13.95, stretch: 15.45 },
        { key: makeId(5), category: 'Inclusion & Diversity', weighting: 2, measure: '% HDP in Management', target: 60, threshold: 55.8, stretch: 61.8 },
        { key: makeId(5), category: 'Volumes moved in rehab activities', weighting: 2, measure: 'Million m3 per annum placed to rehabilitate opencast pits', target: 9.9, threshold: 9.2, stretch: 10.2 }
      ]
    },
    {
      category: 'Production',
      key: makeId(5),
      weighting: 35,
      children: [
        { key: makeId(5), category: 'ROM Production', weighting: '20', measure: '# of ex-pit ROM Production (Mt)', target: 4.6, threshold: 4.2, stretch: 4.7 },
        { key: makeId(5), category: 'On Mine Cash Cost', weighting: '15', measure: 'FOR Cash Cost (R million)', target: 1.906, threshold: 2.039, stretch: 1.849 }
      ]
    },
    {
      category: 'Asset Finance',
      key: makeId(5),
      weighting: 5,
      children: [
        {
          key: makeId(5),
          category: 'Sustaining Capital',
          weighting: '5',
          measure: 'SIB & Stripping and Dev. Capital expenditure (R million)',
          target: 71,
          threshold: 76,
          stretch: 66
        }
      ]
    },
    {
      category: 'Company Finance',
      key: makeId(5),
      weighting: 10,
      children: [
        {
          key: makeId(5),
          category: 'Adjusted EBITDA',
          weighting: '5',
          measure: 'Adjusted EBITDA (Rm)',
          target: 22.327,
          threshold: 23.890,
          stretch: 20.764
        },
        {
          key: makeId(5),
          category: 'Adjusted Operating Free Cash Flow',
          weighting: '5',
          measure: 'Adjusted Operating Free Cash Flow (Rm)',
          target: 14.540,
          threshold: 15.558,
          stretch: 13.522
        }
      ]
    }
  ]
}
const feedbackMap = {
  0: 'Not achieved',
  1: 'Achieved',
  2: 'Exceeded'
}
export const performanceTable = {
  base: {
    columns: [
      {
        title: 'Environment, Social & Governance',
        dataIndex: 'name',
        align: 'left',
        key: 'name'
      },
      {
        title: '10',
        dataIndex: 'number',
        align: 'left',
        key: 'number'
      },
      {
        title: '',
        dataIndex: 'percentage',
        align: 'left',
        key: 'percentage'
      },
      {
        title: '',
        key: 'total',
        dataIndex: 'total',
        align: 'left'
      }
    ],
    data: [
      {
        key: makeId(5),
        name: 'Level 4-5 Environmental incidents',
        number: 2.5,
        percentage: '# of incidents',
        total: 0
      },
      {
        key: makeId(5),
        name: 'Energy Efficiency',
        number: 2.5,
        percentage: '% annual improvement against 2025 Target',
        total: 2.5
      },
      {
        key: makeId(5),
        name: 'Inclusive Procurement',
        number: 2.5,
        percentage: '% of addressable spend',
        total: 15
      },
      {
        key: makeId(5),
        name: 'Inclusion & Diversity',
        number: 2.5,
        percentage: '% HDP in Management',
        total: 60
      }
    ]

  },
  final: {
    manager: {
      columns: [
        {
          title: '',
          dataIndex: 'empty',
          key: generateKey('empty')
        },
        {
          title: "Employee's KRAs",
          dataIndex: 'name',
          align: 'left',
          key: generateKey('name')
        },
        {
          title: 'Objective',
          dataIndex: 'objective',
          align: 'left',
          key: generateKey('objective'),
          width: '25%'
        },
        {
          title: 'Weighting',
          dataIndex: 'weighting',
          align: 'left',
          key: generateKey('weighting')
        },
        {
          title: 'Feedback',
          dataIndex: 'rating',
          align: 'left',
          key: generateKey('feedback'),
          render: (value) => (feedbackMap[value])
        },
        {
          title: 'Rating',
          dataIndex: 'rating',
          align: 'left',
          key: generateKey('rating')
        },
        {
          title: "Manager's Comments",
          dataIndex: 'comment',
          align: 'left',
          key: generateKey('comment'),
          width: '35%'
        }
      ]
    },
    employee: {
      columns: [
        {
          title: '',
          dataIndex: 'empty',
          key: generateKey('empty')
        },
        {
          title: 'Key Result Area (KRA)',
          dataIndex: 'name',
          align: 'left',
          key: generateKey('name')
        },
        {
          title: 'Objective',
          dataIndex: 'objective',
          align: 'left',
          key: generateKey('objective'),
          width: '25%'
        },
        {
          title: 'Weighting',
          dataIndex: 'weighting',
          align: 'left',
          key: generateKey('weighting')
        },
        // {
        //   title: 'Feedback',
        //   dataIndex: 'rating',
        //   align: 'left',
        //   key: generateKey('feedback'),
        //   render: (value) => (feedbackMap[value])
        // },
        {
          title: 'Comment',
          dataIndex: 'comment',
          align: 'left',
          key: generateKey('comment'),
          width: '35%'
        }
      ]
    },
    columns: [
      {
        title: '',
        dataIndex: 'empty',
        key: generateKey('empty')
      },
      {
        title: 'Name',
        dataIndex: 'name',
        align: 'left',
        key: generateKey('name')
      },
      {
        title: 'Objective',
        dataIndex: 'objective',
        align: 'left',
        key: generateKey('objective'),
        width: '55%'
      },
      {
        title: 'Weighting',
        dataIndex: 'weighting',
        align: 'left',
        key: generateKey('weighting')
      },
      {
        title: 'Feedback',
        dataIndex: 'rating',
        align: 'left',
        key: generateKey('feedback'),
        render: (value) => (feedbackMap[value])
      },
      {
        title: 'Rating',
        dataIndex: 'rating',
        align: 'left',
        key: generateKey('rating')
      },
      {
        title: 'Comment',
        dataIndex: 'comment',
        align: 'left',
        key: generateKey('comment')
      }
    ]
  },
  kra: {
    columns: [
      {
        title: '',
        dataIndex: 'empty'
      },
      {
        title: 'Name',
        dataIndex: 'name',
        align: 'left',
        key: generateKey('name')
      },
      {
        title: 'Weighting',
        dataIndex: 'weighting',
        align: 'left',
        key: generateKey('weighting')
      },
      {
        title: 'Assessment',
        dataIndex: 'assessment',
        align: 'left',
        key: generateKey('assessment'),
        width: '55%'
      }
    ]
  },
  idp: {
    columns: [
      {
        title: 'Name',
        dataIndex: 'need',
        align: 'left',
        key: generateKey('name')
      },
      {
        title: 'Assessment',
        dataIndex: 'assessment',
        align: 'left',
        key: generateKey('comment')
        // width: '75%'
      }
    ]
  },
  businessObjectives: {
    columns: [
      {
        title: 'Performance Category',
        dataIndex: 'category',
        align: 'right',
        key: 'category'
      },
      {
        title: 'Weighting',
        dataIndex: 'weighting',
        align: 'left',
        key: 'weighting'
      },
      {
        title: 'Peformance Measure',
        dataIndex: 'measure',
        align: 'left',
        key: 'measure'
      },
      {
        title: 'On-Target',
        dataIndex: 'target',
        align: 'left',
        key: 'target'
      },
      {
        title: 'Threshold',
        dataIndex: 'threshold',
        align: 'left',
        key: 'threshold'
      },
      {
        title: 'Stretch',
        dataIndex: 'stretch',
        align: 'left',
        key: 'stretch'
      }
    ],
    data: [
      {
        category: 'Safety & Health',
        key: makeId(5),
        weighting: 10,
        children: [
          { key: makeId(5), category: 'TFCFR', weighting: 5, measure: '% Improvement on avg of prev 3 years', target: 1.37, threshold: 1.2741, stretch: 1.41 },
          { key: makeId(5), category: 'HIV & Treatment', weighting: 5, measure: '% of Employees who know their HIV status', target: 95, threshold: 88.35, stretch: 97.85 }
        ]
      },
      {
        category: 'Environment, Social & Governance',
        key: makeId(5),
        weighting: 10,
        children: [
          { key: makeId(5), category: 'Level 4-5 Environmental incidents', weighting: 2.5, measure: '# of incidents', target: 0, threshold: 1, stretch: 0 },
          { key: makeId(5), category: 'Energy Efficiency', weighting: 2.5, measure: '% annual improvement against 2025 Target', target: 2, threshold: 1.86, stretch: 2.06 },
          { key: makeId(5), category: 'Inclusive Procurement', weighting: 2.5, measure: '% of addressable spend', target: 15, threshold: 13.95, stretch: 15.45 },
          { key: makeId(5), category: 'Inclusion & Diversity', weighting: 2.5, measure: '% HDP in Management', target: 60, threshold: 55.8, stretch: 61.8 }
        ]
      },
      {
        category: 'Production',
        key: makeId(5),
        weighting: 30,
        children: [
          { key: makeId(5), category: 'Export Saleable Production', weighting: '20', measure: '# of Export Saleable Production (Mt)', target: '13.8', threshold: '12.8', stretch: '14.2' },
          { key: makeId(5), category: 'FOB Cost / Export Ton', weighting: '10', measure: 'FOB Cost per Export Ton (R/t)', target: '1,104', threshold: '1,182', stretch: '1,071' }
        ]
      },
      {
        category: 'Finance',
        key: makeId(5),
        weighting: 20,
        children: [
        // category
        // weighting
        // measure
        // target
        // threshold
        // stretch
          {
            key: makeId(5),
            category: 'Adjusted EBITDA',
            weighting: '10',
            measure: 'Adjusted EBITDA (Rm)',
            target: '22,327',
            threshold: '19,424 ',
            stretch: '23,890 '
          },
          {
            key: makeId(5),
            category: 'Adjusted Operating Free Cash Flow',
            weighting: '10',
            measure: 'Adjusted Operating Free Cash Flow (Rm)',
            target: '14,540',
            threshold: '12,650',
            stretch: '15,558'
          }
        ]
      }
    ]
  },
  functionalObjectives: {
    Marketing: marketing,
    Safety: she,
    Environment: she,
    Health: she,
    'S&S': she,
    Audit: finance,
    Finance: finance,
    IM: finance,
    'Legal & Co Sec': finance,
    'Strategy & Business Development': finance,
    'Supply Chain': finance,
    Mining: coo,
    Security: coo,
    'Human Resources': hr,
    'BI & WM': technical,
    'Coal Processing': technical,
    Engineering: technical,
    Projects: technical,
    'Resource Development & Ops Excellence': technical,
    'Corporate Affairs': coporateAffairs,
    Thungela: Thungela,
    'Goedehoop Colliery': Goedehoop,
    'Greenside Colliery': Greenside,
    'Zibulo Colliery': Zibulo,
    'Mafube Colliery': Mafube,
    'Khwezela Colliery': Kwezela,
    'Isibonelo Colliery': Isibonelo,
    'e9b44e5a-7f5c-4db3-b3b6-41c7b35f74e4': finance,
    'f207712d-3eaf-4fb6-859f-16075d48b40a': hr,
    'a7a8ec2b-ce91-40a7-a57e-a4578ff53692': marketing,
    '7a7a9bb3-374d-4f3c-8c61-6877855e2b37': she,
    '2b44b632-7091-47e7-b272-ed4c8a8ab190': technical,
    '60520a89-71c8-4a8b-b47a-59b900012ffa': coo,
    '9dc10028-fdfe-41c8-a362-92fa4a131702': coporateAffairs
  },
  teamObjectives: {
    columns: [
      {
        align: 'left',
        title: 'Key Result Area (Key Results Area)',
        dataIndex: 'kra',
        key: 'kra'
      },
      {
        align: 'left',
        title: 'Description of Key Results Area',
        dataIndex: 'description',
        key: 'description'
      },
      {
        title: 'Weighting',
        dataIndex: 'weighting',
        align: 'left',
        key: 'weighting'
      }
    ]
  }
}
